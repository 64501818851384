import { ApplicationInsightsTrackerContext, TrackerContextBase } from '@nbkc/tracker';
import _ from 'lodash';

export class CommonTrackerContext extends TrackerContextBase {
	public instanceId?: string;
	public tenantId?: string;
	public userId?: string;

	public constructor(props: CommonTrackerContextProps = { }) {
		super();
		_.merge(this, props);
	}

	ai(): ApplicationInsightsTrackerContext {
		const aiContext = new ApplicationInsightsTrackerContext();
		aiContext.userId = this.userId;
		aiContext.instanceId = this.instanceId;
		aiContext.tenantId = this.tenantId;
		return aiContext;
	}
}

export interface CommonTrackerContextProps {
	instanceId?: string;
	tenantId?: string;
	userId?: string;
}

<div *ngIf="!options?.isHidden" class="fixed top-0 left-0 w-full z-10">
	<div class="header-content">
		<img class="w-1/3 md:w-auto ml-6" alt="nbkc logo" src="/assets/nbkc-logo.svg">
		<button tabindex="0" mat-button color="primary" class="mr-6 cursor-pointer p-2 rounded-lg"
			*ngIf="auth.authenticationContext$ | async as authContext" [matMenuTriggerFor]="afterMenu">
			<span class="flex items-center text-black">
				<div class="fal fa-user-circle fa-2x mr-2"></div>
				<div class="hidden md:flex">{{authContext.user?.email}}</div>
			</span>
		</button>
	</div>
	<mat-menu #afterMenu="matMenu" class="userMenu" xPosition="after">
		<button mat-menu-item class="rounded-lg" (click)="goToAvailability()">Start a new application</button>
		<button mat-menu-item class="rounded-lg" [routerLink]="['/logout']">Sign out</button>
		<div class="md:hidden" mat-menu-item *ngIf="auth.authenticationContext$ | async as authContext">
			{{authContext.user?.email}}</div>
	</mat-menu>
</div>
import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
	// eslint-disable-next-line @angular-eslint/directive-selector
	selector: '[blueFinDynamicComponent]'
})
export class DynamicComponentDirective {
	constructor(public view: ViewContainerRef) {
	}
}

<div class="flex justify-center m-4" *ngIf="!isReviewModeActive">
	<button tabindex="0"
		id="element-next-{{id}}"
		class="max-w-xs w-full text-white rounded-full py-3 px-4 shadow-button text-lg"
		[disabled]="disabled"
		mat-raised-button
		color="primary"
		(click)="navigateToNext()"
		data-cy="next-button">{{buttonText$ | async}}</button>
</div>
<div class="flex flex-col justify-center items-center m-4" *ngIf="isReviewModeActive">
	<button tabindex="0" id="element-review-next-{{id}}"
		class="max-w-xs w-full text-white rounded-full py-3 px-4 shadow-button text-lg mb-4" [disabled]="disabled"
		mat-raised-button color="primary" (click)="navigateToNext()">Next</button>
	<button tabindex="0" id="element-go-to-review-{{id}}"
		class="max-w-xs w-full rounded-full py-3 px-4 shadow-none text-lg review-button" [disabled]="disabled"
		mat-button color="primary" (click)="navigateToReview()">Go to Review Disclosures</button>
</div>

export const KnownRoutes = {
	AddApplicants: 'add-applicants',
	ApplicationReview: 'application-review',
	BusinessApplicant: 'business-applicant',
	BusinessExpectations: 'business-expectations',
	DebitCard: 'debit-card',
	Denied: 'denied',
	ApplicantDetails: 'applicant-details',
	ManualReview: 'manual-review',
	OnHold: 'on-hold',
	PrimaryApplicant: 'primary-applicant',
	ProductSelection: 'product-selection',
	ProhibitedBusinesses: 'prohibited-businesses',
	SetupOnlineBanking: 'setup-online-banking',
};

import { computed, Injectable, signal } from '@angular/core';
import { TodoItem } from '@common/lib/models/todo-item';
import { personalTodoItems } from '@experience/app/models-ui/todo-items/personal-todo-items.model';
import { businessTodoItems } from '@experience/app/models-ui/todo-items/business-todo-items.model';
import { Progress } from '@experience/app/models/onboarding/progress.model';
import { LookupGroup } from '@experience/app/models/tenant/lookup-group.model';
import { LookupItem } from '@experience/app/models/tenant/lookup-item.model';
import { Product } from '@experience/app/models/tenant/product.model';

@Injectable({
	providedIn: 'root',
})
export class InternalStateService {
	private readonly state = {
		$isUpconverted: signal<boolean>(true),
		$todoItems: signal<TodoItem[]>([]),
		$applicationKind: signal<string>(''),
		$progress: signal<Progress>({} as Progress),
		$lookups: signal<Record<string, LookupItem[]>>({} as Record<string, LookupItem[]>),
		$products: signal<Product[]>([]),
		$currentScreen: signal<string>(undefined),
	};

	readonly $isOldExperience = this.state.$isUpconverted.asReadonly(); // isUpConverted
	readonly $todoItems = this.state.$todoItems.asReadonly();
	readonly $applicationKind = this.state.$applicationKind.asReadonly();
	readonly $progress = this.state.$progress.asReadonly();
	readonly $lookups = this.state.$lookups.asReadonly();
	readonly $products = this.state.$products.asReadonly();
	readonly $isPersonal = computed(() => this.state.$applicationKind() === 'Personal');
	readonly $currentScreen = this.state.$currentScreen.asReadonly();

	constructor() {}

	setTodoItems(todoItems: TodoItem[]) {
		this.state.$todoItems.set([...todoItems]);
	}

	setIsOldExperience(isOldExperience: boolean) {
		this.state.$isUpconverted.set(isOldExperience);
	}

	setApplicationKind(kind: string) {
		this.state.$applicationKind.set(kind);
		if (this.$isPersonal()) {
			this.state.$todoItems.set([...personalTodoItems]);
			return;
		}
		this.state.$todoItems.set([...businessTodoItems]);
	}

	setProgress(progress: Progress) {
		this.state.$progress.set(progress);
	}

	setLookups(lookupGroups: LookupGroup[]): void {
		const lookups: Record<string, LookupItem[]> = {};
		lookupGroups.forEach((lookup: LookupGroup) => {
			//Remove "Personal Experience" or "Business Experience" to simplify
			if (lookup.name.includes('Experience')) {
				const name = lookup.name.slice(20);
				lookups[name] = lookup.items;
			}
		});
		this.state.$lookups.set(lookups);
	}

	setProducts(products: Product[]): void {
		this.state.$products.set(products);
	}

	setCurrentScreen(currentScreen: string): void {
		this.state.$currentScreen.set(currentScreen);
	}
}

import { Component } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ContainerComponent } from '@common/lib/components/containers/container/container.component';
import { DynamicComponentBase } from '@common/lib/components/dynamic/dynamic-component.base';
import { Observable } from 'rxjs';
import { ModalContainerComponent } from '@common/lib/components/containers/modal-container/modal-container.component';
import { FormField } from '@common/lib/models/form-field.model';
import { CommonFormService } from '@common/lib/services/common-form.service';

@Component({
	selector: 'common-modal-button',
	templateUrl: './modal-button.component.html',
	styleUrls: ['./modal-button.component.scss']
})

export class ModalButtonComponent extends DynamicComponentBase<ModalButtonComponentFields, ModalButtonComponentOptions> {
	public buttonText$: Observable<string>;
	public disabled: boolean = false;
	public container: ContainerComponent = null;

	constructor(public formService: CommonFormService, public matDialog: MatDialog) {
		super();
	}

	public onComponentLoaded(): void {
		this.buttonText$ = this.formService.getModelValue$<string>(this.fields?.buttonTextPath?.modelPaths, this.options?.buttonTextValue);
		this.disabled = this.options?.disabled === 'true';
	}

	public openModal(): void {
		const dialogConfig = new MatDialogConfig();
		dialogConfig.disableClose = true;
		dialogConfig.id = 'modal-component';
		dialogConfig.height = this.options?.modalHeight ?? '350px';
		dialogConfig.width = this.options?.modalWidth ?? '600px';
		dialogConfig.data = {
			components: this.components,
			options: this.options
		};
		this.matDialog.open(ModalContainerComponent, dialogConfig);
	}
}

interface ModalButtonComponentFields extends Record<string, FormField> {
	buttonTextPath: FormField;

}

interface ModalButtonComponentOptions {
	modalHeight: string;
	modalWidth: string;
	disabled: string;
	buttonTextValue: string;

}

import { ChangeDetectionStrategy, Component, input, OnInit } from '@angular/core';
import { MaskedInputComponent } from '@experience/app/components-new/masked-input/masked-input.component';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { TaxIdentificationFormGroup } from '@experience/app/components-new/individual-applicant/individual-applicant-form/individual-applicant-form.group';
import { FormGroup, ReactiveFormsModule, ValidationErrors, ValidatorFn } from '@angular/forms';
import { FormControlPipe } from '@experience/app/shared/pipes/form-control-pipe/form-control.pipe';

@Component({
	selector: 'app-tax-identification',
	standalone: true,
	imports: [MaskedInputComponent, MatError, ReactiveFormsModule, MatFormField, FormControlPipe, MatLabel],
	templateUrl: './tax-identification.component.html',
	styleUrl: './tax-identification.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaxIdentificationComponent implements OnInit {
	$applicantNameOrDefault = input<string>('');
	$taxIdentificationFormGroup = input<FormGroup<TaxIdentificationFormGroup>>(
		{} as FormGroup<TaxIdentificationFormGroup>,
	);
	$isSSN = input<boolean>(true);

	taxIdNumberValidator: ValidatorFn = (taxIdentification: FormGroup): ValidationErrors | null => {
		if (
			taxIdentification.get('number').value !== taxIdentification.get('numberConfirmation').value &&
			taxIdentification.get('numberConfirmation').value !== ''
		) {
			taxIdentification.get('numberConfirmation').setErrors({ taxIdNumberMismatch: true });
			taxIdentification.get('number').setErrors({ taxIdNumberMismatch: true });
		}
		return null;
	};

	ngOnInit() {
		this.$taxIdentificationFormGroup().setValidators(this.taxIdNumberValidator);
		this.$taxIdentificationFormGroup().updateValueAndValidity();
	}
}

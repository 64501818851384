<!-- See the spec.ts file before using, this will need to be refactored do to mat-chip-list being deprecated in favor of mat-chip-listbox -->
<div class="pt-4">
	<form [formGroup]="internalForm">
		<mat-form-field class="w-full" appearance="outline">
			<mat-label>{{internalForm.value.label}}</mat-label>
			<mat-chip-listbox aria-label="selection">
				<mat-chip-option class="cursor-pointer radio-chip" *ngFor="let option of chipOptions$ | async" (superClick)="selectOption(option)"
					[selected]="getValue(option) === selectedValue" [selectable]="true">
					{{getDisplay(option)}}
				</mat-chip-option>
			</mat-chip-listbox>
		</mat-form-field>
	</form>
</div>

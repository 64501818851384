import { Injector } from '@angular/core';
import { LegacyRouteMonitor } from '../legacy-route-monitor';
import { AppRouteMonitor } from '../app-route-monitor';

export const startRouteMonitor = (injector: Injector): Promise<void> =>
	new Promise((resolve) => {
		injector.get(LegacyRouteMonitor).start();
		injector.get(AppRouteMonitor).start();
		resolve();
	});

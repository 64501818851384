<form [formGroup]="applicantForm">
	<div class="flex flex-col gap-4">
		<app-divider classes="mt-4 mb-4" text="Basic Information"></app-divider>
		<mat-form-field>
			<mat-label>First Name</mat-label>
			<input formControlName="firstName" matInput required data-cy="input-field" />
			@if (applicantForm.get('firstName').hasError('required')) {
				<mat-error>Please include {{ $applicantNameOrDefault() }}'s first name.</mat-error>
			}
			@if (applicantForm.get('firstName').hasError('maxLength')) {
				<mat-error>{{ $applicantNameOrDefault() }}'s first name must be 40 characters or less.</mat-error>
			}
		</mat-form-field>
		<mat-form-field>
			<mat-label>Middle Name</mat-label>
			<input formControlName="middleName" matInput data-cy="input-field" />
			@if (applicantForm.get('middleName').hasError('maxLength')) {
				<mat-error>{{ $applicantNameOrDefault() }}'s middle name must be 40 characters or less.</mat-error>
			}
		</mat-form-field>
		<mat-form-field>
			<mat-label>Last Name</mat-label>
			<input formControlName="lastName" matInput required data-cy="input-field" />
			@if (applicantForm.get('lastName').hasError('required')) {
				<mat-error>Please include {{ $applicantNameOrDefault() }}'s last name.</mat-error>
			}
			@if (applicantForm.get('lastName').hasError('maxLength')) {
				<mat-error>{{ $applicantNameOrDefault() }}'s last name must be 40 characters or less.</mat-error>
			}
		</mat-form-field>
		<mat-form-field>
			<mat-label>{{ $isPersonal() ? 'Email' : 'Personal Email' }}</mat-label>
			<app-masked-input
				[formControl]="applicantForm.get('email') | formControl"
				maskType="email"
				[required]="true"
				data-cy="input-field"
			>
			</app-masked-input>
			@if (applicantForm.get('email').hasError('required') || applicantForm.get('email').hasError('mask')) {
				<mat-error>Please include a valid email for {{ $applicantNameOrDefault() }}.</mat-error>
			}
		</mat-form-field>
		<mat-form-field>
			<mat-label>Phone Number</mat-label>
			<app-masked-input
				[formControl]="applicantForm.get('phoneNumber') | formControl"
				maskType="phone"
				[required]="true"
				data-cy="input-field"
				(blur)="saveApplication()"
			>
			</app-masked-input>
			<mat-hint
				>This phone number will be used if you need to activate your debit card or reset your
				password.</mat-hint
			>
			@if (applicantForm.get('phoneNumber').hasError('required')) {
				<mat-error>Please include {{ $applicantNameOrDefault() }}'s phone number.</mat-error>
			}
			@if (applicantForm.get('phoneNumber').hasError('mask')) {
				<mat-error>Please include a valid phone number for {{ $applicantNameOrDefault() }}.</mat-error>
			}
		</mat-form-field>

		@if (!$isPersonal()) {
			<app-divider classes="mt-4 mb-4" text="Business Role"></app-divider>

			<div formGroupName="businessRoleDetails">
				<mat-form-field>
					<mat-label>Primary Role</mat-label>
					<input formControlName="businessRole" matInput required data-cy="input-field" />
					@if ($businessRoleDetails().get('businessRole').hasError('required')) {
						<mat-error>Please enter a primary role with the business.</mat-error>
					}
				</mat-form-field>

				<div class="mb-4 flex justify-between">
					<div>
						<mat-label class="mr-auto toggle-label">Do you own 25% or more of this company?</mat-label>
					</div>
					<div>
						<mat-label class="mx-3 toggle-label">No</mat-label>
						<mat-slide-toggle formControlName="isBeneficialOwner" class="nbkc-blue" data-cy="toggle-field">
						</mat-slide-toggle>
						<mat-label class="mx-3 toggle-label">Yes</mat-label>
					</div>
				</div>

				<div class="mb-4 flex justify-between">
					<div>
						<mat-label class="mr-auto toggle-label"
							>Will you be authorized to sign on this account?</mat-label
						>
					</div>
					<div>
						<mat-label class="mx-3 toggle-label">No</mat-label>
						<mat-slide-toggle formControlName="isAuthorizedSigner" class="nbkc-blue" data-cy="toggle-field">
						</mat-slide-toggle>
						<mat-label class="mx-3 toggle-label">Yes</mat-label>
					</div>
				</div>
			</div>
		}

		<app-divider classes="mt-4 mb-4" text="Address"></app-divider>
		<div formArrayName="addresses">
			<!--		Physical Address  -->
			<app-address-form [$addressForm]="getAddressGroup(0)" [$applicantNameOrDefault]="$applicantNameOrDefault()">
			</app-address-form>

			<div class="flex justify-between mt-4 mb-4">
				<div>
					<mat-label class="toggle-label">Is this also the mailing address?</mat-label>
				</div>
				<div class="'text-base flex justify-end mb-4'">
					<mat-label class="mx-3 toggle-label">No</mat-label>
					<!--				standalone: true keeps toggle out of form controls, tabIndex allows tabbing to this-->
					<mat-slide-toggle
						[(ngModel)]="$usePhysicalAddressAsMailingAddress"
						(ngModelChange)="onChangeAddressType()"
						[ngModelOptions]="{ standalone: true }"
						tabIndex="0"
						class="nbkc-blue"
						data-cy="toggle-field"
					>
					</mat-slide-toggle>
					<mat-label class="mx-3 toggle-label">Yes</mat-label>
				</div>
			</div>

			<!--		Mailing Address -->
			@if (!$usePhysicalAddressAsMailingAddress()) {
				<app-address-form
					[$addressForm]="getAddressGroup(1)"
					[$applicantNameOrDefault]="$applicantNameOrDefault()"
				>
				</app-address-form>
			}
		</div>

		<app-divider classes="mt-4 mb-4" text="Identity"></app-divider>
		<div [formGroup]="identification">
			<mat-form-field>
				<mat-label>Primary ID Type</mat-label>
				<mat-select formControlName="type" required data-cy="select-field">
					<mat-option [value]="IdentificationType.DriversLicense">U.S. Driver's License</mat-option>
					<mat-option [value]="IdentificationType.Passport">Passport</mat-option>
					<mat-option [value]="IdentificationType.StateIdCard">U.S. State ID Card</mat-option>
				</mat-select>
				@if ($identification().get('type').hasError('required')) {
					<mat-error>Please select an identification type for {{ $applicantNameOrDefault() }}.</mat-error>
				}
			</mat-form-field>
			@switch ($idType()) {
				@case (IdentificationType.Passport) {
					<mat-form-field>
						<mat-label>Passport ID Number</mat-label>
						<input formControlName="number" matInput required [attr.tabindex]="0" data-cy="input-field" />
						@if ($identification().get('number').hasError('required')) {
							<mat-error>Please include {{ $applicantNameOrDefault() }}'s passport number.</mat-error>
						}
						@if ($identification().get('number').hasError('maxLength')) {
							<mat-error
								>{{ $applicantNameOrDefault() }}'s passport number must be 25 characters or
								less.</mat-error
							>
						}
					</mat-form-field>
					<mat-form-field>
						<mat-label>Passport Expiration Date</mat-label>
						<app-masked-input
							[formControl]="$identification().get('expirationDate') | formControl"
							maskType="date"
							[required]="true"
							data-cy="input-field"
						>
						</app-masked-input>
						@if ($identification().get('expirationDate').hasError('required')) {
							<mat-error
								>Please include {{ $applicantNameOrDefault() }}'s passport expiration date.</mat-error
							>
						}
						@if ($identification().get('expirationDate').hasError('expiredId')) {
							<mat-error>{{ $applicantNameOrDefault() }}'s passport must not be expired.</mat-error>
						}
					</mat-form-field>
					<mat-form-field>
						<mat-label>Passport Issuing Country</mat-label>
						<mat-select
							formControlName="issuingCountry"
							required
							[attr.tabindex]="0"
							data-cy="select-field"
						>
							@for (country of $lookups().Countries; track country.value) {
								<mat-option [value]="country.value">{{ country.display }}</mat-option>
							}
						</mat-select>
						@if ($identification().get('issuingCountry').hasError('required')) {
							<mat-error
								>Please include the issuing country for {{ $applicantNameOrDefault() }}'s
								passport.</mat-error
							>
						}
					</mat-form-field>
				}
				@case (IdentificationType.DriversLicense) {
					<mat-form-field>
						<mat-label>Driver's License Issuing State</mat-label>
						<mat-select formControlName="issuingState" required [attr.tabindex]="0" data-cy="select-field">
							@for (state of $lookups().States; track state.value) {
								<mat-option [value]="state.value">{{ state.display }}</mat-option>
							}
						</mat-select>
						@if ($identification().get('issuingState').hasError('required')) {
							<mat-error
								>Please select a state for {{ $applicantNameOrDefault() }}'s driver's
								license.</mat-error
							>
						}
					</mat-form-field>
					<mat-form-field>
						<mat-label>Driver's License Number</mat-label>
						<input formControlName="number" matInput required [attr.tabindex]="0" data-cy="input-field" />
						@if ($identification().get('number').hasError('required')) {
							<mat-error
								>Please include {{ $applicantNameOrDefault() }}'s driver's license number.</mat-error
							>
						}
						@if ($identification().get('number').hasError('maxLength')) {
							<mat-error
								>{{ $applicantNameOrDefault() }}'s driver's license number must be 25 characters or
								less.</mat-error
							>
						}
					</mat-form-field>
					<mat-form-field>
						<mat-label>Driver's License Expiration Date</mat-label>
						<app-masked-input
							[formControl]="$identification().get('expirationDate') | formControl"
							maskType="date"
							[required]="true"
							data-cy="input-field"
						>
						</app-masked-input>
						@if ($identification().get('expirationDate').hasError('required')) {
							<mat-error
								>Please include {{ $applicantNameOrDefault() }}'s driver's license expiration
								date.</mat-error
							>
						}
						@if ($identification().get('expirationDate').hasError('expiredId')) {
							<mat-error
								>{{ $applicantNameOrDefault() }}'s driver's license must not be expired.</mat-error
							>
						}
					</mat-form-field>
				}
				@case (IdentificationType.StateIdCard) {
					<mat-form-field>
						<mat-label>ID Issuing State</mat-label>
						<mat-select formControlName="issuingState" [attr.tabindex]="0" required data-cy="select-field">
							@for (state of $lookups().States; track state.value) {
								<mat-option [value]="state.value">{{ state.display }}</mat-option>
							}
						</mat-select>
						@if ($identification().get('issuingState').hasError('required')) {
							<mat-error
								>Please select a state for {{ $applicantNameOrDefault() }}'s state id card.</mat-error
							>
						}
					</mat-form-field>
					<mat-form-field>
						<mat-label>ID Number</mat-label>
						<input formControlName="number" matInput [attr.tabindex]="0" required data-cy="input-field" />
						@if ($identification().get('number').hasError('required')) {
							<mat-error
								>Please include {{ $applicantNameOrDefault() }}'s state identification card
								number.</mat-error
							>
						}
						@if ($identification().get('number').hasError('maxLength')) {
							<mat-error
								>{{ $applicantNameOrDefault() }}'s state id card number must be 25 characters or
								less.</mat-error
							>
						}
					</mat-form-field>
					<mat-form-field>
						<mat-label>State ID Expiration Date</mat-label>
						<app-masked-input
							[formControl]="$identification().get('expirationDate') | formControl"
							maskType="date"
							[required]="true"
							data-cy="input-field"
						>
						</app-masked-input>
						@if ($identification().get('expirationDate').hasError('required')) {
							<mat-error
								>Please include {{ $applicantNameOrDefault() }}'s state id expiration date.</mat-error
							>
						}
						@if ($identification().get('expirationDate').hasError('expiredId')) {
							<mat-error>{{ $applicantNameOrDefault() }}'s identification must not be expired.</mat-error>
						}
					</mat-form-field>
				}
			}
		</div>
		<mat-form-field>
			<mat-label>Date of Birth</mat-label>
			<app-masked-input
				[formControl]="applicantForm.get('dateOfBirth') | formControl"
				maskType="date"
				[required]="true"
				data-cy="input-field"
			>
			</app-masked-input>
			@if (
				applicantForm.get('dateOfBirth').hasError('required') ||
				applicantForm.get('dateOfBirth').hasError('maxAge')
			) {
				<mat-error>Please include a valid date of birth for {{ $applicantNameOrDefault() }}.</mat-error>
			}
		</mat-form-field>
		<div formGroupName="taxIdentification">
			<app-tax-identification
				[$taxIdentificationFormGroup]="$taxIdentification()"
				[$applicantNameOrDefault]="$applicantNameOrDefault()"
			></app-tax-identification>
		</div>
		<mat-form-field>
			<mat-label>Mother's Maiden Name</mat-label>
			<input
				formControlName="mothersMaidenName"
				(blur)="saveApplication()"
				matInput
				required
				data-cy="input-field"
			/>
			@if (applicantForm.get('mothersMaidenName').hasError('required')) {
				<mat-error>Please include {{ $applicantNameOrDefault() }}'s mother's maiden name.</mat-error>
			}
			@if (applicantForm.get('mothersMaidenName').hasError('maxLength')) {
				<mat-error
					>{{ $applicantNameOrDefault() }}'s mother's maiden name must be 40 characters or less.</mat-error
				>
			}
		</mat-form-field>

		<app-divider classes="mt-4 mb-4" text="Additional Details"></app-divider>

		<mat-form-field>
			<mat-label>What is your primary income source?</mat-label>
			<mat-select formControlName="incomeSource" required data-cy="select-field">
				@for (source of $lookups().IncomeSources; track source.value) {
					<mat-option [value]="source.value">{{ source.display }}</mat-option>
				}
			</mat-select>
			@if (applicantForm.get('incomeSource').hasError('required')) {
				<mat-error>Please include {{ $applicantNameOrDefault() }}'s income source.</mat-error>
			}
		</mat-form-field>
		<mat-form-field>
			<mat-label>What is your occupation?</mat-label>
			<mat-select formControlName="occupation" required data-cy="select-field">
				@for (occupation of $lookups().Occupations; track occupation.value) {
					<mat-option [value]="occupation.value">{{ occupation.display }}</mat-option>
				}
			</mat-select>
			@if (applicantForm.get('occupation').hasError('required')) {
				<mat-error>Please include {{ $applicantNameOrDefault() }}'s occupation.</mat-error>
			}
		</mat-form-field>

		@if (!$isPersonal() && $isPrimaryApplicant()) {
			<div class="form-group flex-column mb-4">
				<div class="flex">
					<mat-checkbox
						[formControl]="$isControlIndividual()"
						class="big-checkbox wrap-checkbox-text"
						required
						data-cy="check-field"
					></mat-checkbox>
					<label class="wrap-text">
						I confirm that I am the Controlling Manager and I have the right to perform financial
						transactions and activity for this business.
					</label>
				</div>
				@if ($isControlIndividual().touched && $isControlIndividual().hasError('required')) {
					<div class="custom-error">
						Please indicate whether or not {{ $applicantNameOrDefault() }} is a control individual.
					</div>
				}
			</div>
		}
		<div class="form-group flex-column mb-4">
			<div class="flex">
				<mat-checkbox
					formControlName="consentsToTaxRequirements"
					class="big-checkbox wrap-checkbox-text"
					required
					data-cy="check-field"
				></mat-checkbox>

				<label>
					I confirm that:
					<ul class="list-disc list-inside">
						<li>I am a U.S. citizen or Resident Alien.</li>
						<li>I reside in the U.S. or U.S. territory for the majority of each calendar year.</li>
						<li>I am not subject to backup withholding by the IRS.</li>
					</ul>
				</label>
			</div>
			@if (
				applicantForm.get('consentsToTaxRequirements').touched &&
				applicantForm.get('consentsToTaxRequirements').hasError('required')
			) {
				<div class="custom-error">
					Please verify residency and tax status for {{ $applicantNameOrDefault() }}.
				</div>
			}
		</div>
	</div>
</form>

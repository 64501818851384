import { Component, input, output } from '@angular/core';
import { AsyncPipe, DecimalPipe, NgClass } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatIcon } from '@angular/material/icon';
import { MatButton } from '@angular/material/button';

@Component({
	selector: 'app-card',
	standalone: true,
	imports: [MatCardModule, NgClass, MatIcon, AsyncPipe, DecimalPipe, MatButton],
	templateUrl: './app-card.component.html',
	styleUrl: './app-card.component.scss',
})
export class AppCardComponent {
	title = input<string>('');
	subtitle = input<string>('');
	secondSubtitle = input<string>('');
	headerIcon = input<string>(''); // Font Awesome icon class
	headerIconColor = input<string>('blue');
	hasCornerBanner = input<boolean>(false);
	headerRate = input<string>('');
	hideHeader = input<boolean>(false);
	xlHeader = input<boolean>(false);
	bodyText = input<string>('');
	cardActionInfo = input<string>('');
	cardActions = input<string[]>([]);
	actionClicked = output<string>();

	onCardActionClicked(event: string): void {
		this.actionClicked.emit(event);
	}
}

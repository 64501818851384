import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApplyEntryPointToApplyEntryPointResourceAdapter } from '@common/lib/adapters/apply-entry-point-to-apply-entry-point-resource.adapter';
import { NavigateRequestAdapter } from '@common/lib/adapters/navigate-request.adapter';
import { ScreenResourceToPresentationDefinitionAdapter } from '@common/lib/adapters/screen-resource-to-presentation-definition.adapter';
import { KnownValues } from '@common/lib/constants/known-values';
import { RouteType } from '@common/lib/models/enum/route-type.enum';
import { PresentationDefinition } from '@common/lib/models/presentation-definition';
import { ApplyFlowRequestResource } from '@common/lib/models/resource/apply-flow-request-resource';
import { ModelCheckpointResource } from '@common/lib/models/resource/events/client/model-checkpoint-resource';
import { TodoItem } from '@common/lib/models/todo-item';
import { NavigateRequest } from '@common/lib/models/navigate-request.model';
import _ from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import {
	debounce,
	debounceTime,
	distinctUntilChanged,
	filter,
	first,
	map,
	mergeMap,
	startWith,
	switchMap,
	tap,
} from 'rxjs/operators';
import { ConditionResourceAdapter } from '@experience/app/adapters/condition-resource.adapter';
import { ReviewDataResourceAdapter } from '@experience/app/adapters/review-data-resource.adapter';
import { AppSession } from '@experience/app/app.session';
import { ExperienceClient } from '@experience/app/clients/experience.client';
import { ClientContext } from '@experience/app/models/client-context';
import { ModelStateService } from '@experience/app/services/model-state.service';
import { LegacyRoutingService } from '@experience/app/services/legacy-routing.service';
import { applyPatch, compare } from 'fast-json-patch';
import { LoadingService } from '@experience/app/services/loading.service';
import { setCurrentExperienceActivity, submitApplicationActivity } from '@common/lib/constants/activities';
import { OnboardResource } from '@common/lib/models/resource/events/client/onboard-resource';
import { OnboardRequest } from '@common/lib/models/onboard-request.model';
import { Tracker } from '@nbkc/tracker-angular';
import { NavigationBlockedEvent } from '../tracking/events/navigation-blocked.event';
import { NavigationError } from '../tracking/events/navigation.error';
import { ExperienceDefinitionResource } from '@common/lib/models/resource/experience-definition.resource';

@Injectable({
	providedIn: 'root',
})
export class PresentationService {
	public isReviewModeActive: boolean;
	public todoList$ = new BehaviorSubject<TodoItem[]>([]);
	public currentExperience: ExperienceDefinitionResource;
	public presentationDefinitions$ = new BehaviorSubject<PresentationDefinition[]>([]);
	public currentPresentationDefinition$ = new BehaviorSubject<PresentationDefinition>(undefined);
	public isProcessingApplicationUpdate = new BehaviorSubject(false);
	public applicationId: string;
	public isReady$ = new BehaviorSubject(true);
	private screenStates: Record<string, any> = {};

	constructor(
		private routingService: LegacyRoutingService,
		private session: AppSession,
		private experienceClient: ExperienceClient,
		private router: Router,
		private modelStateService: ModelStateService,
		private loadingService: LoadingService,
		private tracker: Tracker,
	) {}

	public subscribeToApplicationUpdates() {
		let waitingUpdates = false;

		this.modelStateService.modelState$
			.pipe(
				filter((modelState) => !_.isEmpty(modelState)),
				filter((modelState) => !modelState.shouldNotSendUpdateToApi),
				distinctUntilChanged((prev, curr) => _.isEqual(prev, curr)),
				tap(() => {
					this.isReady$.next(false);
					waitingUpdates = true;
				}),
				debounceTime(2000),
				debounce(() => this.isProcessingApplicationUpdate.pipe(first((isActive) => isActive === false))),
				tap(() => {
					this.isProcessingApplicationUpdate.next(true);
					waitingUpdates = false;
				}),
				mergeMap((modelState) => this.updateApplication(modelState)),
				tap(() => {
					this.isProcessingApplicationUpdate.next(false);
					if (!this.isProcessingApplicationUpdate.getValue() && !waitingUpdates) {
						this.isReady$.next(true);
					}
				}),
			)
			.subscribe();
	}

	public resetExperience(): void {
		this.currentExperience = null;
		this.applicationId = null;
		this.screenStates = {};
		this.currentPresentationDefinition$.next(null);
		this.presentationDefinitions$.next([]);
		this.todoList$.next([]);
		this.routingService.setCurrentRoute(null);

		this.routingService.setInitialRoute('');

		const sessionContextUpdate = new ClientContext();
		sessionContextUpdate.applicationId = null;
		sessionContextUpdate.appName = null;
		sessionContextUpdate.appVersion = null;
		sessionContextUpdate.sessionParams = { applicationId: null };
		this.session.updateSession(sessionContextUpdate);

		this.disableReviewMode();
	}

	public disableReviewMode(): void {
		this.isReviewModeActive = false;
	}

	public enableReviewMode(): void {
		this.isReviewModeActive = true;
	}

	public getCurrentPresentationDefinition(): PresentationDefinition {
		const targetDefinition = this.presentationDefinitions$
			.getValue()
			?.find((definition) => definition.route === this.routingService.currentRoute);
		return targetDefinition;
	}

	public navigateByRouteType(routeType: RouteType) {
		let fullRoute: string;
		return this.isReady$
			.pipe(
				startWith(this.isReady$.getValue()),
				first((isReady) => isReady),
				tap(() => {
					fullRoute = `${KnownValues.workflowPath}${this.routingService.getRoute(routeType, this.presentationDefinitions$.getValue())}`;

					if (!fullRoute) {
						this.tracker.event(new NavigationError(routeType.toString()));
					}
				}),
				switchMap(() => this.navigateToURL$(fullRoute)),
			)
			.subscribe();
	}

	public navigateToURL$(fullRoute: string) {
		const navigateStoreEvent = new NavigateRequest(fullRoute);
		const requestBody = new ApplyFlowRequestResource();
		const navigateStoreEventToNavigateEventResourceAdapter = new NavigateRequestAdapter();

		requestBody.presentationEvent = navigateStoreEventToNavigateEventResourceAdapter.adapt(navigateStoreEvent);

		if (navigateStoreEvent.target !== '') {
			return this.isReady$.pipe(
				startWith(this.isReady$.getValue()),
				first((isReady) => isReady),
				switchMap(() => this.experienceClient.flow$(requestBody, this.applicationId)),
				map((response) => {
					if (!response || _.isEmpty(response)) {
						return;
					}

					if (response.progress?.visitedScreens) {
						const visitedScreens = response.progress.visitedScreens;
						const completedTodos = this.todoList$
							.getValue()
							.filter((todoItem) => visitedScreens.includes(todoItem.associatedRoute));
						completedTodos?.forEach((todoItem) => (todoItem.complete = true));
					}

					const todoItems = this.todoList$.getValue();
					todoItems.forEach((todoItem) => {
						if (response.progress.visitedScreens.includes(todoItem.associatedRoute)) {
							todoItem.complete = true;
						}
					});

					this.todoList$.next(todoItems);

					return response;
				}),
				switchMap(() => this.router.navigateByUrl(fullRoute)),
				tap(() => {
					this.loadingService.stopLoadingActivity(setCurrentExperienceActivity);
				}),
			);
		}
	}

	public submitApplication() {
		this.updateApplication(this.modelStateService.modelState$.getValue(), true).subscribe({
			next: () => {
				this.navigateByRouteType(RouteType.next);
				this.disableReviewMode();
				this.loadingService.stopLoadingActivity(submitApplicationActivity);
			},
			error: () => {
				this.loadingService.stopAllLoadingActivities();
			},
		});
	}

	public startExperience(definition): Observable<void> {
		const applyEntryPointToApplyEntryPointResourceAdapter = new ApplyEntryPointToApplyEntryPointResourceAdapter();

		const target = applyEntryPointToApplyEntryPointResourceAdapter.adapt(definition);
		return this.experienceClient.start$(target).pipe(
			map((response) => {
				if (!response || _.isEmpty(response)) {
					return;
				}

				this.currentExperience = response.snapshot?.experience;

				this.updateSessionContext(response.snapshot?.applicationId);
				this.updateApplicationId(response.snapshot?.applicationId);
				this.updateRoutingData(response, true);
				if (response.snapshot?.model) {
					this.updateReviewData(response.snapshot.model.reviewData);
					this.updateConditions(response.snapshot.model.conditions);
					this.updateModelState(
						response.snapshot.model.application,
						response.snapshot.model.lookups,
						response.snapshot.model.context,
					);
				}
				this.navigateByRouteType(RouteType.initial);
				return;
			}),
		);
	}

	public onboardApplication(onboardEvent: OnboardRequest) {
		const requestBody = new ApplyFlowRequestResource();
		const onboardResource = new OnboardResource();
		onboardResource.username = onboardEvent.username;
		onboardResource.password = onboardEvent.password;
		requestBody.presentationEvent = onboardResource;

		return this.experienceClient.flow$(requestBody, this.applicationId).pipe(
			map((response) => {
				if (!response || _.isEmpty(response)) {
					throw new Error();
				}

				this.updateApplication(response.snapshot?.applicationId);
				this.updateRoutingData(response, false);

				if (response.snapshot?.model) {
					this.updateReviewData(response.snapshot.model.reviewData);
					this.updateConditions(response.snapshot.model.conditions);
					this.updateModelState(
						response.snapshot.model.application,
						response.snapshot.model.lookups,
						response.snapshot.model.context,
					);
				}

				return;
			}),
		);
	}

	public changeCurrentScreen(fullRoute: string): void {
		const routePath = fullRoute?.replace(KnownValues.workflowPath, '');
		const targetDefinition = this.presentationDefinitions$
			.getValue()
			?.find((definition) => definition.route === routePath);
		let allowedRoute: boolean;
		if (this.routingService.currentRoute) {
			const currentRouteDefinition = this.getCurrentPresentationDefinition();
			if (currentRouteDefinition) {
				allowedRoute = currentRouteDefinition.availableRoutes?.some((route) => route === `${routePath}`);

				if (!this.screenStates[currentRouteDefinition.name]) {
					this.screenStates[currentRouteDefinition.name] = {};
				}
				this.screenStates[currentRouteDefinition.name].visited = true;
			}
		} else {
			allowedRoute = true;
		}

		if (targetDefinition && allowedRoute) {
			this.routingService.setCurrentRoute(routePath);
			this.currentPresentationDefinition$.next(
				this.presentationDefinitions$
					.getValue()
					.find((currentDefinition) => currentDefinition.route === routePath),
			);
		} else {
			this.tracker.event(new NavigationBlockedEvent(this.routingService.currentRoute, routePath));
		}
	}

	public updateApplicationId(applicationId) {
		if (applicationId) {
			this.applicationId = applicationId;
		}
	}

	public updateRoutingData(response, isInitial) {
		if (response.snapshot?.experience) {
			const screenResourceToPresentationDefinitionAdapter = new ScreenResourceToPresentationDefinitionAdapter();
			const adapterOptions: any = {};

			const availableRoutes = response.snapshot.experience.availableRoutes;
			adapterOptions.availableRoutes = availableRoutes;

			const experience = screenResourceToPresentationDefinitionAdapter.adaptCollection(
				response.snapshot.experience?.screens,
				adapterOptions,
			);

			this.presentationDefinitions$.next(experience);

			const initialRoute = this.determineInitialRoute(response.snapshot.experience);

			this.routingService.setInitialRoute(initialRoute);

			const selectedDefinitionRoute = isInitial
				? this.routingService.initialRoute
				: this.routingService.currentRoute;
			if (selectedDefinitionRoute) {
				this.currentPresentationDefinition$.next(
					experience.find((currentDefinition) => currentDefinition.route === selectedDefinitionRoute),
				);
			}
			this.setUpTodoItems(availableRoutes);
		}
	}

	public setUpTodoItems(availableRoutes: string[]) {
		const todoList = availableRoutes
			?.map((route) => {
				const ignoreScreens = ['approved', 'signer-details', 'holder-details', 'document-uploads'];

				if (ignoreScreens.includes(route.replace(KnownValues.workflowPath, ''))) {
					return;
				}

				const matchingDefinition = this.presentationDefinitions$
					.getValue()
					.find(
						(definitionToMatch) => definitionToMatch.name === route.replace(KnownValues.workflowPath, ''),
					);

				const item = new TodoItem();
				item.title = matchingDefinition?.displayName;
				item.complete = this.screenStates[matchingDefinition?.name]?.visited ?? false;
				item.associatedRoute = matchingDefinition?.route;

				return item;
			})
			.filter((item) => !!item);

		this.todoList$.next(todoList);
	}

	private updateApplication(currentState: any, isSubmitting?: boolean): Observable<any> {
		const requestBody = new ApplyFlowRequestResource();
		const modelCheckpointResource = new ModelCheckpointResource();

		modelCheckpointResource.currentState = currentState.application;

		if (isSubmitting) {
			modelCheckpointResource.submit = true;
		}

		requestBody.presentationEvent = modelCheckpointResource;

		return this.experienceClient.flow$(requestBody, currentState?.application?.id).pipe(
			map((response: any) => {
				if (!response || _.isEmpty(response)) {
					return;
				}

				if (response.snapshot?.model) {
					const patchedModelState = _.cloneDeep(this.modelStateService.modelState$.getValue());

					if (response.snapshot?.model.application) {
						const diffFromCurrentApplication = compare(
							currentState.application,
							response.snapshot.model.application,
						);
						try {
							applyPatch(patchedModelState.application, diffFromCurrentApplication);
						} catch (error) {
							//look into why this happens
							console.log(error);
						}
						if (diffFromCurrentApplication.length > 0) {
							this.modelStateService.updateApplicationWithoutTriggeringApi(patchedModelState.application);
						}

						this.updateApplicationId(response?.snapshot?.applicationId);
						this.updateRoutingData(response, false);
					}

					if (response.snapshot?.model.lookups) {
						const diffFromCurrentLookups = compare(currentState.lookups, response.snapshot.model.lookups);
						applyPatch(patchedModelState.lookups, diffFromCurrentLookups);

						this.modelStateService.updateLookupsWithoutNotifyingSubscribers(patchedModelState.lookups);
					}

					if (response.snapshot.model.reviewData) {
						const items = _.orderBy(
							response.snapshot.model.reviewData.items,
							['modelPath', 'message'],
							['asc', 'asc'],
						);
						response.snapshot.model.reviewData.items = items;

						const reviewDataAdapter = new ReviewDataResourceAdapter();
						this.modelStateService.reviewData$.next(
							reviewDataAdapter.adapt(response.snapshot.model.reviewData),
						);
					}

					if (response.snapshot.model.conditions) {
						const conditionAdapter = new ConditionResourceAdapter();
						this.modelStateService.conditions$.next(
							conditionAdapter.adapt(response.snapshot.model.conditions),
						);
					}
				}
				return;
			}),
		);
	}

	private updateConditions(conditions) {
		if (conditions) {
			const conditionAdapter = new ConditionResourceAdapter();
			this.modelStateService.updateConditions(conditionAdapter.adapt(conditions));
		}
	}

	private updateSessionContext(applicationId) {
		if (applicationId) {
			const sessionContextUpdate = new ClientContext();
			sessionContextUpdate.applicationId = applicationId;
			this.session.updateSession(sessionContextUpdate);
		}
	}

	private updateReviewData(reviewData) {
		if (reviewData) {
			const reviewDataAdapter = new ReviewDataResourceAdapter();
			this.modelStateService.updateReviewData(reviewDataAdapter.adapt(reviewData));
		}
	}

	private updateModelState(application, lookups, context) {
		if (application && lookups) {
			this.modelStateService.updateModelState({
				application,
				lookups,
				context: context || {},
			});
		}
	}

	private determineInitialRoute(experience) {
		if (!experience.activeScreen) {
			return null;
		}

		const screenDefinition = experience.screens.find((screen) => screen.name === experience.activeScreen);

		if (screenDefinition) {
			const prevRoute = screenDefinition.routeDefinition.previousScreen;
			return screenDefinition.requiresContext ? prevRoute : experience.activeScreen;
		}

		return experience.activeScreen;
	}
}

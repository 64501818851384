import { TodoItem } from '@common/lib/models/todo-item';
import { KnownRoutes } from '@experience/app/constants/known-routes';

export const personalTodoItems = (): TodoItem[] => [
	{
		title: 'Select Your Account',
		subtitle: `Our rates are designed with the right annual percentage yield (APY) to help you save more money.\
			Choose the account that best fits your lifestyle.`,
		associatedRoute: KnownRoutes.ProductSelection,
		visible: true,
		complete: false,
	},
	{
		title: 'Tell Us About Yourself',
		subtitle: `Let's start with you and go from there.`,
		associatedRoute: KnownRoutes.PrimaryApplicant,
		visible: true,
		complete: false,
	},
	{
		title: 'Add More People',
		subtitle: 'Add people to create a joint account',
		associatedRoute: KnownRoutes.AddApplicants,
		visible: true,
		complete: false,
	},
	{
		title: 'Order Debit Card',
		subtitle: 'We can send you a debit card connected to your new account.',
		associatedRoute: KnownRoutes.DebitCard,
		visible: false,
		complete: false,
	},
	{
		title: 'Review Disclosures',
		subtitle: 'When you have reviewed the disclosures and are ready to proceed, click the Submit button.',
		associatedRoute: KnownRoutes.ApplicationReview,
		visible: true,
		complete: false,
	},
	{
		title: 'Application Received',
		associatedRoute: KnownRoutes.ManualReview,
		visible: true,
		complete: false,
	},
	{
		title: 'Set Up Online Banking',
		subtitle: 'Your new account has been approved and created!',
		associatedRoute: KnownRoutes.SetupOnlineBanking,
		visible: true,
		complete: false,
	},
];

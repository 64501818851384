import { ApplicationInsightsTrackingProvider, ConsoleTrackingProvider, TrackerEvent } from '@nbkc/tracker';
import { ApplicationTrackerContext } from '@experience/app/tracking/application-tracker-context';

export class ContainerSelectDefaultCaseEvent extends TrackerEvent<ApplicationTrackerContext> {
	public name: string = 'container-select-default-case';
	public providers = [ConsoleTrackingProvider, ApplicationInsightsTrackingProvider];

	constructor(value: string) {
		super();
		this.data = {
			value
		};
	}

}

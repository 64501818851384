import { Injectable } from '@angular/core';
import { DynamicModelValue } from '@common/lib/models/model-value/dynamic-model-value';
import { StaticModelValue } from '@common/lib/models/model-value/static-model-value';
import { Observable } from 'rxjs';
import { Operation } from 'fast-json-patch';
import { ModelValue, ModelValueCollection } from '@common/lib/models/model-value/model-value.types';
import { Address } from '@common/lib/models/address';

@Injectable({
	providedIn: 'root'
})
export abstract class CommonFormService {

	// eslint-disable-next-line max-len
	abstract getModelValue$<TResponse>(modelPaths: ModelValueCollection, defaultValue?: TResponse, placeholderOverride?: any): Observable<TResponse>;

	abstract setModelValue(newValue: any, path: (DynamicModelValue | StaticModelValue)[]);

	abstract patchModelValue(jsonPatch: Operation[]): void;

	abstract getModelValuePath$(modelValue: ModelValue): Observable<string>;

	abstract searchForAddress$(addressQuery: string): Observable<Address[]>;

}

import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import _ from 'lodash';
import { ReviewData } from '@common/lib/models/review-data';

export class ElementReviewItemValidator {
	public createValidatorFn(targetElementId: string, reviewData$: BehaviorSubject<ReviewData>): AsyncValidatorFn {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		return (control: AbstractControl): Observable<ValidationErrors | null> => reviewData$.pipe(
			map(reviewData => {
				if (reviewData?.reviewItems) {
					const targetReviewItem = _.find(reviewData.reviewItems, (reviewItem) => reviewItem.targetElementId === targetElementId);

					if (targetReviewItem) {
						return { reviewItems: targetReviewItem.message };
					}
				}
				return null;
			}),
			first());
	}
}

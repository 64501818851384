import { Component } from '@angular/core';
import { combineLatest, from, Observable, of } from 'rxjs';
import { DynamicComponentBase } from '../dynamic/dynamic-component.base';
import { FormField } from '@common/lib/models/form-field.model';
import { concatMap, switchMap } from 'rxjs/operators';
import { ActionCollection } from '@common/lib/models/actions/action.types';
import { ActionHelper } from '@common/lib/utilities/action-helper/action-helper';
import { CommonFormService } from '@common/lib/services/common-form.service';

@Component({
	selector: 'common-profile-header',
	templateUrl: './profile-header.component.html',
	styleUrls: ['./profile-header.component.scss']
})
export class ProfileHeaderComponent extends DynamicComponentBase<ProfileHeaderComponentFields, ProfileHeaderComponentOptions> {
	public firstName$: Observable<string>;
	public lastName$: Observable<string>;
	public nameLine$: Observable<string>;
	public email$: Observable<string>;
	public role$: Observable<string>;
	public iconClass: string;
	public showEdit: boolean = false;

	constructor(public formService: CommonFormService,
				private actionHelper: ActionHelper) {
		super();
	}

	onComponentLoaded(): void {
		this.firstName$ = this.formService.getModelValue$<string>(this.fields?.firstName?.modelPaths);
		this.lastName$ = this.formService.getModelValue$<string>(this.fields?.lastName?.modelPaths);
		this.nameLine$ = combineLatest([
			this.firstName$,
			this.lastName$
		]).pipe(
			switchMap(([first, last]) => {
				if (first) {
					return of(`${ first } ${ last || '' }`);
				} else {
					return this.formService.getModelValue$(this.fields?.namePlaceHolder?.modelPaths, this.options?.namePlaceholderValue);
				}
			})
		);
		this.email$ = this.formService.getModelValue$<string>(this.fields?.email?.modelPaths);
		this.role$ = this.formService.getModelValue$<string>(this.fields?.role?.modelPaths);
		this.iconClass = this.options?.iconClass;
	}

	public runActions(actions: ActionCollection): void {
		from(actions).pipe(
			concatMap((action) => this.actionHelper.runAction(action))
		).subscribe();
	}
}

interface ProfileHeaderComponentFields extends Record<string, FormField> {
	firstName: FormField;
	lastName: FormField;
	email: FormField;
	role: FormField;
	namePlaceHolder: FormField;
}

interface ProfileHeaderComponentOptions {
	removeActions: ActionCollection;
	editActions: ActionCollection;
	iconClass: string;
	namePlaceholderValue: string;
	displayEdit: boolean;
}

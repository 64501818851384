<div class="flex justify-between">
	<i [ngClass]="iconClass || 'fal fa-user-circle'" class="text-5xl"></i>
	<div class="ml-4 mt-1 flex-grow overflow-hidden">
		<div>
			<span class="leading-6 profile-title">{{nameLine$ | async}}</span>
		</div>
		<div>
			<span class="profile-subtitle">{{email$ | async}}</span>
		</div>
		<div>
			<span class="profile-subtitle">{{role$ | async}}</span>
		</div>
	</div>
</div>
<div class="action-button-row">
	<div class="self-end mt-2" *ngIf="options?.removeActions">
		<button mat-button color="warn" (click)="runActions(options?.removeActions)">
			<span class="selfprofile-button-text-end profile-button-text">Remove</span>
		</button>
	</div>
	<div class="self-end mt-2" *ngIf="options?.displayEdit">
		<button mat-button color="primary" (click)="runActions(options?.editActions)">
			<span class="selfprofile-button-text-end profile-button-text">Edit Profile</span>
		</button>
	</div>
</div>
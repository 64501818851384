<div class="flex justify-center m-4">
	<button
		tabindex="0"
		id="next-button"
		class="max-w-xs w-full text-white rounded-full py-3 px-4 shadow-button text-lg"
		mat-raised-button
		color="primary"
		data-cy="next-button"
		[disabled]="false"
		(click)="onClickNextButton()"
	>
		Next
	</button>
</div>

@if ($reviewModeEnabled()) {
	<div class="flex justify-center m-4">
		<button
			tabindex="0"
			mat-button
			class="max-w-xs w-full rounded-full py-3 px-4 shadow-none text-lg review-button"
			data-cy="review-errors-button"
			mat-button
			color="primary"
			(click)="onClickGoToReviewDisclosures()"
		>
			Go to Review Disclosures
		</button>
	</div>
}

import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core';
import { AppTodoHeaderComponent } from '@experience/app/components-new/app-todo-header/app-todo-header.component';
import { AppCardComponent } from '@experience/app/components-new/card/app-card.component';
import { BusinessApplicantFormComponent } from '@experience/app/components-new/business-applicant/business-applicant-form/business-applicant-form.component';
import { ApplicationStateService } from '@experience/app/services/application-state.service';
import { NextTodoButtonComponent } from '@experience/app/components-new/next-button/next-todo-button.component';

@Component({
	selector: 'app-business-applicant-card',
	standalone: true,
	imports: [AppTodoHeaderComponent, AppCardComponent, BusinessApplicantFormComponent, NextTodoButtonComponent],
	templateUrl: './business-applicant-card.component.html',
	styleUrl: './business-applicant-card.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BusinessApplicantCardComponent {
	private applicationStateService = inject(ApplicationStateService);
	$businessName = computed(() => this.applicationStateService.$application().business?.name || 'Your Business Name');
}

import { Injector } from '@angular/core';
import { UrlUtility } from '@common/lib/utilities/url-utility';
import { AppSession } from '@experience/app/app.session';
import { take } from 'rxjs/operators';
import { ClientContext } from '@experience/app/models/client-context';

export const loadUrlParams = (injector: Injector): Promise<void> =>
	new Promise((resolve) => {
		const sessionUtility = injector.get(AppSession);

		sessionUtility.data$.pipe(take(1)).subscribe((session) => {
			const sessionContext = new ClientContext();

			const sessionParamsCaseInsensitive = (key: string) =>
				sessionContext?.sessionParams[
					Object.keys(sessionContext.sessionParams).find((k) => k.toLowerCase() === key.toLowerCase())
				];

			if (!session.firstUrl) {
				sessionContext.firstUrl = window.location.href;
			}

			sessionContext.sessionParams = UrlUtility.getUrlParams();

			const applicationId = sessionParamsCaseInsensitive('applicationid');
			const appName = sessionParamsCaseInsensitive('appname');

			if (applicationId) {
				sessionContext.applicationId = applicationId;
			}

			if (appName) {
				sessionContext.appName = appName;
			}

			sessionUtility.updateSession(sessionContext);
		});
		resolve();
	});

import {
	Component,
	ComponentRef,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	Output,
	SimpleChanges,
	Type,
	ViewChild,
	ViewContainerRef
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DynamicComponentDirective } from '@common/lib/directives/dynamic-component.directive';
import { ComponentDefinition } from '@common/lib/models/component-definition.model';
import { Subject } from 'rxjs';
import { DynamicComponentBase } from './dynamic-component.base';
import { DYNAMIC_COMPONENTS } from './dynamic-components';

@Component({
	// eslint-disable-next-line @angular-eslint/component-selector
	selector: 'app-dynamic',
	template: `
		<ng-template class="font-gt-walsheim" blueFinDynamicComponent></ng-template>`,
	styleUrls: ['./dynamic.component.scss']
})
export class DynamicComponent implements OnChanges, OnDestroy {
	@Input() componentDefinition: ComponentDefinition;
	@Input() parentForm: FormGroup;
	@Input() data: any;

	@Output() genericEventEmitter: EventEmitter<any> = new EventEmitter();

	@ViewChild(DynamicComponentDirective, { static: true }) dynamicComponent: DynamicComponentDirective;

	private componentRef: ComponentRef<DynamicComponentBase<any, any>> = null;
	private activated: boolean = false;
	private destroy: Subject<any> = new Subject<any>();

	constructor(private viewContainerRef: ViewContainerRef) { }

	ngOnChanges(changes: SimpleChanges) {
		if (changes.componentDefinition) {
			this.updateComponent();
		}
	}

	ngOnDestroy(): void {
		this.destroy.next();
		this.destroy.complete();
	}

	private rebuildViewContainer(resolvedComponent: Type<DynamicComponentBase<null, null>>): void {
		this.componentRef = this.viewContainerRef.createComponent(resolvedComponent);
	}

	private updateComponent() {
		if (!this.activated) {
			const resolvedComponent = DYNAMIC_COMPONENTS[this.componentDefinition?.componentType];
			if (!resolvedComponent) {
				// eslint-disable-next-line no-console
				console.error('Could not find the component for Type: ', this.componentDefinition?.componentType);
				return;
			}
			this.rebuildViewContainer(resolvedComponent);
			this.componentRef.instance.id = this.componentDefinition.id;
			this.componentRef.instance.options = this.componentDefinition.options;
			this.componentRef.instance.fields = this.componentDefinition.fields;
			this.componentRef.instance.type = this.componentDefinition.componentType;
			this.componentRef.instance.components = this.componentDefinition.components;
			if (this.parentForm) {
				this.componentRef.instance.parentForm = this.parentForm;
			}
			this.componentRef.instance.registerComponentInternals();
			this.componentRef.instance.emitter = this.genericEventEmitter;
			this.componentRef.instance.data = this.data;
			this.componentRef.instance.context = this.componentDefinition?.context;
			this.componentRef.instance.onComponentLoaded();
			this.activated = true;
		} else {
			this.componentRef.instance.options = this.componentDefinition?.options;
			this.componentRef.instance.fields = this.componentDefinition?.fields;
			this.componentRef.instance.context = this.componentDefinition?.context;
			this.componentRef.instance.onComponentUpdated();
		}
	}
}

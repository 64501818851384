import { Component, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ComponentDefinition } from '@common/lib/models/component-definition.model';
import { FormField } from '@common/lib/models/form-field.model';
import { Subject } from 'rxjs';
import { ComponentContext } from '@common/lib/models/component-context';

type TFieldGeneric = Record<string, FormField>;
type TOptionGeneric = Record<string, any>;

@Component({
	// eslint-disable-next-line @angular-eslint/component-selector
	selector: 'app-dynamic-base',
	template: ``
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export abstract class DynamicComponentBase<TFields extends TFieldGeneric, TOptions extends TOptionGeneric> implements OnDestroy {
	protected static formMaps: Map<string, FormGroup> = new Map();
	public emitter: EventEmitter<any> = new EventEmitter();
	public data: any;
	public parentForm?: FormGroup;
	public components: ComponentDefinition[];
	public id: string;
	public fields: TFields;
	public options: TOptions;
	public type: string;
	public context: ComponentContext;


	// HTML Options
	public containerClass: string;

	protected destroy$: Subject<void> = new Subject<void>();

	public onComponentLoaded(): void {
	}

	public onComponentUpdated(): void {
	}

	public registerComponentInternals(): void {
	}

	public isValidComponent(componentId: string): boolean {
		return DynamicComponentBase.formMaps.get(componentId || this.id)?.valid;
	}

	public getComponentDefinition(component: ComponentDefinition): ComponentDefinition {
		return this.components.find(componentDefinition => componentDefinition.id === component.id);
	}

	public trackByIdFn(index: number, item: any): number {
		return item.id;
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}

import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { DynamicComponentBase } from '../dynamic/dynamic-component.base';
import { FormField } from '@common/lib/models/form-field.model';
import { CommonFormService } from '@common/lib/services/common-form.service';

@Component({
	selector: 'common-generic-header',
	templateUrl: './generic-header.component.html',
	styleUrls: ['./generic-header.component.scss'],
})
export class GenericHeaderComponent extends DynamicComponentBase<GenericHeaderComponentFields, GenericHeaderComponentOptions> {
	public title$: Observable<string>;
	public subtitle$: Observable<string>;
	public icon$: Observable<string>;

	constructor(public formService: CommonFormService) {
		super();
	}

	onComponentLoaded(): void {
		this.title$ = this.formService.getModelValue$(this.fields?.title?.modelPaths, this.options?.titleValue);
		this.subtitle$ =
			this.formService.getModelValue$(this.fields?.subtitle?.modelPaths, this.options?.subtitleValue);
		this.icon$ = this.formService.getModelValue$(this.fields?.icon?.modelPaths, this.options?.iconClassesValue);
	}
}

interface GenericHeaderComponentFields extends Record<string, FormField> {
	title: FormField;
	subtitle: FormField;
	icon: FormField;
}

interface GenericHeaderComponentOptions {
	iconClassesValue: string;
	subtitleValue: string;
	titleValue: string;
}

import { TodoItem } from '@common/lib/models/todo-item';
import { KnownRoutes } from '@experience/app/constants/known-routes';

export const personalTodoItems: TodoItem[] = [
	{
		title: 'Select Your Account',
		subtitle: `Our rates are designed with the right annual percentage yield (APY) to help you save more money.\
			Choose the account that best fits your lifestyle.`,
		associatedRoute: KnownRoutes.ProductSelection,
		complete: false,
	},
	{
		title: 'Tell Us About Yourself',
		subtitle: `Let's start with you and go from there.`,
		associatedRoute: KnownRoutes.PrimaryApplicant,
		complete: false,
	},
	{
		title: 'Set Up Your Account',
		subtitle: 'Select the best account option below.',
		associatedRoute: KnownRoutes.AccountOwnership,
		complete: false,
	},
	{
		title: 'Order Debit Card',
		associatedRoute: KnownRoutes.DebitCard,
		complete: false,
	},
	{
		title: 'Review Disclosures',
		subtitle: 'When you have reviewed the disclosures and are ready to proceed, click the Next button.',
		associatedRoute: KnownRoutes.ApplicationReview,
		complete: false,
	},
	{
		title: 'Set Up Online Banking',
		subtitle: 'Your new account has been approved and created!',
		associatedRoute: KnownRoutes.SetupOnlineBanking,
		complete: false,
	},
];

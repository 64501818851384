export class OnboardRequest {

	public username: string;
	public password: string;

	constructor(username: string, password) {
		this.username = username;
		this.password = password;
	}
}

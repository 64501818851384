import { Component, computed, inject } from '@angular/core';
import { ApplicationStateService } from '@experience/app/services/application-state.service';
import { Application } from '@experience/app/models/onboarding/application.model';
import { knownApplicationStatus } from '@common/lib/constants/known-application-statuses';

@Component({
	selector: 'app-terminal-screen',
	standalone: true,
	imports: [],
	templateUrl: './terminal-screen.component.html',
	styleUrl: './terminal-screen.component.scss',
})
export class TerminalScreenComponent {
	private applicationStateService = inject(ApplicationStateService);

	$application = this.applicationStateService.$application;
	$headerText = computed<string>(() => this.getHeaderText(this.$application()));
	$bodyText = computed<string>(() => this.getBodyText(this.$application()));

	getHeaderText(application: Application): string {
		switch (application.status) {
			case knownApplicationStatus.Denied:
				return 'An Update on Your Application Status';
			case knownApplicationStatus.OnHold:
				return `We're Reviewing Your Application`;
		}
	}

	getBodyText(application: Application): string {
		switch (application.status) {
			case knownApplicationStatus.Denied:
				return `We reviewed your recent application, and at this time we are unable to approve your request for a new account.  \n \n
				We understand this can be frustrating, however there are various factors taken into consideration during our standard review process.
				If you'd like to learn more about declined applications please click `;
			case knownApplicationStatus.OnHold:
				return `Thank you for considering us for your banking needs.
				Our team is currently reviewing your application.
				We’ll provide an update via email once the review process is complete.`;
		}
	}

	protected readonly knownApplicationStatus = knownApplicationStatus;
}

import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthenticationProviderBase } from '@common/lib/utilities/authentication/authentication-providers/authentication-provider.base';
import { Auth0AuthenticationProvider } from '@common/lib/utilities/authentication/authentication-providers/auth0.authentication-provider';
import { AuthenticationContext } from '@common/lib/utilities/authentication/authentication-context';
import { Tracker } from '@nbkc/tracker-angular';
import { CommonTrackerContextProps } from '@common/lib/tracking/common-tracker-context';

@Injectable({
	providedIn: 'root'
})
export class AuthenticationUtility {
	public authenticationContext$: Observable<AuthenticationContext>;
	private authenticationProvider: AuthenticationProviderBase;

	constructor(private injector: Injector) {
		this.createAuthenticationProvider();
	}

	public login(returnUrl: string = '/'): void {
		this.authenticationProvider.login(returnUrl);
	}

	public logout(): void {
		this.authenticationProvider.logout();
	}

	public async refreshAuth(): Promise<any> {
		await this.authenticationProvider.refreshAuth();
	}

	public handleAuthCallback$(): Observable<string> {
		return this.authenticationProvider.handleAuthenticationCallback$() as Observable<string>;
	}

	private createAuthenticationProvider(): void {
		this.authenticationProvider = this.injector.get(Auth0AuthenticationProvider);
		this.authenticationProvider.onProviderStart();
		this.authenticationContext$ = this.authenticationProvider.onProviderAuthentication$;
		this.authenticationContext$.subscribe((context) => {
			const tracker = this.injector.get(Tracker);
			tracker.setTrackerContext<CommonTrackerContextProps>({
				userId: context.user?.email
			});
		});
	}
}

<div [ngClass]="containerClass || 'w-full'">
	<form [formGroup]="internalForm" class="w-full">
		<mat-form-field class="w-full" appearance="outline">
			<mat-label>{{options?.label}}</mat-label>
			<input matInput formControlName="value"
				   class="{{inputClass}}"
				   data-cy="input-field"
				   [textMask]="inputMask"
				   (focus)="setFocusedControl(internalForm.get('value'))"
				   (blur)="onInputBlur()" #inputElement>
		</mat-form-field>
		<div class="text-10.5px pt-2.5 pb-2.5">
			<common-character-length *ngIf="options?.characterCountEnabled"
				[formField]="fields?.value" [validatedControl]="internalForm.get('value')" (isGuideVisible)="isCharacterLengthHelperVisible = $event">
			</common-character-length>
			<div *ngIf="options?.subtext" class="subtext" [ngClass]="isCharacterLengthHelperVisible ? 'pt-2.5': ''">
				{{options.subtext}}
			</div>
			<common-validation-messages [validatedControl]="internalForm.get('value')" [associatedField]="fields['value']"></common-validation-messages>
		</div>
	</form>
</div>

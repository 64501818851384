import { Component, Injector, Input } from '@angular/core';
import { CommonFormService } from '@common/lib/services/common-form.service';
import { Condition } from '@common/lib/models/condition';
import { DocumentInfo } from '@common/lib/models/document-info';
import { ErrorTypes } from '@common/lib/models/enum/error-types.enum';
import { FormField } from '@common/lib/models/form-field.model';
import { GeneratorUtility } from '@common/lib/utilities/generator-utility';
import { GeneralError } from '@common/lib/models/general-error.model';
import { DocumentService } from '@experience/app/services/document.service';
import { DocumentUploadComplete } from '@experience/app/models/document-upload-complete.model';
import { RequestDocumentUpload } from '@experience/app/models/request-document-upload.model';
import _ from 'lodash';
import { switchMap } from 'rxjs/operators';
import { DynamicFormComponentBase } from '@common/lib/components/dynamic/dynamic-form-component.base';
import { CommonErrorService } from '@common/lib/services/common-error.service';

@Component({
	// eslint-disable-next-line @angular-eslint/component-selector
  selector: 'common-manual-upload',
  templateUrl: './manual-upload.component.html',
  styleUrls: ['./manual-upload.component.scss']
})
export class ManualUploadComponent extends DynamicFormComponentBase<UploadManualComponentFields, UploadManualComponentOptions>  {
  @Input() condition: Condition;

  constructor(public injector: Injector,
    public formService: CommonFormService,
	private errorService: CommonErrorService,
	private documentUploadService: DocumentService) {
      super(injector);
  }

  public handleFileInput(e: Event, condition: Condition): void {
	const {files} = (e.target as HTMLInputElement);
	const request = new RequestDocumentUpload();
	const validExtensions = ['png', 'jpg', 'jpeg', 'pdf', 'tif', 'tiff', 'gif'];

	const validFiles = _.filter(files, (file) => {
		const extension = file.name.split('.').pop();
		return _.includes(validExtensions, extension);
	});

	const containsInvalidFiles = validFiles?.length < files?.length;

	if (validFiles.length > 0) {

		const documentsToUpload = [];
		const currentDocumentUploadGroupId = GeneratorUtility.generateUuidv4();

		validFiles.forEach((file) => {
			documentsToUpload.push(new DocumentInfo(file));

			condition.recentFiles.unshift({
				fileName: file.name,
				displayUpload: true,
				uploadComplete: false,
				documentUploadGroupId: currentDocumentUploadGroupId
			});
		});

		request.documentGroupId = condition.id;
		request.documentGroup = condition.name;
		request.documents = documentsToUpload;

		setTimeout(() => {

			this.documentUploadService.requestDocumentUpload(request).pipe(
				switchMap((response) => this.documentUploadService.postDocuments$(documentsToUpload, response.container, response.uri))
			)
			.subscribe(() => {

				condition.recentFiles.filter((file) => file.documentUploadGroupId === currentDocumentUploadGroupId)
					.forEach((file) => file.uploadComplete = true);

				const completeEvent = new DocumentUploadComplete();
				completeEvent.documents = documentsToUpload;
				completeEvent.documentGroupId = condition.id;
				completeEvent.documentGroup = condition.name;
				this.documentUploadService.saveDocumentUploaded(completeEvent);
			});
		});
	}

	if (containsInvalidFiles) {
		const event = new GeneralError();
		event.isRetrying = false;
		event.errorType = ErrorTypes.invalidUserInteraction;
		event.message = `We are unable to accept one of the uploaded documents.
			Please use a document with one of the following extensions: ${ validExtensions.join(', ') }`;
		event.blocking = true;
		this.errorService.handleError(event);
	}
  }

  clearFileInput(event: Event) {
		(event.target as HTMLInputElement).value = null;
	}

}

interface UploadManualComponentFields extends Record<string, FormField> {

}

interface UploadManualComponentOptions {
}

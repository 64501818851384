import { Injectable } from '@angular/core';
import { GeneralError } from '../models/general-error.model';

@Injectable({
	providedIn: 'root'
})
export abstract class CommonErrorService {
	abstract handleError(event: GeneralError);
}



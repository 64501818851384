<div [ngClass]="containerClass || 'w-full'">
	<form [formGroup]='internalForm'>
		<div class="mb-2 text-base">
			<mat-label>{{options?.label}}</mat-label>
		</div>
		<ul>
			<li *ngFor="let choice of choices$ | async" class="m-2">
				<mat-checkbox class="big wrap-text"
							  [checked]="isChecked(choice) | async"
							  [value]="getCheckboxValue(choice)"
							  (change)="toggleCheckbox($event)"
				*ngIf="getCheckboxDisplay(choice)?.trim()">
					{{getCheckboxDisplay(choice)}}
				</mat-checkbox>
			</li>
		</ul>
		<common-validation-messages [validatedControl]="internalForm"
									[associatedField]="fields.selected"></common-validation-messages>
	</form>
</div>


import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DynamicComponentBase } from '@common/lib/components/dynamic/dynamic-component.base';
import { ContainerComponent } from '@common/lib/components/containers/container/container.component';
import { FormField } from '@common/lib/models/form-field.model';

@Component({
	selector: 'common-modal-container',
	templateUrl: './modal-container.component.html',
	styleUrls: ['./modal-container.component.scss']
})
export class ModalContainerComponent extends DynamicComponentBase<ModalContainerComponentFields, ModalContainerComponentOptions> {
	public myClasses: string;
	public shouldDisplay: boolean = true;
	public container: ContainerComponent = null;

	constructor(
		public dialogRef: MatDialogRef<ModalContainerComponent>,
		@Inject(MAT_DIALOG_DATA) data) {
		super();
		this.components = data.components;
		this.options = data.options;
	}

	onComponentLoaded(): void {
		this.myClasses = this.options?.classes;
	}

	public closeModal(): void {
		this.dialogRef.close();
	}
}
interface ModalContainerComponentFields extends Record<string, FormField>{

}

interface ModalContainerComponentOptions {
	classes: string;
}

import { Type } from '@angular/core';
import { AccountProfileSummaryComponent } from '../account-profile-summary/account-profile-summary.component';
import { NextButtonComponent } from '../buttons/next-button/next-button.component';
import { ActionButtonComponent } from '../buttons/action-button/action-button.component';
import { CopyButtonComponent } from '../buttons/copy-button/copy-button.component';
import { LinkButtonComponent } from '../buttons/link-button/link-button.component';
import { ModalButtonComponent } from '../buttons/modal-button/modal-button.component';
import { CardActionsComponent } from '../containers/card/card-actions/card-actions.component';
import { CardBodyComponent } from '../containers/card/card-body/card-body.component';
import { CardFooterComponent } from '../containers/card/card-footer/card-footer.component';
import { CardHeaderComponent } from '../containers/card/card-header/card-header.component';
import { CardComponent } from '../containers/card/card.component';
import { ContainerComponent } from '../containers/container/container.component';
import { SelectContainerComponent } from '../containers/select-container/select-container.component';
import { ModalContainerComponent } from '../containers/modal-container/modal-container.component';
import { DividerComponent } from '../divider/divider.component';
import { AddressComponent } from '../form-controls/address/address.component';
import { CheckboxComponent } from '../form-controls/checkbox/checkbox.component';
import { CreditCardComponent } from '../form-controls/credit-card/credit-card.component';
import { InputComponent } from '../form-controls/input/input.component';
import { SocialSecurityComponent } from '../form-controls/inputs/social-security/social-security.component';
import { RadioButtonChipsComponent } from '../form-controls/radio-button-chips/radio-button-chips.component';
import { RadioButtonGroupComponent } from '../form-controls/radio-button-group/radio-button-group/radio-button-group.component';
import { SelectComponent } from '../form-controls/select/select.component';
import { ChipSelectComponent } from '../form-controls/chip-select/chip-select.component';
import { MultiSelectChipsComponent } from '../form-controls/multi-select-chips/multi-select-chips.component';
import { ToggleComponent } from '../form-controls/toggle/toggle.component';
import { GenericHeaderComponent } from '../generic-header/generic-header.component';
import { ProfileHeaderComponent } from '../profile-header/profile-header.component';
import { ProductHeaderComponent } from '../product-header/product-header.component';
import { TextComponent } from '../text/text.component';
import { IconComponent } from '../icon/icon.component';
import { ValidationBadgeComponent } from '../validation-badge/validation-badge.component';
import { DynamicComponentBase } from './dynamic-component.base';
import { TextAreaComponent } from '../form-controls/textarea/textarea.component';
import { ReviewComponent } from '@common/lib/components/review/review.component';
import { CheckboxGroupComponent } from '../form-controls/checkbox-group/checkbox-group/checkbox-group.component';
import { AuthorizedUsersComponent } from '../form-controls/authorized-users/authorized-users.component';
import { BackButtonComponent } from '@common/lib/components/buttons/back-button/back-button.component';
import { OnboardingComponent } from '@common/lib/components/form-controls/onboarding/onboarding.component';

export const DYNAMIC_COMPONENTS: Record<string, Type<DynamicComponentBase<any, any>>> = {
	'account-profile-summary': AccountProfileSummaryComponent,
	'authorized-users': AuthorizedUsersComponent,
	'button-next': NextButtonComponent,
	'button-back': BackButtonComponent,
	'button-action': ActionButtonComponent,
	'button-link': LinkButtonComponent,
	'button-copy': CopyButtonComponent,
	container: ContainerComponent,
	'containers-select': SelectContainerComponent,
	card: CardComponent,
	'card-header': CardHeaderComponent,
	'card-body': CardBodyComponent,
	'card-actions': CardActionsComponent,
	'card-footer': CardFooterComponent,
	divider: DividerComponent,
	'form-controls-checkbox': CheckboxComponent,
	'form-controls-checkbox-group': CheckboxGroupComponent,
	'form-controls-credit-card': CreditCardComponent,
	'form-controls-inputs-date': InputComponent,
	'form-controls-inputs-phone': InputComponent,
	'form-controls-inputs-zip': InputComponent,
	'form-controls-inputs-text': InputComponent,
	'form-controls-inputs-ein': InputComponent,
	'form-controls-textarea': TextAreaComponent,
	'form-controls-inputs-social-security': SocialSecurityComponent,
	'form-controls-radio-button-chips': RadioButtonChipsComponent,
	'form-controls-radio-button-group': RadioButtonGroupComponent,
	'form-controls-select': SelectComponent,
	'form-controls-chip-select': ChipSelectComponent,
	'form-controls-multi-select-chips': MultiSelectChipsComponent,
	'modal-button': ModalButtonComponent,
	'modal-container': ModalContainerComponent,
	'form-controls-toggle': ToggleComponent,
	'form-controls-address': AddressComponent,
	'generic-header': GenericHeaderComponent,
	'profile-header': ProfileHeaderComponent,
	'product-header': ProductHeaderComponent,
	text: TextComponent,
	icon: IconComponent,
	'validation-badge': ValidationBadgeComponent,
	review: ReviewComponent,
	'form-controls-onboarding': OnboardingComponent,
};

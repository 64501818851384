<div *ngIf="buttonType === 'card'" class="block justify-center my-4">
	<common-card-skeleton *ngIf="loadingService.isLoadingCardSkeletonVisible; else cardButton"></common-card-skeleton>

	<ng-template #cardButton>
		<button
			id="action-button={{ id }}"
			class="card-button w-full py-8 pl-0.5 shadow-button rounded-xl justify-start"
			mat-raised-button
			(click)="runButtonActions()"
			[disabled]="disabled"
		>
			<i class="far fa-plus-circle fa-2x m-4"></i>{{ buttonText$ | async }}
		</button>
	</ng-template>
</div>

<div *ngIf="buttonType === 'basic'" class="flex">
	<button id="action-button={{ id }}" mat-button (click)="runButtonActions()" [color]="color" [disabled]="disabled">
		{{ buttonText$ | async }}
	</button>
	<app-dynamic></app-dynamic>
</div>

<div *ngIf="buttonType === 'warn'" class="flex">
	<button mat-button (click)="runButtonActions()" [color]="'warn'" [disabled]="disabled">
		{{ buttonText$ | async }}
	</button>
	<app-dynamic></app-dynamic>
</div>

<div *ngIf="buttonType === 'icon'" class="flex">
	<button mat-icon-button (click)="runButtonActions()">
		<i [ngClass]="iconClass"></i>
	</button>
</div>

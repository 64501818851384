import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule, provideAnimations } from '@angular/platform-browser/animations';
import { BlueFinCommonModule } from '@common/lib/common.module';
import { ErrorInterceptor } from '@common/lib/interceptors/error-interceptor';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BodyComponent } from './components/body/body.component';
import { CatchAllComponent } from './components/catch-all/catch-all.component';
import { ErrorComponent } from './components/error/error.component';
import { HeaderComponent } from './components/header/header.component';
import { LoadingComponent } from './components/loading/loading.component';
import { LoginComponent } from '@experience/app/components/login/login.component';
import { startupFactory } from '@experience/app/app.startup';
import { AuthenticationInterceptor } from '@common/lib/utilities/authentication/interceptors/authentication.interceptor';
import { TodoListComponent } from './components/todo-list/todo-list.component';
import { CommonFormService } from '@common/lib/services/common-form.service';
import { FormAccess } from '@experience/app/access/form.access';
import { LogoutComponent } from './components/logout/logout.component';
import { MatMenuModule } from '@angular/material/menu';
import { DynamicScreenComponent } from '@common/lib/components/dynamic/dynamic-screen.component';
import { CommonConfiguration } from '@common/lib/utilities/configuration/common.configuration';
import { ExperienceConfiguration } from '@experience/app/experience.configuration';
import { CommonErrorService } from '@common/lib/services/common-error.service';
import { ExperienceErrorService } from './services/experience-error.service';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { AppCardComponent } from '@experience/app/components-new/card/app-card.component';

@NgModule({
	declarations: [
		AppComponent,
		BodyComponent,
		CatchAllComponent,
		ErrorComponent,
		HeaderComponent,
		LoadingComponent,
		LoginComponent,
		TodoListComponent,
		LogoutComponent,
		DynamicScreenComponent,
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		BlueFinCommonModule,
		BrowserAnimationsModule,
		MatMenuModule,
		MatDialogModule,
		AppCardComponent,
	],
	providers: [
		{
			provide: APP_INITIALIZER,
			useFactory: startupFactory,
			deps: [Injector],
			multi: true,
		},
		provideHttpClient(withInterceptorsFromDi()),
		{
			provide: HTTP_INTERCEPTORS,
			useClass: ErrorInterceptor,
			multi: true,
		},
		provideAnimations(),
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthenticationInterceptor,
			multi: true,
		},
		{
			provide: CommonErrorService,
			useExisting: ExperienceErrorService,
		},
		{
			provide: CommonFormService,
			useExisting: FormAccess,
		},
		{
			provide: CommonConfiguration,
			useExisting: ExperienceConfiguration,
		},
		{
			//In Angular 15+ this option allows form field options to nestle closer together. A new div was added and by
			//default it takes up space under the control. This makes it to where it only show when active.
			provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
			useValue: {
				subscriptSizing: 'dynamic',
			},
		},
	],
	bootstrap: [AppComponent],
})
export class AppModule {}

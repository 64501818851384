import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AppSession } from '@experience/app/app.session';
import { ExperienceConfiguration } from '@experience/app/experience.configuration';
import { ClientContext } from '@experience/app/models/client-context';

@Injectable({
  providedIn: 'root'
})
export class SocureDevicerService {

  constructor(private router: Router, public appSession: AppSession, private config: ExperienceConfiguration,) { }

  public start(): void {
    if (!this.config.turnOnSocureDevicer) {
      return;
    }

    this.injectSocureScript();

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.getSocureSessionID(event.url);
      }
    });
  }

  private injectSocureScript(): void {
    const script = document.createElement('script');
    script.src = 'https://js.dvnfo.com/devicer.min.js';
    document.head.appendChild(script);
  }

  private getSocureSessionID(url: string): void {
    const deviceFPOptions: devicerOptions = {
      publicKey: this.config.socureDevicerPublicKey,
      userConsent: true,
      endpoint: 'https://dvnfo.com',
      context: undefined
    };

    deviceFPOptions.context = url === '/' ? 'homepage' : 'signup';

    const devicer = window['devicer'];
    devicer.run(deviceFPOptions, (response) => {
      const session = new ClientContext();
      session.socureSessionid = response.sessionId;
      this.appSession.updateSession(session);
    });
  }

}


interface devicerOptions {
  publicKey: string;
  userConsent: boolean;
  endpoint?: string;
  context: 'homepage' | 'signup' | 'login' | 'profile' | 'password' | 'transaction' | 'checkout';
}

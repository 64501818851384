<h2 mat-dialog-title class="flex mb-4">
	<div class="text-6xl mr-4">
		<i class="fa-light fa-block-question"></i>
	</div>
	<div class="text-xl">Are you sure you want to start a new application?</div>
</h2>

<mat-dialog-content class="pb-3 mat-typography flex overflow-y-hidden">
	<div>
		<p class="text-lg">You created an application on {{ data.lastStartDate | date: 'mediumDate' }}.</p>
		<button mat-button color="primary" class="p-2" [mat-dialog-close]="'continue'">Continue Application</button>
	</div>
</mat-dialog-content>
<mat-dialog-actions align="end">
	<button mat-button color="warn" mat-dialog-close>Cancel</button>
	<button mat-button color="primary" [mat-dialog-close]="true">Start a new application</button>
</mat-dialog-actions>

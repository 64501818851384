export class DocumentUploadData {
	fileName: string;
	displayUpload: boolean;
	uploadComplete: boolean;
	documentUploadGroupId?: string;

	constructor(fileName: string, displayUpload?: boolean, uploadComplete?: boolean) {
		this.fileName = fileName;
		this.displayUpload = displayUpload || false;
		this.uploadComplete = uploadComplete || false;
	}
}

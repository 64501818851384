import { DocumentInfo } from '@common/lib/models/document-info';

export class RequestDocumentUpload {
	public documentGroupId: string;
	public documentGroup: string;
	public documents: DocumentInfo[];

	constructor(documentGroup?: string, documentGroupId?: string) {
		this.documentGroupId = documentGroupId;
		this.documentGroup = documentGroup;
	}
}


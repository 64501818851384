import { Component, OnDestroy } from '@angular/core';
import { from, Observable } from 'rxjs';
import { DynamicComponentBase } from '../../dynamic/dynamic-component.base';
import { FormField } from '@common/lib/models/form-field.model';
import { catchError, concatMap, tap } from 'rxjs/operators';
import { ActionCollection } from '@common/lib/models/actions/action.types';
import { ActionHelper } from '@common/lib/utilities/action-helper/action-helper';
import { CommonFormService } from '@common/lib/services/common-form.service';
import { LoadingService } from '@experience/app/services/loading.service';

@Component({
	selector: 'common-action-button',
	templateUrl: './action-button.component.html',
	styleUrls: ['./action-button.component.scss']
})
export class ActionButtonComponent extends DynamicComponentBase<ActionButtonComponentFields, ActionButtonComponentOptions> implements OnDestroy {
	public buttonText$: Observable<string>;
	public color: string;
	public disabled: boolean = false;
	public iconClass: string;
	public classes: string;
	public processing: boolean = false;
	public buttonType: string;

	constructor(public formService: CommonFormService,
				private actionHelper: ActionHelper,
				public loadingService: LoadingService
	) {
		super();
	}

	onComponentLoaded(): void {
		this.color = this.options?.color;
		this.disabled = !!this.options?.disabled;
		this.iconClass = this.options?.iconClass;
		this.buttonType = this.options?.buttonType ?? 'basic';
		this.buttonText$ = this.formService.getModelValue$<string>(this.fields?.buttonTextPath?.modelPaths, this.options?.buttonTextValue);
	}

	public runButtonActions(): void {
		const patchActions = this.options.actions.filter(x => x.type === 'patch');
		const navigateActions = this.options.actions.filter(x => x.type === 'navigate');

		from([...patchActions, ...navigateActions]).pipe(
			tap(() => {
				this.loadingService.setIsLoadingCardSkeletonVisible(true);
			}),
			concatMap((action) => this.actionHelper.runAction(action)),
			catchError((error) => error),
		).subscribe({
			complete: () => {
				this.loadingService.setIsLoadingCardSkeletonVisible(false);
			}
		});
	}
}

interface ActionButtonComponentFields extends Record<string, FormField> {
	buttonTextPath: FormField;
}

interface ActionButtonComponentOptions {
	color: string;
	disabled: string;
	iconClass: string;
	buttonType: string;
	buttonTextValue: string;
	actions: ActionCollection;
}

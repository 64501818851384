<div class="card-div-container flex justify-center mb-4">
	<mat-card appearance="outlined" class="w-full rounded-xl overflow-hidden">
		<div class="flex flex-col flex-grow">
			<div class="pl-4 pr-4 pt-4 mb-4 text-left relative flex-grow">
				<div class="text-gray-darkest">
					<div class="w-1/5 inline-block">
						<span class="skeleton-box h-16 w-16 inline-block rounded-full"></span>
					</div>
					<div class="w-4/5 inline-block">
						<span class="skeleton-box h-6 w-1/6 inline-block rounded-full"></span>
						<span class="skeleton-box h-6 w-full inline-block rounded-full"></span>
					</div>
					<br>
					<br>
					<br>
					<span class="skeleton-box h-8 w-full inline-block rounded-full"></span>
					<br>
					<br>
					<span class="skeleton-box h-8 w-full inline-block rounded-full"></span>
					<br>
					<br>
					<span class="skeleton-box h-8 w-full inline-block rounded-full"></span>
					<br>
					<br>
					<span class="skeleton-box h-8 w-full inline-block rounded-full"></span>
				</div>
			</div>
		</div>
	</mat-card>
</div>

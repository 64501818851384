import { Component } from '@angular/core';
import { AppCardComponent } from '../card/app-card.component';
import { AppTodoHeaderComponent } from '../app-todo-header/app-todo-header.component';
import { NextTodoButtonComponent } from '../next-button/next-todo-button.component';

@Component({
	selector: 'app-prohibited-businesses',
	standalone: true,
	imports: [AppCardComponent, AppTodoHeaderComponent, NextTodoButtonComponent],
	templateUrl: './prohibited-businesses.component.html',
	styleUrl: './prohibited-businesses.component.scss',
})
export class ProhibitedBusinessesComponent {
	public businesses = [
		{ Icon: 'fa-light fa-money-bill-wave', DisplayText: 'ATM / Money Service Business' },
		{ Icon: 'fa-light fa-cannabis', DisplayText: 'Cannabis / Marijuana Related' },
		{ Icon: 'fa-light fa-coins', DisplayText: 'Cryptocurrency' },
		{ Icon: 'fa-light fa-raygun', DisplayText: 'Firearms and Ammunition' },
		{ Icon: 'fa-light fa-dolly', DisplayText: 'Import / Export Goods' },
		{ Icon: 'fa-light fa-dice', DisplayText: 'Casino / Gambling' },
		{ Icon: 'fa-light fa-money-check-edit-alt', DisplayText: 'Lending, Financing' },
		{ Icon: 'fa-light fa-chart-line', DisplayText: 'Securities, Futures, Commission, or Commodity Trading' },
		{
			Icon: 'fa-light fa-chart-network',
			DisplayText: 'Pooled Investments / Investment Broker on Behalf of Others',
		},
		{ Icon: 'fa-light fa-credit-card', DisplayText: 'Credit Card System Operations' },
		{ Icon: 'fa-light fa-hands-usd', DisplayText: 'Credit Repair' },
		{ Icon: 'fa-light fa-phone', DisplayText: 'Telemarketing' },
		{ Icon: 'fa-light fa-chess-pawn', DisplayText: 'Pawn Brokerage' },
		{ Icon: 'fa-light fa-turtle', DisplayText: 'Shell Corporations' },
	];
}

import { ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit, signal } from '@angular/core';
import { AppCardComponent } from '@experience/app/components-new/card/app-card.component';
import { IndividualApplicantFormComponent } from '@experience/app/components-new/individual-applicant/individual-applicant-form/individual-applicant-form.component';
import { AuthenticationUtility } from '@common/lib/utilities/authentication/authentication.utility';
import { InternalStateService } from '@experience/app/services/internal-state.service';
import { Subscription } from 'rxjs';
import { AppTodoHeaderComponent } from '../app-todo-header/app-todo-header.component';
import { NextTodoButtonComponent } from '../next-button/next-todo-button.component';

@Component({
	selector: 'app-individual-applicant',
	standalone: true,
	imports: [AppCardComponent, IndividualApplicantFormComponent, AppTodoHeaderComponent, NextTodoButtonComponent],
	templateUrl: './individual-applicant-card.component.html',
	styleUrl: './individual-applicant-card.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IndividualApplicantCardComponent implements OnInit {
	private auth = inject(AuthenticationUtility);
	private internalStateService = inject(InternalStateService);
	private destroyRef = inject(DestroyRef);
	$ownerEmail = signal<string>('');
	$isPersonal = this.internalStateService.$isPersonal;
	private subscription: Subscription = new Subscription();

	ngOnInit() {
		if (this.$isPersonal()) {
			this.subscription.add(
				this.auth.authenticationContext$.subscribe((context) => {
					this.$ownerEmail.set(context.user?.email);
				}),
			);
		}
		this.destroyRef.onDestroy(() => this.subscription.unsubscribe());
	}
}

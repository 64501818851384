<div class="p-0 mt-5">
	<span class="text-base">Would you like to add additional authorized debit card users?</span>
</div>
<div class="flex items-center cursor-pointer" *ngIf="!isEditing" (click)="add()">
	<button mat-button color="primary" class="fa-lg mt-1 leading-8">
		<i class="far fa-plus-square"></i>
		<div class="inline-block pl-2 pr-0.5">{{options?.label}}</div>
	</button>
</div>
<form [formGroup]="internalForm">
	<div *ngIf="isEditing" class="mt-4">
		<div class="flex justify-between mb-0">
			<mat-form-field appearance="outline" class="w-full m-2">
				<mat-label>First Name</mat-label>
				<input matInput formControlName="firstName" data-cy="input-field"/>
			</mat-form-field>
			<mat-form-field appearance="outline" class="w-full m-2">
				<mat-label>Last Name</mat-label>
				<input matInput formControlName="lastName" data-cy="input-field"/>
			</mat-form-field>
		</div>
		<div class="flex justify-between items-center pb-2">
			<button color="primary" mat-button class="text-base cursor-pointer ml-2" (click)="cancel()">Cancel</button>
			<div>
				<button mat-raised-button mat-flat-button color="primary" class="text-white text-base mr-2"
					(click)="save()" [disabled]="!internalForm.valid">Save</button>
			</div>
		</div>
	</div>
</form>
<div class="flex flex-col text-base">
	<div class="p-0" *ngFor="let user of authorizedUsers$ | async; let i = index;">
		<div *ngIf="i === 0" class="mt-2">
			Additional Authorized Cardholders:
		</div>
		<button mat-icon-button color="warn" (click)="removeUser(user)">
			<i class="far fa-times fa-lg"></i>
		</button>
		<span>{{user.firstName}} {{user.lastName}}</span>
	</div>
</div>

<div *ngIf="!isComplete" class="card-div-container flex justify-center mb-6">
	<mat-card appearance="outlined" class="w-full overflow-hidden rounded-xl shadow-card p-4" data-cy="card">
		<div class="flex content-start w-full mb-2">
			<div class="mr-3"><i [ngClass]="condition.icon" class="text-3xl font-black"></i></div>
			<div class="card-title">{{ condition.name }}</div>
		</div>
		<div class="flex items-center">
			<div class="condition-description">{{ condition.description }}</div>
		</div>
		<div class="flex justify-end">
			<button mat-button color="primary" class="cursor-pointer p-2" (click)="openModal()">
				Begin Verification
			</button>
		</div>
	</mat-card>
</div>

<div class="modal-overlay" *ngIf="isModalOpen">
	<div class="centered-modal">
		<mat-card appearance="outlined" class="rounded-xl shadow-card h-full">
			<span class="w-full absolute">
				<button
					mat-icon-button
					tabindex="0"
					class="modal-close-button absolute cursor-pointer"
					(click)="closeModal()"
				>
					<i class="fal fa-times text-2xl modal-close-icon"></i>
				</button>
			</span>
			<div id="vouched-element" class="h-full"></div>
		</mat-card>
	</div>
</div>

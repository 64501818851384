import { Component, OnInit, Renderer2 } from '@angular/core';
import { delay, filter } from 'rxjs/operators';
import { submitApplicationActivity } from '@common/lib/constants/activities';
import { interval, Subject } from 'rxjs';
import { OnDestroy } from '@angular/core';
import { LoadingService } from '@experience/app/services/loading.service';

@Component({
	selector: 'experience-loading',
	templateUrl: './loading.component.html',
	styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent implements OnInit, OnDestroy {
	public loaderClass: string = 'inactive';
	public fadeClass: boolean = false;
	public isSubmitting: boolean = false;
	public loadingText: string;
	protected destroy$: Subject<void> = new Subject<void>();

	constructor(private render: Renderer2, private loadingService: LoadingService) {
	}

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	ngOnInit(): void {
		this.loadingService.currentLoadingActivities$.subscribe(currentLoadingActivities => {
			if (currentLoadingActivities.length > 0) {
				this.isSubmitting = currentLoadingActivities.includes(submitApplicationActivity);
			}
		});

		this.loadingService.isLoading$.subscribe(isloading => {
			if (isloading) {
				this.draw();
			}
		});

		this.createEraseSubscription();
	}

	public draw(): void {
		this.render.addClass(document.body, 'overflow-hidden');
		this.fadeClass = false;

		this.loadingText = 'Checking for errors';

		const loadingTextArr = [
			'Reviewing your application',
			'Reviewing your data'
		];

		interval(2000).subscribe(() => {
			if (loadingTextArr.length > 0) {
				this.loadingText = loadingTextArr.pop();
			}
		});
	}

	public erase(): void {
		this.fadeClass = true;
		this.render.removeClass(document.body, 'overflow-hidden');
	}

	public createEraseSubscription(): void {
		this.loadingService.isLoading$.asObservable().pipe(
			filter((isLoading) => !isLoading),
			delay(1000)
		).subscribe(() => {
			this.erase();
		});
	}
}

import { Component, Injector, Input } from '@angular/core';
import { DynamicFormComponentBase } from '../../../dynamic/dynamic-form-component.base';
import { FormField } from '@common/lib/models/form-field.model';

@Component({
	selector: 'common-card-actions',
	templateUrl: './card-actions.component.html',
	styleUrls: ['./card-actions.component.scss']
})
export class CardActionsComponent extends DynamicFormComponentBase<CardActionsComponentFields, CardActionsComponentOptions> {
	@Input() data: CardInputData;

	constructor(
		public injector: Injector
	) {
		super(injector);
	}
}

interface CardActionsComponentFields extends Record<string, FormField> {

}

interface CardActionsComponentOptions {

}

interface CardInputData {
	expanded: boolean;
}

import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { DynamicComponentBase } from '../dynamic/dynamic-component.base';
import { FormField } from '@common/lib/models/form-field.model';
import { CommonFormService } from '@common/lib/services/common-form.service';

@Component({
	selector: 'common-product-header',
	templateUrl: './product-header.component.html',
	styleUrls: ['./product-header.component.scss']
})
export class ProductHeaderComponent extends DynamicComponentBase<ProductHeaderComponentFields, ProductHeaderComponentOptions> {
	public title$: Observable<string>;
	public details$: Observable<string[]>;
	public subtitle$: Observable<string>;
	public rate$: Observable<string>;
	public icon$: Observable<string>;

	constructor(public formService: CommonFormService) {
		super();
	}

	onComponentLoaded(): void {
		this.title$ = this.formService.getModelValue$<string>(this.fields?.title?.modelPaths, this.options?.titleValue);
		this.subtitle$ = this.formService.getModelValue$<string>(this.fields?.subtitle?.modelPaths, this.options?.subtitleValue);
		this.details$ = this.formService.getModelValue$<string[]>(this.fields?.details?.modelPaths, [this.options?.detailsValue]);
		this.rate$ = this.formService.getModelValue$<string>(this.fields?.rate?.modelPaths, this.options?.rateValue);
		this.icon$ = this.formService.getModelValue$<string>(this.fields?.icon?.modelPaths, this.options?.iconClassesValue);
	}
}

interface ProductHeaderComponentFields extends Record<string, FormField> {
	title: FormField;
	details: FormField;
	subtitle: FormField;
	rate: FormField;
	icon: FormField;
}

interface ProductHeaderComponentOptions {
	titleValue: string;
	detailsValue: string;
	subtitleValue: string;
	rateValue: string;
	iconClassesValue: string;
}

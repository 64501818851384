import { Component, Injector } from '@angular/core';
import { DynamicFormComponentBase } from '../../dynamic/dynamic-form-component.base';
import { FormField } from '@common/lib/models/form-field.model';

@Component({
	selector: 'common-toggle',
	templateUrl: './toggle.component.html',
	styleUrls: ['./toggle.component.scss'],
})
export class ToggleComponent extends DynamicFormComponentBase<ToggleComponentFields, ToggleComponentOptions> {
	public labelClass: string;

	constructor(public injector: Injector) {
		super(injector);
	}

	onComponentLoaded(): void {
		this.registerAllControls({ debounceTime: 350 });
		if (this.options?.isDisabled) {
			this.internalForm.controls[this.fields?.enabled?.name].disable({ emitEvent: false });
		}
		this.containerClass = this.options?.containerClass;
		this.labelClass = this.options?.labelClass;
	}
}

interface ToggleComponentFields extends Record<string, FormField> {
	enabled: FormField;
}

interface ToggleComponentOptions {
	containerClass: string;
	label: string;
	labelClass: string;
	isDisabled: boolean;
	onLabel: string;
	offLabel: string;
}

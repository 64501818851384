import {
	AbstractControl,
	AsyncValidatorFn,
	FormArray,
	FormBuilder,
	FormControl,
	FormGroup,
	ValidatorFn
} from '@angular/forms';
import { debounceTime, filter, first, mergeMap,  take, takeUntil } from 'rxjs/operators';
import { DynamicComponentBase } from './dynamic-component.base';
import { FormField } from '@common/lib/models/form-field.model';
import { ValidationRule } from '@common/lib/models/validation-rule';
import { ValidationRuleConverter } from '@common/lib/utilities/validation-rule-converter-utility';
import _ from 'lodash';
import { Component, ElementRef, Injector, OnDestroy } from '@angular/core';
import { DynamicRegistrationUtility } from '@common/lib/utilities/dynamic-registration.utility';
import { DynamicModelValue } from '@common/lib/models/model-value/dynamic-model-value';
import { StaticModelValue } from '@common/lib/models/model-value/static-model-value';
import { CommonFormService } from '@common/lib/services/common-form.service';
import { ValidationRuleTypes } from '@common/lib/models/enum/validation-rule-types.enum';
import { ElementReviewItemValidator } from '@common/lib/validators/element-review-item.validator';
import { ModelReviewItemValidator } from '@common/lib/validators/model-review-item.validator';
import { JsonPatchUtility } from '@common/lib/utilities/json-patch-utility';
import { ModelStateService } from '@experience/app/services/model-state.service';
import { PresentationService } from '@experience/app/services/presentation.service';

type TFieldGeneric = Record<string, FormField>;
type TOptionGeneric = Record<string, any>;

@Component({
	selector: 'common-dynamic-base',
	template: `
		<div></div>`
})
// eslint-disable-next-line @angular-eslint/component-class-suffix,max-len
export class DynamicFormComponentBase<TFields extends TFieldGeneric, TOptions extends TOptionGeneric> extends DynamicComponentBase<TFields, TOptions> implements OnDestroy {

	public internalForm: FormGroup;
	public formService: CommonFormService;
	public formBuilder: FormBuilder;
	public elementRef: ElementRef;
	public modelStateService: ModelStateService;
	public presentationService: PresentationService;
	private dynamicRegistrationUtility: DynamicRegistrationUtility;
	private focusedControl: AbstractControl;
	private validationRuleConverter: ValidationRuleConverter;
	private modelReviewItemValidators: Record<string, ModelReviewItemValidator> = {};

	constructor(injector: Injector) {
		super();
		this.formService = injector.get(CommonFormService);
		this.dynamicRegistrationUtility = injector.get(DynamicRegistrationUtility);
		this.formBuilder = injector.get(FormBuilder);
		this.elementRef = injector.get(ElementRef);
		this.validationRuleConverter = new ValidationRuleConverter();
		this.internalForm = this.formBuilder.group({});
		this.modelStateService = injector.get(ModelStateService);
		this.presentationService = injector.get(PresentationService);
	}

	public setFocusedControl(control: AbstractControl): void {
		this.focusedControl = control;
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public onFormControlValueChanges(controlName: string, newValue: any): any {
	}

	public clearFocusedControl(): void {
		this.focusedControl = null;
	}

	public registerComponentInternals(): void {
		if (this.parentForm) {
			this.internalForm = this.parentForm?.registerControl(this.id, this.formBuilder.group({})) as FormGroup;
		}
		DynamicComponentBase.formMaps.set(this.id, this.internalForm);
	}

	public ngOnDestroy(): void {
		super.ngOnDestroy();
		this.dynamicRegistrationUtility.unRegister(`component-form-get-${ this.id }`);
	}

	protected registerControl(formField: FormField): FormControl;

	// eslint-disable-next-line max-len
	protected registerControl(path: (DynamicModelValue | StaticModelValue)[] | FormField, name: string, validators: ValidationRule[], options?: FormControlRegistrationOptions): FormControl;

	// eslint-disable-next-line max-len
	protected registerControl(pathOrFormField: (DynamicModelValue | StaticModelValue)[] | FormField, name?: string, validators?: ValidationRule[], options?: FormControlRegistrationOptions): FormControl {
		if (!pathOrFormField) {
			return;
		}

		let path;

		if (pathOrFormField instanceof Array) {
			path = pathOrFormField;
		} else {
			path = pathOrFormField.modelPaths;
			name = pathOrFormField.name;
			validators = pathOrFormField.validationRules;
		}

		const control = this.buildFormControl(validators);
		const jsonPointer = JsonPatchUtility.convertJsonPathToJsonPointer(path[0]?.path);
		const modelReviewItemValidator = this.modelReviewItemValidators[jsonPointer];

		let controlValueChanges$ = control.valueChanges;
		if (options?.debounceTime > 0) {
			controlValueChanges$ = controlValueChanges$.pipe(debounceTime(options?.debounceTime));
		}
		controlValueChanges$.pipe(
			mergeMap((newValue) => {
				if (modelReviewItemValidator && options?.pendingChangesBehavior !== 'visible') {
					modelReviewItemValidator.pendingChanges++;
					control.markAsPending({ emitEvent: false });
					control.markAsUntouched();
				}

				const finalValue = this.onFormControlValueChanges(name, newValue) || newValue;
				if (newValue !== finalValue) {
					control.setValue(finalValue, { emitEvent: false });
				}
				this.formService.setModelValue(finalValue, path);

				return this.presentationService.isReady$.pipe(
					filter(isReady => isReady === true),
					first()
				);
			})
		).subscribe({
			next: () => {
				if (modelReviewItemValidator && options?.pendingChangesBehavior !== 'visible') {
					modelReviewItemValidator.pendingChanges--;
					setTimeout(() => {
						if (!modelReviewItemValidator?.pendingChanges) {
							control.updateValueAndValidity({ emitEvent: false });
							control.markAsTouched();
						}
					});
				}
			},
			error: () => {
				if (modelReviewItemValidator && options?.pendingChangesBehavior !== 'visible') {
					modelReviewItemValidator.pendingChanges--;
					setTimeout(() => {
						if (!modelReviewItemValidator?.pendingChanges) {
							control.updateValueAndValidity({ emitEvent: false });
							control.markAsTouched();
						}
					});
				}
			}
		});

		if (options?.isDisabled) {
			control.disable({ emitEvent: false });
		}

		this.formService.getModelValue$(path).pipe(take(1)).subscribe((newValue) => {
			control.setValue(newValue, { emitEvent: false });
		});

		this.dynamicRegistrationUtility.registerElement(`component-form-get-${ this.id }`, this.elementRef.nativeElement,
			() => this.formService.getModelValue$(path).pipe(takeUntil(this.destroy$)).subscribe((newValue) => {
				if (this.focusedControl !== control) {
					if (newValue !== control.value) {
						control.setValue(newValue, { emitEvent: false });
					}
				}
			}),
			() => this.modelStateService.reviewData$.asObservable().pipe(takeUntil(this.destroy$)).subscribe(() => {
				if (!this.modelReviewItemValidators[path[0].path]?.pendingChanges) {
					control.updateValueAndValidity({ emitEvent: false });
				}
			})
		);

		this.internalForm.registerControl(name, control);

		if (this.parentForm) {
			this.parentForm.registerControl(this.id, this.internalForm);
		}

		return control;
	}

	protected buildFormControl(validationRules: ValidationRule[]): FormControl {
		const validators: ValidatorFn[] = [];
		const asyncValidators: AsyncValidatorFn[] = [];

		_.forEach(validationRules, (rule: ValidationRule) => {
			const validator
				= this.validationRuleConverter.validationRuleToValidator(rule);

			if (validator) {
				validators.push(validator);
			}
			const reviewData$ = this.modelStateService.reviewData$;
			let asyncValidator;

			switch (rule.type) {
				case ValidationRuleTypes.elementReviewItems:{
					const elementReviewItemValidator = new ElementReviewItemValidator();
					asyncValidator = elementReviewItemValidator.createValidatorFn(this.id, reviewData$);
					break;
				}
				case ValidationRuleTypes.modelReviewItems:{
					const modelValue = this.formService.getModelValuePath$(rule.modelValue);
					const modelReviewItemValidator = new ModelReviewItemValidator();
					this.modelReviewItemValidators[(rule.modelValue as DynamicModelValue).path] = modelReviewItemValidator;
					asyncValidator = modelReviewItemValidator.createValidatorFn(modelValue,
						reviewData$);
					break;
				}
				default:
					return null;
			}

			if (asyncValidator) {
				asyncValidators.push(asyncValidator);
			}
		});

		return new FormControl(null, validators, asyncValidators);
	}

	protected createArray(name: string, targetForm: FormGroup = this.internalForm): FormArray {
		const array = this.formBuilder.array([]);
		targetForm.registerControl(name, array);
		return array;
	}

	protected addFormGroupToArray(targetArray: FormArray): FormGroup {
		const fg = new FormGroup({});
		targetArray.push(fg);
		return fg;
	}

	protected registerAllControls(options?: FormControlRegistrationOptions): void {
		_.forEach(this.fields, (field) => {
			this.registerControl(field.modelPaths, field.name, field.validationRules, options);
		});
	}
}

interface FormControlRegistrationOptions {
	isDisabled?: boolean;
	debounceTime?: number;
	/**
	 * Determines if validation errors are shown during async validation.
	 * 'hidden' - All validation errors are hidden during revalidation
	 * 'visible' - Validation is shown even if it isn't the most current validations results.
	 */
	pendingChangesBehavior?: 'hidden' | 'visible';
}



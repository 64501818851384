import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { DynamicComponentBase } from '../../dynamic/dynamic-component.base';
import { FormField } from '@common/lib/models/form-field.model';
import { CommonFormService } from '@common/lib/services/common-form.service';

@Component({
	selector: 'common-email-button',
	templateUrl: './link-button.component.html',
	styleUrls: ['./link-button.component.scss']
})
export class LinkButtonComponent extends DynamicComponentBase<LinkButtonComponentFields, LinkButtonComponentOptions> {
	public buttonText$: Observable<string>;
	public link$: Observable<string>;
	public disabled: boolean = false;
	public divClasses: string;
	public linkClasses: string;

	private defaultDivClasses: string = 'flex justify-start p-0';
	private defaultLinkClasses: string = 'text-left whitespace-normal text-lg p-1';

	constructor(
		private formService: CommonFormService
	) {
		super();
		this.divClasses = this.defaultDivClasses;
		this.linkClasses = this.defaultLinkClasses;
	}

	onComponentLoaded(): void {
		this.buttonText$ = this.formService.getModelValue$<string>(this.fields?.buttonTextPath?.modelPaths, this.options?.buttonTextValue);
		this.disabled = this.options?.disabled === 'true';
		this.link$ = this.formService.getModelValue$<string>(this.fields?.linkPath?.modelPaths, this.options?.linkUrlValue);
		this.divClasses = this.options?.divClasses || this.defaultDivClasses;
		this.linkClasses = this.options?.linkClasses || this.defaultLinkClasses;
	}
}

interface LinkButtonComponentFields extends Record<string, FormField> {
	linkPath: FormField;
	buttonTextPath: FormField;
}

interface LinkButtonComponentOptions {
	disabled: string;
	buttonTextValue: string;
	linkUrlValue: string;
	divClasses: string;
	linkClasses: string;
}

<form [formGroup]="businessApplicantForm">
	<div class="flex flex-col gap-4">
		<app-divider classes="mt-4 mb-4" text="Business Details"></app-divider>
		<mat-form-field>
			<mat-label>Legal Business Name</mat-label>
			<input formControlName="name" matInput required data-cy="input-field" />
			@if (businessApplicantForm.get('name').hasError('required')) {
				<mat-error>Please include your business's legal name.</mat-error>
			}
			@if (businessApplicantForm.get('name').hasError('maxLength')) {
				<mat-error>Your business's legal name must be 40 characters or less.</mat-error>
			}
		</mat-form-field>
		<div class="flex justify-between mb-4">
			<div>
				<mat-label class="text-base">Do you do business under a DBA name?</mat-label>
			</div>
			<div class="'text-base flex justify-end mb-4'">
				<mat-label class="mx-3 text-base">No</mat-label>
				<!--				standalone: true keeps toggle out of form controls, tabIndex allows tabbing to this-->
				<mat-slide-toggle
					[(ngModel)]="$hasDbaName"
					(ngModelChange)="onDbaNameChange()"
					[ngModelOptions]="{ standalone: true }"
					tabIndex="0"
					class="nbkc-blue"
					data-cy="toggle-field"
				>
				</mat-slide-toggle>
				<mat-label class="mx-3 text-base">Yes</mat-label>
			</div>
		</div>
		@if ($hasDbaName()) {
			<mat-form-field>
				<mat-label>Business Name</mat-label>
				<input formControlName="doingBusinessAs" matInput required data-cy="input-field" />
				@if (businessApplicantForm.get('doingBusinessAs').hasError('required')) {
					<mat-error>Please include {{ $applicantNameOrDefault() }}'s legal name.</mat-error>
				}
				@if (businessApplicantForm.get('doingBusinessAs').hasError('maxLength')) {
					<mat-error>{{ $applicantNameOrDefault() }}'s legal name must be 36 characters or less.</mat-error>
				}
			</mat-form-field>
		}
		<mat-form-field>
			<mat-label>Business Structure Type</mat-label>
			<mat-select
				formControlName="structure"
				(blur)="saveApplication()"
				required
				[attr.tabindex]="0"
				data-cy="select-field"
			>
				@for (structure of $lookups().BusinessStructures; track structure.value) {
					<mat-option [value]="structure.value">{{ structure.display }}</mat-option>
				}
			</mat-select>
			@if (businessApplicantForm.get('structure').hasError('required')) {
				<mat-error>Please include {{ $applicantNameOrDefault() }}'s business structure.</mat-error>
			}
		</mat-form-field>
		<mat-form-field>
			<mat-label>Business Registration U.S. State or Territory</mat-label>
			<mat-select formControlName="registeredState" required [attr.tabindex]="0" data-cy="select-field">
				@for (state of $lookups().States; track state.value) {
					<mat-option [value]="state.value">{{ state.display }}</mat-option>
				}
			</mat-select>
			@if (businessApplicantForm.get('registeredState').hasError('required')) {
				<mat-error>Please include {{ $applicantNameOrDefault() }}'s registered state.</mat-error>
			}
		</mat-form-field>
		@if (businessApplicantForm.get('structure').value === 'Sole Proprietor') {
			<div class="flex justify-between mb-4">
				<div>
					<mat-label class="text-base">Do you have an Employer Identification Number (EIN)?</mat-label>
				</div>
				<div class="'text-base flex justify-end mb-4'">
					<mat-label class="mx-3 text-base">No</mat-label>
					<!--				standalone: true keeps toggle out of form controls, tabIndex allows tabbing to this-->
					<mat-slide-toggle
						[(ngModel)]="$hasEIN"
						(ngModelChange)="onHasEINChange()"
						[ngModelOptions]="{ standalone: true }"
						tabIndex="0"
						class="nbkc-blue"
						data-cy="toggle-field"
					>
					</mat-slide-toggle>
					<mat-label class="mx-3 text-base">Yes</mat-label>
				</div>
			</div>
		}
		<div formGroupName="taxIdentification">
			<app-tax-identification
				[$taxIdentificationFormGroup]="$taxIdentification()"
				[$applicantNameOrDefault]="$applicantNameOrDefault()"
				[$isSSN]="!$hasEIN()"
				(blur)="saveApplication()"
			></app-tax-identification>
		</div>

		<app-divider classes="mt-4 mb-4" text="Business Activities"></app-divider>
		<mat-form-field>
			<mat-label>Tell Us About Your Business</mat-label>
			<textarea formControlName="description" matInput required rows="5" data-cy="textarea-field"> </textarea>
			<mat-hint
				>Minimum length 80 characters. Maximum length 500 characters.
				<ul class="list-disc ml-4">
					<li>What does your business do?</li>
					<li>How do you market your product or services?</li>
					<li>How do you plan to use your account?</li>
				</ul>
			</mat-hint>
			@if (businessApplicantForm.get('description').hasError('required')) {
				<mat-error>Please include a description of {{ $applicantNameOrDefault() }}.</mat-error>
			}
			@if (businessApplicantForm.get('description').hasError('minLength')) {
				<mat-error>{{ $applicantNameOrDefault() }}'s description must be at least 80 characters.</mat-error>
			}
			@if (businessApplicantForm.get('description').hasError('maxLength')) {
				<mat-error>{{ $applicantNameOrDefault() }}'s description must be 500 characters or less.</mat-error>
			}
		</mat-form-field>
		<mat-form-field>
			<mat-label>Business Industry</mat-label>
			<mat-select formControlName="naicsCode" required [attr.tabindex]="0" data-cy="select-field">
				@for (industry of $lookups().BusinessIndustries; track industry.value) {
					<mat-option [value]="industry.value">{{ industry.display }}</mat-option>
				}
			</mat-select>
			@if (businessApplicantForm.get('naicsCode').hasError('required')) {
				<mat-error>Please include {{ $applicantNameOrDefault() }}'s industry.</mat-error>
			}
		</mat-form-field>
		<mat-form-field>
			<mat-label>Phone Number</mat-label>
			<app-masked-input
				[formControl]="businessApplicantForm.get('phoneNumber') | formControl"
				maskType="phone"
				[required]="true"
				data-cy="input-field"
			>
			</app-masked-input>
			<mat-hint
				>This phone number will be used if you need to activate your debit card or reset your
				password.</mat-hint
			>
			@if (businessApplicantForm.get('phoneNumber').hasError('required')) {
				<mat-error>Please include {{ $applicantNameOrDefault() }}'s phone number.</mat-error>
			}
			@if (businessApplicantForm.get('phoneNumber').hasError('mask')) {
				<mat-error>Please include a valid phone number for {{ $applicantNameOrDefault() }}.</mat-error>
			}
		</mat-form-field>
		<mat-form-field>
			<mat-label>Business Website or Social Media Link</mat-label>
			<input formControlName="website" (blur)="saveApplication()" matInput required data-cy="input-field" />
			@if (businessApplicantForm.get('website').hasError('required')) {
				<mat-error>Please include a website for {{ $applicantNameOrDefault() }}.</mat-error>
			}
		</mat-form-field>

		<app-divider classes="mt-4 mb-4" text="Address"></app-divider>

		<div formArrayName="addresses">
			<!--		Physical Address  -->
			<app-address-form [$addressForm]="getAddressGroup(0)" [$applicantNameOrDefault]="$applicantNameOrDefault()">
			</app-address-form>

			<div class="flex justify-between mt-4 mb-4">
				<div>
					<mat-label class="mx-3 text-base">Is this also the mailing address?</mat-label>
				</div>
				<div class="'text-base flex justify-end mb-4'">
					<mat-label class="mx-3 text-base">No</mat-label>
					<!--				standalone: true keeps toggle out of form controls, tabIndex allows tabbing to this-->
					<mat-slide-toggle
						[(ngModel)]="$usePhysicalAddressAsMailingAddress"
						(ngModelChange)="onChangeAddressType()"
						[ngModelOptions]="{ standalone: true }"
						tabIndex="0"
						class="nbkc-blue"
						data-cy="toggle-field"
					>
					</mat-slide-toggle>
					<mat-label class="mx-3 text-base">Yes</mat-label>
				</div>
			</div>

			<!--		Mailing Address -->
			@if (!$usePhysicalAddressAsMailingAddress()) {
				<app-address-form
					[$addressForm]="getAddressGroup(1)"
					[$applicantNameOrDefault]="$applicantNameOrDefault()"
				>
				</app-address-form>
			}
		</div>
	</div>
</form>

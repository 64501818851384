import {
	ApplicationInsightsEvent,
	ApplicationInsightsProviderMapping,
	ApplicationInsightsTrackingProvider,
	ConsoleTrackingProvider,
	TrackerEvent
} from '@nbkc/tracker';
import { ApplicationTrackerContext } from '../application-tracker-context';

export class ApplicationStartEvent extends TrackerEvent<ApplicationTrackerContext> implements ApplicationInsightsProviderMapping {
	public name: string = 'web-application-start';
	public providers = [ConsoleTrackingProvider, ApplicationInsightsTrackingProvider];

	public ai(): ApplicationInsightsEvent {
		const event = new ApplicationInsightsEvent();
		event.name = this.name;
		return event;
	}
}

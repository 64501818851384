import { Component, Injector } from '@angular/core';
import { MatRadioChange } from '@angular/material/radio';
import { DynamicFormComponentBase } from '@common/lib/components/dynamic/dynamic-form-component.base';
import { FormField } from '@common/lib/models/form-field.model';
import { Observable } from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
	selector: 'common-radio-button-group',
	templateUrl: './radio-button-group.component.html',
	styleUrls: ['./radio-button-group.component.scss']
})
export class RadioButtonGroupComponent extends DynamicFormComponentBase<RadioButtonGroupComponentFields, RadioButtonGroupComponentOptions>{
	public choices$: Observable<{displayName: string; value: string}[]>;

	constructor(
		public injector: Injector
	) {
		super(injector);
	}

	onComponentLoaded(): void {
		this.registerAllControls({ isDisabled: this.options?.isDisabled });
		this.containerClass = this.options?.containerClass;

		this.choices$ = this.formService
			.getModelValue$<Record<string, unknown>[] | string[]>(this.fields?.choices.modelPaths)
			.pipe(
				map(options => options.map(option => {
						if(typeof option === 'string') {
							return {displayName: option, value: option};
						}
						return {displayName: option?.displaySelector, value: option?.valueSelector};
					})));


	}

	public getRadioButtonDisplay(item: Record<string, unknown> | string): string {
		if (this.options?.displaySelector) {
			return item[this.options?.displaySelector];
		}

		return item as string;
	}

	public selectRadio(event: MatRadioChange) {
		this.formService.setModelValue(event.value, this.fields?.selected?.modelPaths);
	}
}

interface RadioButtonGroupComponentFields extends Record<string, FormField> {
	choices: FormField;
	selected: FormField;
	isVisible: FormField;
}

interface RadioButtonGroupComponentOptions {
	label: string;
	containerClass: string;
	isDisabled: boolean;
	valueSelector: string;
	displaySelector: string;
	isVisibleWhen: string;
}

import { Component, ElementRef, Injector, ViewChild } from '@angular/core';
import { DynamicFormComponentBase } from '../../dynamic/dynamic-form-component.base';
import { FormField } from '@common/lib/models/form-field.model';
import { ValidationRuleTypes } from '@common/lib/models/enum/validation-rule-types.enum';
import { tap } from 'rxjs/operators';
import { OnboardRequest } from '@common/lib/models/onboard-request.model';
import { FormControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { DynamicModelValue } from '@common/lib/models/model-value/dynamic-model-value';
import { ExperienceConfiguration } from '@experience/app/experience.configuration';

@Component({
	selector: 'common-onboarding',
	templateUrl: './onboarding.component.html',
	styleUrls: ['./onboarding.component.scss']
})
export class OnboardingComponent extends DynamicFormComponentBase<OnboardingComponentFields, OnboardingComponentOptions> {

	@ViewChild('inputElement', { static: true }) public inputElement: ElementRef<HTMLInputElement>;
	@ViewChild('loginSubmitButton') loginSubmitButton;
	public nextRoute: string = '';
	public enrollmentInProgress: boolean = false;
	public passwordMeetsRequirements: boolean = false;
	public userNameIsValid = true;
	public q2LoginFormURL = '';

	constructor(public injector: Injector, private config: ExperienceConfiguration) {
		super(injector);
	}

	public onComponentLoaded(): void {
		this.q2LoginFormURL = this.config.q2LoginFormURL;

		this.internalForm.addControl('username', new FormControl('', {
			validators: [
				Validators.required,
				this.usernameMustBeValid()
			],
			updateOn: 'change'
		}));

		this.internalForm.addControl('checked', new FormControl('', {
			validators: [
				Validators.requiredTrue
			],
			updateOn: 'change'
		}));

		this.registerAllControls({ isDisabled: this.options?.isDisabled, pendingChangesBehavior: 'visible' });

		this.internalForm.get('username').valueChanges.subscribe(() => {
			if (!this.userNameIsValid) {
				this.userNameIsValid = true;
			}
		});

		const maxLengthValidationRule = this.fields?.username?.validationRules?.find((rule) => rule.type === ValidationRuleTypes.maxLength);
		if (maxLengthValidationRule) {
			this.inputElement.nativeElement.maxLength = +maxLengthValidationRule.constraintValue;
		}
	}

	passwordMeetsRequirementsUpdate(meetsRequirements: boolean) {
		this.passwordMeetsRequirements = meetsRequirements;
	}

	public checkSubmitOnEnter(e: Event): void {
		e.preventDefault();
		if (!this.internalForm.valid || this.enrollmentInProgress || !this.passwordMeetsRequirements) {
			return;
		}
		this.attemptEnroll();
	}

	public attemptEnroll(): void {
		this.enrollmentInProgress = true;

		this.presentationService.onboardApplication(new OnboardRequest(
			this.internalForm.get('username').value.trim(),
			this.internalForm.get('password').value.trim()
			))
			.pipe(
				tap(() => {

					const reviewData = this.modelStateService.reviewData$.getValue();

					if (reviewData?.reviewItems?.length === 0) {
						this.loginSubmitButton.nativeElement.click();
						return;
					}

					if (reviewData.reviewItems.find(x => x.modelPath === (this.fields['username'].modelPaths[0] as DynamicModelValue).path)) {
						this.userNameIsValid = false;
						this.internalForm.get('username').updateValueAndValidity();
					}

					this.enrollmentInProgress = false;

				})
			).subscribe({error: () => this.enrollmentInProgress = false});
	}

	usernameMustBeValid(): ValidatorFn {
		return (): ValidationErrors | null => {
			const isValid = this.userNameIsValid;
			const reviewData = this.modelStateService.reviewData$.getValue();
			const targetReviewItem = reviewData.reviewItems.find(x => x.modelPath === (this.fields['username'].modelPaths[0] as DynamicModelValue).path);
			return isValid ? null : { reviewItems: targetReviewItem.message };
    };
  }
}

export interface OnboardingComponentFields extends Record<string, FormField> {
	username: FormField;
	firstName: FormField;
	lastName: FormField;
	email: FormField;
}

export interface OnboardingComponentOptions {
	isDisabled: boolean;
	label: string;
	subtext: string;
	containerClass: string;
	class: string;
	textClass: string;
}

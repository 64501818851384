import { ValidationRule } from './validation-rule';
import { ModelValueCollection } from '@common/lib/models/model-value/model-value.types';

export class FormField {
	public name: string;
	public modelPaths: ModelValueCollection;
	public formState?: any;
	public validationRules: ValidationRule[];
	public context?: string;
}

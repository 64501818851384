import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { DynamicComponentBase } from '../dynamic/dynamic-component.base';
import { FormField } from '@common/lib/models/form-field.model';
import { CommonFormService } from '@common/lib/services/common-form.service';
import { map } from 'rxjs/operators';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
	selector: 'common-text',
	templateUrl: './text.component.html',
	styleUrls: ['./text.component.scss']
})
export class TextComponent extends DynamicComponentBase<TextComponentFields, TextComponentOptions> {
	public text$: Observable<SafeHtml>;
	public textClass: string;
	public class: string;

	constructor(public formService: CommonFormService, private sanitizer: DomSanitizer) {
		super();
	}

	onComponentLoaded(): void {
		this.text$ = this.formService.getModelValue$<string>(this.fields?.text?.modelPaths, this.options?.textValue).pipe(
			map((value) => this.sanitizer.bypassSecurityTrustHtml(value))
		);
		this.class = this.options?.class;
		this.textClass = this.options?.textClass;
		this.containerClass = this.options?.containerClass;
	}
}

interface TextComponentFields extends Record<string, FormField> {
	text: FormField;
}

interface TextComponentOptions {
	class: string;
	textClass: string;
	textValue: string;
	containerClass: string;
}

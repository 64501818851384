import { HttpErrorResponse } from '@angular/common/http';
import {
	ApplicationInsightsError,
	ApplicationInsightsProviderMapping,
	ApplicationInsightsTrackingProvider,
	ConsoleTrackingProvider,
	TrackerError
} from '@nbkc/tracker';
import { ApplicationTrackerContext } from '@experience/app/tracking/application-tracker-context';

export class HttpError extends TrackerError<ApplicationTrackerContext> implements ApplicationInsightsProviderMapping {
	public name: string = 'http-request-failure';
	public providers = [ConsoleTrackingProvider, ApplicationInsightsTrackingProvider];

	public constructor(public data: HttpErrorProps) {
		super(new Error('http request failed'));
	}

	public ai(): ApplicationInsightsError {
		const aiEvent = new ApplicationInsightsError();
		aiEvent.name = this.name;
		aiEvent.extras = this.data.error;
		aiEvent.error = this.error;
		return aiEvent;
	}
}

interface HttpErrorProps {
	error: HttpErrorResponse;
}

import { ComponentDefinition } from '@common/lib/models/component-definition.model';
import { AdapterBase, AdapterOptions, CollectionAdapterOptions } from '@common/lib/utilities/adapter-base';
import _ from 'lodash';
import { FieldResourceToFormFieldAdapter } from './field-resource-to-form-field.adapter';
import { ComponentContext } from '@common/lib/models/component-context';
import { ElementResource } from '@common/lib/models/resource/element-resource';

export class ElementResourceToComponentDefinitionAdapter extends AdapterBase<ElementResource, ComponentDefinition> {
	private fieldResourceToFormFieldAdapter: FieldResourceToFormFieldAdapter = new FieldResourceToFormFieldAdapter();

	adapt(source: ElementResource, options?: ElementResourceToComponentDefinitionAdapterOptions): ComponentDefinition {
		const dest = new ComponentDefinition();
		if (options?.elementMap && options.elementMap[source.type]) {
			source.type = options.elementMap[source.type];
		}
		dest.componentType = options?.elementMap?.[source.type] ?? source?.type;
		if (!source?.type) {
			// eslint-disable-next-line no-console
			console.error('Missing Component Type or Component Type Not Found!', source);
		}
		dest.options = source?.options;
		dest.fields = { };
		_.forEach(source?.fields, (item) => {
			dest.fields[item.fieldName] = this.fieldResourceToFormFieldAdapter.adapt(item);
		});
		dest.components = this.adaptCollection(source?.elements, { screenName : options.screenName});
		if (source?.id) {
			dest.id = source?.id;
		}
		dest.context = new ComponentContext();
		dest.context.screen = options.screenName;
		return dest;
	}

	adaptCollection(sourceCollection: Array<ElementResource>,
					options: ElementResourceToComponentDefinitionCollectionAdapterOptions): Array<ComponentDefinition> {
		return super.adaptCollection(sourceCollection, options);
	}
}

interface ElementResourceToComponentDefinitionAdapterOptions extends AdapterOptions {
	screenName: string;
	elementMap?: Record<string, string>;
}

interface ElementResourceToComponentDefinitionCollectionAdapterOptions extends CollectionAdapterOptions {
	screenName: string;
	elementMap?: Record<string, string>;
}

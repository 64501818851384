<div [ngClass]="containerClass || 'w-full'">
	<form [formGroup]="internalForm">
		<mat-form-field class="w-full" appearance="outline" >
			<mat-label>{{options?.label}}</mat-label>
			<textarea [maxLength]="options?.maxLength ?? ''" matInput formControlName="value"
				class="{{textareaClass}}"
				(focus)="setFocusedControl(internalForm.get('value'))"
				(blur)="clearFocusedControl()">
			</textarea>
		</mat-form-field>
		<div class="text-10.5px pt-2.5">
			<common-character-length *ngIf="options?.characterCountEnabled" 
				[formField]="fields?.value" [validatedControl]="internalForm.get('value')" (isGuideVisible)="isCharacterLengthHelperVisible = $event">
			</common-character-length>
			<span class="subtext" *ngIf="subText$" [innerHTML]="subText$ | async"></span>
			<common-validation-messages [validatedControl]="internalForm.get('value')" [associatedField]="fields['value']"></common-validation-messages>
		</div>
	</form>
</div>


import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ApplicationStateService } from '@experience/app/services/application-state.service';
import { AppTodoHeaderComponent } from '@experience/app/components-new/app-todo-header/app-todo-header.component';
import { AppCardComponent } from '@experience/app/components-new/card/app-card.component';
import { NextTodoButtonComponent } from '@experience/app/components-new/next-button/next-todo-button.component';
import { AppRoutingService } from '@experience/app/services/app-routing.service';
import { MatButton } from '@angular/material/button';
import { KnownRoutes } from '@experience/app/constants/known-routes';
import { ApplicantKind } from '@experience/app/models/enums/applicant-kind';

@Component({
	selector: 'app-add-people',
	standalone: true,
	imports: [AppTodoHeaderComponent, AppCardComponent, NextTodoButtonComponent, MatButton],
	templateUrl: './add-people.component.html',
	styleUrl: './add-people.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddPeopleComponent {
	private applicationStateService = inject(ApplicationStateService);
	private routingService = inject(AppRoutingService);
	$application = this.applicationStateService.$application;

	constructor() {}

	onAddSomeoneClicked(): void {
		const applicantIndex = this.$application()?.applicants?.length;
		this.routingService.routeToApplicationScreen(KnownRoutes.ApplicantDetails, applicantIndex.toString());
	}

	onCardActionClicked(event: string, index: number): void {
		switch (event) {
			case 'Edit Profile':
				this.routingService.routeToApplicationScreen(KnownRoutes.ApplicantDetails, index.toString());
				break;
			case 'Remove':
				this.onRemoveClicked(index);
				break;
		}
	}

	onRemoveClicked(index: number): void {
		const updatedApplicants = this.$application().applicants.filter((_, i) => i !== index);
		this.applicationStateService.updateApplicationValues({ applicants: updatedApplicants });
	}

	protected readonly ApplicantKind = ApplicantKind;
}

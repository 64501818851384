import { NgClass } from '@angular/common';
import { Component, input } from '@angular/core';

@Component({
	selector: 'app-divider',
	standalone: true,
	imports: [NgClass],
	templateUrl: './app-divider.component.html',
	styleUrl: './app-divider.component.scss',
})
export class AppDividerComponent {
	text = input<string>('');
	classes = input<string>('');
}

export abstract class CacheProvider {
	public get<T>(key: string): T | string {
		const valueFromCache = this.getValuesFromCache(key);
		return this.tryToSerializeAsObject<T>(valueFromCache);
	}

	public clear(key: string): void {
		this.removeValueFromCache(key);
	}

	public set<T>(key: string, value?: T): void {
		this.setValue<T>(key, value);
	}

	private tryToSerializeAsObject<T>(value: string): T | string {
		try {
			return JSON.parse(value);
		} catch (error) {
			return value;
		}
	}

	protected abstract setValue<T>(key: string, value: T): void;

	protected abstract removeValueFromCache(key: string): void;

	protected abstract getValuesFromCache(key: string): string;


}

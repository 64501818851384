import { IntentStatus } from '@common/lib/constants/intent-status';
import { DocumentUploadData } from './document-upload-data.model';

export class Condition {
	id: string;
	name: string;
	description: string;
	conditionType: string;
	icon: string;
	documentType: string;
	status: IntentStatus;
	recentFiles: DocumentUploadData[];
	totalFileCount: number;
}

import { ModelValueType } from '@common/lib/models/model-value/model-value.types';

/**
 * Used to represent a value that is resolved at runtime that doesn't change.
 * Probably will deprecate. Shouldn't be used anywhere and not sure if it buys us anything.
 *
 * @deprecated
 */
export class StaticModelValue {
	public value: string;
	public type: ModelValueType = 'static';
}

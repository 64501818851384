<app-todo-header></app-todo-header>
@for (person of $application().applicants; track person.id; let i = $index) {
	<div class="select-container flex justify-center mb-4" [class.hidden]="person.kind === ApplicantKind.Primary">
		<app-card
			[title]="person.firstName + ' ' + person.lastName"
			[subtitle]="person.email"
			headerIcon="fa-user"
			[cardActions]="['Remove', 'Edit Profile']"
			(actionClicked)="onCardActionClicked($event, i)"
			class="w-full cursor-pointer"
		>
		</app-card>
	</div>
}

<div class="select-container flex justify-center mb-4">
	<app-card (click)="onAddSomeoneClicked()" class="w-full cursor-pointer">
		<div class="text-2xl nbkc-dark-gray">
			<i class="far fa-plus-circle m-4 nbkc-blue fa-xl"></i>
			Add someone else to the account
		</div>
	</app-card>
</div>

<app-next-todo-button></app-next-todo-button>

import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';

@Injectable({
	providedIn: 'root',
})
export class NotificationService {
	private snackBarInstance: MatSnackBarRef<SimpleSnackBar>;
	private snackBarDefaultDismissDelayInSeconds: number = 20;
	private snackBarDefaultDismissText: string = 'close';

	constructor(private snackBar: MatSnackBar) {}

	public displaySnackBarMessage(message: string): void {
		this.displaySnackBarNotification(message);
	}

	private displaySnackBarNotification(message: string, dismissText?: string, dismissDelay?: number): void {
		dismissText = dismissText || this.snackBarDefaultDismissText;
		dismissDelay = dismissDelay || this.snackBarDefaultDismissDelayInSeconds;
		this.snackBarInstance = this.snackBar.open(message, dismissText.toUpperCase(), {
			duration: dismissDelay * 1000,
			horizontalPosition: 'right',
			verticalPosition: 'bottom',
		});
	}
}

import { AdapterBase } from '@common/lib/utilities/adapter-base';
import { DocumentUploadComplete } from '@experience/app/models/document-upload-complete.model';
import { DocumentUploadCompleteRequestResource } from '@common/lib/models/resource/events/client/document-upload-complete-request-resource';
import { DocumentInfoResource } from '@common/lib/models/resource/document-info.resource';

export class DocumentUploadCompleteAdapter extends AdapterBase<DocumentUploadComplete, DocumentUploadCompleteRequestResource> {
	adapt(source: DocumentUploadComplete): DocumentUploadCompleteRequestResource {
		const dest = new DocumentUploadCompleteRequestResource();
		dest.documentGroupId = source.documentGroupId;
		dest.documentGroup = source.documentGroup;
		dest.documentNames = [];

		source.documents.forEach((doc) => {
			const resource = new DocumentInfoResource();
			resource.id = doc.id;
			resource.fileName = doc.file.name;
			dest.documentNames.push(resource);
		});

		return dest;
	}
}

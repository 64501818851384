import { Address } from '@common/lib/models/address';
import { AdapterBase } from '@common/lib/utilities/adapter-base';
import { AddressResource } from '@common/lib/models/resource/address/address-resource';

export class AddressApiToAddressFormAdapter extends AdapterBase<AddressResource, Address> {
	adapt(source: AddressResource): Address {
		if (source) {
			const addressItem = source;
			if (addressItem) {
				let addressLine = '';
				if (addressItem.address1 != null) {
					addressLine = addressItem.address1.trim();
				}
				if (addressItem.address2 != null) {
					if (addressItem.address1 != null) {
						addressLine = addressLine + ' ';
					}
					addressLine = addressLine + addressItem.address2.trim();
				}
				let freeFormAddress = '';

				if (addressLine != null && addressLine !== '') {
					freeFormAddress = addressLine;
				}

				if (addressItem.city != null) {
					if (freeFormAddress != null && freeFormAddress !== '') {
						freeFormAddress = freeFormAddress + ', ';
					}
					freeFormAddress = freeFormAddress + addressItem.city;
				}

				if (addressItem.stateCode != null) {
					if (freeFormAddress != null && freeFormAddress !== '') {
						freeFormAddress = freeFormAddress + ', ';
					}
					freeFormAddress = freeFormAddress + addressItem.stateCode;
				}

				if (addressItem.zip != null) {
					if (freeFormAddress != null && freeFormAddress !== '') {
						freeFormAddress = freeFormAddress + ' ';
					}
					freeFormAddress = freeFormAddress + addressItem.zip;
				}
				const returnAddress = new Address();
				returnAddress.address1 = addressLine;
				returnAddress.city = addressItem.city;
				returnAddress.state = addressItem.stateCode;
				returnAddress.zip = addressItem.zip;
				returnAddress.full = freeFormAddress;
				return returnAddress;

			} else {
				return new Address();
			}
		}
	}
}

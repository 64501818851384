import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ValidationRule } from '@common/lib/models/validation-rule';
import { ValidationRuleConverter } from '@common/lib/utilities/validation-rule-converter-utility';
import _ from 'lodash';
import { FormField } from '@common/lib/models/form-field.model';

@Component({
	selector: 'common-validation-messages',
	templateUrl: './validation-messages.component.html',
	styleUrls: ['./validation-messages.component.scss']
})
export class ValidationMessagesComponent {
	@Input() validatedControl: AbstractControl;
	@Input() associatedField: FormField;

	constructor(public validationRuleConverter: ValidationRuleConverter) {
	}

	public shouldShowValidationMessages(control: AbstractControl): boolean {
		if (control?.untouched || control?.pending) {
			return false;
		}

		return !!control?.errors;
	}

	public getValidationMessage(): string {
		if (!this.validatedControl || !this.validatedControl.errors) {
			return null;
		}

		const firstErrorKey = Object.keys(this.validatedControl.errors)[0];
		const errorValue = this.validatedControl.errors[firstErrorKey];

		if (_.isString(errorValue)) {
			return errorValue as string;
		} else {
			return this.getValidationMessageFromRule(firstErrorKey);
		}
	}

	private getValidationMessageFromRule(ruleName: string): string {
		if (!this.associatedField) {
			return 'Invalid';
		}

		const matchingRule = _.find(this.associatedField.validationRules, (rule: ValidationRule) =>
			rule.type === this.validationRuleConverter.angularValidatorNameToRuleType(ruleName));

		return matchingRule?.failureMessage ?? 'Invalid';
	}
}

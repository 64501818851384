import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { DynamicComponentBase } from '../dynamic/dynamic-component.base';
import { AccountSummaryDetails } from './account-summary-details.model';
import { FormField } from '@common/lib/models/form-field.model';
import { CommonFormService } from '@common/lib/services/common-form.service';

@Component({
	selector: 'common-account-profile-summary',
	templateUrl: './account-profile-summary.component.html',
	styleUrls: ['./account-profile-summary.component.scss']
})
export class AccountProfileSummaryComponent extends DynamicComponentBase<AccountProfileSummaryComponentFields, AccountProfileSummaryComponentOptions> {
	public title$: Observable<string>;
	public userName$: Observable<string>;
	public routingNumber$: Observable<string>;
	public accountInfo$: Observable<AccountSummaryDetails[]>;

	constructor(
		public formService: CommonFormService
	) {
		super();
	}

	onComponentLoaded(): void {
		this.title$ = this.formService.getModelValue$<string>(this.fields?.title?.modelPaths);
		this.userName$ = this.formService.getModelValue$<string>(this.fields?.username?.modelPaths);
		this.routingNumber$ = this.formService.getModelValue$<string>(this.fields?.routingNumber?.modelPaths);
		this.accountInfo$ = this.formService
			.getModelValue$<AccountSummaryDetails[]>(this.fields?.accountInfo?.modelPaths);
	}
}

interface AccountProfileSummaryComponentFields extends Record<string, FormField> {
	title: FormField;
	username: FormField;
	routingNumber: FormField;
}

interface AccountProfileSummaryComponentOptions {
}

import { ModelValue, ModelValueType } from '@common/lib/models/model-value/model-value.types';

/**
 * ModelValue wrapper for a value that is resolved at runtime.
 */
export class DynamicModelValue extends ModelValue {
	public path: string;
	public valueParams: DynamicModelValue[];
	public context: string;
	public type: ModelValueType = 'dynamic';
}

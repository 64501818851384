import { FormField } from '@common/lib/models/form-field.model';
import { AdapterBase } from '@common/lib/utilities/adapter-base';
import { FieldResource } from '@common/lib/models/resource/field-resource';
import { ModelValueResourceToModelValueAdapter } from '@common/lib/adapters/model-value-resource-to-model-value.adapter';

const modelValueToFormModelValueAdapter = new ModelValueResourceToModelValueAdapter();

export class FieldResourceToFormFieldAdapter extends AdapterBase<FieldResource, FormField> {
	adapt(source: FieldResource): FormField {
		const dest = new FormField();
		dest.modelPaths = modelValueToFormModelValueAdapter.adaptCollection(source?.modelPaths);
		dest.name = source?.fieldName;
		dest.validationRules = source.validationRules;
		dest.context = source.contextPointer;
		return dest;
	}
}


import { Injectable } from '@angular/core';
import { mapToCanActivate, NavigationEnd, NavigationStart, Route, Router, Routes } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { BodyComponent } from '@experience/app/components/body/body.component';
import { AvailableRouteGuard } from '@experience/app/services/guards/available-route.guard';
import { PresentationDefinition } from '@common/lib/models/presentation-definition';
import { AuthenticationGuard } from '@common/lib/utilities/authentication/guards/authentication.guard';
import { defaultRoutes } from '@experience/app/app-routing.module';
import { DynamicScreenComponent } from '@common/lib/components/dynamic/dynamic-screen.component';
import { PresentationService } from '@experience/app/services/presentation.service';
import { Tracker } from '@nbkc/tracker-angular';
import { OnlineBankingViewEvent } from './tracking/events/online-banking-view.event';

// TODO - This class can be removed after OldFin is decomissioned.
@Injectable({
	providedIn: 'root',
})
export class LegacyRouteMonitor {
	private readonly defaultRoutes = defaultRoutes;

	public constructor(
		private router: Router,
		private presentationService: PresentationService,
		private tracker: Tracker,
	) {}

	public start(): void {
		this.onRouteConfigurationChanges$().subscribe();

		this.router.events.subscribe((event) => {
			if (event instanceof NavigationStart) {
				if (event.url.startsWith('/workflow')) {
					this.presentationService.changeCurrentScreen(event.url);
				}
			}

			if (event instanceof NavigationEnd) {
				if (event.url === '/workflow/setup-online-banking') {
					if (this.presentationService.currentExperience?.appName === 'deposits-personal') {
						this.tracker.event(new OnlineBankingViewEvent({ experienceType: 'bluefin-view-olb-personal' }));
					} else if (this.presentationService.currentExperience?.appName === 'deposits-business') {
						this.tracker.event(new OnlineBankingViewEvent({ experienceType: 'bluefin-view-olb-business' }));
					}
				}
			}
		});
	}

	private onRouteConfigurationChanges$(): Observable<void> {
		return combineLatest([
			this.presentationService.presentationDefinitions$.pipe(filter((definitions) => !!definitions)),
			this.presentationService.todoList$.asObservable().pipe(
				filter((list) => !!list),
				map((list) => list.map((item) => item.associatedRoute)),
			),
		]).pipe(
			map(([definitions, routes]) => {
				const workflowRoutes = definitions.map((definition) => {
					const screenIndex = routes.indexOf(definition.name);
					return this.createRouteFromPresentationDefinition(definition, screenIndex);
				});
				const bodyRoot: Routes = [
					{
						path: `workflow`,
						component: BodyComponent,
						canActivate: mapToCanActivate([AuthenticationGuard]),
						children: workflowRoutes,
					},
				];
				this.router.resetConfig(bodyRoot.concat(this.defaultRoutes));
			}),
		);
	}

	private createRouteFromPresentationDefinition(
		presentationDefinition: PresentationDefinition,
		screenIndex: number,
	): Route {
		return {
			path: `${presentationDefinition.name}`,
			component: DynamicScreenComponent,
			canActivate: mapToCanActivate([AvailableRouteGuard]),
			data: {
				screenIndex,
			},
		};
	}
}

<app-todo-header></app-todo-header>
<app-card>
	<div class="flex flex-col gap-4">
		<div class="text-base flex justify-end">
			<mat-label class="mr-auto">Would you like a debit card?</mat-label>
			<mat-label class="mx-3 text-base">No</mat-label>
			<mat-slide-toggle
				color="primary"
				data-cy="toggle-field"
				(change)="onWantsDebitCardChanged($event)"
				[(ngModel)]="$wantsDebitCard"
			>
			</mat-slide-toggle>
			<mat-label class="mx-3 text-base">Yes</mat-label>
		</div>
		@if ($wantsDebitCard()) {
			@if ($isBusinessApplication()) {
				<div class="w-full">
					<mat-form-field class="w-full">
						<mat-label>Business Name On Card</mat-label>
						<input
							matInput
							data-cy="input-field"
							[maxlength]="maxCardLength"
							[formControl]="businessNameOnCardControl"
							(blur)="onBusinessNameOnCardBlur()"
						/>
						@if ($charactersRemainingMessage()) {
							<mat-hint>{{ $charactersRemainingMessage() }}</mat-hint>
						}
					</mat-form-field>
					@if (businessNameOnCardControl.touched && businessNameOnCardControl.hasError('required')) {
						<mat-error>Please enter the business name you would like on the debit card.</mat-error>
					}
				</div>
			}
			@if ($applicants()?.length > 0) {
				<mat-label class="text-base mr-auto">Please select everyone who should receive a debit card:</mat-label>
				<ul>
					@for (applicant of $applicants(); track $index) {
						<li class="text-base">
							<mat-checkbox
								class="big wrap-text"
								data-cy="check-field"
								[checked]="applicant.isSelected"
								(change)="onApplicantChecked(applicant)"
							>
								{{ applicant.firstName }} {{ applicant.lastName }}
							</mat-checkbox>
						</li>
					}
				</ul>
			} @else {
				<div class="text-base flex justify-end">
					<span class="nbkc-red"
						>Please complete adding an applicant to your application before setting up your debit
						card(s).</span
					>
				</div>
			}
		}
	</div>
</app-card>
<app-next-todo-button></app-next-todo-button>

import { Component } from '@angular/core';

@Component({
	selector: 'experience-catch-all',
	templateUrl: './catch-all.component.html',
	styleUrls: ['./catch-all.component.scss']
})
export class CatchAllComponent {
	constructor() { }
}

import { Component, Injector, Input } from '@angular/core';
import { DynamicFormComponentBase } from '../../../dynamic/dynamic-form-component.base';
import { FormField } from '@common/lib/models/form-field.model';

@Component({
	selector: 'common-card-header',
	templateUrl: './card-header.component.html',
	styleUrls: ['./card-header.component.scss']
})
export class CardHeaderComponent extends DynamicFormComponentBase<CardHeaderComponentFields, CardHeaderComponentOptions> {
	@Input() data: CardInputData;

	constructor(
		public injector: Injector
	) {
		super(injector);
	}

	onComponentLoaded(): void {
	}
}

interface CardHeaderComponentFields extends Record<string, FormField> {

}

interface CardHeaderComponentOptions {

}

interface CardInputData {
	expanded: boolean;
	isExpandable: boolean;
}

import _ from 'lodash';
import { CommonTrackerContext, CommonTrackerContextProps } from '@common/lib/tracking/common-tracker-context';
import { GoogleTagManagerTrackingContext, GoogleAnalyticsTrackingContext } from '@nbkc/tracker';

export class ApplicationTrackerContext extends CommonTrackerContext {
	public currentScreen?: string;

	public constructor(props: ApplicationTrackerContextProps = {}) {
		super(props);
		_.merge(this, props);
	}

	public ga(): GoogleAnalyticsTrackingContext {
		const gaContext = new GoogleAnalyticsTrackingContext();
		gaContext.page = this.currentScreen;
		return gaContext;
	}

	public gtm(): GoogleTagManagerTrackingContext {
		const gtmContext = new GoogleTagManagerTrackingContext();
		gtmContext.page = this.currentScreen;
		return gtmContext;
	}
}

export interface ApplicationTrackerContextProps extends CommonTrackerContextProps {
	currentScreen?: string;
}

import { AdapterBase } from '@common/lib/utilities/adapter-base';
import { ApplyEntryPoint } from '@common/lib/models/apply-entry-point';
import { ApplyEntryPointResource } from '@common/lib/models/resource/apply-entry-point-resource';

export class ApplyEntryPointToApplyEntryPointResourceAdapter extends AdapterBase<ApplyEntryPoint, ApplyEntryPointResource> {
	adapt(source: ApplyEntryPoint): ApplyEntryPointResource {
		if (!source) {
			return null;
		}

		const dest = new ApplyEntryPointResource();
		dest.displayName = source?.title;
		dest.version = source?.version;
		dest.displayDescription = source?.description;
		dest.applicationId = source?.applicationId;
		dest.applicationName = source?.applicationName;
		dest.actionDescription = source?.actionDescription;
		dest.actionUri = source?.actionUri;
		dest.isVisible = source?.isVisible;
		return dest;
	}
}

import { ChangeDetectionStrategy, Component, inject, Injector, input, OnInit, Signal } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MaskedInputComponent } from '@experience/app/components-new/masked-input/masked-input.component';
import {
	MAT_FORM_FIELD_DEFAULT_OPTIONS,
	MatError,
	MatFormField,
	MatHint,
	MatLabel,
} from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import {
	MatAutocomplete,
	MatAutocompleteSelectedEvent,
	MatAutocompleteTrigger,
	MatOption,
} from '@angular/material/autocomplete';
import { MatSelect } from '@angular/material/select';
import { InternalStateService } from '@experience/app/services/internal-state.service';
import { AddressFormGroup } from '@experience/app/components-new/address-form/address-form.group';
import { Observable, of } from 'rxjs';
import { Address as ApiAddress } from '@common/lib/models/address';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { toSignal } from '@angular/core/rxjs-interop';
import { LocationService } from '@experience/app/services/location.service';
import { FormControlPipe } from '@experience/app/shared/pipes/form-control-pipe/form-control.pipe';

@Component({
	selector: 'app-address-form',
	standalone: true,
	imports: [
		MaskedInputComponent,
		MatError,
		MatFormField,
		MatHint,
		MatInput,
		MatLabel,
		MatOption,
		MatSelect,
		ReactiveFormsModule,
		MatAutocomplete,
		MatAutocompleteTrigger,
		FormControlPipe,
	],
	templateUrl: './address-form.component.html',
	styleUrl: './address-form.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
			useValue: {
				appearance: 'outline',
				subscriptSizing: 'dynamic',
			},
		},
	],
})
export class AddressFormComponent implements OnInit {
	private internalStateService = inject(InternalStateService);
	private locationService = inject(LocationService);
	private injector = inject(Injector);
	$addressForm = input<FormGroup<AddressFormGroup>>({} as FormGroup<AddressFormGroup>);
	$applicantNameOrDefault = input<string>('');
	$lookups = this.internalStateService.$lookups;
	protected readonly FormControl = FormControl;

	public filteredAddresses: Observable<ApiAddress[]>;
	public $filteredAddresses: Signal<ApiAddress[]>;

	ngOnInit() {
		this.filteredAddresses = this.$addressForm()
			.get('address1')
			.valueChanges.pipe(
				debounceTime(250),
				distinctUntilChanged(),
				switchMap<string, Observable<ApiAddress[]>>((value) => {
					if (!value) {
						return of([]);
					}

					return this.locationService.searchAddress(value);
				}),
			);

		this.$filteredAddresses = toSignal<ApiAddress[]>(this.filteredAddresses, { injector: this.injector });
	}

	onAddressSelected(onAddressSelectedEvent: MatAutocompleteSelectedEvent): void {
		const selectedAddress = onAddressSelectedEvent.option?.value;
		this.$addressForm().get('address1').patchValue(selectedAddress?.address1);
		this.$addressForm().get('address2').patchValue(selectedAddress?.address2);
		this.$addressForm().get('city').patchValue(selectedAddress?.city);
		this.$addressForm().get('state').patchValue(selectedAddress?.state);
		this.$addressForm().get('postalCode').patchValue(selectedAddress?.zip);
	}
}

import { Component, computed, inject } from '@angular/core';
import { ApplicationStateService } from '@experience/app/services/application-state.service';
import { InternalStateService } from '@experience/app/services/internal-state.service';
import { AppDividerComponent } from '../divider/app-divider.component';
import { Product } from '@experience/app/models/tenant/product.model';
import { CommonModule } from '@angular/common';
import { AppCardComponent } from '../card/app-card.component';
import { MatButtonModule } from '@angular/material/button';
import { AppTodoHeaderComponent } from '../app-todo-header/app-todo-header.component';
import { ApplicationsApiService } from '@experience/app/services/applications-api.service';
import { LoadingService } from '@experience/app/services/loading.service';
import { submitApplicationActivity } from '@common/lib/constants/activities';
import { NotificationService } from '@experience/app/services/notification.service';
import { ApplyResponse } from '@experience/app/models/onboarding/apply-response.model';
import { AppRoutingService } from '@experience/app/services/app-routing.service';

@Component({
	selector: 'app-review-disclosures',
	standalone: true,
	imports: [CommonModule, MatButtonModule, AppDividerComponent, AppCardComponent, AppTodoHeaderComponent],
	templateUrl: './app-review-disclosures.component.html',
	styleUrl: './app-review-disclosures.component.scss',
})
export class AppReviewDisclosuresComponent {
	applicationsApiSerivce = inject(ApplicationsApiService);
	applicationStateService = inject(ApplicationStateService);
	internalStateService = inject(InternalStateService);
	loadingService = inject(LoadingService);
	notificationService = inject(NotificationService);
	routingService = inject(AppRoutingService);

	$qualification = this.applicationStateService.$qualification;
	$selectedProducts = computed<Product[]>(() => {
		const products = this.internalStateService.$products();
		const accounts = this.applicationStateService.$application().accounts;

		if (!accounts || accounts.length < 0) {
			return [];
		}

		const selectedProducts = [];
		for (const account of accounts) {
			const selectedProduct = products.find((p) => p.id === account.productId);
			if (selectedProduct) {
				selectedProducts.push(selectedProduct);
			}
		}

		return selectedProducts;
	});

	submit(): void {
		this.loadingService.startLoadingActivity(submitApplicationActivity);
		this.applicationsApiSerivce.submitApplication(this.applicationStateService.$id()).subscribe(
			(response: ApplyResponse) => {
				this.routingService.routeByApplicationStatus(response.application.status);
				this.loadingService.stopLoadingActivity(submitApplicationActivity);
			},
			() => {
				this.loadingService.stopLoadingActivity(submitApplicationActivity);
				this.notificationService.displaySnackBarMessage(
					'Something went wrong submitting your application. Please try again.',
				);
			},
		);
	}

	getProductIcon(productName: string): string {
		switch (productName) {
			case 'The nbkc Everything Account':
				return 'fa-ring';
			case 'Personal Money Market Account':
				return 'fa-chart-line';
			case 'Business Account':
				return 'fa-money-check-alt';
			case 'Business Money Market Account':
				return 'fa-piggy-bank';
			case '7-Month Certificate of Deposit':
			case '11-Month Certificate of Deposit':
			case '1-Year Certificate of Deposit':
			case '2-Year Certificate of Deposit':
			case '3-Year Certificate of Deposit':
			case '4-Year Certificate of Deposit':
			case '5-Year Certificate of Deposit':
				return 'fa-coin';
		}
	}
}

import { Injectable } from '@angular/core';
import { Tracker } from '@nbkc/tracker-angular';
import { Router } from '@angular/router';
import { GeneralError } from '@common/lib/models/general-error.model';
import { ErrorTypes } from '@common/lib/models/enum/error-types.enum';
import { HandledError } from '@experience/app/tracking/events/handled.error';
import { ErrorModalComponent } from '@common/lib/components/error-modal/error-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { LoadingService } from './loading.service';
import { NotificationService } from './notification.service';
import { CommonErrorService } from '@common/lib/services/common-error.service';

@Injectable({
	providedIn: 'root'
})
export class ExperienceErrorService implements CommonErrorService {
	constructor(
		private tracker: Tracker,
		private router: Router,
		private dialog: MatDialog,
		private loadingService: LoadingService,
		private notificationService: NotificationService) {
	}

	handleError(error: GeneralError): void {
		switch (error.errorType) {
			case ErrorTypes.unauthorized:
				this.handleUnauthorizedError();
				break;
			case ErrorTypes.recoverable:
				this.handleRecoverable(error);
				break;
			case ErrorTypes.fatal:
				this.handleFatalError(error);
				break;
			case ErrorTypes.unableToLoad:
				this.handleUnableToLoad();
				break;
			case ErrorTypes.invalidUserInteraction:
				this.handleInvalidUserInteraction(error);
				break;
			default:
				this.handleUnknownError(error);
				break;
		}
		this.tracker.event(new HandledError(error.errorType, error.rawError));
	}

	private handleUnauthorizedError(): void {
		this.router.navigate(['/error']).then(() => {
			this.loadingService.stopAllLoadingActivities();
		});
	}

	private handleUnableToLoad(): void {
		this.router.navigate(['/error']).then(() => {
			this.loadingService.stopAllLoadingActivities();
		});
	}

	private handleRecoverable(error: GeneralError): void {
		if (error.blocking) {
			this.dialog.open(ErrorModalComponent, {
				data: {
					message: 'We are having a difficult time processing your request. Please try again soon.'
				},
				disableClose: true
			});
		} else {
			this.notificationService.displaySnackBarMessage('We are having a difficult time processing your request. Please try again soon.');
		}
	}

	private handleFatalError(error: GeneralError): void {
		if (error.blocking) {
			this.dialog.open(ErrorModalComponent, {
				data: {
					message: 'Unexpected Error. Please contact us at customersupport@nbkc.com.'
				},
				disableClose: true
			});
		} else {
			this.notificationService.displaySnackBarMessage('Unexpected Error Saving Data');
		}

	}

	private handleUnknownError(error: GeneralError): void {
		if (error.blocking) {
			this.dialog.open(ErrorModalComponent, {
				data: {
					message: 'Unknown Error. Please contact us at customersupport@nbkc.com.'
				},
				disableClose: true
			});
		} else {
			this.notificationService.displaySnackBarMessage('Unknown Error Saving Data');
		}
	}

	private handleInvalidUserInteraction(error: GeneralError): void {
		if (error.blocking) {
			this.dialog.open(ErrorModalComponent, {
				data: {
					message: error.message
				},
				disableClose: true
			});
		} else {
			this.notificationService.displaySnackBarMessage('We are having a difficult time processing your request. Please try again soon.');
		}
	}
}

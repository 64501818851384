import { GeneratorUtility } from '@common/lib/utilities/generator-utility';

export class DocumentInfo {
	public readonly id: string;
	public file: File;

	constructor(file: File) {
		this.id = GeneratorUtility.generateUuidv4();
		this.file = file;
	}
}

import { Component, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { DynamicFormComponentBase } from '../../dynamic/dynamic-form-component.base';
import { FormField } from '@common/lib/models/form-field.model';

@Component({
	selector: 'common-select',
	templateUrl: './select.component.html',
	styleUrls: ['./select.component.scss']
})
export class SelectComponent extends DynamicFormComponentBase<SelectComponentFields, SelectComponentOptions> {

	public options$: Observable<Record<string, string>[]>;
	public displaySelector: string = '';
	public valueSelector: string = '';
	public hideRequiredMark: boolean = false;
	public selectClasses: string;

	constructor(
		public injector: Injector
	) {
		super(injector);
	}

	onComponentLoaded(): void {
		this.registerAllControls({ isDisabled: this.options?.isDisabled });
		this.selectClasses = this.options?.classes;
		this.options$ = this.formService.getModelValue$<Record<string, string>[]>(this.fields?.options?.modelPaths);
		this.displaySelector = this.options?.displaySelector;
		this.valueSelector = this.options?.valueSelector;
		this.hideRequiredMark = !!(this.options?.hideRequiredMark) || false;
	}

	public getValue(item: Record<string, string>): any {
		if (this.valueSelector) {
			return item[this.valueSelector];
		}
		return item;
	}

	public getDisplay(item: Record<string, string>): string {
		if (this.displaySelector) {
			return item[this.displaySelector];
		}
		return item[0];
	}

	public compareWith(optionValue: any, selectedValue: any): boolean {
		return optionValue?.id && selectedValue?.id ?
			optionValue.id === selectedValue.id :
			optionValue === selectedValue;
	}
}

interface SelectComponentFields extends Record<string, FormField> {
	value: FormField;
}

interface SelectComponentOptions {
	displaySelector: string;
	valueSelector: string;
	hideRequiredMark: string;
	label: string;
	subtext: string;
	classes: string;
	isDisabled: boolean;
}

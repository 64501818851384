<div *ngIf="visible$ | async">
	<form [formGroup]="internalForm">
		<mat-form-field class="w-full" appearance="outline">
			<mat-label>{{options?.address1Label || 'Address 1'}}</mat-label>
			<input matInput formControlName="address1"
				(focus)="setFocusedControl(internalForm.get('address1'))"
				(blur)="clearFocusedControl()"
				[matAutocomplete]="auto"
				class="{{options.inputClass}}"
				data-cy="input-field">
				<mat-autocomplete #auto="matAutocomplete" (optionSelected)="addressSelected($event)">
					<mat-option *ngFor="let address of filteredOptions | async" [value]="address">
					  {{address.full}}
					</mat-option>
				</mat-autocomplete>
		</mat-form-field>
		<div class="pt-2.5 pb-2.5">
			<div *ngIf="options?.address1Subtext" class="subtext">
				{{options.address1Subtext}}
			</div>
			<common-validation-messages [validatedControl]="internalForm.get('address1')" [associatedField]="fields.address1"></common-validation-messages>
		</div>
		<mat-form-field class="w-full" appearance="outline" *ngIf="fields?.address2">
			<mat-label>{{options?.address2Label || 'Address 2'}}</mat-label>
			<input matInput formControlName="address2"
				(focus)="setFocusedControl(internalForm.get('address2'))"
				(blur)="clearFocusedControl()"
				class="{{options.inputClass}}"
				data-cy="input-field">
		</mat-form-field>
		<div class="text-10.5px pt-2.5 pb-2.5">
			<div *ngIf="options?.address2Subtext" class="subtext">
				{{options.address2Subtext}}
			</div>
			<common-validation-messages [validatedControl]="internalForm.get('address2')" [associatedField]="fields.address2"></common-validation-messages>
		</div>
		<mat-form-field class="w-full" appearance="outline">
			<mat-label>{{options?.cityLabel || 'City'}}</mat-label>
			<input matInput formControlName="city"
				(focus)="setFocusedControl(internalForm.get('city'))"
				(blur)="clearFocusedControl()" class="{{options.inputClass}}" data-cy="input-field">
		</mat-form-field>
		<div class="text-10.5px pt-2.5 pb-2.5">
			<div *ngIf="options?.citySubtext" class="subtext">
				{{options.citySubtext}}
			</div>
			<common-validation-messages [validatedControl]="internalForm.get('city')" [associatedField]="fields.city"></common-validation-messages>
		</div>
		<mat-form-field class="w-full {{options.selectClass}}" appearance="outline" [hideRequiredMarker]="hideRequiredMark">
			<mat-label>{{options?.stateLabel || 'State'}}</mat-label>
			<mat-select formControlName="state" data-cy="select-field">
				<mat-option *ngFor="let item of states$ | async" [value]="item[options.stateValueSelector]">
					{{item[options.stateDisplaySelector]}}
				</mat-option>
			</mat-select>
		</mat-form-field>
		<div class="text-10.5px pt-2.5 pb-2.5">
			<div *ngIf="options?.stateSubtext" class="subtext">
				{{options.stateSubtext}}
			</div>
			<common-validation-messages [validatedControl]="internalForm.get('state')" [associatedField]="fields.state"></common-validation-messages>
		</div>
		<mat-form-field class="w-full" appearance="outline">
			<mat-label>{{options?.zipLabel || 'Zip'}}</mat-label>
			<input matInput formControlName="zip" (focus)="setFocusedControl(internalForm.get('zip'))"
				[textMask]="zipMask"
				(blur)="clearFocusedControl()"
				class="{{options.inputClass}}"
			   	data-cy="input-field">
		</mat-form-field>
		<div class="text-10.5px pt-2.5 pb-2.5">
			<div *ngIf="options?.zipSubtext" class="subtext">
				{{options.zipSubtext}}
			</div>
			<common-validation-messages [validatedControl]="internalForm.get('zip')" [associatedField]="fields.zip"></common-validation-messages>
		</div>
	</form>
</div>

import { Component, Input, OnInit } from '@angular/core';
import { Condition } from '@common/lib/models/condition';
import _ from 'lodash';

@Component({
  selector: 'common-upload-document-list',
  templateUrl: './upload-document-list.component.html',
  styleUrls: ['./upload-document-list.component.scss']
})
export class UploadDocumentListComponent implements OnInit {
  @Input() condition: Condition;
  completedUploads: string[] = [];
  numberOfFiles: number = 0;

  constructor() { }

  ngOnInit(): void {
    this.numberOfFiles = this.condition.totalFileCount;
  }

  public uploadCompleteEvent(documentUploadGroupId) {
    if (!this.completedUploads.includes(documentUploadGroupId)) {
      this.completedUploads.push(documentUploadGroupId);
      this.triggerDocumentCompleteActions(documentUploadGroupId);
    }
  }

  public triggerDocumentCompleteActions(documentUploadGroupId) {
		const completeUpload = this.condition.recentFiles.filter((file) => file.displayUpload && file.documentUploadGroupId === documentUploadGroupId);
    const uploadedFiles = _.clone(completeUpload);

    uploadedFiles.reverse().forEach((document, index) => {
      setTimeout(()=>{
        document.displayUpload = false;
        this.numberOfFiles += 1;
      }, (index+1) * 500);
    });
	}

  getNumberOfFilesToShow() {
    const numberOfUploadingFiles = this.condition.recentFiles?.filter((file) => file.displayUpload).length;
    return numberOfUploadingFiles > 3 ? numberOfUploadingFiles : 3;
  }

  getNumberOfAdditionalFiles() {
    const currentlyUploading = this.condition.recentFiles?.filter((file) => file.displayUpload === true).length;
    const lessThanCalc = this.numberOfFiles - (3 - currentlyUploading);
    return currentlyUploading < 3 ? lessThanCalc : this.numberOfFiles;
  }
}

import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { PatchAction } from '@common/lib/models/actions/patch-action';
import { NavigateAction } from '@common/lib/models/actions/navigate-action';
import { Action } from '@common/lib/models/actions/action.types';
import { CommonFormService } from '@common/lib/services/common-form.service';
import { PresentationService } from '@experience/app/services/presentation.service';

@Injectable({
	providedIn: 'root'
})
export class ActionHelper {

	constructor(
		private formService: CommonFormService,
		private presentationService: PresentationService
	) { }

	public runAction(action: Action): Observable<any> {
		switch (action.type) {
			case 'patch':
				return this.runPatchAction(action);
			case 'navigate':
				return this.runNavigateAction(action);
		}
	}

	private runPatchAction(action: PatchAction): Observable<void> {
		return of(this.formService.patchModelValue([action.patch]));
	}

	private runNavigateAction(action: NavigateAction): Observable<boolean> {
		return this.presentationService.navigateToURL$(action.nextScreen);
	}
}

<div class="loading-container w-full fixed top-0 left-0 opacity-1" [ngClass]="{ fade: fadeClass }">
	<div *ngIf="isSubmitting; else elseBlock" class="flex justify-center w-full p-10">
		<div class="w-64">
			<img alt='White nbkc logo' src="assets/nbkc-logo-white.svg" class="w-full">
		</div>
	</div>
	<ng-template #elseBlock>
		<div class="flex justify-center w-full h-1/3 p-10">
			<div class="w-64">
				<img alt='White nbkc logo' src="assets/nbkc-logo-white.svg" class="w-full">
			</div>
		</div>
	</ng-template>	

	<div *ngIf="isSubmitting" class="w-full p-3 text-white">
		<span class="flex justify-center block text-2xl text-center md:text-left md:text-5xl font-bold leading-12">Processing Your Application</span>
		<span class="flex justify-center pb-2 text-base mx-10 md:px-24 2xl:px-96 2xl:mx-72 2xl:pt-2">Your information has been saved and submitted. This may take a moment, so please hang tight.</span>
	</div>
	<div class="flex justify-center pt-20">
		<div class="flex flex-col">
			<img alt="Spinning bicycle loader" src="assets/nbkc-bike-white.svg" class="w-64">
		</div>
	</div>
	<div *ngIf="isSubmitting" class="flex justify-center pt-2 pb-2 text-base mt-4 mb-4 text-white">
		{{loadingText}} <div class="dot-falling"></div>
	</div>
</div>


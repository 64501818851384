import { Component } from '@angular/core';
import { AppTodoHeaderComponent } from '../app-todo-header/app-todo-header.component';
import { AppCardComponent } from '../card/app-card.component';

@Component({
	selector: 'experience-app-setup-online-banking',
	standalone: true,
	imports: [AppTodoHeaderComponent, AppCardComponent],
	templateUrl: './app-setup-online-banking.component.html',
	styleUrl: './app-setup-online-banking.component.scss',
})
export class AppSetupOnlineBankingComponent {}

import { Injectable } from '@angular/core';
import { forkJoin, from, Observable, of, Subject, throwError } from 'rxjs';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import createAuth0Client, { RedirectLoginResult } from '@auth0/auth0-spa-js';
import { catchError, concatMap, map, share, shareReplay, takeUntil, tap } from 'rxjs/operators';
import { AuthenticationProviderBase } from '@common/lib/utilities/authentication/authentication-providers/authentication-provider.base';
import { CommonConfiguration } from '@common/lib/utilities/configuration/common.configuration';

@Injectable({
	providedIn: 'root'
})
export class Auth0AuthenticationProvider extends AuthenticationProviderBase {
	private auth0Client$: Observable<Auth0Client>;
	private handleRedirectCallback$: Observable<RedirectLoginResult>;
	private dispose$: Subject<any> = new Subject<any>();

	constructor(private config: CommonConfiguration) {
		super();
	}

	public handleAuthenticationCallback$(): Observable<RedirectLoginResult> {
		let targetRoute;
		return this.handleRedirectCallback$.pipe(
			tap((cbRes) => {
				targetRoute =
					cbRes.appState && cbRes.appState.target
						? cbRes.appState.target
						: '/';
			}),
			map(() => targetRoute)
		);
	}

	public login(redirectPath: string = '/'): void {
		this.auth0Client$.subscribe(async (client: Auth0Client) => {
			const appState: any = {
				target: redirectPath
			};

			await client.loginWithRedirect({
				// eslint-disable-next-line @typescript-eslint/naming-convention
				redirect_uri: `${ window.location.origin }/login`,
				appState
			});
		});
	}

	public logout(): void {
		this.auth0Client$.subscribe((client: Auth0Client) => {
			client.logout({
				// eslint-disable-next-line @typescript-eslint/naming-convention
				client_id: this.config.auth0ClientId,
				returnTo: `${ window.location.origin }`
			});
		});
	}

	public async refreshAuth(): Promise<any> {
		this.dispose$.next();
		await this.onProviderStart();
	}

	public onProviderStart(): void {
		const auth0Options = {
			domain: this.config.auth0Domain,
			// eslint-disable-next-line @typescript-eslint/naming-convention
			client_id: this.config.auth0ClientId,
			// eslint-disable-next-line @typescript-eslint/naming-convention
			redirect_uri: `${ window.location.origin }/login`,
			scope: 'openid profile email',
			audience: this.config.auth0Audience
		};

		this.auth0Client$ = (from(
			createAuth0Client(auth0Options)
		) as Observable<Auth0Client>).pipe(
			shareReplay(1),
			catchError((err) => throwError(err)),
			takeUntil(this.dispose$)
		);

		const getTokenSilently$ = this.auth0Client$.pipe(
			concatMap((client: Auth0Client) =>
				from(client.getTokenSilently())
			),
			share()
		);

		const getUser$ = this.auth0Client$.pipe(
			concatMap((client: Auth0Client) => from(client.getUser())),
			share()
		);

		this.handleRedirectCallback$ = this.auth0Client$.pipe(
			concatMap((client) => from(client.handleRedirectCallback()))
		);

		this.onProviderAuthentication$ = getTokenSilently$.pipe(
			concatMap((response) => forkJoin({
				token: of(response),
				isAuthenticated: of(true),
				user: getUser$
			})),
			catchError((error) => of({
				token: null,
				isAuthenticated: false,
				user: null,
				hasError: error.error !== 'login_required'
			}))
		);
	}
}

<div>
	<form [formGroup]="internalForm">
		<mat-form-field class="w-full credit-card-preview" appearance="outline" [hideRequiredMarker]="hideRequiredMark">
			<mat-label>{{internalForm.get('cardNumberLabel').value}}</mat-label>
			<input matInput formControlName="cardNumberValue" [textMask]="creditCardNumberMask" (focus)="setFocusedControl(internalForm.get('cardNumberValue'))" (blur)="clearFocusedControl()" data-cy="input-field">
			<mat-icon matSuffix svgIcon="{{cardType}}" aria-hidden="false" aria-label="credit card logo"></mat-icon>
		</mat-form-field>
		<div class="fused-inputs">
			<mat-form-field class="w-full first-fused-input" appearance="outline" [hideRequiredMarker]="hideRequiredMark">
				<mat-label>{{internalForm.get('cardExpirationLabel').value}}</mat-label>
				<input matInput formControlName="cardExpirationValue" [textMask]="expirationDateMask" (focus)="setFocusedControl(internalForm.get('cardExpirationValue'))" (blur)="clearFocusedControl()" data-cy="input-field">
			</mat-form-field>
			<mat-form-field class="w-full second-fused-input" appearance="outline" [hideRequiredMarker]="hideRequiredMark">
				<mat-label>{{internalForm.get('securityCodeLabel').value}}</mat-label>
				<input matInput formControlName="securityCodeValue" [textMask]="securityCodeMask" (focus)="setFocusedControl(internalForm.get('securityCodeValue'))" (blur)="clearFocusedControl()" data-cy="input-field">
			</mat-form-field>
		</div>
	</form>
</div>


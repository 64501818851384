<div class="approval-container max-w-lg w-full text-base mb-6">
	<div class="mb-4 pl-2">
		Your new account has been approved and created!
	</div>
	<div class="mb-4 pl-2">
		<a target="_blank" href="{{q2LoginFormURL}}" rel="noopener noreferrer">
			Log in as an existing user
		</a>
		or create your nbkc user below.
	</div>
</div>

<div class="card-div-container flex justify-center mb-4">
	<mat-card appearance="outlined" class="w-full overflow-hidden rounded-xl shadow-card p-4" data-cy="card">
		<div [ngClass]="options?.containerClass || 'w-full'">
			<form [formGroup]="internalForm" class="w-full" action="{{q2LoginFormURL}}" method="post" #form>
				<div class="flex items-center pb-6">
					<div class="text-5xl fa-light fa-shield-keyhole"></div>
					<div class="ml-4 mt-1 flex-grow">
						<div>
							<span class="text-xl font-semibold">Create your nbkc user</span>
						</div>
					</div>
				</div>
				<mat-form-field class="w-full" appearance="outline">
					<mat-label>{{options?.label}}</mat-label>
					<input matInput formControlName="username" id="user_id" name="user_id"
						   class="{{options?.class}}"
						   (focus)="setFocusedControl(internalForm.get('username'))"
						   (keydown.enter)="checkSubmitOnEnter($event)"
						   (blur)="clearFocusedControl()" #inputElement data-cy="input-field">
				</mat-form-field>
				<div class="pt-2.5 pb-2.5">
					<div *ngIf="options?.subtext" class="subtext">
						{{options.subtext}}
					</div>
					<common-validation-messages
						[validatedControl]="internalForm.get('username')"
						[associatedField]="fields['username']">
					</common-validation-messages>
				</div>
				<common-password-validator [formGroup]="internalForm"
				   (passwordMeetsRequirementsEvent)="passwordMeetsRequirementsUpdate($event)"
					(keydown.enter)="checkSubmitOnEnter($event)">
				</common-password-validator>

				<mat-checkbox formControlName="checked" class="">
					<div>I agree to the <a href="https://www.nbkc.com/legal-notices/disclosures/online-and-mobile-banking-agreement" target="_blank" rel="noopener noreferrer" >Online & Mobile Banking Agreement</a>.</div>
				</mat-checkbox>
				<button id="loginSubmitButton" #loginSubmitButton type="submit" (click)="form.submit()"></button>
			</form>
		</div>
	</mat-card>
</div>
<div class="flex flex-col justify-center items-center m-4">
	<button id="submit-application-button-{{id}}" class="w-full text-white rounded-full py-3 px-4 shadow-button text-lg" style="transition: width .75s ease-in-out"
		[ngClass]="{'w-full': !enrollmentInProgress, 'w-0': enrollmentInProgress}"
		color="primary" mat-raised-button (click)="attemptEnroll()" [disabled]="!internalForm.valid || enrollmentInProgress || !passwordMeetsRequirements">
			<span *ngIf="!enrollmentInProgress">Create User</span>
			<div *ngIf="enrollmentInProgress" class="flex flex-col items-center">
				<mat-spinner color="primary" [diameter]="30"></mat-spinner>
			</div>
	</button>
</div>

import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { DynamicComponentBase } from '../../dynamic/dynamic-component.base';
import { Clipboard } from '@angular/cdk/clipboard';
import { takeUntil } from 'rxjs/operators';
import { FormField } from '@common/lib/models/form-field.model';
import { CommonFormService } from '@common/lib/services/common-form.service';

@Component({
	selector: 'common-copy-button',
	templateUrl: './copy-button.component.html',
	styleUrls: ['./copy-button.component.scss']
})
export class CopyButtonComponent extends DynamicComponentBase<CopyButtonComponentFields, CopyButtonComponentOptions> {
	public buttonText$: Observable<string>;
	public disabled: boolean = false;
	public link: string = null;

	constructor(
		private formService: CommonFormService,
		private clipboard: Clipboard
	) {
		super();
	}

	onComponentLoaded(): void {
		this.buttonText$ = this.formService.getModelValue$<string>(this.fields?.buttonTextPath?.modelPaths, this.options?.buttonTextValue);
		this.disabled = this.options?.disabled === 'true';

		this.formService.getModelValue$<string>(this.fields?.copyText?.modelPaths).pipe(
			takeUntil(this.destroy$)
		).subscribe((link) => {
			this.link = link;
		});
	}

	public copyToClipboard(): void {
		this.clipboard.copy(this.link);
	}
}

interface CopyButtonComponentFields extends Record<string, FormField> {
	copyText: FormField;
	buttonTextPath: FormField;
}

interface CopyButtonComponentOptions {
	disabled: string;
	buttonTextValue: string;

}

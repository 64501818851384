import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { InternalStateService } from '@experience/app/services/internal-state.service';
import { ApplicationsApiService } from '@experience/app/services/applications-api.service';
import { ApplicationStateService } from '@experience/app/services/application-state.service';
import { AppRoutingService } from '@experience/app/services/app-routing.service';

@Component({
	selector: 'app-next-todo-button',
	standalone: true,
	imports: [MatButtonModule],
	templateUrl: './next-todo-button.component.html',
	styleUrl: './next-todo-button.component.scss',
})
export class NextTodoButtonComponent {
	private internalStateService = inject(InternalStateService);
	private applicationStateService = inject(ApplicationStateService);
	private applicationApiService = inject(ApplicationsApiService);
	private routingService = inject(AppRoutingService);
	public $todoList = this.internalStateService.$todoItems;

	onClickNextButton(): void {
		const application = this.applicationStateService.$application();

		this.applicationApiService.saveApplication(application);
		this.navigateToNextTodoItem();
	}

	private navigateToNextTodoItem(): void {
		const currentScreenName = this.internalStateService.$currentScreen();
		const currentRouteIndex = this.$todoList().findIndex((t) => t.associatedRoute === currentScreenName);

		//update to do list state
		const updatedTodoList = [...this.$todoList()];
		updatedTodoList[currentRouteIndex].complete = true;
		this.internalStateService.setTodoItems(updatedTodoList);

		const nextTodoItem = this.$todoList()[currentRouteIndex + 1];
		this.routingService.routeToApplicationScreen(nextTodoItem.associatedRoute);
	}
}

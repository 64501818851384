import { Injectable } from '@angular/core';
import { CommonFormService } from '@common/lib/services/common-form.service';
import { DynamicModelValue } from '@common/lib/models/model-value/dynamic-model-value';
import { StaticModelValue } from '@common/lib/models/model-value/static-model-value';
import { combineLatest, Observable, of } from 'rxjs';
import { applyPatch, getValueByPointer, Operation } from 'fast-json-patch';
import { DynamicModelValueResource } from '@common/lib/models/resource/model-value/dynamic-model-value.resource';
import { Address } from '@common/lib/models/address';
import { ModelValue, ModelValueCollection } from '@common/lib/models/model-value/model-value.types';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { TextTransformUtility } from '@common/lib/utilities/text-transform/text-transform.utility';
import { JsonPatchUtility } from '@common/lib/utilities/json-patch-utility';
import { ModelStateService } from '../services/model-state.service';
import _ from 'lodash';
import { ExperienceClient } from '../clients/experience.client';
import { AddressApiToAddressFormAdapter } from '@common/lib/adapters/address-api-to-address-form.adapter';

@Injectable({
	providedIn: 'root'
})
export class FormAccess implements CommonFormService {
	private addressAdapter: AddressApiToAddressFormAdapter = new AddressApiToAddressFormAdapter();

	constructor(
		private experienceClient: ExperienceClient,
		private modelStateService: ModelStateService
	) { }

	/**
	 * Resolves JSON pointer and path values from the model state.
	 * If a collection of values is provided then one of the following occurs
	 * 1) We check to see if the defaultValue uses interpolation. If it does we will interpolate the string
	 * with the values from the ModelValueCollection paths.
	 * 		- If any of the ModelPath values return undefined we will drop in the property name at
	 * 		the end of the ModelPath as a placeholder value.
	 * 2) If the default value does not use interpolation, we will do one of the following:
	 * 		A) If the collection contains exactly one item, we will return the value of that item.
	 * 		B) If the collection contains more than one item, we will return a collection of values.
	 *
	 * If a collection is not provided, then we will return the default value.
	 *
	 * @param modelPaths
	 * @param defaultValue
	 */
	public getModelValue$<TResponse>(modelPaths: ModelValueCollection, defaultValue?: TResponse, placeholderOverride?: any): Observable<TResponse> {
		if (modelPaths?.length > 0) {
			const values$ = modelPaths.map((path) => {
				if (path.type === 'dynamic') {
					return this.getDynamicModelValue$<TResponse>(path, defaultValue);
				}
				return of(defaultValue);
			});

			if (typeof defaultValue === 'string' && this.isInterpolatedString(defaultValue)) {
				return combineLatest(modelPaths.map((path) => {
					if (path.type === 'dynamic') {
						return this.getDynamicModelValue$<TResponse>(path).pipe(
							map((modelPathValue) => {

								if (placeholderOverride && !modelPathValue) {
									return placeholderOverride;
								}
								else if (!modelPathValue) {
									path = path as DynamicModelValue;
									const lastPathChunkIndex = path?.path.lastIndexOf('/');
									return path?.path.substring(lastPathChunkIndex + 1);
								}

								return modelPathValue;
							})
						);
					}
					return of(null);
				})).pipe(
					map((values) => {
						let builder = this.parseInterpolatedValues(defaultValue, values as unknown as string[]);
						builder = this.parseAsMarkdown(builder);
						return builder as unknown as TResponse;
					})
				);
			}

			if (modelPaths.length === 1) {
				return values$[0];
			}

			return combineLatest(values$) as unknown as Observable<TResponse>;
		}
		return of(defaultValue);
	}

	/**
	 * Applies a JSON patch to the model state.
	 *
	 * @param jsonPatch
	 */
	public patchModelValue(jsonPatch: Operation[]): void {
		if (!jsonPatch || jsonPatch.length === 0) {
			return;
		}

		const cloneModelState = _.cloneDeep(this.modelStateService.modelState$.getValue());

		jsonPatch.forEach((operation: any) => {
			try {
				operation.value = JSON.parse(operation.value);
			} catch {
				console.warn('Failed to parse operation value as JSON. Leaving as raw value.');
			}
		});
		JsonPatchUtility.tryOrIgnorePatch(cloneModelState, jsonPatch);
		this.modelStateService.updateModelState(cloneModelState);
	}

	/**
	 * Sets the value of the provided JSON path or pointer.
	 *
	 * @param newValue
	 * @param path
	 */
	public setModelValue(newValue: any, paths: (DynamicModelValue | StaticModelValue)[]) {
		const path = paths[0] as DynamicModelValue;
		const currentModelState = _.cloneDeep(this.modelStateService.modelState$.getValue());

		let pathWithContext;
		if (path.type === 'dynamic' && path.context) {
			const result = getValueByPointer(currentModelState, JsonPatchUtility.convertJsonPathToJsonPointer(path.context));
			if (result) {
				pathWithContext = JsonPatchUtility.convertJsonPathToJsonPointer(result) + JsonPatchUtility.convertJsonPathToJsonPointer(path.path);
			}
		}
		const modelValueAsDynamic = path as DynamicModelValue;
		let finalPath = pathWithContext || JsonPatchUtility.convertJsonPathToJsonPointer(modelValueAsDynamic.path);
		finalPath = JsonPatchUtility.resolveLastArrayIndexForPathsWithDashes(currentModelState, finalPath);
		applyPatch(currentModelState, [
			{
				value: newValue,
				op: 'replace',
				path: finalPath
			}
		]);

		this.modelStateService.updateModelState(currentModelState);
	}

	/**
	 * Resolves paths with a given modelValue context to the actual path.
	 * For example: path: /firstName, context: /context/currentSigner.
	 * Given the value /context/currentSigner = '/application/signers/1';
	 * The path will resolve to /application/signers/1/firstName;
	 *
	 * @param modelValue
	 */
	public getModelValuePath$(modelValue: DynamicModelValueResource): Observable<string> {
		return this.getAbsoluteJsonPointerPath(modelValue.path, modelValue.context);
	}


	/**
	 * Performs an address search.
	 *
	 * @param addressQuery
	 */
	public searchForAddress$(addressQuery: string): Observable<Address[]> {
		return this.experienceClient.searchAddress$(addressQuery).pipe(
			map((addressResponse) => addressResponse?.addresses?.map((val) => this.addressAdapter.adapt(val)))
		);
	}

	/**
	 * Resolves paths with a given context to the actual path.
	 * For example: path: /firstName, context: /context/currentSigner.
	 * Given the value /context/currentSigner = '/application/signers/1';
	 * The path will resolve to /application/signers/1/firstName;
	 *
	 * @param jsonPath
	 * @param context
	 */
	private getAbsoluteJsonPointerPath(jsonPath: string, context?: string): Observable<string>;

	private getAbsoluteJsonPointerPath(jsonPath: string, context?: string, targetModel?: any): string;

	private getAbsoluteJsonPointerPath(jsonPath: string, context?: string, targetModel?: any): Observable<string> | string {
		if (!targetModel) {
			return this.modelStateService.modelState$.pipe(
				map(modelstate => {
					let pathWithContext;
					if (context) {
						const result = getValueByPointer(modelstate, JsonPatchUtility.convertJsonPathToJsonPointer(context));
						if (result) {
							pathWithContext = JsonPatchUtility.convertJsonPathToJsonPointer(result) + JsonPatchUtility.convertJsonPathToJsonPointer(jsonPath);
						}
					}
					// eslint-disable-next-line @typescript-eslint/no-shadow
					const finalPath = JsonPatchUtility.convertJsonPathToJsonPointer(pathWithContext || jsonPath);
					return JsonPatchUtility.resolveLastArrayIndexForPathsWithDashes(modelstate, finalPath);
				})
			);
		}

		let targetPathWithContext;
		if (context) {
			const result = getValueByPointer(targetModel, JsonPatchUtility.convertJsonPathToJsonPointer(context));
			if (result) {
				targetPathWithContext = JsonPatchUtility.convertJsonPathToJsonPointer(result) + JsonPatchUtility.convertJsonPathToJsonPointer(jsonPath);
			}
		}
		const finalPath = JsonPatchUtility.convertJsonPathToJsonPointer(targetPathWithContext || jsonPath);
		return JsonPatchUtility.resolveLastArrayIndexForPathsWithDashes(targetModel, finalPath);
	}

	/**
	 * Internal method for retrieving a single value from the model.
	 *
	 * @param modelValue
	 * @param defaultValue
	 * @private
	 */
	private getDynamicModelValue$<TResponse>(modelValue: ModelValue, defaultValue?: any): Observable<TResponse> {
		const dynamicModelValue = modelValue as DynamicModelValue;

		return this.modelStateService.modelState$.pipe(
			distinctUntilChanged((prevModelState, currModelState) => {
				try {
					const prevResult = prevModelState ? getValueByPointer(prevModelState,
						this.getAbsoluteJsonPointerPath(dynamicModelValue.path, dynamicModelValue.context, prevModelState)
					) : undefined;

					const currentResult = getValueByPointer(currModelState,
						this.getAbsoluteJsonPointerPath(dynamicModelValue.path, dynamicModelValue.context, currModelState)
					);
					return _.isEqual(prevResult, currentResult);
				}
				catch (error) {
					console.log(`No Bueno! We couldn't follow the path to the model. ${dynamicModelValue.path}`);
					return undefined;
				}
			}),
			map(modelState => {
				try {
					const result = getValueByPointer(modelState,
						this.getAbsoluteJsonPointerPath(dynamicModelValue.path, dynamicModelValue.context, modelState)
					);
					if (result || result === false) {
						return _.clone(result);
					}
					return defaultValue;
				} catch (error) {
					console.log(`No Bueno! We couldn't follow the path to the model. ${dynamicModelValue.path}`);
					return undefined;
				}
			})
		);
	}

	/**
	 * Renders any markdown present in a string as html.
	 *
	 * @param value
	 * @private
	 */
	private parseAsMarkdown(value: string): string {
		return TextTransformUtility.render(value);
	}

	/**
	 * Checked to see if a string uses any interpolation syntax such as {0} or {<anyNumber>}.
	 *
	 * @param value
	 * @private
	 */
	private isInterpolatedString(value: string): boolean {
		const interpolationSearchString = /({[0-9]+})/g;
		return !!value?.match(interpolationSearchString);
	}

	/**
	 * Parses a string with the provided parameters.
	 * For example: value: "My name is {0}" params: ["John"]
	 * The result will be "My name is John"
	 * False values will be replaced with empty strings.
	 *
	 * @param value
	 * @param params
	 * @private
	 */
	private parseInterpolatedValues(value: string, params: string[]) {
		let finalText = value;
		params?.forEach((paramValue, index) => {
			do {
				finalText = finalText.replace(`{${index}}`, paramValue || '');
			} while (finalText.includes(`{${index}}`));
		});
		return finalText;
	}
}

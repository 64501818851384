import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ClientBase, PossibleHeaders } from './client.base';
import { ClientContext } from '@experience/app/models/client-context';
import { ApplyFlowResponseResource } from '@common/lib/models/resource/apply-flow-response-resource';
import { ApplyEntryPointResource } from '@common/lib/models/resource/apply-entry-point-resource';
import { ApplyFlowRequestResource } from '@common/lib/models/resource/apply-flow-request-resource';
import { AppSession } from '@experience/app/app.session';
import { CommonConfiguration } from '@common/lib/utilities/configuration/common.configuration';
import { ApplicationAvailabilityResource } from '@common/lib/models/resource/application-availability-resource';
import { AddressesResource } from '@common/lib/models/resource/address/addresses-resource';
import { BrowserDataUtility } from '@common/lib/utilities/browser-data-utility';
import { GeneralError } from '@common/lib/models/general-error.model';
import { ErrorTypes } from '@common/lib/models/enum/error-types.enum';
import { ProviderError } from '@common/lib/models/provider-error.model';
import { CommonErrorService } from '@common/lib/services/common-error.service';

@Injectable({
	providedIn: 'root',
})
export class ExperienceClient extends ClientBase {
	public clientBaseUrl: string;
	private currentContext = new ClientContext();

	constructor(
		private readonly http: HttpClient,
		private readonly session: AppSession,
		private readonly config: CommonConfiguration,
		private readonly browserDataUtility: BrowserDataUtility,
		private readonly errorService: CommonErrorService,
	) {
		super(http);
		this.session.data$.subscribe((sessionContext) => {
			this.currentContext = sessionContext;
		});
		this.currentContext.browserData = this.browserDataUtility.getBrowserData();
		this.session.updateSession(this.currentContext);
		this.clientBaseUrl = this.config.experienceApi;
	}

	public start$(target: ApplyEntryPointResource, applicationId?: string): Observable<ApplyFlowResponseResource> {
		const sessionContextUpdate = new ClientContext();

		if (applicationId) {
			sessionContextUpdate.applicationId = applicationId;
			this.session.updateSession(sessionContextUpdate);
		}

		sessionContextUpdate.sessionParams = { appname: target.applicationName, applicationId };
		sessionContextUpdate.appName = target.applicationName;
		sessionContextUpdate.appVersion = target.version;

		this.session.updateSession(sessionContextUpdate);

		return this.post<ApplyFlowResponseResource>(`/apply/start`, target).pipe(
			catchError((err) => {
				const providerError = new ProviderError(ErrorTypes.unableToLoad, err.message, err);
				this.errorService.handleError(providerError as GeneralError);
				return of({} as ApplyFlowResponseResource);
			}),
		);
	}

	public searchAddress$(address: string): Observable<AddressesResource> {
		return this.get<AddressesResource>(`/location/search?query=${address}`).pipe(
			catchError((err) => {
				this.createError(err);
				return of({} as AddressesResource);
			}),
		);
	}

	public availableExperiences$(): Observable<ApplicationAvailabilityResource> {
		return this.post<ApplicationAvailabilityResource>(`/availability`, {}).pipe(
			catchError((err) => {
				this.createError(err);
				return of({} as ApplicationAvailabilityResource);
			}),
		);
	}

	public flow$(postData: ApplyFlowRequestResource, applicationId?: string): Observable<ApplyFlowResponseResource> {
		if (applicationId) {
			const sessionContextUpdate = new ClientContext();
			sessionContextUpdate.applicationId = applicationId;
			this.session.updateSession(sessionContextUpdate);
		}
		return this.post<ApplyFlowResponseResource>(`/apply/flow`, postData).pipe(
			catchError((err) => {
				this.createError(err);
				return of({} as ApplyFlowResponseResource);
			}),
		);
	}

	public heartBeat(): Observable<void> {
		return this.get<void>(`/`);
	}

	protected onAddHeaders(): PossibleHeaders | void {
		const headers: Record<string, string> = {};
		// todo: This should be an observable instead of stored in the class.
		if (this.currentContext) {
			const { ...filteredContext } = this.currentContext;
			headers['client-context'] = JSON.stringify(filteredContext);
		}
		return headers;
	}

	protected onResponse<T>(response: HttpResponse<T>): void {
		const sessionContextString = response?.headers?.get('client-context');
		if (sessionContextString) {
			const session = JSON.parse(sessionContextString) as ClientContext;

			/*
				This if block prevents additional flow responses from overriding session values originating from a throttled request
					that the client no longer cares about receiving an update about (mostly in reference to older flow requests after
					switching applications).
			*/
			if (session.appName != null && session.applicationId != null) {
				this.session.updateSession(session);
			}
		}
	}

	protected createError(error: HttpErrorResponse) {
		const message = error.message;
		let errorType: ErrorTypes;

		switch (error.status) {
			case 400:
				errorType = ErrorTypes.recoverable;
				break;
			case 401:
				errorType = ErrorTypes.unauthorized;
				break;
			case 403:
				errorType = ErrorTypes.fatal;
				break;
			case 408:
				errorType = ErrorTypes.recoverable;
				break;
			case 422:
				errorType = ErrorTypes.fatal;
				break;
			case 500:
				errorType = ErrorTypes.fatal;
				break;
			default:
				errorType = ErrorTypes.unknown;
		}
		const providerError = new ProviderError(errorType, message, error);
		this.errorService.handleError(providerError as GeneralError);
	}
}

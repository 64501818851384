import { RequestResource } from '@common/lib/models/resource/events/client/request-resource';
import { KnownRequests } from '@common/lib/constants/known-requests';
import { DocumentInfoResource } from '@common/lib/models/resource/document-info.resource';

export class RequestDocumentUploadRequestResource extends RequestResource {
	public name: string = KnownRequests.requestDocumentUpload;
	public documentGroupId: string;
	public documentGroup: string;
	public documents: DocumentInfoResource[];
}


import { TrackerEvent } from '@nbkc/tracker';
import { ApplicationTrackerContext } from '@experience/app/tracking/application-tracker-context';

export class VouchedActionEvent extends TrackerEvent<ApplicationTrackerContext> {

	public name: string;

	constructor(actionName: string) {
		super();
		this.name = 'vouched-' + actionName;
	}
}

import { Component, ElementRef, Injector, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { DynamicFormComponentBase } from '../../dynamic/dynamic-form-component.base';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { map, switchMap } from 'rxjs/operators';
import { FormField } from '@common/lib/models/form-field.model';

@Component({
	selector: 'common-chip-select',
	templateUrl: './chip-select.component.html',
	styleUrls: ['./chip-select.component.scss']
})
export class ChipSelectComponent extends DynamicFormComponentBase<ChipSelectComponentFields, ChipSelectComponentOptions> {
	@ViewChild('chipsInput') chipsInput: ElementRef<HTMLInputElement>;
	@ViewChild('auto') matAutocomplete: MatAutocomplete;

	public options$: Observable<Record<string, string>[]>;
	public displaySelector: string = '';
	public valueSelector: string = '';
	public hideRequiredMark: boolean = false;
	public removable: boolean = true;
	public selectable: boolean = true;
	public chipsCtrl: FormControl = new FormControl();
	public selectedItems: string[] = [];
	public filteredItems$: Observable<Record<string, string>[]>;
	public separatorKeysCodes: number[] = [ENTER, COMMA];

	constructor(
		public injector: Injector
	) {
		super(injector);
	}

	onComponentLoaded(): void {
		this.registerAllControls();
		this.options$ = this.formService.getModelValue$<Record<string, string>[]>(this.fields?.options?.modelPaths);
		this.displaySelector = this.options?.displaySelector;
		this.valueSelector = this.options?.valueSelector;
		this.hideRequiredMark = !!(this.options?.hideRequiredMark) || false;

		this.filteredItems$ = this.chipsCtrl.valueChanges.pipe(
			switchMap((input: string) => this.options$.pipe(
				map((records) => {
					if (input != null) {
						input = input.toLowerCase();
					}

					return records.filter((record) => {
						const itemValue = this.getValue(record).toLowerCase();
						if (itemValue != null) {
							return itemValue.indexOf(input) === 0;
						}
						return null;
					});
				})
			))
		);
	}

	public getValue(item: Record<string, string>): string {
		if (this.valueSelector) {
			return item[this.valueSelector];
		}
		return item[0];
	}

	public getDisplay(item: Record<string, string>): string {
		if (this.displaySelector) {
			return item[this.displaySelector];
		}
		return item[0];
	}

	public remove(item: string): void {
		const index = this.selectedItems.indexOf(item, 0);

		if (index > -1) {
			this.selectedItems.splice(index, 1);
			this.formService.setModelValue(this.selectedItems, this.fields?.value.modelPaths);
		}
	}

	public selected(event: MatAutocompleteSelectedEvent): void {
		this.selectedItems.push(event.option.viewValue);
		this.formService.setModelValue(this.selectedItems, this.fields?.value.modelPaths);
		this.chipsInput.nativeElement.value = '';
		this.chipsCtrl.setValue(null);
	}
}

interface ChipSelectComponentFields extends Record<string, FormField> {

}

interface ChipSelectComponentOptions {
	displaySelector: string;
	valueSelector: string;
	hideRequiredMark: string;
}

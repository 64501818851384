<div class="checkbox-component" [ngClass]="containerClass || 'mt-2'">
	<form [ngSwitch]="options?.mode" [formGroup]="internalForm" >
		<mat-checkbox *ngSwitchCase="'Boolean'" formControlName="checked" class="big wrap-text" data-cy="check-field">
			<div [innerHTML]="label$| async"></div>
		</mat-checkbox>
		<mat-checkbox *ngSwitchCase="'Index'" formControlName="checked" class="big wrap-text" [checked]="isIndexChecked$ | async" (change)="setIndex($event.checked)" data-cy="check-field">
			<div [innerHTML]="label$| async"></div>
		</mat-checkbox>
		<mat-checkbox *ngSwitchCase="'Object'" formControlName="checked" class="big wrap-text" [checked]="isIndexChecked$ | async" (change)="setObject($event.checked)" data-cy="check-field">
			<div [innerHTML]="label$| async"></div>
		</mat-checkbox>
	</form>
</div>

import { Component, Injector } from '@angular/core';
import { DynamicFormComponentBase } from '../../dynamic/dynamic-form-component.base';
import { FormField } from '@common/lib/models/form-field.model';
import { Observable } from 'rxjs';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { map } from 'rxjs/operators';

@Component({
	selector: 'common-textarea',
	templateUrl: './textarea.component.html',
	styleUrls: ['./textarea.component.scss']
})

export class TextAreaComponent extends DynamicFormComponentBase<TextAreaComponentFields, TextAreaComponentOptions> {
	public inputMask: any = { mask: false };
	public textareaClass: string = null;
	public isCharacterLengthHelperVisible: boolean = false;
	public subText$: Observable<SafeHtml>;

	constructor(
		public injector: Injector,
		private sanitizer: DomSanitizer
	) {
		super(injector);
	}

	onComponentLoaded(): void {
		this.textareaClass = this.options?.class;
		this.containerClass = this.options?.containerClass;
		this.registerAllControls({ isDisabled: this.options?.isDisabled });
		this.subText$ = this.formService.getModelValue$<string>(null, this.options?.subtext).pipe(
			map((value) => this.sanitizer.bypassSecurityTrustHtml(value))
		);
	}
}
interface TextAreaComponentFields extends Record<string, FormField> {
	value: FormField;
}

interface TextAreaComponentOptions {
	useIconValidation: string;
	label: string;
	subtext: string;
	class: string;
	containerClass: string;
	isDisabled: boolean;
	characterCountEnabled: boolean;
	maxLength: string;
}

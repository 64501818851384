import { ApplicationInsightsEvent, GoogleTagManagerEvent, TrackerEvent } from '@nbkc/tracker';
import { ApplicationTrackerContext } from '../application-tracker-context';

export class ApplicationSubmitEvent extends TrackerEvent<ApplicationTrackerContext> {
    public name: string = '';
	public productName: string = '';

    constructor(params: ApplicationSubmitEventParams) {
        super();
        this.name = params.experienceType;
		this.productName = params.productName;
    }

    public ai(): ApplicationInsightsEvent {
		const event = new ApplicationInsightsEvent();
		event.name = this.name;
		event.data = {
			productName: this.productName
		};
		return event;
	}

	public gtm(): GoogleTagManagerEvent {
		const event = new GoogleTagManagerEvent();
		event.event = this.name;
		event.data = {
			productName: this.productName
		};
		return event;
	}
}

interface ApplicationSubmitEventParams {
    experienceType: 'bluefin-click-submit-personal' | 'bluefin-click-submit-business';
	productName: string;
}

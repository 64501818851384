import { ComponentDefinition } from '@common/lib/models/component-definition.model';

export class PresentationDefinition {
	public name: string;
	public description: string;
	public displayName: string;
	public requiresContext: boolean;
	public route: string;
	public body: ComponentDefinition;
	public header: ComponentDefinition;
	public footer: ComponentDefinition;
	public availableRoutes: string[];
	public next: string;
	public previous: string;
}

<form [formGroup]="applicantForm">
	<app-divider classes="mt-10 mb-10" text="Basic Information"></app-divider>
	<mat-form-field>
		<mat-label>First Name</mat-label>
		<input formControlName="firstName" matInput required data-cy="input-field" />
		@if (applicantForm.get('firstName').hasError('required')) {
			<mat-error>Please include {{ $applicantNameOrDefault() }}'s first name.</mat-error>
		}
		@if (applicantForm.get('firstName').hasError('maxLength')) {
			<mat-error>{{ $applicantNameOrDefault() }}'s first name must be 40 characters or less.</mat-error>
		}
	</mat-form-field>
	<mat-form-field>
		<mat-label>Middle Name</mat-label>
		<input formControlName="middleName" matInput data-cy="input-field" />
		@if (applicantForm.get('middleName').hasError('maxLength')) {
			<mat-error>{{ $applicantNameOrDefault() }}'s middle name must be 40 characters or less.</mat-error>
		}
	</mat-form-field>
	<mat-form-field>
		<mat-label>Last Name</mat-label>
		<input formControlName="lastName" matInput required data-cy="input-field" />
		@if (applicantForm.get('lastName').hasError('required')) {
			<mat-error>Please include {{ $applicantNameOrDefault() }}'s last name.</mat-error>
		}
		@if (applicantForm.get('lastName').hasError('maxLength')) {
			<mat-error>{{ $applicantNameOrDefault() }}'s last name must be 40 characters or less.</mat-error>
		}
	</mat-form-field>
	<mat-form-field>
		<mat-label>Email</mat-label>
		<app-masked-input
			[formControl]="getFormControlInstance('email')"
			maskType="email"
			[required]="true"
			data-cy="input-field"
		>
		</app-masked-input>
		@if (applicantForm.get('email').hasError('required') || applicantForm.get('email').hasError('mask')) {
			<mat-error>Please include a valid email for {{ $applicantNameOrDefault() }}.</mat-error>
		}
	</mat-form-field>
	<mat-form-field>
		<mat-label>Phone Number</mat-label>
		<app-masked-input
			[formControl]="getFormControlInstance('phoneNumber')"
			maskType="phone"
			[required]="true"
			data-cy="input-field"
			(blur)="saveApplication()"
		>
		</app-masked-input>
		<mat-hint
			>This phone number will be used if you need to activate your debit card or reset your password.</mat-hint
		>
		@if (applicantForm.get('phoneNumber').hasError('required')) {
			<mat-error>Please include {{ $applicantNameOrDefault() }}'s phone number.</mat-error>
		}
		@if (applicantForm.get('phoneNumber').hasError('mask')) {
			<mat-error>Please include a valid phone number for {{ $applicantNameOrDefault() }}.</mat-error>
		}
	</mat-form-field>
	@if (!$isPersonal()) {
		<app-divider classes="mt-10 mb-10" text="Business Role"></app-divider>
	}

	<app-divider classes="mt-10 mb-10" text="Address"></app-divider>
	<div formArrayName="addresses">
		<div [formGroup]="$physicalAddress()">
			<mat-form-field>
				<mat-label>Address</mat-label>
				<input formControlName="address1" matInput required data-cy="input-field" />
				<mat-hint>No P.O. Boxes</mat-hint>
				@if ($physicalAddress().get('address1').hasError('required')) {
					<mat-error>Please include {{ $applicantNameOrDefault() }}'s address line 1.</mat-error>
				}
				@if ($physicalAddress().get('address1').hasError('maxLength')) {
					<mat-error
						>{{ $applicantNameOrDefault() }}'s address line 1 must be 40 characters or less.</mat-error
					>
				}
			</mat-form-field>
			<mat-form-field>
				<mat-label>Address Line 2</mat-label>
				<input formControlName="address2" matInput data-cy="input-field" />
				@if ($physicalAddress().get('address2').hasError('maxLength')) {
					<mat-error
						>{{ $applicantNameOrDefault() }}'s address line 2 must be 40 characters or less.</mat-error
					>
				}
			</mat-form-field>
			<mat-form-field>
				<mat-label>City</mat-label>
				<input formControlName="city" matInput required data-cy="input-field" />
				@if ($physicalAddress().get('city').hasError('required')) {
					<mat-error>Please include {{ $applicantNameOrDefault() }}'s address city.</mat-error>
				}
				@if ($physicalAddress().get('city').hasError('maxLength')) {
					<mat-error>{{ $applicantNameOrDefault() }}'s address city must be 25 characters or less.</mat-error>
				}
			</mat-form-field>
			<mat-form-field>
				<mat-label>State</mat-label>
				<mat-select formControlName="state" required data-cy="select-field">
					@for (state of $lookups().States; track state.value) {
						<mat-option [value]="state.value">{{ state.display }}</mat-option>
					}
				</mat-select>
				@if ($physicalAddress().get('state').hasError('required')) {
					<mat-error>Please include {{ $applicantNameOrDefault() }}'s state.</mat-error>
				}
			</mat-form-field>
			<mat-form-field>
				<mat-label>Zip Code</mat-label>
				<app-masked-input
					[formControl]="getFormControlInstance('postalCode', 'physicalAddress')"
					maskType="zip"
					[required]="true"
					data-cy="input-field"
					(blur)="updateApplicants()"
				>
				</app-masked-input>
				@if ($physicalAddress().get('postalCode').hasError('required')) {
					<mat-error>Please include {{ $applicantNameOrDefault() }}'s address zip code.</mat-error>
				}
				@if ($physicalAddress().get('postalCode').hasError('mask')) {
					<mat-error
						>Please include a valid zip code for {{ $applicantNameOrDefault() }}'s address.</mat-error
					>
				}
			</mat-form-field>
		</div>
		<div class="flex justify-between">
			<div>Is this also the mailing address?</div>
			<div class="'text-base flex justify-end mb-4'">
				<mat-label class="mx-3 text-base">No</mat-label>
				<mat-slide-toggle
					[(ngModel)]="$usePhysicalAddressAsMailingAddress"
					(ngModelChange)="onChangeAddressType()"
					[ngModelOptions]="{ standalone: true }"
					class="nbkc-blue"
					data-cy="toggle-field"
				>
				</mat-slide-toggle>
				<mat-label class="mx-3 text-base">Yes</mat-label>
			</div>
		</div>

		<div [formGroup]="$mailingAddress()" [class.hidden]="$usePhysicalAddressAsMailingAddress()">
			<app-divider classes="mt-10 mb-10" text="Mailing Address"></app-divider>
			<mat-form-field>
				<mat-label>Address</mat-label>
				<input formControlName="address1" matInput required data-cy="input-field" />
				@if ($mailingAddress().get('address1').hasError('required')) {
					<mat-error>Please include {{ $applicantNameOrDefault() }}'s address line 1.</mat-error>
				}
				@if ($mailingAddress().get('address1').hasError('maxLength')) {
					<mat-error
						>{{ $applicantNameOrDefault() }}'s address line 1 must be 40 characters or less.</mat-error
					>
				}
			</mat-form-field>
			<mat-form-field>
				<mat-label>Address Line 2</mat-label>
				<input formControlName="address2" matInput required data-cy="input-field" />
				@if ($mailingAddress().get('address2').hasError('maxLength')) {
					<mat-error
						>{{ $applicantNameOrDefault() }}'s address line 2 must be 40 characters or less.</mat-error
					>
				}
			</mat-form-field>
			<mat-form-field>
				<mat-label>City</mat-label>
				<input formControlName="city" matInput required data-cy="input-field" />
				@if ($mailingAddress().get('city').hasError('required')) {
					<mat-error>Please include {{ $applicantNameOrDefault() }}'s address city.</mat-error>
				}
				@if ($mailingAddress().get('city').hasError('maxLength')) {
					<mat-error>{{ $applicantNameOrDefault() }}'s address city must be 25 characters or less.</mat-error>
				}
			</mat-form-field>
			<mat-form-field>
				<mat-label>State</mat-label>
				<mat-select formControlName="state" required data-cy="select-field">
					@for (state of $lookups().States; track state.value) {
						<mat-option [value]="state.value">{{ state.display }}</mat-option>
					}
				</mat-select>
				@if ($mailingAddress().get('state').hasError('required')) {
					<mat-error>Please include {{ $applicantNameOrDefault() }}'s address state.</mat-error>
				}
			</mat-form-field>
			<mat-form-field>
				<mat-label>Zip Code</mat-label>
				<app-masked-input
					[formControl]="getFormControlInstance('postalCode', 'mailingAddress')"
					maskType="zip"
					[required]="true"
					data-cy="input-field"
				>
				</app-masked-input>
				@if ($mailingAddress().get('postalCode').hasError('required')) {
					<mat-error>Please include {{ $applicantNameOrDefault() }}'s address zip code.</mat-error>
				}
				@if ($mailingAddress().get('postalCode').hasError('mask')) {
					<mat-error
						>Please include a valid zip code for {{ $applicantNameOrDefault() }}'s address.</mat-error
					>
				}
			</mat-form-field>
		</div>
	</div>

	<app-divider classes="mt-10 mb-10" text="Identity"></app-divider>
	<div [formGroup]="identification">
		<mat-form-field>
			<mat-label>Primary ID Type</mat-label>
			<mat-select formControlName="type" required data-cy="select-field">
				<mat-option [value]="IdentificationType.DriversLicense">U.S. Driver's License</mat-option>
				<mat-option [value]="IdentificationType.Passport">Passport</mat-option>
				<mat-option [value]="IdentificationType.StateIdCard">U.S. State ID Card</mat-option>
			</mat-select>
			@if ($identification().get('type').hasError('required')) {
				<mat-error>Please select an identification type for {{ $applicantNameOrDefault() }}.</mat-error>
			}
		</mat-form-field>
		@switch ($idType()) {
			@case (IdentificationType.Passport) {
				<mat-form-field>
					<mat-label>Passport ID Number</mat-label>
					<input formControlName="number" matInput required data-cy="input-field" />
					@if ($identification().get('number').hasError('required')) {
						<mat-error>Please include {{ $applicantNameOrDefault() }}'s passport number.</mat-error>
					}
					@if ($identification().get('number').hasError('maxLength')) {
						<mat-error
							>{{ $applicantNameOrDefault() }}'s passport number must be 25 characters or less.</mat-error
						>
					}
				</mat-form-field>
				<mat-form-field>
					<mat-label>Passport ExpirationDate</mat-label>
					<app-masked-input
						[formControl]="getFormControlInstance('expirationDate', 'identification')"
						maskType="date"
						[required]="true"
						data-cy="input-field"
					>
					</app-masked-input>
					@if ($identification().get('expirationDate').hasError('required')) {
						<mat-error
							>Please include {{ $applicantNameOrDefault() }}'s passport expiration date.</mat-error
						>
					}
					@if ($identification().get('expirationDate').hasError('expiredId')) {
						<mat-error>{{ $applicantNameOrDefault() }}'s passport must not be expired.</mat-error>
					}
				</mat-form-field>
				<mat-form-field>
					<mat-label>Passport Issuing Country</mat-label>
					<mat-select formControlName="issuingCountry" required data-cy="select-field">
						@for (country of $lookups().Countries; track country.value) {
							<mat-option [value]="country.value">{{ country.display }}</mat-option>
						}
					</mat-select>
					@if ($identification().get('issuingCountry').hasError('required')) {
						<mat-error
							>Please include the issuing country for {{ $applicantNameOrDefault() }}'s
							passport.</mat-error
						>
					}
				</mat-form-field>
			}
			@case (IdentificationType.DriversLicense) {
				<mat-form-field>
					<mat-label>Driver's License Issuing State</mat-label>
					<mat-select formControlName="issuingState" required data-cy="select-field">
						@for (state of $lookups().States; track state.value) {
							<mat-option [value]="state.value">{{ state.display }}</mat-option>
						}
					</mat-select>
					@if ($identification().get('issuingState').hasError('required')) {
						<mat-error
							>Please select a state for {{ $applicantNameOrDefault() }}'s driver's license.</mat-error
						>
					}
				</mat-form-field>
				<mat-form-field>
					<mat-label>Driver's License Number</mat-label>
					<input formControlName="number" matInput required data-cy="input-field" />
					@if ($identification().get('number').hasError('required')) {
						<mat-error>Please include {{ $applicantNameOrDefault() }}'s driver's license number.</mat-error>
					}
					@if ($identification().get('number').hasError('maxLength')) {
						<mat-error
							>{{ $applicantNameOrDefault() }}'s driver's license number must be 25 characters or
							less.</mat-error
						>
					}
				</mat-form-field>
				<mat-form-field>
					<mat-label>Driver's License ExpirationDate</mat-label>
					<app-masked-input
						[formControl]="getFormControlInstance('expirationDate', 'identification')"
						maskType="date"
						[required]="true"
						data-cy="input-field"
					>
					</app-masked-input>
					@if ($identification().get('expirationDate').hasError('required')) {
						<mat-error
							>Please include {{ $applicantNameOrDefault() }}'s driver's license expiration
							date.</mat-error
						>
					}
					@if ($identification().get('expirationDate').hasError('expiredId')) {
						<mat-error>{{ $applicantNameOrDefault() }}'s driver's license must not be expired.</mat-error>
					}
				</mat-form-field>
			}
			@case (IdentificationType.StateIdCard) {
				<mat-form-field>
					<mat-label>ID Issuing State</mat-label>
					<mat-select formControlName="issuingState" data-cy="select-field">
						@for (state of $lookups().States; track state.value) {
							<mat-option [value]="state.value">{{ state.display }}</mat-option>
						}
					</mat-select>
					@if ($identification().get('issuingState').hasError('required')) {
						<mat-error
							>Please select a state for {{ $applicantNameOrDefault() }}'s state id card.</mat-error
						>
					}
				</mat-form-field>
				<mat-form-field>
					<mat-label>ID Number</mat-label>
					<input formControlName="number" matInput required data-cy="input-field" />
					@if ($identification().get('number').hasError('required')) {
						<mat-error
							>Please include {{ $applicantNameOrDefault() }}'s state identification card
							number.</mat-error
						>
					}
					@if ($identification().get('number').hasError('maxLength')) {
						<mat-error
							>{{ $applicantNameOrDefault() }}'s state id card number must be 25 characters or
							less.</mat-error
						>
					}
				</mat-form-field>
				<mat-form-field>
					<mat-label>State ID Expiration Date</mat-label>
					<app-masked-input
						[formControl]="getFormControlInstance('expirationDate', 'identification')"
						maskType="date"
						[required]="true"
						data-cy="input-field"
					>
					</app-masked-input>
					@if ($identification().get('expirationDate').hasError('required')) {
						<mat-error
							>Please include {{ $applicantNameOrDefault() }}'s state id expiration date.</mat-error
						>
					}
					@if ($identification().get('expirationDate').hasError('expiredId')) {
						<mat-error>{{ $applicantNameOrDefault() }}'s identification must not be expired.</mat-error>
					}
				</mat-form-field>
			}
		}
	</div>
	<mat-form-field>
		<mat-label>Date of Birth</mat-label>
		<app-masked-input
			[formControl]="getFormControlInstance('dateOfBirth')"
			maskType="date"
			[required]="true"
			data-cy="input-field"
		>
		</app-masked-input>
		@if (
			applicantForm.get('dateOfBirth').hasError('required') || applicantForm.get('dateOfBirth').hasError('maxAge')
		) {
			<mat-error>Please include a valid date of birth for {{ $applicantNameOrDefault() }}.</mat-error>
		}
	</mat-form-field>
	<div [formGroup]="taxIdentification">
		<mat-form-field>
			<mat-label>Social Security Number</mat-label>
			<app-masked-input
				[formControl]="getFormControlInstance('number', 'taxIdentification')"
				maskType="ssn"
				[required]="true"
				data-cy="input-field"
			>
			</app-masked-input>
			@if (
				$taxIdentification().get('number').hasError('required') ||
				taxIdentification.get('number').hasError('mask')
			) {
				<mat-error
					>Please include a valid social security number for {{ $applicantNameOrDefault() }}.</mat-error
				>
			}
			@if ($taxIdentification().get('number').hasError('ssnMismatch')) {
				<mat-error
					>Please confirm that social security numbers for {{ $applicantNameOrDefault() }} match.</mat-error
				>
			}
		</mat-form-field>
		<mat-form-field>
			<mat-label>Confirm Social Security Number</mat-label>
			<app-masked-input
				[formControl]="getFormControlInstance('ssnConfirmation', 'taxIdentification')"
				maskType="ssn"
				[required]="true"
				data-cy="input-field"
			>
			</app-masked-input>
			@if ($taxIdentification().get('ssnConfirmation').hasError('required')) {
				<mat-error>Please confirm social security number for {{ $applicantNameOrDefault() }}.</mat-error>
			}
			@if ($taxIdentification().get('ssnConfirmation').hasError('ssnMismatch')) {
				<mat-error
					>Please confirm that social security numbers for {{ $applicantNameOrDefault() }} match.</mat-error
				>
			}
		</mat-form-field>
	</div>
	<mat-form-field>
		<mat-label>Mother's Maiden Name</mat-label>
		<input formControlName="mothersMaidenName" (blur)="saveApplication()" matInput required data-cy="input-field" />
		@if (applicantForm.get('mothersMaidenName').hasError('required')) {
			<mat-error>Please include {{ $applicantNameOrDefault() }}'s mother's maiden name.</mat-error>
		}
		@if (applicantForm.get('mothersMaidenName').hasError('maxLength')) {
			<mat-error>{{ $applicantNameOrDefault() }}'s mother's maiden name must be 40 characters or less.</mat-error>
		}
	</mat-form-field>

	<app-divider classes="mt-10 mb-10" text="Additional Details"></app-divider>

	<mat-form-field>
		<mat-label>What is your primary income source?</mat-label>
		<mat-select formControlName="incomeSource" required data-cy="select-field">
			@for (source of $lookups()['Income Sources']; track source.value) {
				<mat-option [value]="source.value">{{ source.display }}</mat-option>
			}
		</mat-select>
		@if (applicantForm.get('incomeSource').hasError('required')) {
			<mat-error>Please include {{ $applicantNameOrDefault() }}'s income source.</mat-error>
		}
	</mat-form-field>
	<mat-form-field>
		<mat-label>What is your occupation?</mat-label>
		<mat-select formControlName="occupation" required data-cy="select-field">
			@for (occupation of $lookups().Occupations; track occupation.value) {
				<mat-option [value]="occupation.value">{{ occupation.display }}</mat-option>
			}
		</mat-select>
		@if (applicantForm.get('occupation').hasError('required')) {
			<mat-error>Please include {{ $applicantNameOrDefault() }}'s occupation.</mat-error>
		}
	</mat-form-field>
	<div class="form-group flex-column">
		<div class="flex">
			<mat-checkbox
				formControlName="consentsToTaxRequirements"
				class="big-checkbox wrap-checkbox-text"
				required
				data-cy="check-field"
			></mat-checkbox>

			<label>
				I confirm that:
				<ul class="list-disc list-inside">
					<li>I am a U.S. citizen or Resident Alien.</li>
					<li>I reside in the U.S. or U.S. territory for the majority of each calendar year.</li>
					<li>I am not subject to backup withholding by the IRS.</li>
				</ul>
			</label>
		</div>
		@if (
			applicantForm.get('consentsToTaxRequirements').dirty &&
			applicantForm.get('consentsToTaxRequirements').hasError('required')
		) {
			<div class="custom-error">Please verify residency and tax status for {{ $applicantNameOrDefault() }}.</div>
		}
	</div>
</form>

<app-todo-header></app-todo-header>
<app-card>
	@for (business of businesses; track business) {
		<div class="text-xl mb-3 flex">
			<div class="mb-4 w-8">
				<i [className]="business.Icon"></i>
			</div>
			<div class="mb-4">
				<div class="ml-6">
					<span class="block">
						{{ business.DisplayText }}
					</span>
				</div>
			</div>
		</div>
	}
</app-card>
<app-next-todo-button></app-next-todo-button>

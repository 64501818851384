import { Injector, isDevMode } from '@angular/core';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const overrideConsoleLog = (injector: Injector): Promise<void> => new Promise((resolve) => {

    if (!isDevMode()) {
        console.log = () => {};
    }

	resolve();
});


import { Component, input } from '@angular/core';
import { AsyncPipe, DecimalPipe, NgClass } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatIcon } from '@angular/material/icon';

@Component({
	selector: 'app-card',
	standalone: true,
	imports: [MatCardModule, NgClass, MatIcon, AsyncPipe, DecimalPipe],
	templateUrl: './app-card.component.html',
	styleUrl: './app-card.component.scss',
})
export class AppCardComponent {
	title = input<string>('');
	subtitle = input<string>('');
	secondSubtitle = input<string>('');
	headerIcon = input<string>(''); // Font Awesome icon class
	headerIconColor = input<string>('blue');
	hasCornerBanner = input<boolean>(false);
	xlHeader = input<boolean>(false);
	cardActionInfo = input<string>('');
	bodyText = input<string>('');
	cardActions = input<string[]>([]);
	headerRate = input<string>('');
	hideHeader = input<boolean>(false);
}

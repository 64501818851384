import { Component, Injector } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { DynamicFormComponentBase } from '../../dynamic/dynamic-form-component.base';
import { MatSelectChange } from '@angular/material/select';
import _ from 'lodash';
import { FormField } from '@common/lib/models/form-field.model';

@Component({
	selector: 'common-multi-select-chips',
	templateUrl: './multi-select-chips.component.html',
	styleUrls: ['./multi-select-chips.component.scss']
})

export class MultiSelectChipsComponent extends DynamicFormComponentBase<MultiSelectChipsComponentFields, MultiSelectChipsComponentOptions> {
	public options$: Observable<Record<string, string>[]>;
	public availableOptions$: Observable<Record<string, string>[]>;
	public selected$: Observable<string[]>;
	public displaySelector: string = '';
	public valueSelector: string = '';
	public hideRequiredMark: boolean = false;
	public removable: boolean = true;
	public disabled: boolean = false;
	public chipsCtrl: FormControl;

	constructor(
		public injector: Injector
	) {
		super(injector);
	}

	onComponentLoaded(): void {
		this.registerAllControls();
		this.disabled = this.options?.isDisabled;
		this.options$ = this.formService.getModelValue$<Record<string, string>[]>(this.fields?.options?.modelPaths);
		this.selected$ = this.formService.getModelValue$<string[]>(this.fields?.selected?.modelPaths);
		this.availableOptions$ = this.selected$.pipe(
			switchMap((selectedValues) => this.options$.pipe(
					map((options) => _.filter(options, (value) => !_.includes(selectedValues, value.description)).sort())
				))
		);
		this.displaySelector = this.options?.displaySelector;
		this.valueSelector = this.options?.valueSelector;
		this.hideRequiredMark = !!(this.options?.hideRequiredMark) || false;
		if (this.options.isDisabled) {
			this.removable = false;
			this.chipsCtrl = new FormControl({ value: '', disabled: true});
		} else {
			this.chipsCtrl = new FormControl();
		}
	}

	public getValue(item: Record<string, string>): string {
		if (this.valueSelector) {
			return item[this.valueSelector];
		}
		return item[0];
	}

	public getDisplay(item: Record<string, string>): string {
		if (this.displaySelector) {
			return item[this.displaySelector];
		}
		return item[0];
	}

	public onChange(event: MatSelectChange): void {
		this.selected$.pipe(take(1)).subscribe((currentSelectedItems) => {
			currentSelectedItems.push(event.value);
			this.formService.setModelValue(currentSelectedItems, this.fields?.selected?.modelPaths);
		});
	}

	public remove(item: string): void {
		this.selected$.pipe(take(1)).subscribe((currentSelectedItems) => {
			const index = currentSelectedItems.indexOf(item, 0);
			currentSelectedItems.splice(index, 1);
			this.formService.setModelValue(currentSelectedItems, this.fields?.selected?.modelPaths);
		});

		this.chipsCtrl.setValue(null);
	}
}

interface MultiSelectChipsComponentFields extends Record<string, FormField> {
	selected: FormField;
	options: FormField;
}

interface MultiSelectChipsComponentOptions {
	hideRequiredMark: string;
	displaySelector: string;
	valueSelector: string;
	label: string;
	formFieldClasses: string;
	matChipClasses: string;
	isDisabled: boolean;
}

import { inject, Injectable } from '@angular/core';
import { knownApplicationStatus } from '@common/lib/constants/known-application-statuses';
import { InternalStateService } from './internal-state.service';
import { ApplicationKind } from '../models/enums/application-kind';
import { Router } from '@angular/router';
import { KnownRoutes } from '../constants/known-routes';

@Injectable({
	providedIn: 'root',
})
export class AppRoutingService {
	private internalStateService = inject(InternalStateService);
	private router = inject(Router);

	routeToApplicationScreen(screenName: string): void {
		this.router.navigateByUrl(`${this.internalStateService.$applicationKind().toLowerCase()}/${screenName}`);
	}

	routeToFullRoute(fullRoute: string): void {
		this.router.navigateByUrl(fullRoute);
	}

	routeByApplicationStatus(applicationStatus: string): void {
		let nextScreen = '';
		switch (applicationStatus) {
			case knownApplicationStatus.Approved:
				nextScreen = KnownRoutes.SetupOnlineBanking;
				break;
			case knownApplicationStatus.InReview:
				nextScreen = KnownRoutes.ManualReview;
				break;
			case knownApplicationStatus.Denied:
				nextScreen = KnownRoutes.Denied;
				break;
			case knownApplicationStatus.InProgress:
				// TODO - Go to last visited screen for existing apps.
				switch (this.internalStateService.$applicationKind()) {
					case ApplicationKind.Personal:
						nextScreen = KnownRoutes.ProductSelection;
						break;
					case ApplicationKind.Business:
						nextScreen = KnownRoutes.ProhibitedBusinesses;
						break;
					default:
						nextScreen = KnownRoutes.OnHold;
						break;
				}
				break;
			default:
				nextScreen = KnownRoutes.OnHold;
				break;
		}

		this.router.navigateByUrl(`${this.internalStateService.$applicationKind().toLowerCase()}/${nextScreen}`);
	}
}

import { CacheProvider } from '@common/lib/utilities/cache/cache-provider';
import _ from 'lodash';

export class LocalStorageCacheProvider extends CacheProvider {
	protected getValuesFromCache(key: string): string {
		return localStorage.getItem(key);
	}

	protected removeValueFromCache(key: string): void {
		localStorage.removeItem(key);
	}

	protected setValue<T>(key: string, value: T): void {
		if (key.includes('_')) {
			throw new Error('The key for the stored value cannot contain an underscore.');
		}

		let valueToStore = null;

		if(typeof value === 'string') {
			valueToStore = value;
		} else if (_.isObjectLike(value)) {
			if (Object.keys(value).length > 0) {
				valueToStore = JSON.stringify(value);
			}
		}

		/* eslint-disable-next-line no-extra-boolean-cast */
		if (!!valueToStore) {
			localStorage.setItem(key, valueToStore);
		}
	}
}

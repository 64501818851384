export abstract class UrlUtility {
	public static getUrlParams(target?: string): any {
		const vars = { };
		const source = target || window.location.href;
		const urlParamRegex = /[?&]+([^=&]+)=([^&]*)/gi;
		source.replace(urlParamRegex, (url, key, value) => {
			vars[key] = value;
			return value;
		});
		return vars;
	}

	public static stripParams(url: string): string {
		const sections = url.split('?');
		return sections[0];
	}
}

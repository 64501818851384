import { AfterViewInit, Component, inject, OnInit, signal } from '@angular/core';
import { TodoItem } from '@common/lib/models/todo-item';
import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { Observable } from 'rxjs';
import { KnownValues } from '@common/lib/constants/known-values';
import { navigateActivity } from '@common/lib/constants/activities';
import { LoadingService } from '@experience/app/services/loading.service';
import { LegacyRoutingService } from '@experience/app/services/legacy-routing.service';
import { PresentationService } from '@experience/app/services/presentation.service';
import { InternalStateService } from '@experience/app/services/internal-state.service';
import { Router } from '@angular/router';

@Component({
	selector: 'experience-todo-list',
	templateUrl: './todo-list.component.html',
	styleUrls: ['./todo-list.component.scss'],
	animations: [
		trigger('listChanged', [
			transition('* => *', [
				// each time the binding value changes
				query(
					':enter',
					[
						style({
							opacity: 0,
						}),
						stagger(100, [
							animate(
								'200ms',
								style({
									opacity: 1,
								}),
							),
						]),
					],
					{ optional: true },
				),
			]),
		]),
	],
})
export class TodoListComponent implements OnInit, AfterViewInit {
	internalStateService = inject(InternalStateService);
	presentationService = inject(PresentationService);
	loadingService = inject(LoadingService);
	routingService = inject(LegacyRoutingService);
	router = inject(Router);

	public todoList = signal<TodoItem[]>([]);
	public currentRoute$: Observable<string>;
	public animationsDisabled: boolean = true;

	ngOnInit() {
		if (this.internalStateService.$isOldExperience()) {
			const todoList = this.presentationService.todoList$.value;
			this.todoList.set(todoList);
		} else {
			this.todoList.set([...this.internalStateService.$todoItems()]);
		}
	}

	ngAfterViewInit(): void {
		setTimeout(() => {
			this.animationsDisabled = false;
		});
	}

	public isActiveScreen(associatedRoute) {
		return associatedRoute === this.routingService.currentRoute;
	}

	public navigateToRoute(item: TodoItem): void {
		if (this.internalStateService.$isOldExperience()) {
			const { associatedRoute, complete } = item;
			if (complete && associatedRoute) {
				const fullRoute = `${KnownValues.workflowPath}${associatedRoute}`;
				this.routingService.setCurrentRoute(associatedRoute);
				this.routingService.navigateToUrl$(fullRoute).subscribe(() => {
					this.loadingService.stopLoadingActivity(navigateActivity);
				});
			}
		} else {
			this.router.navigateByUrl(
				`${this.internalStateService.$applicationKind().toLowerCase()}/${item.associatedRoute}`,
			);
		}
	}
}

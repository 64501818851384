<app-todo-header></app-todo-header>
<app-divider text="Legal Disclosures"></app-divider>
@for (product of $selectedProducts(); track product.id) {
	<div class="card-div-container flex justify-center mb-6">
		<app-card
			class="w-full"
			subtitle="Legal Disclosures"
			[title]="product.name"
			[headerIcon]="getProductIcon(product.name)"
		>
			@for (disclosure of product.disclosures; track $index) {
				<div class="flex justify-start p-0">
					<a
						mat-flat-button
						class="text-left whitespace-normal text-lg p-1"
						target="_blank"
						href="{{ disclosure.uri }}"
					>
						{{ disclosure.name }}
					</a>
				</div>
			}
		</app-card>
	</div>
}

@if ($qualification()?.failures?.length > 0) {
	<app-divider text="Complete before you proceed"></app-divider>
	@for (failure of $qualification()?.failures; track $index) {
		<div class="card-div-container flex justify-center mb-6">
			<app-card class="w-full" [hideHeader]="true">
				<div class="flex">
					<i class="far fa-exclamation-square text-3xl mr-3 self-center"></i>
					<span class="review-message whitespace-pre-wrap self-center">
						{{ failure.failureMessage }}
					</span>
				</div>
			</app-card>
		</div>
	}
	<button
		class="w-full text-white rounded-full py-2 px-4"
		mat-raised-button
		color="primary"
		data-cy="submit-button"
		disabled="true"
	>
		Submit
	</button>
} @else {
	<div class="card-div-container flex justify-center mb-6">
		<app-card
			class="w-full"
			title="Everything Looks Good"
			headerIcon="fa-check"
			headerIconColor="blue"
			bodyText="By clicking Submit you confirm that you had the opportunity to review the legal disclosures."
		>
		</app-card>
	</div>
	<button
		class="w-full text-white rounded-full py-2 px-4"
		mat-raised-button
		color="primary"
		data-cy="submit-button"
		(click)="submit()"
	>
		Submit
	</button>
}

import { BehaviorSubject, Observable, of } from 'rxjs';
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { first, map, withLatestFrom } from 'rxjs/operators';
import _ from 'lodash';
import { ReviewData } from '@common/lib/models/review-data';

export class ModelReviewItemValidator {
	public pendingChanges: number = 0;

	public createValidatorFn(targetModelPath$: Observable<string>, reviewData$: BehaviorSubject<ReviewData>): AsyncValidatorFn {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		return (control: AbstractControl): Observable<ValidationErrors | null> => {
			if (this.pendingChanges) {
				return of(null);
			}

			return targetModelPath$
			.pipe(
				withLatestFrom(reviewData$),
				map(([path, reviewData ]) => {
					if (reviewData?.reviewItems) {
						const targetReviewItem = _.find(reviewData.reviewItems, (reviewItem) => reviewItem.modelPath.includes(path));

						if (targetReviewItem) {
							return { reviewItems: targetReviewItem.message };
						}
					}
					return null;
				}),
				first()
			);
		};
	}
}

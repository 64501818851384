import { ConfigurationUtilityBase } from '@common/lib/utilities/configuration/configuration.utility';
import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export abstract class CommonConfiguration extends ConfigurationUtilityBase{
	public auth0Audience: string;
	public auth0CallbackUrl: string;
	public auth0ClientId: string;
	public auth0Domain: string;
	public auth0LogoutUrl: string;
	public experienceApi: string;
	public consoleTrackerEnabled: string;
	public applicationInsightsExcludedDomains: string;
	public applicationInsightsId: string;
	public applicationInsightsTrackerEnabled: string;
	public googleAnalyticsId: string;
	public googleAnalyticsTrackerEnabled: string;
	public googleTagManagerId: string;
	public googleTagManagerTrackerEnabled: string;
	public googleTagManagerAuthToken: string;
	public googleTagManagerEnvironment: string;
}

import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatButton } from '@angular/material/button';
import {
	MAT_DIALOG_DATA,
	MatDialogActions,
	MatDialogClose,
	MatDialogContent,
	MatDialogTitle,
} from '@angular/material/dialog';
import { DialogData } from '@experience/app/components-new/landing-page/landing-page.component';
import { DatePipe } from '@angular/common';

@Component({
	selector: 'app-new-app-dialog',
	standalone: true,
	imports: [MatButton, MatDialogActions, MatDialogContent, MatDialogTitle, MatDialogClose, DatePipe],
	templateUrl: './new-app-dialog.component.html',
	styleUrl: './new-app-dialog.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewAppDialogComponent {
	readonly data = inject<DialogData>(MAT_DIALOG_DATA);
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatRadioModule } from '@angular/material/radio';
import { AccessibilityAngularModule } from '@nbkc/accessibility-angular';
import { TextMaskModule } from './utilities/angular2-text-mask/text-mask';
import { AccountProfileSummaryComponent } from './components/account-profile-summary/account-profile-summary.component';
import { NextButtonComponent } from './components/buttons/next-button/next-button.component';
import { ActionButtonComponent } from './components/buttons/action-button/action-button.component';
import { CopyButtonComponent } from './components/buttons/copy-button/copy-button.component';
import { LinkButtonComponent } from './components/buttons/link-button/link-button.component';
import { ModalButtonComponent } from './components/buttons/modal-button/modal-button.component';
import { CardActionsComponent } from './components/containers/card/card-actions/card-actions.component';
import { CardBodyComponent } from './components/containers/card/card-body/card-body.component';
import { CardFooterComponent } from './components/containers/card/card-footer/card-footer.component';
import { CardHeaderComponent } from './components/containers/card/card-header/card-header.component';
import { CardComponent } from './components/containers/card/card.component';
import { ContainerComponent } from './components/containers/container/container.component';
import { SelectContainerComponent } from './components/containers/select-container/select-container.component';
import { ModalContainerComponent } from './components/containers/modal-container/modal-container.component';
import { DividerComponent } from './components/divider/divider.component';
import { DynamicComponent } from './components/dynamic/dynamic.component';
import { AddressComponent } from './components/form-controls/address/address.component';
import { CheckboxComponent } from './components/form-controls/checkbox/checkbox.component';
import { CreditCardComponent } from './components/form-controls/credit-card/credit-card.component';
import { InputComponent } from './components/form-controls/input/input.component';
import { SocialSecurityComponent } from './components/form-controls/inputs/social-security/social-security.component';
import { RadioButtonChipsComponent } from './components/form-controls/radio-button-chips/radio-button-chips.component';
import { RadioButtonGroupComponent } from './components/form-controls/radio-button-group/radio-button-group/radio-button-group.component';
import { SelectComponent } from './components/form-controls/select/select.component';
import { ChipSelectComponent } from './components/form-controls/chip-select/chip-select.component';
import { MultiSelectChipsComponent } from './components/form-controls/multi-select-chips/multi-select-chips.component';
import { ToggleComponent } from './components/form-controls/toggle/toggle.component';
import { GenericHeaderComponent } from './components/generic-header/generic-header.component';
import { ProfileHeaderComponent } from './components/profile-header/profile-header.component';
import { ProductHeaderComponent } from './components/product-header/product-header.component';
import { TextComponent } from './components/text/text.component';
import { IconComponent } from './components/icon/icon.component';
import { ValidationBadgeComponent } from './components/validation-badge/validation-badge.component';
import { ValidationMessagesComponent } from './components/form-controls/validation-messages/validation-messages.component';
import { DynamicComponentDirective } from './directives/dynamic-component.directive';
import { TextAreaComponent } from './components/form-controls/textarea/textarea.component';
import { CardSkeletonComponent } from '@common/lib/components/containers/card/card-skeleton/card-skeleton.component';
import { CheckboxGroupComponent } from './components/form-controls/checkbox-group/checkbox-group/checkbox-group.component';
import { ReviewComponent } from '@common/lib/components/review/review.component';
import { RouterModule } from '@angular/router';
import { DynamicFormComponentBase } from '@common/lib/components/dynamic/dynamic-form-component.base';
import { TrackerAngularModule, VisibilityTracker } from '@nbkc/tracker-angular';
import { AuthorizedUsersComponent } from './components/form-controls/authorized-users/authorized-users.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BackButtonComponent } from '@common/lib/components/buttons/back-button/back-button.component';
import { CharacterLengthComponent } from './components/character-length/character-length.component';
import { OnboardingComponent } from '@common/lib/components/form-controls/onboarding/onboarding.component';
import { UploadProgressBarComponent } from './components/upload-progress-bar/upload-progress-bar.component';
import { UploadDocumentListComponent } from './components/upload-document-list/upload-document-list.component';
import { ManualUploadComponent } from './components/manual-upload/manual-upload.component';
import { IdentityVerificationVouchedComponent } from './components/identity-verification-vouched/identity-verification-vouched.component';
import { ErrorModalComponent } from '@common/lib/components/error-modal/error-modal.component';
import { PasswordValidatorComponent } from './components/password-validator/password-validator.component';

const angularMaterialImports = [
	MatInputModule,
	MatCardModule,
	MatSelectModule,
	MatDividerModule,
	MatCheckboxModule,
	MatSlideToggleModule,
	MatButtonModule,
	MatSnackBarModule,
	MatChipsModule,
	MatIconModule,
	MatAutocompleteModule,
	MatDialogModule,
	MatProgressSpinnerModule,
	MatRadioModule,
	MatProgressBarModule,
];

const dynamicComponents = [
	DynamicFormComponentBase,
	AccountProfileSummaryComponent,
	SelectContainerComponent,
	NextButtonComponent,
	ActionButtonComponent,
	LinkButtonComponent,
	CopyButtonComponent,
	ContainerComponent,
	CardComponent,
	CardHeaderComponent,
	CardBodyComponent,
	CardActionsComponent,
	CardFooterComponent,
	DividerComponent,
	CheckboxComponent,
	ToggleComponent,
	SelectComponent,
	ChipSelectComponent,
	MultiSelectChipsComponent,
	ModalButtonComponent,
	ModalContainerComponent,
	SocialSecurityComponent,
	GenericHeaderComponent,
	ProductHeaderComponent,
	ValidationBadgeComponent,
	TextComponent,
	IconComponent,
	RadioButtonChipsComponent,
	RadioButtonGroupComponent,
	CreditCardComponent,
	ProfileHeaderComponent,
	InputComponent,
	TextAreaComponent,
	AddressComponent,
	DynamicComponent,
	DynamicComponentDirective,
	CardSkeletonComponent,
	AuthorizedUsersComponent,
	CheckboxGroupComponent,
	ReviewComponent,
	BackButtonComponent,
	OnboardingComponent,
];

const components = [
	CharacterLengthComponent,
	ValidationMessagesComponent,
	UploadProgressBarComponent,
	UploadDocumentListComponent,
	ManualUploadComponent,
	IdentityVerificationVouchedComponent,
	ErrorModalComponent,
	PasswordValidatorComponent,
];

@NgModule({
	declarations: [...dynamicComponents, ...components],
	imports: [
		CommonModule,
		ReactiveFormsModule,
		AccessibilityAngularModule,
		TextMaskModule,
		RouterModule,
		BrowserAnimationsModule,
		TrackerAngularModule.withTrackers([VisibilityTracker]),
		...angularMaterialImports,
	],
	exports: [...dynamicComponents, ...angularMaterialImports],
})
export class BlueFinCommonModule {}

import { BrowserData } from '../models/browser-data';
import { Injectable } from '@angular/core';
import Bowser from 'bowser';

@Injectable({
	providedIn: 'root'
})
export class BrowserDataUtility {

	private readonly browserData?: Bowser.Parser.ParsedResult;

	constructor() {
		const userAgent = window?.navigator?.userAgent;
		if (userAgent) {
			this.browserData = Bowser.parse(userAgent);
		}
	}

	public getBrowserData(): BrowserData {
		return {
			operatingSystem: this.getOperatingSystem(),
			browser: this.getBrowser(),
			device: this.getDevice(),
			location: null
		};
	}

	private getOperatingSystem(): string {
		return this.browserData?.os?.name;
	}

	private getBrowser(): string {
		return this.browserData?.browser?.name;
	}

	private getDevice(): string {
		return this.browserData?.platform?.type;
	}
}


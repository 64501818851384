<div [formGroup]="$addressForm()" class="flex flex-col gap-4">
	<mat-form-field>
		<mat-label>Address</mat-label>
		<input formControlName="address1" matInput required data-cy="input-field" [matAutocomplete]="auto" />
		<mat-autocomplete #auto="matAutocomplete" (optionSelected)="onAddressSelected($event)">
			@for (address of $filteredAddresses(); track $index) {
				<mat-option [value]="address">
					{{ address.full }}
				</mat-option>
			}
		</mat-autocomplete>
		<mat-hint>No P.O. Boxes</mat-hint>
		@if ($addressForm().get('address1').hasError('required')) {
			<mat-error>Please include {{ $applicantNameOrDefault() }}'s address line 1.</mat-error>
		}
		@if ($addressForm().get('address1').hasError('maxLength')) {
			<mat-error>{{ $applicantNameOrDefault() }}'s address line 1 must be 40 characters or less.</mat-error>
		}
	</mat-form-field>
	<mat-form-field>
		<mat-label>Address Line 2</mat-label>
		<input formControlName="address2" matInput data-cy="input-field" />
		@if ($addressForm().get('address2').hasError('maxLength')) {
			<mat-error>{{ $applicantNameOrDefault() }}'s address line 2 must be 40 characters or less.</mat-error>
		}
	</mat-form-field>
	<mat-form-field>
		<mat-label>City</mat-label>
		<input formControlName="city" matInput required data-cy="input-field" />
		@if ($addressForm().get('city').hasError('required')) {
			<mat-error>Please include {{ $applicantNameOrDefault() }}'s address city.</mat-error>
		}
		@if ($addressForm().get('city').hasError('maxLength')) {
			<mat-error>{{ $applicantNameOrDefault() }}'s address city must be 25 characters or less.</mat-error>
		}
	</mat-form-field>
	<mat-form-field>
		<mat-label>State</mat-label>
		<mat-select formControlName="state" required data-cy="select-field">
			@for (state of $lookups().States; track state.value) {
				<mat-option [value]="state.value">{{ state.display }}</mat-option>
			}
		</mat-select>
		@if ($addressForm().get('state').hasError('required')) {
			<mat-error>Please include {{ $applicantNameOrDefault() }}'s state.</mat-error>
		}
	</mat-form-field>
	<mat-form-field>
		<mat-label>Zip Code</mat-label>
		<app-masked-input
			[formControl]="$addressForm().get('postalCode') | formControl"
			maskType="zip"
			[required]="true"
			data-cy="input-field"
		>
		</app-masked-input>
		@if ($addressForm().get('postalCode').hasError('required')) {
			<mat-error>Please include {{ $applicantNameOrDefault() }}'s address zip code.</mat-error>
		}
		@if ($addressForm().get('postalCode').hasError('mask')) {
			<mat-error>Please include a valid zip code for {{ $applicantNameOrDefault() }}'s address.</mat-error>
		}
	</mat-form-field>
</div>

import _ from 'lodash';
import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class DynamicRegistrationUtility {
	public registeredElements: Record<string, {
		element: HTMLElement;
		subscribeFns: (() => Subscription)[];
		subscribers?: Subscription[];
	}> = { };
	public registrationActive: boolean = false;

	public registerElement(id: string, element: HTMLElement, ...subscribeFns: any): void {
		this.activateRegistration();
		if (this.registeredElements[id] != null) {
			return;
		}
		this.registeredElements[id] = {
			element,
			subscribeFns,
			subscribers: []
		};
	}

	public activateRegistration(): void {
		if (!this.registrationActive) {
			this.registeredElements = {};
			document.addEventListener('scroll', _.throttle(() => {
					_.each(this.registeredElements, (item) => {
						const visible = isInViewport(item.element, 500);
						if (visible && item?.subscribers?.length < 1) {
							item.subscribeFns?.forEach((subscribeFn) => {
								item.subscribers.push(subscribeFn());
							});
						} else if (!visible && item.subscribers?.length > 0) {
							item.subscribers?.forEach((subscriber) => {
								subscriber?.unsubscribe();
							});
							item.subscribers = [];
						}
					});
				}, 200)
				, true);
			this.registrationActive = true;
		}
	}

	public unRegister(id: string): void {
		this.registeredElements[id]?.subscribers?.forEach((subscriber) => {
			subscriber?.unsubscribe();
		});
		delete this.registeredElements[id];
	}
}

const isInViewport = (element: HTMLElement, buffer: number = 0) => {
	const rect = element.getBoundingClientRect();
	const visibleHeight = (window.innerHeight || document.documentElement.clientHeight) + buffer;
	const visibleWidth = (window.innerWidth || document.documentElement.clientWidth) + buffer;

	return (
		rect.top >= 0 &&
		rect.left >= 0 &&
		rect.bottom <= visibleHeight &&
		rect.right <= visibleWidth
	);
};

import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, mergeMap, tap } from 'rxjs/operators';
import { AuthenticationUtility } from '@common/lib/utilities/authentication/authentication.utility';
import { GeneralError } from '@common/lib/models/general-error.model';
import { ErrorTypes } from '@common/lib/models/enum/error-types.enum';
import { CommonErrorService } from '@common/lib/services/common-error.service';

@Injectable({
	providedIn: 'root'
})
export class AuthenticationInterceptor implements HttpInterceptor {
	constructor(private authenticationUtility: AuthenticationUtility, private errorService: CommonErrorService) {
	}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return this.authenticationUtility.authenticationContext$.pipe(
			tap((auth) => {
				if (!auth.isAuthenticated && !auth.hasError) {
					this.authenticationUtility.login();
				} else if (auth.hasError) {
					const error = new GeneralError();
					error.isRetrying = false;
					error.errorType = ErrorTypes.unauthorized;
					error.message = 'There was an issue getting your user information.';
					this.errorService.handleError(error);
				}
			}),
			filter((auth) => typeof auth.token === 'string'),
			mergeMap((auth) => {
				const tokenReq = req.clone({
					setHeaders: { authorization: `Bearer ${ auth.token }` }
				});
				return next.handle(tokenReq);
			}),
			catchError((err) => throwError(err))
		);
	}
}

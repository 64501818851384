<div class="card-div-container flex justify-center mb-6">
	<mat-card appearance="outlined" class="w-full overflow-hidden rounded-xl shadow-card" data-cy="card">
        <mat-card-title class="flex content-start w-full p-5">
            <div class="mr-3"><i [ngClass]="condition.icon" class="text-3xl"></i></div>
            <div class="card-title">{{condition.name}}</div>
        </mat-card-title>
		<mat-card-content>
			<div class="flex items-center">
				<div class="condition-description">{{condition.description}}</div>
			</div>
			<div class="flex flex-col items-start pt-2">
				<common-upload-document-list class="w-full" [condition]="condition"></common-upload-document-list>
			</div>
		</mat-card-content>
        <mat-card-actions align="end">
            <button mat-button color="primary" class="cursor-pointer p-2"
                (keydown.enter)="uploadElement.click()" (click)="uploadElement.click()">
                Upload
            </button>
        </mat-card-actions>
        <form [formGroup]="internalForm">
            <input multiple class="hidden" type="file" id="file"
                accept=".png,image/png,.jpg,.jpeg,image/jpeg,.pdf,application/pdf,.tif,.tiff,image/tiff,.gif,image/gif"
                (change)="handleFileInput($event, condition)" (click)="clearFileInput($event)" #uploadElement
			    data-cy="input-field">
        </form>
    </mat-card>
</div>

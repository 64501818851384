import { CommonModule, NgClass } from '@angular/common';
import { Component, input } from '@angular/core';
import { DocumentUploadData } from '@common/lib/models/document-upload-data.model';
import { AppUploadProgressComponent } from '../app-upload-progress/app-upload-progress.component';

@Component({
	selector: 'app-document-upload-list',
	standalone: true,
	imports: [NgClass, CommonModule, AppUploadProgressComponent],
	templateUrl: './app-document-upload-list.component.html',
	styleUrl: './app-document-upload-list.component.scss',
})
export class AppDocumentUploadListComponent {
	uploadList = input<DocumentUploadData[]>();
}

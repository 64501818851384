import { ChangeDetectionStrategy, Component, computed, inject, OnDestroy, OnInit } from '@angular/core';
import { DatePipe, NgForOf, NgIf, NgOptimizedImage } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { Subscription } from 'rxjs';
import {
	getApplicationActivity,
	getLookupsActivity,
	getProductsActivity,
	navigateActivity,
	setCurrentExperienceActivity,
} from '@common/lib/constants/activities';
import { ApplyEntryPoint } from '@common/lib/models/apply-entry-point';
import { AppCardComponent } from '@experience/app/components-new/card/app-card.component';
import { LoadingService } from '@experience/app/services/loading.service';
import { PresentationService } from '@experience/app/services/presentation.service';
import { ApplicationsApiService } from '@experience/app/services/applications-api.service';
import {
	NEW_APPLICATION_SUMMARY_CARDS,
	ONBOARDING_SUMMARY_CARDS,
} from '@experience/app/constants/onboarding-summary-cards';
import { MatDialog } from '@angular/material/dialog';
import { NewAppDialogComponent } from '../new-app-dialog/new-app-dialog.component';
import { InternalStateService } from '@experience/app/services/internal-state.service';
import { ExperienceConfiguration } from '@experience/app/experience.configuration';
import { ApplicationSummary } from '@experience/app/models/onboarding/application-summary.model';
import { ApplicationKind } from '@experience/app/models/enums/application-kind';
import { toSignal } from '@angular/core/rxjs-interop';
import { AppDividerComponent } from '../divider/app-divider.component';
import { AppRoutingService } from '@experience/app/services/app-routing.service';
import { KnownRoutes } from '@experience/app/constants/known-routes';

export interface DialogData {
	lastStartDate: string;
}

@Component({
	selector: 'app-landing-page',
	standalone: true,
	imports: [MatCardModule, NgForOf, NgIf, AppCardComponent, NgOptimizedImage, DatePipe, AppDividerComponent],
	templateUrl: './landing-page.component.html',
	styleUrl: './landing-page.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [
		trigger('listAnimation', [
			transition('* => *', [
				query(
					':enter',
					[
						style({ transform: 'translateX(-150%)' }),
						stagger(500, [animate('450ms', style({ transform: 'translateX(0)' }))]),
					],
					{ optional: true },
				),
			]),
		]),
	],
})
export class LandingPageComponent implements OnInit, OnDestroy {
	loadingService = inject(LoadingService);
	private internalStateService = inject(InternalStateService);
	private applicationsService = inject(ApplicationsApiService);
	private presentationService = inject(PresentationService);
	private routingService = inject(AppRoutingService);
	private config = inject(ExperienceConfiguration);
	readonly useNewExperience = this.config.useNewExperience;
	readonly confirmNewAppDialog = inject(MatDialog);

	applicationSummaries = toSignal<ApplicationSummary[], ApplicationSummary[]>(
		this.applicationsService.getApplicationSummaries$(),
		{
			initialValue: [],
		},
	);

	pendingApplications = computed(() => {
		const openApplications = this.sortApplicationsByDate(
			this.applicationSummaries().filter((summary) => {
				if (['InProgress', 'Approved', 'InReview', 'OnHold'].includes(summary.status)) {
					return summary;
				}
			}),
		);
		return [...this.sortApplicationsByDate(openApplications), ...this.sortApplicationsByDate(openApplications)];
	});

	closedApplications = computed(() => {
		const closed = this.applicationSummaries().filter((summary) => {
			if (['Onboarded', 'Denied', 'Archived', 'Withdrawn'].includes(summary.status)) {
				return summary;
			}
		});
		return this.sortApplicationsByDate(closed);
	});
	newApplications = [ApplicationKind.Personal, ApplicationKind.Business];
	private subscription: Subscription = new Subscription();

	constructor() {}

	ngOnInit(): void {
		this.loadingService.stopLoadingActivity(navigateActivity);
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

	private sortApplicationsByDate(applicationSummaries: ApplicationSummary[]): ApplicationSummary[] {
		return applicationSummaries.sort(
			(a, b) => new Date(b.modifiedDate).getTime() - new Date(a.modifiedDate).getTime(),
		);
	}

	handleExistingApplication(application: ApplicationSummary): void {
		if (application.status === 'Onboarded') {
			window.location.href = 'https://nbkc.com';
			return;
		}

		this.internalStateService.setIsOldExperience(application.isUpconverted);

		if (application.isUpconverted) {
			this.createEntryPointAndSetOldExperience(application);
			return;
		}

		this.internalStateService.setApplicationKind(application.kind);
		this.getLookupsAndProducts();

		this.loadingService.startLoadingActivity(getApplicationActivity);
		this.applicationsService.getApplication(application.id);
		this.routingService.routeByApplicationStatus(application.status);
	}

	startNewApplication(applicationKind: string): void {
		// TODO: Remove useNewExperience when going live with new bluefin
		this.internalStateService.setApplicationKind(applicationKind);
		if (this.useNewExperience) {
			this.internalStateService.setIsOldExperience(false);
			//If existing application(s) present dialog asking if user would like to continue the existing application
			if (this.pendingApplications().length) {
				const dialogRef = this.confirmNewAppDialog.open(NewAppDialogComponent, {
					width: '400px',
					data: { lastStartDate: this.pendingApplications()[0].startDate },
				});
				dialogRef.afterClosed().subscribe((result) => {
					if (result === 'continue') {
						//user selects continue existing app
						if (this.pendingApplications()[0].isUpconverted) {
							this.createEntryPointAndSetOldExperience(this.pendingApplications()[0]);
							return;
						}
						this.handleExistingApplication(this.pendingApplications()[0]);
						return;
					} else {
						this.getProductsAndStartApplication(applicationKind);
					}
				});
			} else {
				this.getProductsAndStartApplication(applicationKind);
				return;
			}
		} else {
			this.startNewApplicationOldExperience(applicationKind);
		}
	}

	private getProductsAndStartApplication(applicationKind: string): void {
		this.getLookupsAndProducts();
		this.loadingService.startLoadingActivity(getApplicationActivity);

		this.applicationsService.startApplication(applicationKind);

		if (applicationKind === ApplicationKind.Personal) {
			this.routingService.routeToApplicationScreen(KnownRoutes.ProductSelection);
		} else {
			this.routingService.routeToApplicationScreen(KnownRoutes.ProhibitedBusinesses);
		}
	}

	private getLookupsAndProducts() {
		const kind = this.internalStateService.$applicationKind();
		this.loadingService.startLoadingActivity(getLookupsActivity);
		this.applicationsService.getLookups(kind);

		this.loadingService.startLoadingActivity(getProductsActivity);
		this.applicationsService.getProducts(kind);
	}

	// Old Experience access methods
	createEntryPointAndSetOldExperience(application: ApplicationSummary): void {
		this.loadingService.startLoadingActivity(setCurrentExperienceActivity);
		const entryPoint = new ApplyEntryPoint();
		entryPoint.applicationId = application.id;
		entryPoint.applicationName = `deposits-${application.kind.toLowerCase()}`;

		if (application.status === 'Onboarded') {
			window.location.href = 'https://nbkc.com';
			return;
		}
		this.internalStateService.setIsOldExperience(application.isUpconverted);
		this.presentationService.startExperience(entryPoint).subscribe();
	}

	startNewApplicationOldExperience(applicationKind: string): void {
		this.internalStateService.setIsOldExperience(true);

		const entryPoint = new ApplyEntryPoint();
		entryPoint.applicationName = `deposits-${applicationKind.toLowerCase()}`;
		entryPoint.version = '1.0';
		if (this.pendingApplications().length) {
			const dialogRef = this.confirmNewAppDialog.open(NewAppDialogComponent, {
				width: '400px',
				data: { lastStartDate: this.pendingApplications()[0].startDate },
			});
			dialogRef.afterClosed().subscribe((result) => {
				if (result === true) {
					//user selects start new app
					this.startOldExperience(entryPoint);
				} else if (result === 'continue') {
					//user selects continue existing app
					this.createEntryPointAndSetOldExperience(this.pendingApplications()[0]);
					return;
				}
			});
		} else {
			this.startOldExperience(entryPoint);
		}
	}

	private startOldExperience(entryPoint: ApplyEntryPoint): void {
		this.loadingService.startLoadingActivity(setCurrentExperienceActivity);
		//TODO switch to false after this goes to prod
		this.internalStateService.setIsOldExperience(!this.useNewExperience);
		this.presentationService.startExperience(entryPoint).subscribe();
	}

	protected readonly NEW_APPLICATION_SUMMARY_CARDS = NEW_APPLICATION_SUMMARY_CARDS;
	protected readonly Object = Object;
	protected readonly ONBOARDING_SUMMARY_CARDS = ONBOARDING_SUMMARY_CARDS;
	protected readonly Date = Date;
}

import { CommonModule } from '@angular/common';
import { Component, input, OnInit } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@Component({
	selector: 'app-upload-progress',
	standalone: true,
	imports: [CommonModule, MatProgressBarModule],
	templateUrl: './app-upload-progress.component.html',
	styleUrl: './app-upload-progress.component.scss',
})
export class AppUploadProgressComponent implements OnInit {
	uploadComplete = input<boolean>();
	uploadGroupId = input<string>();

	showCompleteDisplay: boolean = false;
	currentProgressPercent: number = 0;
	durationInMs: number = 1500;

	ngOnInit(): void {
		const progressInterval = setInterval(() => {
			if (this.currentProgressPercent < 90) {
				this.currentProgressPercent += 10;
			}

			if (this.currentProgressPercent === 90 && !this.uploadComplete) {
				this.currentProgressPercent = 95;
			}

			if (this.currentProgressPercent >= 90 && this.uploadComplete) {
				this.currentProgressPercent = 100;
				this.showCompleteDisplay = true;
				clearInterval(progressInterval);
			}
		}, this.durationInMs / 10);
	}
}

import { Injector } from '@angular/core';
import { Tracker } from '@nbkc/tracker-angular';
import { ApplicationTrackerContext } from '@experience/app/tracking/application-tracker-context';
import { ApplicationStartEvent } from '@experience/app/tracking/events/application-start.event';
import { ExperienceConfiguration } from '@experience/app/experience.configuration';
import { HeartbeatEvent } from '@common/lib/tracking/events/heartbeat.event';

export const loadTracker = (injector: Injector): Promise<void> => new Promise((resolve) => {
	const tracker = injector.get(Tracker);
	const config = injector.get(ExperienceConfiguration);
	tracker.start({
		consoleTrackerEnabled: config.consoleTrackerEnabled || 'false',
		applicationInsightsExcludedDomains: config.applicationInsightsExcludedDomains,
		applicationInsightsId: config.applicationInsightsId,
		applicationInsightsTrackerEnabled: config.applicationInsightsTrackerEnabled || 'false',
		googleAnalyticsIds: config.googleAnalyticsId,
		googleAnalyticsTrackerEnabled: config.googleAnalyticsTrackerEnabled || 'false',
		googleTagManagerId: config.googleTagManagerId,
		googleTagManagerTrackerEnabled: config.googleTagManagerTrackerEnabled || 'false',
		googleTagManagerAuthToken: config.googleTagManagerAuthToken,
		googleTagManagerEnvironment: config.googleTagManagerEnvironment
	}, new ApplicationTrackerContext());
	tracker.event(new ApplicationStartEvent());
	(window as any).trackerInstance = tracker;

	setInterval(() => {
		tracker.event(new HeartbeatEvent());
	}, 180 * 1000);

	resolve();
});


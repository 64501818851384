import { TodoItem } from '@common/lib/models/todo-item';
import { KnownRoutes } from '@experience/app/constants/known-routes';

export const businessTodoItems: TodoItem[] = [
	{
		title: 'Prohibited Businesses',
		subtitle: 'Before getting started, you should know we are not able to service these businesses at the moment.',
		associatedRoute: KnownRoutes.ProhibitedBusinesses,
		complete: false,
	},
	{
		title: 'Tell Us About Yourself',
		subtitle: `Let's start with you and go from there.`,
		associatedRoute: KnownRoutes.PrimaryApplicant,
		complete: false,
	},
	{
		title: 'Tell Us About Your Business',
		subtitle: 'Help us understand the primary activities of your business.',
		associatedRoute: KnownRoutes.BusinessApplicant,
		complete: false,
	},
	{
		title: 'Business Expectations',
		subtitle:
			'Select any activities or services that your business may use. Provide us with a range of volume activity.',
		associatedRoute: KnownRoutes.BusinessExpectations,
		complete: false,
	},
	{
		title: 'Product Selection',
		subtitle: 'Select all the accounts your business needs. You may select multiple accounts if needed.',
		associatedRoute: KnownRoutes.ProductSelection,
		complete: false,
	},
	{
		title: 'Order Debit Card',
		subtitle: 'We can send you a business debit card connected to your business account.',
		associatedRoute: KnownRoutes.DebitCard,
		complete: false,
	},
	{
		title: 'Review and Disclosures',
		subtitle: 'When you have reviewed the disclosures and are ready to proceed, click the Next button.',
		associatedRoute: KnownRoutes.ApplicationReview,
		complete: false,
	},
	{
		title: 'Set Up Online Banking',
		subtitle: 'Your new account has been approved and created!',
		associatedRoute: KnownRoutes.SetupOnlineBanking,
		complete: false,
	},
	{
		title: 'Application Received',
		associatedRoute: KnownRoutes.ManualReview,
		complete: false,
	},
];

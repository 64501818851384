import { Component, ElementRef, Injector, ViewChild } from '@angular/core';
import { TEXT_MASKS } from '@common/lib/constants/text-masks';
import { DynamicFormComponentBase } from '../../dynamic/dynamic-form-component.base';
import { FormField } from '@common/lib/models/form-field.model';
import { ValidationRuleTypes } from '@common/lib/models/enum/validation-rule-types.enum';

@Component({
	selector: 'common-input',
	templateUrl: './input.component.html',
	styleUrls: ['./input.component.scss']
})
export class InputComponent extends DynamicFormComponentBase<InputComponentFields, InputComponentOptions> {
	@ViewChild('inputElement', { static: true }) public inputElement: ElementRef;

	public inputMask: any = { mask: false };
	public inputClass: string = null;
	public isCharacterLengthHelperVisible = false;

	constructor(
		public injector: Injector
	) {
		super(injector);
	}

	onComponentLoaded(): void {
		this.registerAllControls({ isDisabled: this.options?.isDisabled });
		this.inputClass = this.options?.class;
		this.containerClass = this.options?.containerClass;
		const maxLengthValidationRule = this.fields?.value?.validationRules?.find((rule) => rule.type === ValidationRuleTypes.maxLength);

		if (maxLengthValidationRule && this.options?.characterCountEnabled) {
			this.inputElement.nativeElement.maxLength = +maxLengthValidationRule.constraintValue;
		}

		switch (this.type) {
			case 'form-controls-inputs-zip':
				this.inputMask = TEXT_MASKS.zip;
				break;
			case 'form-controls-inputs-phone':
				this.inputMask = TEXT_MASKS.phone;
				break;
			case 'form-controls-inputs-ein':
				this.inputMask = TEXT_MASKS.ein;
				break;
			case 'form-controls-inputs-date':
				switch (this.options?.dateSelectionRange) {
					case 'future':
						this.inputMask = TEXT_MASKS.dateFuture;
						break;
					case 'all':
						this.inputMask = TEXT_MASKS.dateAll;
						break;
					case 'past':
					default:
						this.inputMask = TEXT_MASKS.datePast;
						break;
				}
				break;
		}
	}

	public onInputBlur(): void {
		// Trim input values on blur. Flow request will only be triggered if the value is truly modified after trimming
		const inputValue: string = this.internalForm.get('value')?.value;

		if (inputValue) {
			this.internalForm.get('value').setValue(inputValue.trim());
		}

		this.clearFocusedControl();
	}

	public onFormControlValueChanges(controlName: string, newValue: any): any {
		if (controlName === 'value' && this.options?.textTransform === 'uppercase' && newValue) {
			return newValue.toUpperCase();
		}
	}
}

interface InputComponentFields extends Record<string, FormField> {
	value: FormField;
}

interface InputComponentOptions {
	isDisabled: boolean;
	label: string;
	subtext: string;
	containerClass: string;
	class: string;
	textClass: string;
	characterCountEnabled: boolean;
	textTransform: 'uppercase' | 'lowercase' | null;
	dateSelectionRange: 'past' | 'future' | 'all';
}

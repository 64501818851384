import { ChangeDetectionStrategy, Component, inject, OnInit, model, computed, DestroyRef, signal } from '@angular/core';
import { InternalStateService } from '@experience/app/services/internal-state.service';
import {
	FormArray,
	FormBuilder,
	FormControl,
	FormGroup,
	FormRecord,
	FormsModule,
	ReactiveFormsModule,
	ValidationErrors,
	ValidatorFn,
	Validators,
} from '@angular/forms';
import { IdentificationType } from '@experience/app/models/enums/identification-type';
import { Subscription } from 'rxjs';
import { TaxIdentificationType } from '@experience/app/models/enums/tax-identification-type';
import {
	MAT_FORM_FIELD_DEFAULT_OPTIONS,
	MatFormField,
	MatLabel,
	MatHint,
	MatError,
} from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { formatDate, NgFor, NgIf } from '@angular/common';
import { MatOption } from '@angular/material/autocomplete';
import { MatSelect } from '@angular/material/select';
import { MaskedInputComponent } from '@experience/app/components-new/masked-input/masked-input.component';
import { NgxMaskDirective } from 'ngx-mask';
import { CdkMonitorFocus } from '@angular/cdk/a11y';
import { MatCheckbox } from '@angular/material/checkbox';
import { AuthenticationUtility } from '@common/lib/utilities/authentication/authentication.utility';
import { CustomValidators } from '@experience/app/shared/custom-validators';
import { AppDividerComponent } from '@experience/app/components-new/divider/app-divider.component';
import { ApplicationStateService } from '@experience/app/services/application-state.service';
import { Address } from '@experience/app/models/onboarding/address.model';
import { IndividualApplicant } from '@experience/app/models/onboarding/individual-applicant.model';
import { TaxIdentification } from '@experience/app/models/onboarding/tax-identification.model';
import { Identification } from '@experience/app/models/onboarding/identification.model';
import { ApplicationsApiService } from '@experience/app/services/applications-api.service';
import { LoadingService } from '@experience/app/services/loading.service';
import { ApplicantKind } from '@experience/app/models/enums/applicant-kind';
import { AddressType } from '@experience/app/models/enums/address-type';

@Component({
	selector: 'app-individual-applicant-form',
	standalone: true,
	imports: [
		ReactiveFormsModule,
		MatFormField,
		MatInput,
		MatSlideToggle,
		FormsModule,
		MatLabel,
		NgIf,
		NgFor,
		MatOption,
		MatSelect,
		MaskedInputComponent,
		NgxMaskDirective,
		CdkMonitorFocus,
		MatHint,
		MatCheckbox,
		AppDividerComponent,
		MatError,
	],
	templateUrl: './individual-applicant-form.component.html',
	styleUrl: './individual-applicant-form.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [{ provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } }],
})
export class IndividualApplicantFormComponent implements OnInit {
	private loadingService = inject(LoadingService);
	private internalStateService = inject(InternalStateService);
	private applicationStateService = inject(ApplicationStateService);
	private fb = inject(FormBuilder);
	private destroyRef = inject(DestroyRef);
	private auth = inject(AuthenticationUtility);
	private apiService = inject(ApplicationsApiService);

	$isPersonal = computed(() => this.internalStateService.$applicationKind() === 'Personal');
	$lookups = this.internalStateService.$lookups;
	$application = this.applicationStateService.$application;
	applicantForm: FormRecord;
	$usePhysicalAddressAsMailingAddress = model<boolean>(true);
	$applicantNameOrDefault = signal<string>('the account holder');
	$idType = signal<string>('');
	$identification = signal<FormGroup>({} as FormGroup);
	$taxIdentification = signal<FormGroup>({} as FormGroup);
	$physicalAddress = signal<FormGroup>({} as FormGroup);
	$mailingAddress = signal<FormGroup>({} as FormGroup);
	applicantEmail: string = '';
	subscription: Subscription = new Subscription();
	ssnMatchValidator: ValidatorFn;

	constructor() {}

	ngOnInit() {
		this.loadingService.stopAllLoadingActivities();
		const applicant = this.$application()?.applicants?.[0] || ({} as IndividualApplicant);
		const physicalAddress = applicant?.addresses?.[0] || ({} as Address);
		const mailingAddress = applicant?.addresses?.[1] || ({} as Address);
		const identification = applicant?.identification || ({} as Identification);
		const taxIdentification = applicant?.taxIdentification || ({} as TaxIdentification);

		this.applicantForm = this.fb.record(
			{
				kind: [ApplicantKind.Primary],
				firstName: [
					applicant?.firstName || '',
					{ validators: [Validators.required, Validators.maxLength(40)] },
				],
				middleName: [applicant?.middleName || '', { validators: Validators.maxLength(40) }],
				lastName: [applicant?.lastName || '', { validators: [Validators.required, Validators.maxLength(40)] }],
				email: ['', { validators: [Validators.email, Validators.required] }],
				phoneNumber: [applicant?.phoneNumber || '', { validators: [Validators.required] }],
				addresses: this.fb.array([
					this.fb.group({
						type: ['Physical'],
						address1: [
							physicalAddress?.address1 || '',
							{ validators: [Validators.required, Validators.maxLength(40)] },
						],
						address2: [physicalAddress?.address2 || '', { validators: [Validators.maxLength(40)] }],
						city: [
							physicalAddress?.city || '',
							{ validators: [Validators.required, Validators.maxLength(25)] },
						],
						state: [physicalAddress?.state || '', { validators: [Validators.required] }],
						postalCode: [physicalAddress?.postalCode || '', { validators: [Validators.required] }],
					}),
					this.fb.group({
						type: ['Mailing'],
						address1: [
							mailingAddress?.address1 || '',
							{ validators: [Validators.required, Validators.maxLength(40)] },
						],
						address2: [mailingAddress?.address2 || '', { validators: [Validators.maxLength(40)] }],
						city: [
							mailingAddress?.city || '',
							{ validators: [Validators.required, Validators.maxLength(25)] },
						],
						state: [mailingAddress?.state || '', { validators: [Validators.required] }],
						postalCode: [mailingAddress?.postalCode || '', { validators: [Validators.required] }],
					}),
				]),
				identification: this.fb.record(
					{
						type: [
							identification?.type ? IdentificationType[identification.type] : '',
							{ validators: Validators.required },
						],
						//eslint-disable-next-line id-blacklist
						number: [
							identification?.number || '',
							{ validators: [Validators.required, Validators.maxLength(25)] },
						],
						expirationDate: [
							identification?.expirationDate
								? formatDate(identification.expirationDate, 'MM/dd/yyyy', 'en-US')
								: '',
							{ validators: [Validators.required, CustomValidators.unexpiredId] },
						],
					},
					{ validators: Validators.required },
				),
				dateOfBirth: [
					applicant?.dateOfBirth ? formatDate(applicant.dateOfBirth, 'MM/dd/yyyy', 'en-US') : '',
					{ validators: [Validators.required, CustomValidators.minimumAge, CustomValidators.maxAge] },
				],
				taxIdentification: this.fb.group(
					{
						type: [
							taxIdentification?.type || TaxIdentificationType.SocialSecurityNumber,
							{ validators: Validators.required },
						],
						//eslint-disable-next-line id-blacklist
						number: [taxIdentification?.number || '', { validators: [Validators.required] }],
						ssnConfirmation: [taxIdentification.number || '', { validators: [Validators.required] }],
					},
					{ validators: this.ssnMatchValidator },
				),
				mothersMaidenName: [
					applicant?.mothersMaidenName || '',
					{ validators: [Validators.required, Validators.maxLength(40)] },
				],
				incomeSource: [applicant?.incomeSource || '', { validators: [Validators.required] }],
				occupation: [applicant?.occupation || '', { validators: [Validators.required] }],
				consentsToTaxRequirements: [
					applicant?.consentsToTaxRequirements || false,
					{ validators: [Validators.required] },
				],
			},
			{ updateOn: 'blur' },
		);

		this.subscription.add(
			this.applicantForm.valueChanges.subscribe(() => {
				this.updateApplicants();
			}),
		);

		// Pre-populate the email field if Personal application
		if (this.$isPersonal()) {
			this.subscription.add(
				this.auth.authenticationContext$.subscribe((context) => {
					this.applicantEmail = context.user?.email;
					this.applicantForm.get('email').setValue(this.applicantEmail);
					this.applicantForm.get('email').disable({ emitEvent: true });
				}),
			);
		} else {
			this.applicantForm.get('email').setValue(this.$application()?.applicants?.[0]?.email || '');
		}

		if (identification.type) {
			this.setIdControlsByType(IdentificationType[identification.type]);
			if (this.identification.get('issuingState')) {
				this.identification.get('issuingState').setValue(identification.issuingState);
			}
			if (this.identification.get('issuingCountry')) {
				this.identification.get('issuingCountry').setValue(identification.issuingCountry);
			}
			this.$idType.set(identification.type);
		}

		this.destroyRef.onDestroy(() => this.onDestroy());
		this.subscription.add(
			this.identification.get('type').valueChanges.subscribe((idType) => {
				if (idType) {
					// Clear the identification number and expiration date when changing ID type
					this.identification.get('number').setValue('');
					this.identification.get('expirationDate').setValue('');
					// Clear the issuing state or country when changing ID type
					this.setIdControlsByType(idType);
					// Trigger if block in template to show the issuing state or country
					this.$idType.set(idType);
				}
			}),
		);

		this.subscription.add(
			this.applicantForm.get('firstName').valueChanges.subscribe({
				next: (value) => {
					this.$applicantNameOrDefault.set(value);
				},
			}),
		);

		this.ssnMatchValidator = (taxIdentification: FormGroup): ValidationErrors | null => {
			if (
				taxIdentification.get('number').value !== taxIdentification.get('ssnConfirmation').value &&
				taxIdentification.get('ssnConfirmation').value !== ''
			) {
				taxIdentification.get('ssnConfirmation').setErrors({ ssnMismatch: true });
				taxIdentification.get('number').setErrors({ ssnMismatch: true });
			}
			return null;
		};
		this.taxIdentification.setValidators(this.ssnMatchValidator);

		//set signals for template
		this.$taxIdentification.set(this.applicantForm.get('taxIdentification') as FormGroup);
		this.$identification.set(this.applicantForm.get('identification') as FormGroup);
		this.$physicalAddress.set(this.getAddressGroup(0));
		this.$mailingAddress.set(this.getAddressGroup(1));
		// Set use mailing address as physical address toggle in template
		if (
			applicant?.addresses?.[0]?.address1 &&
			applicant?.addresses?.[0]?.address1 === applicant?.addresses?.[1]?.address1
		) {
			this.$usePhysicalAddressAsMailingAddress.set(true);
		}
	}

	saveApplication() {
		this.updateApplicants();
		this.apiService.saveApplication(this.$application());
	}

	updateApplicants(): void {
		const allApplicants = this.$application()?.applicants ?? [];
		const primaryApplicant = { ...(this.applicantForm.value as IndividualApplicant) };
		primaryApplicant.email = this.applicantForm.get('email').value;

		let mailingAddress = primaryApplicant.addresses[1];
		if (this.$usePhysicalAddressAsMailingAddress()) {
			mailingAddress = { ...primaryApplicant.addresses[0] };
			mailingAddress.type = AddressType.Mailing;
			primaryApplicant.addresses[1] = mailingAddress;
		} else {
			// Ensure mailing address set to correct type.
			mailingAddress.type = AddressType.Mailing;
		}

		allApplicants[0] = primaryApplicant;
		this.applicationStateService.updateApplicationValues({ applicants: allApplicants });
	}

	setIdControlsByType(idType: string): void {
		if (idType === IdentificationType.StateIdCard || idType === IdentificationType.DriversLicense) {
			if (this.identification.get('issuingCountry')) {
				this.identification.removeControl('issuingCountry');
			}
			if (!this.identification.get('issuingState')) {
				this.identification.addControl('issuingState', new FormControl('', Validators.required));
			}
		}
		if (idType === IdentificationType.Passport) {
			if (this.identification.get('issuingState')) {
				this.identification.removeControl('issuingState');
			}
			this.identification.addControl('issuingCountry', new FormControl('', Validators.required));
		}
	}

	onChangeAddressType(): void {
		const mailingAddress = this.getAddressGroup(1);
		if (this.$usePhysicalAddressAsMailingAddress) {
			mailingAddress.reset();
		} else {
			const physicalAddress = this.getAddressGroup(0);
			mailingAddress.patchValue(physicalAddress.getRawValue());
		}
	}

	getAddressGroup(index: number) {
		const addresses = this.applicantForm.get('addresses') as FormArray;
		return addresses.at(index) as FormGroup;
	}

	getFormControlInstance(controlName: string, formGroup?: string): FormControl {
		switch (formGroup) {
			case 'identification':
				return this.identification.get(controlName) as FormControl;
			case 'taxIdentification':
				return this.taxIdentification.get(controlName) as FormControl;
			case 'physicalAddress':
				return this.getAddressGroup(0).get(controlName) as FormControl;
			case 'mailingAddress':
				return this.getAddressGroup(1).get(controlName) as FormControl;
			default:
				return this.applicantForm.get(controlName) as FormControl;
		}
	}

	get identification() {
		return this.applicantForm.get('identification') as FormGroup;
	}

	get taxIdentification() {
		return this.applicantForm.get('taxIdentification') as FormGroup;
	}

	onDestroy(): void {
		this.updateApplicants();
		this.subscription.unsubscribe();
	}

	protected readonly IdentificationType = IdentificationType;
}

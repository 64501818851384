import { Component } from '@angular/core';
import { NextTodoButtonComponent } from '../next-button/next-todo-button.component';
import { AppTodoHeaderComponent } from '../app-todo-header/app-todo-header.component';

@Component({
	selector: 'experience-placeholder',
	standalone: true,
	imports: [AppTodoHeaderComponent, NextTodoButtonComponent],
	templateUrl: './placeholder.component.html',
})
export class PlaceholderComponent {}

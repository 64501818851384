import { Component, ElementRef, Injector, ViewChild } from '@angular/core';
import { DynamicFormComponentBase } from '../../dynamic/dynamic-form-component.base';
import { FormField } from '@common/lib/models/form-field.model';
import { combineLatest, forkJoin, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import _ from 'lodash';
import { ComparisonUtility } from '@common/lib/utilities/comparison-utility';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
	selector: 'common-check-box',
	templateUrl: './checkbox.component.html',
	styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent extends DynamicFormComponentBase<CheckboxComponentFields, CheckboxComponentOptions> {
	@ViewChild('parsedTextOutput', { static: false })
	public textOutputElement: ElementRef;

	public label$: Observable<SafeHtml>;
	public isIndexChecked$: Observable<boolean>;

	constructor(
		public injector: Injector
	) {
		super(injector);
	}

	onComponentLoaded(): void {
		this.containerClass = this.options?.containerClass;
		this.registerAllControls({ isDisabled: this.options?.isDisabled });
		const sanitizer = this.injector.get(DomSanitizer);

		this.label$ = this.formService.getModelValue$(this.fields?.label?.modelPaths, this.options?.labelValue, this.options?.placeholderOverride).pipe(
			map((value) => sanitizer.bypassSecurityTrustHtml(value))
		);
		if (this.options?.mode === CheckboxModes.index) {
			this.isIndexChecked$ = this.formService.getModelValue$<string>(this.fields?.checked?.modelPaths).pipe(
				map((isChecked) => _.includes(isChecked, this.options?.index))
			);
		}
		if (this.options?.mode === CheckboxModes.object) {
			this.isIndexChecked$ = combineLatest([
				this.formService.getModelValue$(this.fields?.iterationPath?.modelPaths),
				this.formService.getModelValue$<any[]>(this.fields?.checked?.modelPaths)
			]).pipe(
				map(([allCheckboxOptions, currentlyCheckedOptions]) =>
					_.some(currentlyCheckedOptions, (checkedOption) => ComparisonUtility.isEqual(checkedOption, allCheckboxOptions[this.options?.index])))
			);
		}
	}

	public setIndex(isChecked: boolean): void {
		this.formService.getModelValue$<string[]>(this.fields?.checked?.modelPaths)
			.pipe(take(1))
			.subscribe((currentCheckedValues) => {
				if (!currentCheckedValues || !(currentCheckedValues instanceof Array)) {
					currentCheckedValues = [];
				}
				if (isChecked && !_.includes(currentCheckedValues, this.options?.index)) {
					currentCheckedValues.push(this.options?.index);
				} else {
					_.remove(currentCheckedValues, this.options?.index);
				}
				this.formService.setModelValue(currentCheckedValues.sort(), this.fields?.checked?.modelPaths);
			});
	}

	public setObject(isChecked: boolean): void {
		forkJoin({
			allCheckboxOptions: this.formService.getModelValue$(this.fields?.iterationPath?.modelPaths).pipe(take(1)),
			currentlyCheckedOptions: this.formService.getModelValue$<any[]>(this.fields?.checked?.modelPaths).pipe(take(1))
		}).subscribe((checkboxContext) => {
			if (!checkboxContext.currentlyCheckedOptions || !(checkboxContext.currentlyCheckedOptions instanceof Array)) {
				checkboxContext.currentlyCheckedOptions = [];
			}

			const existInCollection = _.some(checkboxContext.currentlyCheckedOptions, (checkedOption) =>
				ComparisonUtility.isEqual(checkedOption, checkboxContext.allCheckboxOptions[this.options?.index]));

			if (isChecked && !existInCollection) {
				checkboxContext.currentlyCheckedOptions.push(checkboxContext.allCheckboxOptions[this.options?.index]);
			} else {
				_.remove(checkboxContext.currentlyCheckedOptions, (option) =>
					ComparisonUtility.isEqual(option, checkboxContext.allCheckboxOptions[this.options?.index]));
			}
			this.formService.setModelValue(checkboxContext.currentlyCheckedOptions, this.fields?.checked?.modelPaths);
		});
	}
}

interface CheckboxComponentFields extends Record<string, FormField> {
	stateOptions: FormField;
	label: FormField;
	checked: FormField;
	iterationPath: FormField;
}

interface CheckboxComponentOptions {
	hideRequiredMark: string;
	labelValue: string;
	placeholderOverride: string;
	mode: string;
	index: string;
	isDisabled: boolean;
	containerClass: string;
}

class CheckboxModes {
	public static index: string = 'Index';
	public static object: string = 'Object';
}

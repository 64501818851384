import _ from 'lodash';

export abstract class AdapterBase<TSource, TDestination> {
	public adaptWith(source: TSource,
		specializedAdapter: SpecializedAdapterFunc<TSource, TDestination>,
		options?: AdapterOptions): TDestination {
		const result = this.adapt(source, options);
		return specializedAdapter(source, result) || result;
	}

	public adaptCollection(sourceCollection: Array<TSource>, options?: CollectionAdapterOptions): Array<TDestination> {
		let result = _.map(sourceCollection, (sourceItem) => this.adapt(sourceItem, options));

		if (options?.compact) {
			result = _.compact(result);
		}

		return result;
	}

	public adaptCollectionWith(sourceCollection: Array<TSource>,
		specializedAdapter: SpecializedAdapterFunc<TSource, TDestination>,
		options?: CollectionAdapterOptions): Array<TDestination> {
		let destResult = _.map(sourceCollection, (sourceItem) => this.adaptWith(sourceItem, specializedAdapter, options));
		if (options?.compact) {
			destResult = _.compact(destResult);
		}
		return destResult;
	}

	public abstract adapt(source: TSource, options?: AdapterOptions): TDestination;
}

export interface AdapterOptions {
	[key: string]: any;
}

export interface CollectionAdapterOptions extends AdapterOptions {
	compact?: boolean;
}

export type SpecializedAdapterFunc<TSource, TDestination> = (
	source: TSource,
	destination: TDestination
) => void | TDestination;

import { inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AddressesResource } from '@common/lib/models/resource/address/addresses-resource';
import { catchError, map } from 'rxjs/operators';
import { AddressApiToAddressFormAdapter } from '@common/lib/adapters/address-api-to-address-form.adapter';
import { Address } from '@common/lib/models/address';

@Injectable({
	providedIn: 'root',
})
export class LocationService {
	private readonly http = inject(HttpClient);

	private readonly addressAdapter = new AddressApiToAddressFormAdapter();

	public searchAddress(address: string): Observable<Address[]> {
		return this.http.get<AddressesResource>(`/api/location/search?query=${address}`).pipe(
			map(
				(locationResponse) =>
					locationResponse?.addresses?.map((address) => this.addressAdapter.adapt(address)) || [],
			),
			catchError(() => of([])),
		);
	}
}

<div>
	<!-- todo: cursor-not-allowed class from selectClasses -->
	<form [formGroup]="internalForm">
		<mat-form-field class="w-full {{selectClasses}}" appearance="outline" [hideRequiredMarker]="hideRequiredMark">
			<mat-label>{{options?.label}}</mat-label>
			<mat-select formControlName="selected" [compareWith]="compareWith" data-cy="select-field">
				<mat-option *ngFor="let item of options$ | async" [value]="getValue(item)">
					{{getDisplay(item)}}
				</mat-option>
			</mat-select>
		</mat-form-field>
		<div class="text-10.5px pt-2.5 pb-2.5">
			<div *ngIf="options?.subtext" class="subtext">
				{{options.subtext}}
			</div>
			<common-validation-messages [validatedControl]="internalForm.get('selected')" [associatedField]="fields['selected']"></common-validation-messages>
		</div>
	</form>
</div>

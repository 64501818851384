import { AdapterBase } from '@common/lib/utilities/adapter-base';
import { ReviewDataResource } from '@common/lib/models/resource/review-data-resource';
import { ReviewData } from '@common/lib/models/review-data';
import { ReviewItem } from '@common/lib/models/review-item';

export class ReviewDataResourceAdapter extends AdapterBase<ReviewDataResource, ReviewData> {
	adapt(source: ReviewDataResource): ReviewData {

		const reviewItems = new Array<ReviewItem>();
		source.reviewItems.forEach((resource) => {
			const reviewItem = new ReviewItem();
			reviewItem.actions = resource.actions;
			reviewItem.message = resource.message;
			reviewItem.modelPath = resource.modelPath;
			reviewItem.ruleName = resource.ruleName;
			reviewItem.targetElementId = resource.targetElementId;
			reviewItem.targetScreen = resource.targetScreen;

			reviewItems.push(reviewItem);
		});

		const dest = new ReviewData();
		dest.isReadyForSubmission = source.isReadyForSubmission;
		dest.reviewItems = reviewItems;

		return dest;
	}
}

import {
	ApplicationInsightsEvent,
	ApplicationInsightsTrackingProvider,
	ConsoleTrackingProvider,
	TrackerEvent
} from '@nbkc/tracker';
import { ApplicationTrackerContext } from '@experience/app/tracking/application-tracker-context';

export class HeartbeatEvent extends TrackerEvent<ApplicationTrackerContext> {
	public name: string = 'heartbeat';
	public providers = [ConsoleTrackingProvider, ApplicationInsightsTrackingProvider];

	constructor() {
		super();
	}

	public ai(): ApplicationInsightsEvent {
		const event = new ApplicationInsightsEvent();
		event.name = this.name;
		event.data = {
			location: window.location.href
		};
		return event;
	}
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'common-upload-progress-bar',
  templateUrl: './upload-progress-bar.component.html',
  styleUrls: ['./upload-progress-bar.component.scss']
})
export class UploadProgressBarComponent implements OnInit {
  @Input() documentUploadGroupId: string;
  @Input() uploadComplete: boolean;
  @Output() uploadCompleteEvent = new EventEmitter<string>();
  showCompleteDisplay: boolean = false;
  currentProgressPercent: number = 0;
  durationInMs: number = 1500;

  constructor() { }

  ngOnInit(): void {
    const progressInterval = setInterval(() => {

      if(this.currentProgressPercent < 90) {
        this.currentProgressPercent += 10;
      }

      if (this.currentProgressPercent === 90 && !this.uploadComplete) {
        this.currentProgressPercent = 95;
      }

      if(this.currentProgressPercent >= 90 && this.uploadComplete) {
        this.currentProgressPercent = 100;
        this.showCompleteDisplay = true;
        clearInterval(progressInterval);
        this.triggerCompleteEvent();
      }

    }, (this.durationInMs/10));
  }

  triggerCompleteEvent() {
    this.uploadCompleteEvent.emit(this.documentUploadGroupId);
  }

}


<div *ngIf="condition.recentFiles?.length > 0" class="w-full flex justify-center pb-1">
    <hr class="mt-2 flex-grow">
    <span class="recent-files-label text-xs pl-1 pr-1">Documents Uploaded</span>
    <hr class="mt-2 flex-grow">
</div>
<div *ngFor="let file of condition.recentFiles; index as i;">
    <div class="document-list-item" *ngIf="i < getNumberOfFilesToShow()">
        <i class="text-sm pr-1" [ngClass]="file.displayUpload ? 'far fa-file-alt' : 'far fa-check-circle'"></i>
        {{file.fileName}}
        <common-upload-progress-bar 
            *ngIf="file.displayUpload" 
            [documentUploadGroupId]="file.documentUploadGroupId" 
            [uploadComplete]="file.uploadComplete" 
            (uploadCompleteEvent)="uploadCompleteEvent($event)">
        </common-upload-progress-bar>
    </div>
</div>
<div *ngIf="getNumberOfAdditionalFiles() > 0">
    <i class="far fa-info-square text-sm pr-1 pt-2"></i>
    {{getNumberOfAdditionalFiles()}} additional file(s) uploaded successfully
</div>
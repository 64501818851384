import { AdapterBase } from '@common/lib/utilities/adapter-base';
import { Condition } from '@common/lib/models/condition';
import { ConditionResource } from '@common/lib/models/resource/condition-resource';
import { DocumentUploadData } from '@common/lib/models/document-upload-data.model';

export class ConditionResourceAdapter extends AdapterBase<ConditionResource[], Condition[]> {
	adapt(source: ConditionResource[]): Condition[] {

		const dest = new Array<Condition>();

		source.forEach((resource) => {
			const condition = new Condition();
			condition.id = resource.id;
			condition.name = resource.name;
			condition.description = resource.description;
			condition.icon = resource.icon;
			condition.conditionType = resource.conditionType;
			condition.documentType = resource.documentType;
			condition.status = resource.status;
			condition.totalFileCount = resource.blobInfo.blobCount;
			condition.recentFiles = this.adaptRecentFiles(resource);

			dest.push(condition);
		});

		return dest;
	}

	adaptRecentFiles(resource: ConditionResource) {
		const recentDocs: DocumentUploadData[] = [];

		resource.blobInfo.fileNames?.forEach((file) => {
			recentDocs.push(new DocumentUploadData(file));
		});

		return recentDocs;
	}
}

import { CommonModule } from '@angular/common';
import { Component, input, OnInit } from '@angular/core';
import {
	AbstractControl,
	FormControl,
	FormGroup,
	ReactiveFormsModule,
	ValidationErrors,
	ValidatorFn,
	Validators,
} from '@angular/forms';
import { MatIconButton } from '@angular/material/button';
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { PasswordRequirement } from '@common/lib/models/pasword-requirement.model';

@Component({
	selector: 'app-password',
	standalone: true,
	imports: [CommonModule, ReactiveFormsModule, MatFormField, MatLabel, MatInput, MatIconButton, MatSuffix],
	templateUrl: './app-password.component.html',
	styleUrl: './app-password.component.scss',
})
export class AppPasswordComponent implements OnInit {
	public $formGroup = input<FormGroup>();
	public passwordRequirements: PasswordRequirement[];
	public passwordIsVisible: boolean = false;
	public passwordFormControl: AbstractControl;

	ngOnInit(): void {
		this.setupPasswordRequirements();

		this.$formGroup().addControl(
			'password',
			new FormControl('', {
				validators: [
					Validators.required,
					Validators.maxLength(50),
					this.onlySpecificSpecialCharactersValidator(),
					this.passwordMeetsAllRequirementsValidator(),
				],
				updateOn: 'change',
			}),
		);

		this.passwordFormControl = this.$formGroup().get('password');
	}

	getPasswordVisibilityIcon() {
		return this.passwordIsVisible ? 'far fa-eye' : 'far fa-eye-slash';
	}

	togglePasswordVisibility() {
		this.passwordIsVisible = !this.passwordIsVisible;
	}

	private setupPasswordRequirements() {
		this.passwordRequirements = [
			{
				message: '8 or more Characters',
				regex: new RegExp('^.{8,}$'),
				isValid: false,
			},
			{
				message: 'One uppercase letter',
				regex: new RegExp('[A-Z]'),
				isValid: false,
			},
			{
				message: 'One lowercase letter',
				regex: new RegExp('[a-z]'),
				isValid: false,
			},
			{
				message: 'One number',
				regex: new RegExp('[0-9]'),
				isValid: false,
			},
			{
				message: 'One of the following !@#$%^*',
				regex: new RegExp('[!@#$%^*]'),
				isValid: false,
			},
		];
	}

	private onlySpecificSpecialCharactersValidator(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			if (!control.value) {
				return null;
			}
			const isValid = new RegExp('^[a-zA-Z0-9!@#$%^*]*$').test(control.value);
			return isValid ? null : { specialCharacters: true };
		};
	}

	private passwordMeetsAllRequirementsValidator(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			const failureResult = { failsRequirements: true };
			const password = control.value;

			if (!password) {
				this.passwordRequirements.forEach((passwordRequirement) => {
					passwordRequirement.isValid = false;
				});
				return failureResult;
			}

			this.passwordRequirements.forEach((passwordRequirement) => {
				passwordRequirement.isValid = passwordRequirement.regex.test(password);
			});

			const passwordMeetsAllRequirements = this.passwordRequirements.filter((x) => !x.isValid).length === 0;
			return passwordMeetsAllRequirements ? null : failureResult;
		};
	}
}

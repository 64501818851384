import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ErrorStateMatcher, ShowOnDirtyErrorStateMatcher } from '@angular/material/core';
import { FormField } from '@common/lib/models/form-field.model';
import { PasswordRequirement } from '@common/lib/models/pasword-requirement.model';

@Component({
  selector: 'common-password-validator',
  templateUrl: './password-validator.component.html',
  styleUrls: ['./password-validator.component.scss']
})
export class PasswordValidatorComponent implements OnInit {

	@Input() formGroup: FormGroup;
	@Output() passwordMeetsRequirementsEvent = new EventEmitter<boolean>();

  passwordRequirements: PasswordRequirement[];
  passwordIsVisible: boolean = false;
  validationMessage: string;
  passwordField: FormField = {} as FormField;
  matcher: ErrorStateMatcher = new ShowOnDirtyErrorStateMatcher();
  passwordFormControl: AbstractControl;

  constructor() { }


  ngOnInit(): void {
    this.setupPasswordRequirements();

    this.formGroup.addControl('password', new FormControl('', {
        validators: [
          Validators.required,
          Validators.maxLength(99),
          this.onlySpecificSpecialCharacters(),
        ],
        updateOn: 'change'
      }));

    this.passwordFormControl = this.formGroup.get('password');

    this.passwordFormControl.valueChanges.subscribe(password => {
      this.passwordRequirements.forEach(passwordRequirement => {
        passwordRequirement.isValid = passwordRequirement.regex.test(password);
      });

      const passwordMeetsRequirements: boolean = this.passwordRequirements.filter(x => !x.isValid).length === 0;
      this.passwordMeetsRequirementsEvent.emit(passwordMeetsRequirements);
    });

  }

  onlySpecificSpecialCharacters(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
      /* eslint-disable-next-line no-useless-escape */
      const isValid = new RegExp('^[a-zA-Z0-9!@#\$%\^*]*$').test(control.value);
      return isValid ? null : { specialCharacters: 'Can only contain the following special characters: !@#$%^*' };
    };
  }

  onPasswordBlur() {
    this.passwordFormControl.markAsDirty();
  }

  getValidationError(): string{
    return Object.keys(this.passwordFormControl.errors)[0];
  }

  getPasswordVisibilityIcon() {
    return this.passwordIsVisible ? 'far fa-eye' : 'far fa-eye-slash';
  }

  togglePasswordVisibility() {
    this.passwordIsVisible = !this.passwordIsVisible;
  }

  setupPasswordRequirements() {
    this.passwordRequirements = [
      {
        message: '8 or more Characters',
        regex: new RegExp('^.{8,}$'),
        isValid: false
      },
      {
        message: 'One uppercase letter',
        regex: new RegExp('[A-Z]'),
        isValid: false
      },
      {
        message: 'One lowercase letter',
        regex: new RegExp('[a-z]'),
        isValid: false
      },
      {
        message: 'One number',
        regex: new RegExp('[0-9]'),
        isValid: false
      },
      {
        message: 'One of the following !@#$%^*',
        /* eslint-disable-next-line no-useless-escape */
        regex: new RegExp('[!@#\$%\^*]'),
        isValid: false
      },
    ];
  }
}


import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	computed,
	DestroyRef,
	inject,
	input,
	model,
	OnInit,
	signal,
} from '@angular/core';
import { InternalStateService } from '@experience/app/services/internal-state.service';
import {
	FormArray,
	FormBuilder,
	FormControl,
	FormGroup,
	FormsModule,
	ReactiveFormsModule,
	ValidatorFn,
	Validators,
} from '@angular/forms';
import { IdentificationType } from '@experience/app/models/enums/identification-type';
import { Subscription } from 'rxjs';
import { TaxIdentificationType } from '@experience/app/models/enums/tax-identification-type';
import {
	MAT_FORM_FIELD_DEFAULT_OPTIONS,
	MatError,
	MatFormField,
	MatHint,
	MatLabel,
} from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { formatDate, NgFor, NgIf } from '@angular/common';
import { MatOption } from '@angular/material/autocomplete';
import { MatSelect } from '@angular/material/select';
import { MaskedInputComponent } from '@experience/app/components-new/masked-input/masked-input.component';
import { NgxMaskDirective } from 'ngx-mask';
import { CdkMonitorFocus } from '@angular/cdk/a11y';
import { MatCheckbox } from '@angular/material/checkbox';
import { AuthenticationUtility } from '@common/lib/utilities/authentication/authentication.utility';
import { CustomValidators } from '@experience/app/shared/custom-validators';
import { AppDividerComponent } from '@experience/app/components-new/divider/app-divider.component';
import { ApplicationStateService } from '@experience/app/services/application-state.service';
import { IndividualApplicant } from '@experience/app/models/onboarding/individual-applicant.model';
import { TaxIdentification } from '@experience/app/models/onboarding/tax-identification.model';
import { Identification } from '@experience/app/models/onboarding/identification.model';
import { ApplicationsApiService } from '@experience/app/services/applications-api.service';
import { ApplicantKind } from '@experience/app/models/enums/applicant-kind';
import { AddressType } from '@experience/app/models/enums/address-type';
import { Address } from '@experience/app/models/onboarding/address.model';
import { AddressFormComponent } from '@experience/app/components-new/address-form/address-form.component';
import {
	BusinessRoleFormGroup,
	IdentificationFormGroup,
	IndividualApplicantFormGroup,
	TaxIdentificationFormGroup,
} from '@experience/app/components-new/individual-applicant/individual-applicant-form/individual-applicant-form.group';
import { AddressFormGroup } from '@experience/app/components-new/address-form/address-form.group';
import { TaxIdentificationComponent } from '@experience/app/components-new/tax-identification/tax-identification.component';
import { FormControlPipe } from '@experience/app/shared/pipes/form-control-pipe/form-control.pipe';

@Component({
	selector: 'app-individual-applicant-form',
	standalone: true,
	imports: [
		ReactiveFormsModule,
		MatFormField,
		MatInput,
		MatSlideToggle,
		FormsModule,
		MatLabel,
		NgIf,
		NgFor,
		MatOption,
		MatSelect,
		MaskedInputComponent,
		NgxMaskDirective,
		CdkMonitorFocus,
		MatHint,
		MatCheckbox,
		AppDividerComponent,
		MatError,
		AddressFormComponent,
		TaxIdentificationComponent,
		FormControlPipe,
	],
	templateUrl: './individual-applicant-form.component.html',
	styleUrl: './individual-applicant-form.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
			useValue: {
				appearance: 'outline',
				subscriptSizing: 'dynamic',
			},
		},
	],
})
export class IndividualApplicantFormComponent implements OnInit, AfterViewInit {
	private internalStateService = inject(InternalStateService);
	private applicationStateService = inject(ApplicationStateService);
	private fb = inject(FormBuilder);
	private destroyRef = inject(DestroyRef);
	private auth = inject(AuthenticationUtility);
	private apiService = inject(ApplicationsApiService);
	$isPersonal = this.internalStateService.$isPersonal;
	$lookups = this.internalStateService.$lookups;
	$application = this.applicationStateService.$application;
	$applicantIndex = input<number>(0);
	applicantForm: FormGroup<IndividualApplicantFormGroup>;
	$usePhysicalAddressAsMailingAddress = model<boolean>(true);
	$applicantNameOrDefault = signal<string>('the account holder');
	$idType = signal<string>('');
	$identification = signal<FormGroup>({} as FormGroup);
	$taxIdentification = signal<FormGroup<TaxIdentificationFormGroup>>({} as FormGroup<TaxIdentificationFormGroup>);
	$physicalAddress = signal<FormGroup<AddressFormGroup>>({} as FormGroup<AddressFormGroup>);
	$mailingAddress = signal<FormGroup<AddressFormGroup>>({} as FormGroup<AddressFormGroup>);
	$businessRoleDetails = signal<FormGroup>({} as FormGroup);
	$isControlIndividual = signal<FormControl>({} as FormControl);
	$isPrimaryApplicant = computed<boolean>(() => this.$applicantIndex() === 0);
	subscription: Subscription = new Subscription();
	ssnMatchValidator: ValidatorFn;

	constructor() {}

	ngOnInit() {
		const applicant = this.$application()?.applicants?.[this.$applicantIndex()] || ({} as IndividualApplicant);
		const physicalAddress = applicant?.addresses?.[0] || ({} as Address);
		const mailingAddress = applicant?.addresses?.[1] || ({} as Address);
		const identification = applicant?.identification || ({} as Identification);
		const taxIdentification = applicant?.taxIdentification || ({} as TaxIdentification);

		this.applicantForm = this.fb.group<IndividualApplicantFormGroup>(
			{
				kind: new FormControl(this.$isPrimaryApplicant() ? ApplicantKind.Primary : ApplicantKind.JointOwner),
				firstName: new FormControl(applicant?.firstName || '', [Validators.required, Validators.maxLength(40)]),
				middleName: new FormControl(applicant?.middleName || '', Validators.maxLength(40)),
				lastName: new FormControl(applicant?.lastName || '', [Validators.required, Validators.maxLength(40)]),
				email: new FormControl('', [Validators.email, Validators.required]),
				phoneNumber: new FormControl(applicant?.phoneNumber || '', Validators.required),
				addresses: this.fb.array<FormGroup<AddressFormGroup>>([
					this.fb.group<AddressFormGroup>(
						{
							type: new FormControl(AddressType.Physical),
							address1: new FormControl(physicalAddress?.address1 || '', [
								Validators.required,
								Validators.maxLength(40),
							]),
							address2: new FormControl(physicalAddress?.address2 || '', Validators.maxLength(40)),
							city: new FormControl(physicalAddress?.city || '', [
								Validators.required,
								Validators.maxLength(25),
							]),
							state: new FormControl(physicalAddress?.state || '', Validators.required),
							postalCode: new FormControl(physicalAddress?.postalCode || '', Validators.required),
						},
						{ updateOn: 'change' },
					),
					this.fb.group<AddressFormGroup>(
						{
							type: new FormControl(AddressType.Mailing),
							address1: new FormControl(mailingAddress?.address1 || '', [
								Validators.required,
								Validators.maxLength(40),
							]),
							address2: new FormControl(mailingAddress?.address2 || '', Validators.maxLength(40)),
							city: new FormControl(mailingAddress?.city || '', [
								Validators.required,
								Validators.maxLength(25),
							]),
							state: new FormControl(mailingAddress?.state || '', Validators.required),
							postalCode: new FormControl(mailingAddress?.postalCode || '', Validators.required),
						},
						{ updateOn: 'change' },
					),
				]),
				identification: this.fb.group<IdentificationFormGroup>({
					type: new FormControl(
						identification?.type ? IdentificationType[identification.type] : IdentificationType.Unknown,
						Validators.required,
					),
					//eslint-disable-next-line id-blacklist
					number: new FormControl(identification?.number || '', [
						Validators.required,
						Validators.maxLength(25),
					]),
					expirationDate: new FormControl(
						identification?.expirationDate
							? formatDate(identification.expirationDate, 'MM/dd/yyyy', 'en-US')
							: '',
						[Validators.required, CustomValidators.unexpiredId],
					),
				}),
				dateOfBirth: new FormControl(
					applicant?.dateOfBirth ? formatDate(applicant.dateOfBirth, 'MM/dd/yyyy', 'en-US') : '',
					[Validators.required, CustomValidators.minimumAge, CustomValidators.maxAge],
				),
				taxIdentification: this.fb.group<TaxIdentificationFormGroup>(
					{
						type: new FormControl(
							taxIdentification?.type || TaxIdentificationType.SocialSecurityNumber,
							Validators.required,
						),
						//eslint-disable-next-line id-blacklist
						number: new FormControl(taxIdentification?.number || '', Validators.required),
						numberConfirmation: new FormControl(taxIdentification.number || '', Validators.required),
					},
					{ validators: this.ssnMatchValidator },
				),
				mothersMaidenName: new FormControl(applicant?.mothersMaidenName || '', [
					Validators.required,
					Validators.maxLength(40),
				]),
				incomeSource: new FormControl(applicant?.incomeSource || '', Validators.required),
				occupation: new FormControl(applicant?.occupation || '', Validators.required),
				consentsToTaxRequirements: new FormControl(
					applicant?.consentsToTaxRequirements || false,
					Validators.requiredTrue,
				),
			},
			{ updateOn: 'blur' },
		);

		// Pre-populate the email field if Personal application
		this.setEmail();

		if (!this.$isPersonal()) {
			this.buildBusinessRoleFormGroup();
		}

		if (identification.type) {
			this.setIdentificationFields(applicant.identification);
		}

		if (this.internalStateService.$reviewModeEnabled()) {
			this.applicantForm.markAllAsTouched();
			this.applicantForm.updateValueAndValidity();
		}

		// Form value changes subscriptions
		this.destroyRef.onDestroy(() => this.onDestroy());
		this.subscription.add(
			this.identification.get('type').valueChanges.subscribe((idType) => {
				if (idType) {
					// Clear the identification number and expiration date when changing ID type
					this.identification.get('number').setValue('');
					this.identification.get('expirationDate').setValue('');
					// Clear the issuing state or country when changing ID type
					this.setIdControlsByType(idType);
					// Trigger if block in template to show the issuing state or country
					this.$idType.set(idType);
				}
			}),
		);

		this.subscription.add(
			this.applicantForm.get('firstName').valueChanges.subscribe({
				next: (value) => {
					if (value?.length) {
						this.$applicantNameOrDefault.set(value);
					}
				},
			}),
		);

		this.setFormGroupSignals();

		// Set use mailing address as physical address toggle in template
		if (
			applicant?.addresses?.[0]?.address1 &&
			applicant?.addresses?.[0]?.address1 !== applicant?.addresses?.[1]?.address1
		) {
			this.$usePhysicalAddressAsMailingAddress.set(false);
		}
	}

	ngAfterViewInit() {
		this.subscribeToFormChanges();
	}

	subscribeToFormChanges(): void {
		this.subscription.add(
			this.applicantForm.valueChanges.subscribe(() => {
				this.updateApplicants();
			}),
		);
	}

	setEmail(): void {
		if (this.$isPersonal() && this.$isPrimaryApplicant()) {
			this.subscription.add(
				this.auth.authenticationContext$.subscribe((context) => {
					const applicantEmail = context.user?.email || '';
					this.applicantForm.get('email').setValue(applicantEmail);
					this.applicantForm.get('email').disable({ emitEvent: true });
				}),
			);
			return;
		}
		this.applicantForm
			.get('email')
			.setValue(this.$application()?.applicants?.[this.$applicantIndex()]?.email || '');
	}

	buildBusinessRoleFormGroup(): void {
		const businessRoleDetails = this.$application()?.applicants?.[this.$applicantIndex()]?.businessRoleDetails;
		const businessRoleDetailsFormGroup = this.fb.group<BusinessRoleFormGroup>({
			businessRole: new FormControl(businessRoleDetails?.businessRole || '', Validators.required),
			isBeneficialOwner: new FormControl(businessRoleDetails?.isBeneficialOwner || this.$isPrimaryApplicant()),
			isAuthorizedSigner: new FormControl(
				businessRoleDetails?.isAuthorizedSigner || this.$isPrimaryApplicant() || false,
			),
		});
		if (this.$isPrimaryApplicant()) {
			businessRoleDetailsFormGroup.addControl(
				'isControlIndividual',
				new FormControl(businessRoleDetails?.isControlIndividual),
			);
		}
		this.applicantForm.addControl('businessRoleDetails', businessRoleDetailsFormGroup);
	}

	setIdentificationFields(identification: Identification): void {
		this.setIdControlsByType(IdentificationType[identification.type]);
		if (this.identification.get('issuingState')) {
			this.identification.get('issuingState').setValue(identification.issuingState);
		}
		if (this.identification.get('issuingCountry')) {
			this.identification.get('issuingCountry').setValue(identification.issuingCountry);
		}
		this.$idType.set(IdentificationType[identification.type]);
	}

	setFormGroupSignals(): void {
		this.$taxIdentification.set(this.applicantForm.get('taxIdentification') as FormGroup);
		this.$identification.set(this.applicantForm.get('identification') as FormGroup);
		this.$physicalAddress.set(this.getAddressGroup(0));
		this.$mailingAddress.set(this.getAddressGroup(1));
		if (!this.$isPersonal()) {
			this.$businessRoleDetails.set(this.applicantForm.get('businessRoleDetails') as FormGroup);
			const isControlIndividual = this.$businessRoleDetails().get('isControlIndividual') as FormControl;
			this.$isControlIndividual.set(isControlIndividual);
		}
	}

	saveApplication() {
		this.updateApplicants();
		this.apiService.saveApplication(this.$application()).subscribe();
	}

	updateApplicants(): void {
		const allApplicants = this.$application()?.applicants || ([] as IndividualApplicant[]);
		const formApplicant = this.applicantForm.getRawValue();

		let mailingAddress = formApplicant.addresses[1];
		if (this.$usePhysicalAddressAsMailingAddress()) {
			mailingAddress = { ...formApplicant.addresses[0] };
			mailingAddress.type = AddressType.Mailing;
			formApplicant.addresses[1] = mailingAddress;
		}

		/*
			The spread operator only performs a shallow merge. This is acceptable for all our current use cases
			and simplifies adapting from the form to the application shape. If in the future a deep merge is needed,
			we should consider using a deep merge function from a library such as lodash.
		*/
		let applicationApplicant = allApplicants[this.$applicantIndex()];
		applicationApplicant = { ...applicationApplicant, ...formApplicant };
		allApplicants[this.$applicantIndex()] = applicationApplicant;

		this.applicationStateService.updateApplicationValues({ applicants: allApplicants });
	}

	setIdControlsByType(idType: string): void {
		if (idType === IdentificationType.StateIdCard || idType === IdentificationType.DriversLicense) {
			if (this.identification.get('issuingCountry')) {
				this.identification.removeControl('issuingCountry');
			}
			if (!this.identification.get('issuingState')) {
				this.identification.addControl('issuingState', new FormControl('', Validators.required));
			}
		}
		if (idType === IdentificationType.Passport) {
			if (this.identification.get('issuingState')) {
				this.identification.removeControl('issuingState');
			}
			this.identification.addControl('issuingCountry', new FormControl('', Validators.required));
		}
	}

	onChangeAddressType(): void {
		const mailingAddress = this.getAddressGroup(1);
		if (this.$usePhysicalAddressAsMailingAddress()) {
			const physicalAddress = this.getAddressGroup(0);
			mailingAddress.patchValue(physicalAddress.getRawValue());
		} else {
			mailingAddress.reset();
		}
		mailingAddress.get('type').setValue(AddressType.Mailing);
	}

	getAddressGroup(index: number) {
		const addresses = this.applicantForm.get('addresses') as FormArray;
		return addresses.at(index) as FormGroup;
	}

	get identification() {
		return this.applicantForm.get('identification') as FormGroup;
	}

	get taxIdentification() {
		return this.applicantForm.get('taxIdentification') as FormGroup;
	}

	onDestroy(): void {
		this.updateApplicants();
		this.subscription.unsubscribe();
	}

	protected readonly IdentificationType = IdentificationType;
	protected readonly FormControl = FormControl;
}

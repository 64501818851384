import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ComponentDefinition } from '@common/lib/models/component-definition.model';
import { PresentationService } from '@experience/app/services/presentation.service';

@Component({
	// eslint-disable-next-line @angular-eslint/component-selector
	selector: 'app-do-not-use-in-html',
	template: `
		<app-dynamic *ngFor="let bodyComponent of bodyComponents; trackBy:trackByFn"
					[componentDefinition]="bodyComponent"></app-dynamic>`,
})
export class DynamicScreenComponent implements OnDestroy {
	public bodyComponents: ComponentDefinition[] = [];
	private bodySubscription: Subscription;
	constructor(private presentationService: PresentationService) {
		this.bodySubscription = this.presentationService.currentPresentationDefinition$.pipe(
		).subscribe((view) => {
			this.bodyComponents = view?.body?.components;
		});
	}

	public trackByFn(index: number, item: any): number {
		return item.id;
	}

	ngOnDestroy(): void {
		this.bodySubscription?.unsubscribe();
	}
}

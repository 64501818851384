import { Injectable } from '@angular/core';
import { LocalStorageCacheProvider } from '@common/lib/utilities/cache/local-storage.cache-provider';
import { SessionStorageCacheProvider } from '@common/lib/utilities/cache/session-storage.cache-provider';

@Injectable({
	providedIn: 'root'
})
export class CacheUtility {
	public localStorage = new LocalStorageCacheProvider();
	public sessionStorage = new SessionStorageCacheProvider();
}

<app-todo-header></app-todo-header>
<app-card
	headerIcon="fa-user"
	[title]="$applicantTitle()"
	[subtitle]="$applicantEmail()"
	[secondSubtitle]="$businessRole()"
>
	<app-individual-applicant-form [$applicantIndex]="$applicantIndex()"> </app-individual-applicant-form>
</app-card>

@if ($applicantIndex() > 0) {
	<div class="flex justify-center m-4">
		<button
			tabindex="0"
			id="next-button"
			class="max-w-xs w-full text-white rounded-full py-3 px-4 shadow-button text-lg"
			mat-raised-button
			color="primary"
			data-cy="next-button"
			[disabled]="false"
			(click)="onClickJointOwnerNextButton()"
		>
			Next
		</button>
	</div>
} @else {
	<app-next-todo-button></app-next-todo-button>
}

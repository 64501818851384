import { Component, OnInit } from '@angular/core';
import { LoadingService } from '@experience/app/services/loading.service';

@Component({
	selector: 'experience-error',
	templateUrl: './error.component.html',
	styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {
	constructor(public loadingService: LoadingService) {
	}

	ngOnInit(): void {
		this.loadingService.stopAllLoadingActivities();
	}

	public loadApplication(): void {
		window.location.href = '';
	}
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AuthenticationUtility } from '@common/lib/utilities/authentication/authentication.utility';
import { Tracker } from '@nbkc/tracker-angular';
import { SendToLoginEvent } from '@experience/app/tracking/events/send-to-login.event';
import { GeneralError } from '@common/lib/models/general-error.model';
import { ErrorTypes } from '@common/lib/models/enum/error-types.enum';
import { CommonErrorService } from '@common/lib/services/common-error.service';

@Injectable({
	providedIn: 'root'
})
export class AuthenticationGuard {
	constructor(private authenticationUtility: AuthenticationUtility,
				private errorService: CommonErrorService,
				private tracker: Tracker) {
	}

	canActivate(next: ActivatedRouteSnapshot,
				state: RouterStateSnapshot): Observable<boolean | UrlTree> {

		return this.authenticationUtility.authenticationContext$.pipe(
			tap((auth) => {
				if (!auth.isAuthenticated && !auth.hasError) {
					this.tracker.event(new SendToLoginEvent());
					this.authenticationUtility.login(state.url);
				} else if (auth.hasError) {
					const error = new GeneralError();
					error.isRetrying = false;
					error.errorType = ErrorTypes.unauthorized;
					error.message = 'There was an issue getting your user information.';
					this.errorService.handleError(error);
				}
			}),
			map((auth) => auth.isAuthenticated && !auth.hasError)
		);
	}
}

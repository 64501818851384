import _ from 'lodash';

export abstract class ComparisonUtility {
	public static isEqual(firstItem: any, secondItem: any): boolean {
		let result = ComparisonUtility.fastIsEqual(firstItem, secondItem);
		if (!result) {
			result = ComparisonUtility.reliableIsEqual(firstItem, secondItem);
		}
		return result;
	}

	public static matchTargetPrimitiveType(target: boolean | string | number, comparison: string): boolean | string | number {
		let convertedComparison: boolean | string | number = comparison;

		if (typeof target === 'boolean') {
			convertedComparison = (comparison === 'true');
		} else if (typeof target === 'number') {
			convertedComparison = parseFloat(comparison);
		}

		return convertedComparison;
	}

	private static fastIsEqual(firstItem: any, secondItem: any): boolean {
		return JSON.stringify(firstItem) === JSON.stringify(secondItem);
	}

	private static reliableIsEqual(firstItem: any, secondItem: any): boolean {
		return _.isEqual(firstItem, secondItem);
	}
}

import { inject, Injectable } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { PageViewEvent, Tracker } from '@nbkc/tracker-angular';
import { ApplicationTrackerContext } from './tracking/application-tracker-context';
import { OnlineBankingViewEvent } from './tracking/events/online-banking-view.event';
import { InternalStateService } from './services/internal-state.service';
import { ApplicationKind } from './models/enums/application-kind';

@Injectable({
	providedIn: 'root',
})
export class AppRouteMonitor {
	router = inject(Router);
	tracker = inject(Tracker);
	internalStateService = inject(InternalStateService);

	public start(): void {
		this.router.events.subscribe((routerEvent) => {
			if (routerEvent instanceof NavigationStart) {
				this.tracker.setTrackerContext(
					new ApplicationTrackerContext({
						currentScreen: routerEvent.url,
					}),
				);

				const currentScreen = routerEvent.url.substring(routerEvent.url.lastIndexOf('/') + 1);
				this.internalStateService.setCurrentScreen(currentScreen);
			}

			if (routerEvent instanceof NavigationEnd) {
				this.tracker.pageView(new PageViewEvent());

				if (routerEvent.url.endsWith('setup-online-banking')) {
					if (this.internalStateService.$applicationKind() === ApplicationKind.Personal) {
						this.tracker.event(new OnlineBankingViewEvent({ experienceType: 'bluefin-view-olb-personal' }));
					} else if (this.internalStateService.$applicationKind() === ApplicationKind.Business) {
						this.tracker.event(new OnlineBankingViewEvent({ experienceType: 'bluefin-view-olb-business' }));
					}
				}
			}
		});
	}
}

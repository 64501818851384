<div [ngClass]="containerClass || 'w-full'">
    <form [formGroup]='internalForm'>
        <div class="mb-2 text-base">
            <mat-label>{{options?.label}}</mat-label>
        </div>
        <mat-radio-group class="flex flex-col" formControlName="selected">
            <mat-radio-button class="m-2" *ngFor="let item of choices$ | async"
                (change)="selectRadio($event)"
                [value]="item.value">
                {{item.displayName}}
            </mat-radio-button>
        </mat-radio-group>
        <common-validation-messages [validatedControl]="internalForm" [associatedField]="fields.selected"></common-validation-messages>
    </form>
</div>

import { Injectable } from '@angular/core';
import { ValidatorFn, Validators } from '@angular/forms';
import { AngularValidatorNames } from '../models/enum/angular-validator-names.enum';
import { ValidationRule } from '../models/validation-rule';
import { ValidationRuleTypes } from '../models/enum/validation-rule-types.enum';

@Injectable({
	providedIn: 'root'
})
export class ValidationRuleConverter {
	public validationRuleToValidator(rule: ValidationRule): ValidatorFn {
		switch (rule.type) {
			case ValidationRuleTypes.required:
				return Validators.required;
			case ValidationRuleTypes.maxLength:
				return Validators.maxLength(+rule.constraintValue);
			case ValidationRuleTypes.minLength:
				return Validators.minLength(+rule.constraintValue);
			case ValidationRuleTypes.pattern:
				return Validators.pattern(rule.constraintValue);
			default:
				return null;
		}
	}

	public angularValidatorNameToRuleType(ruleName: string): string {
		switch (ruleName) {
			case AngularValidatorNames.required:
				return ValidationRuleTypes.required;
			case AngularValidatorNames.minLength:
				return ValidationRuleTypes.minLength;
			case AngularValidatorNames.maxLength:
				return ValidationRuleTypes.maxLength;
			case AngularValidatorNames.pattern:
				return ValidationRuleTypes.pattern;
			default:
				return null;
		}
	}
}

/* eslint-disable no-underscore-dangle */
import {
	ChangeDetectionStrategy,
	Component,
	computed,
	ElementRef,
	HostBinding,
	inject,
	input,
	Input,
	model,
	OnDestroy,
	OnInit,
	Optional,
	Renderer2,
	Self,
	signal,
	Signal,
	ViewChild,
} from '@angular/core';
import {
	ControlValueAccessor,
	FormControl,
	FormsModule,
	NgControl,
	ReactiveFormsModule,
	Validators,
} from '@angular/forms';
import { NgClass, NgStyle } from '@angular/common';
import { CdkMonitorFocus, FocusMonitor } from '@angular/cdk/a11y';
import { MatFormFieldControl, MatSuffix } from '@angular/material/form-field';
import { ErrorStateMatcher } from '@angular/material/core';
import { NgxMaskDirective, provideNgxMask, NgxMaskConfig } from 'ngx-mask';
import { take } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { InputMaskConfig } from '@experience/app/models-ui/input-mask-config';
import { TEXT_MASK_CONFIGS } from '@common/lib/constants/text-masks-ngx';
import { MatIconButton } from '@angular/material/button';

class CustomMaskErrorStateMatcher implements ErrorStateMatcher {
	isErrorState(control: FormControl | null): boolean {
		return control.invalid && (control.dirty || control.touched);
	}
}

@Component({
	imports: [
		CdkMonitorFocus,
		FormsModule,
		NgClass,
		NgxMaskDirective,
		NgStyle,
		ReactiveFormsModule,
		MatIconButton,
		MatSuffix,
	],
	providers: [
		provideNgxMask(),
		{
			provide: MatFormFieldControl,
			useExisting: MaskedInputComponent,
		},
		{
			provide: ErrorStateMatcher,
			useClass: CustomMaskErrorStateMatcher,
		},
	],
	selector: 'app-masked-input',
	standalone: true,
	styleUrl: './masked-input.component.scss',
	templateUrl: './masked-input.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaskedInputComponent implements OnInit, OnDestroy, MatFormFieldControl<string>, ControlValueAccessor {
	static nextId: number = 0;
	private renderer = inject(Renderer2);
	@ViewChild('maskedInput', { read: ElementRef, static: true })
	input: ElementRef;
	formControl = input<FormControl>(new FormControl('', Validators['mask']));
	@ViewChild(NgxMaskDirective, { static: true })
	maskDirective: NgxMaskDirective;

	/**
	 * Mask Implementation variables
	 */
	maskType = input<string>(); //from TEXT_MASK_CONFIGS
	currentMask = model({} as InputMaskConfig);
	hideProtected = model(false);
	inputType = signal('');
	maskPatterns: Signal<NgxMaskConfig['patterns']> = computed(() => this.currentMask().config?.patterns ?? undefined);
	maskConfigs: Record<string, InputMaskConfig>;
	mask = computed(() => this.currentMask().mask);

	/**
	 * Angular Form Control variables
	 */
	onTouched: () => void = () => {};
	onChange: (value: string) => void = () => {};

	/**
	 * MatFormFieldControl variables
	 */
	@Input()
	set value(value: string) {
		this.formControl().setValue(value);
		this.onChange(value);
		this.stateChanges.next();
	}

	get value() {
		return this.formControl().value;
	}

	@Input()
	set placeholder(value: string) {
		this._placeholder = value;
		this.stateChanges.next();
	}

	get placeholder(): string {
		return this._placeholder;
	}

	@Input()
	disabled: boolean;
	@Input()
	required: boolean;
	errorState: boolean = false;
	stateChanges = new Subject<void>();
	internalErrorStateMatcher: CustomMaskErrorStateMatcher = new CustomMaskErrorStateMatcher(); // checks this.control for mask errors
	get empty(): boolean {
		return !this.formControl().value;
	}

	controlType: string = 'masked-input';
	disableAutomaticLabeling: boolean;
	focused: boolean = false;

	@HostBinding('class.floated')
	get shouldLabelFloat(): boolean {
		return this.focused || !this.empty;
	}

	@HostBinding()
	id: string = `${this.maskType()}-mask-${MaskedInputComponent.nextId++}`;
	@HostBinding('attr.aria-describedby')
	describedBy = '';
	userAriaDescribedBy: string;

	private _placeholder: string = this.currentMask()?.config?.placeHolderCharacter || '';
	private subscription: Subscription = new Subscription();

	constructor(
		@Optional() @Self() public ngControl: NgControl,
		private focusMonitor: FocusMonitor,
	) {
		if (this.ngControl !== null) {
			this.ngControl.valueAccessor = this;
		}
	}

	ngOnInit(): void {
		this.getInputType(this.maskType());
		this.maskConfigs = TEXT_MASK_CONFIGS;
		if (!this.maskConfigs[this.maskType()]) {
			console.error(`No mask configuration found for ${this.maskType()}. See mask options in TEXT_MASK_CONFIGS`);
			return;
		}
		this.currentMask.set(this.maskConfigs[this.maskType()]);
		if (this.currentMask()?.config) {
			provideNgxMask(this.currentMask().config);
		}
		if (this.maskType() === 'ssn' && this.formControl()?.value.length) {
			this.toggleShowProtected();
		}
		// Accessibility assistance
		this.focusMonitor.monitor(this.input).subscribe((focused) => {
			this.focused = !!focused;
			this.stateChanges.next();
		});
		this.focusMonitor
			.monitor(this.input)
			.pipe(take(1))
			.subscribe(() => {
				this.onTouched();
			});
		this.subscription.add(
			this.formControl().statusChanges.subscribe((status) => {
				if (status === 'DISABLED') {
					this.setDisabledState(true);
				} else if (status === 'VALID' || status === 'INVALID' || status === 'PENDING') {
					this.setDisabledState(false);
				}
			}),
		);
	}

	ngOnDestroy() {
		this.focusMonitor.stopMonitoring(this.input);
		this.stateChanges.complete();
		this.subscription.unsubscribe();
	}

	/**
	 * Check for validation errors from ngx-mask(this.control) and from MatFormField(ngControl)
	 */
	checkForErrors() {
		this.errorState = this.internalErrorStateMatcher.isErrorState(this.formControl());
		if (this.errorState && this.formControl()?.errors?.mask) {
			this.userAriaDescribedBy = this.formControl()?.errors?.mask ? 'error-message' : '';
			this.ngControl.control.setErrors({ mask: true });
		}
		this.stateChanges.next();
	}

	/**
	 * Angular ControlValueAccessor interface methods
	 */
	writeValue(val: string): void {
		this.input.nativeElement.value = val;
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled;
		this.renderer.setProperty(this.input, 'disabled', isDisabled);
		this.stateChanges.next();
	}

	/**
	 * MatFormFieldControl interface methods
	 */

	onContainerClick(): void {
		this.focusMonitor.focusVia(this.input, 'program');
	}

	setDescribedByIds(ids: string[]): void {
		this.describedBy = ids.join(' ');
	}

	onInputFocus(): void {
		this.onTouched();
	}

	toggleShowProtected(): void {
		this.hideProtected.set(!this.hideProtected());
		if (this.hideProtected()) {
			this.currentMask.set({ ...this.currentMask(), mask: 'XXX-XX-0000' });
		} else {
			this.currentMask.set({ ...this.currentMask(), mask: '000-00-0000' });
		}
	}

	private getInputType(maskType: string): void {
		switch (maskType) {
			case 'email':
				this.inputType.set('email');
				break;
			case 'decimal':
			case 'age':
				this.inputType.set('number');
				break;
			case 'phone':
				this.inputType.set('tel');
				break;
			case 'percent':
			case 'date':
				this.inputType.set('date');
				break;
			case 'password':
				this.inputType.set('password');
				break;
			default:
				this.inputType.set('text');
		}
	}
}

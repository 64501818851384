import { Observable, ReplaySubject } from 'rxjs';
import { take } from 'rxjs/operators';
import _ from 'lodash';
import { CacheUtility } from '@common/lib/utilities/cache/cache.utility';
import { SessionContextBase } from '@common/lib/utilities/session/session-context-base';

export abstract class SessionUtility<TSessionContext extends SessionContextBase> {
	public data$: Observable<TSessionContext>;
	private sessionSubject: ReplaySubject<TSessionContext> = new ReplaySubject<TSessionContext>();

	constructor(private cacheUtility: CacheUtility) {
		this.data$ = this.sessionSubject.asObservable();

		const sessionContext = {
			...(this.cacheUtility.localStorage.get<TSessionContext>('client-context') as TSessionContext),
			...(this.cacheUtility.sessionStorage.get<TSessionContext>('client-context') as TSessionContext),
		};

		this.sessionSubject.next(sessionContext || ({} as TSessionContext));
	}

	public updateSession(sessionUpdate: Partial<TSessionContext>): void {
		if (!sessionUpdate) {
			return;
		}

		this.data$.pipe(take(1)).subscribe((currentSessionContext) => {
			_.merge(currentSessionContext, sessionUpdate);
			try {
				if (sessionUpdate?.cachedKeys?.length > 0) {
					const valuesToSave: any = {};
					_.forEach(currentSessionContext, (value, key) => {
						if (sessionUpdate.cachedKeys.includes(key)) {
							valuesToSave[key] = value;
						}
					});
					this.cacheUtility.sessionStorage.set('client-context', valuesToSave);
				}

				if (sessionUpdate?.localStorageKeys?.length > 0) {
					const valuesToSave: any = {};
					_.forEach(currentSessionContext, (value, key) => {
						if (sessionUpdate.localStorageKeys.includes(key)) {
							valuesToSave[key] = value;
						}
					});
					this.cacheUtility.localStorage.set('client-context', valuesToSave);
				}
			} catch (error) {}

			this.sessionSubject.next(currentSessionContext);
		});
	}
}

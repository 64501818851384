import { SessionContextBase } from '@common/lib/utilities/session/session-context-base';
import { Cache } from '@common/lib/utilities/session/decorators/cache';
import { BrowserData } from '@common/lib/models/browser-data';
import { LocalStorage } from '@common/lib/utilities/session/decorators/localstorage';

export class ClientContext extends SessionContextBase {
	@Cache()
	public sessionParams?: any;
	@Cache()
	public firstUrl?: string;
	public appName?: string;
	public appVersion?: string;
	public environment?: any;
	public sessionId?: string;
	@LocalStorage()
	public applicationId?: string;
	public isAnonymous?: boolean = true;
	public browserData?: BrowserData;
	public iovationBlackBoxToken?: string;
	public socureSessionid?: string;
}

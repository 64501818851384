<form [formGroup]="businessExpectationsForm">
	<app-todo-header></app-todo-header>
	@for (question of financeQuestions; track question) {
		<div class="flex flex-col mb-4">
			<app-card [hideHeader]="true">
				<div class="flex gap-5 items-start">
					<div>
						{{ question.description }}
					</div>
					<mat-label>No</mat-label>
					<div>
						<mat-slide-toggle
							[formControlName]="question.toggleControlName"
							data-cy="toggle-field"
						></mat-slide-toggle>
					</div>
					<mat-label>Yes</mat-label>
				</div>
				<div class="">
					<mat-label class="prompttext">{{ question.promptText }}</mat-label>
				</div>
				@if (businessExpectationsForm.get(question.toggleControlName).value) {
					<div class="mt-6">
						<mat-label class="w-full">{{ question.inDescription }}</mat-label>
						<mat-form-field class="w-full mt-3" appearance="outline">
							<mat-label>
								{{ question.inLabel }}
							</mat-label>
							<mat-select
								[formControlName]="question.inControl"
								[required]="businessExpectationsForm.get(question.toggleControlName).value"
								data-cy="select-field"
							>
								@for (transactionRange of transactionRanges; track transactionRange) {
									<mat-option [value]="transactionRange">{{ transactionRange }}</mat-option>
								}
							</mat-select>
							<mat-error>Please select an amount.</mat-error>
						</mat-form-field>
					</div>
					<div class="mt-6">
						<mat-label class="w-full">{{ question.outDescription }}</mat-label>
						<mat-form-field class="w-full mt-3" appearance="outline">
							<mat-label>
								{{ question.outLabel }}
							</mat-label>
							<mat-select
								[formControlName]="question.outControl"
								[required]="businessExpectationsForm.get(question.toggleControlName).value"
								data-cy="select-field"
							>
								@for (transactionRange of transactionRanges; track transactionRange) {
									<mat-option [value]="transactionRange">{{ transactionRange }}</mat-option>
								}
							</mat-select>
							<mat-error>Please select an amount.</mat-error>
						</mat-form-field>
					</div>
				}
			</app-card>
		</div>
	}
	<div class="flex flex-col gap-4 mb-4">
		<app-card [hideHeader]="true">
			<div class="flex gap-5 items-start">
				<div class="w-full">Are you interested in using Autobooks?</div>
				<mat-label>No</mat-label>
				<div>
					<mat-slide-toggle formControlName="wantsToUseAutoBooks" data-cy="toggle-field"></mat-slide-toggle>
				</div>
				<mat-label>Yes</mat-label>
			</div>

			<mat-label class="prompttext mt-1"
				>At nbkc bank, we partner with Autobooks so you can send invoices to your customers and get paid
				electronically (via ACH, Debit & Credit Cards).</mat-label
			>
			<mat-label class="prompttext mt-3"
				>By selecting yes, you authorize nbkc to share your information with Autobooks so they can contact you.
				You can opt-out of sharing at any time by contacting nbkc.</mat-label
			>
		</app-card>
	</div>
	<app-next-todo-button></app-next-todo-button>
</form>

<div class="flex items-center">
	<i class="{{icon$ | async}} product-header-icon"></i>
	<div class="flex-grow ml-4 mt-1">
		<span class="product-title">{{title$ | async}}</span>
		<ng-container *ngIf="details$ | async">
			<div *ngFor="let detail of details$ | async">
				<span class="product-details">{{detail}}</span>
			</div>
		</ng-container>
	</div>
	<div class="rate" *ngIf="rate$ | async">
		<div class="flex items-end">
			<span class="text-4xl mr-1 font-semibold">{{rate$ | async | number : '1.2-2'}}</span>
			<div class="m-0 p-0 font-normal">
				<div class="text-sm transform translate-y-1.5">APY</div>
				<div class="text-xl">%</div>
			</div>
		</div>
	</div>
</div>
<div class="mt-1">
	<span class="subtitle">{{subtitle$ | async}}</span>
</div>


import { FormField } from './form-field.model';
import { ComponentContext } from '@common/lib/models/component-context';
import { GeneratorUtility } from '@common/lib/utilities/generator-utility';

export class ComponentDefinition {
	public id: string;
	public componentType: string;
	public components?: ComponentDefinition[];
	public fields: Record<string, FormField>;
	public options: Record<string, string>;
	public context: ComponentContext;

	constructor() {
		// Actually set from the backend. A consistent ID yields performance boosts, but a new one every time is functional
		this.id = GeneratorUtility.generateUuidv4();
	}
}

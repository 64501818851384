import { Component, Injector, Input } from '@angular/core';
import { DynamicFormComponentBase } from '../../../dynamic/dynamic-form-component.base';
import { FormField } from '@common/lib/models/form-field.model';

@Component({
	selector: 'common-card-body',
	templateUrl: './card-body.component.html',
	styleUrls: ['./card-body.component.scss']
})
export class CardBodyComponent extends DynamicFormComponentBase<CardBodyComponentFields, CardBodyComponentOptions> {
	@Input() data: CardInputData;

	constructor(
		public injector: Injector
	) {
		super(injector);
	}
}
interface CardBodyComponentFields extends Record<string, FormField>{

}

interface CardBodyComponentOptions {

}

interface CardInputData {
	expanded: boolean;
}

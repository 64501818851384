import { Injectable } from '@angular/core';
import { remove } from 'lodash';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class LoadingService {
	public currentLoadingActivities$ = new BehaviorSubject<Array<symbol>>([]);
	public isLoading$ = new BehaviorSubject<boolean>(true);
	public isLoadingCardSkeletonVisible;
	private loadingActivities: Array<symbol> = [];

	constructor() {}

	public startLoadingActivity(activity: symbol) {
		if (!this.loadingActivities) {
			this.loadingActivities = [];
		}
		this.loadingActivities.push(activity);
		this.currentLoadingActivities$.next(this.loadingActivities);
		this.isLoading$.next(true);
	}

	public stopLoadingActivity(activity: symbol) {
		if (this.loadingActivities) {
			remove(this.loadingActivities, (activitySymbol) => activitySymbol === activity);
		}
		this.currentLoadingActivities$.next(this.loadingActivities);
		this.isLoading$.next(this.loadingActivities.length > 0);
	}

	public stopAllLoadingActivities() {
		if (this.loadingActivities) {
			this.loadingActivities.length = 0;
		}
		this.currentLoadingActivities$.next(this.loadingActivities);
		this.isLoading$.next(false);
	}

	public setIsLoadingCardSkeletonVisible(isLoadingCardSkeletonVisible: boolean): void {
		this.isLoadingCardSkeletonVisible = isLoadingCardSkeletonVisible;
	}
}

import { RequestResource } from '@common/lib/models/resource/events/client/request-resource';
import { ReviewItemResource } from '@common/lib/models/resource/review-item-resource';

export class ModelCheckpointResource extends RequestResource {
	public name: string = 'model-checkpoint';
	public currentState: ModelResource;
	public submit: boolean;
}

export class ModelResource {
	application: any;
	lookups: any;
	reviewItems: ReviewItemResource[];
}

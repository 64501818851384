import { Component, Injector } from '@angular/core';
import { FormField } from '@common/lib/models/form-field.model';
import { from, Observable } from 'rxjs';
import { concatMap, take, takeUntil } from 'rxjs/operators';
import { ActionHelper } from '@common/lib/utilities/action-helper/action-helper';
import { DynamicFormComponentBase } from '../dynamic/dynamic-form-component.base';
import _ from 'lodash';
import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { submitApplicationActivity } from '@common/lib/constants/activities';
import { Condition } from '@common/lib/models/condition';
import { ReviewData } from '@common/lib/models/review-data';
import { ReviewItem } from '@common/lib/models/review-item';
import { CommonFormService } from '@common/lib/services/common-form.service';
import { LoadingService } from '@experience/app/services/loading.service';
import { LegacyRoutingService } from '@experience/app/services/legacy-routing.service';
import { PresentationService } from '@experience/app/services/presentation.service';
import { AppSession } from '@experience/app/app.session';
import { Tracker } from '@nbkc/tracker-angular';
import { ApplicationSubmitEvent } from '@experience/app/tracking/events/application-submit.event';
import { DynamicModelValue } from '@common/lib/models/model-value/dynamic-model-value';

@Component({
	// eslint-disable-next-line @angular-eslint/component-selector
	selector: 'common-review',
	templateUrl: './review.component.html',
	styleUrls: ['./review.component.scss'],
	animations: [
		trigger('listAnimation', [
			transition('* => *', [
				// each time the binding value changes
				query(
					':leave',
					[
						style({
							opacity: 1,
						}),
						stagger(100, [
							animate(
								'150ms',
								style({
									opacity: 0,
								}),
							),
						]),
					],
					{ optional: true },
				),
				query(
					':enter',
					[
						style({
							opacity: 0,
						}),
						stagger(100, [
							animate(
								'150ms',
								style({
									opacity: 1,
								}),
							),
						]),
					],
					{ optional: true },
				),
			]),
		]),
	],
})
export class ReviewComponent extends DynamicFormComponentBase<ReviewComponentFields, ReviewComponentOptions> {
	public reviewData$: Observable<ReviewData>;
	public conditions$: Observable<Condition[]>;
	public hideSubmit: boolean = false;
	public hasReviewItems: boolean = false;
	public uploadConditions: Condition[] = [];

	public firstName$: Observable<string>;
	public lastName$: Observable<string>;
	public email$: Observable<string>;
	public phoneNumber$: Observable<string>;

	constructor(
		public injector: Injector,
		private actionHelper: ActionHelper,
		public formService: CommonFormService,
		public loadingService: LoadingService,
		public routingService: LegacyRoutingService,
		public presentationService: PresentationService,
		private tracker: Tracker,
		public session: AppSession,
	) {
		super(injector);
	}

	onComponentLoaded(): void {
		this.firstName$ = this.formService.getModelValue$<string>(this.fields?.firstName?.modelPaths);
		this.lastName$ = this.formService.getModelValue$<string>(this.fields?.lastName?.modelPaths);
		this.email$ = this.formService.getModelValue$<string>(this.fields?.email?.modelPaths);
		this.phoneNumber$ = this.formService.getModelValue$<string>(this.fields?.phoneNumber?.modelPaths);

		this.reviewData$ = this.modelStateService.reviewData$;
		this.conditions$ = this.modelStateService.conditions$;
		this.hideSubmit = this.options.hideApplicationSubmit;

		this.reviewData$.pipe(takeUntil(this.destroy$)).subscribe((reviewData) => {
			this.hasReviewItems = reviewData?.reviewItems?.length > 0;
		});

		this.conditions$.pipe(takeUntil(this.destroy$)).subscribe((conditions) => {
			this.addNewConditions(conditions);
			this.removeRemovedConditions(conditions);
		});
	}

	public reviewResult(result: ReviewItem): void {
		this.presentationService.enableReviewMode();
		if (result.actions) {
			from(result.actions)
				.pipe(concatMap((action) => this.actionHelper.runAction(action)))
				.subscribe();
		}
	}

	public submit(): void {
		this.session.data$.pipe(take(1)).subscribe((session) => {
			if (session.appName === 'deposits-business') {
				const selectedProductsPath = new DynamicModelValue();
				selectedProductsPath.path = '$.application.selectedProducts';

				this.formService.getModelValue$<any[]>([selectedProductsPath]).subscribe((products) => {
					this.tracker.event(
						new ApplicationSubmitEvent({
							experienceType: 'bluefin-click-submit-business',
							productName: products.map((product) => product.name).join('_'),
						}),
					);
				});
			} else {
				const selectedProductPath = new DynamicModelValue();
				selectedProductPath.path = '$.application.selectedProduct';

				this.formService.getModelValue$<any>([selectedProductPath]).subscribe((product) => {
					this.tracker.event(
						new ApplicationSubmitEvent({
							experienceType: 'bluefin-click-submit-personal',
							productName: product.name,
						}),
					);
				});
			}
		});

		this.loadingService.startLoadingActivity(submitApplicationActivity);
		this.presentationService.submitApplication();
	}

	addNewConditions(conditions: Condition[]) {
		_.differenceWith(conditions, this.uploadConditions, (condition, uploadCondition) =>
			condition.id ? condition.id === uploadCondition.id : condition.name === uploadCondition.name,
		).forEach((condition) => this.uploadConditions.push(condition));
	}

	removeRemovedConditions(conditions: Condition[]) {
		this.uploadConditions = _.intersectionWith(this.uploadConditions, conditions, (current, previous) =>
			current.id ? current.id === previous.id : current.name === previous.name,
		);
	}
}

interface ReviewComponentFields extends Record<string, FormField> {
	firstName: FormField;
	lastName: FormField;
	email: FormField;
	phoneNumber: FormField;
}

interface ReviewComponentOptions {
	documentGroup: string;
	hideApplicationSubmit: boolean;
}

import { Injector } from '@angular/core';
import { ExperienceConfiguration } from '@experience/app/experience.configuration';

export const loadConfiguration = (injector: Injector): Promise<void> => new Promise((resolve) => {
	const config = injector.get(ExperienceConfiguration);
	config.start$(`/config.json`).subscribe(() => {
		resolve();
	});
});



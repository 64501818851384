import { Component, ViewEncapsulation } from '@angular/core';
import { DynamicComponentBase } from '@common/lib/components/dynamic/dynamic-component.base';
import { FormField } from '@common/lib/models/form-field.model';
import _ from 'lodash';
import { Tracker } from '@nbkc/tracker-angular';
import { ContainerSelectDefaultCaseEvent } from '@experience/app/tracking/events/container-select-default-case.event';
import { CommonNotificationService } from '@common/lib/services/common-notification.service';
import { CommonFormService } from '@common/lib/services/common-form.service';

@Component({
	selector: 'common-select-container',
	templateUrl: './select-container.component.html',
	styleUrls: ['./select-container.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class SelectContainerComponent extends DynamicComponentBase<SelectContainerComponentFields, SelectContainerComponentOptions> {

	public myClasses: string;
	public mode: SelectMode = SelectMode.single;
	public maxSelected: number = null;
	public selectOptions: any;
	public selectValue: any;
	public isDisabled: boolean = false;

	constructor(
		public formService: CommonFormService,
		public notificationService: CommonNotificationService,
		private tracker: Tracker
	) {
		super();
	}

	onComponentLoaded(): void {
		this.myClasses = this.options?.classes as string;
		this.formService.getModelValue$(this.fields?.options?.modelPaths)
			.subscribe(options => {
				this.selectOptions = options;
			});
		this.isDisabled = this.options.isDisabled;
		switch (this.options?.selectMode) {
			case 'Single':
				this.mode = SelectMode.single;
				break;
			case 'Radio':
				this.mode = SelectMode.radio;
				break;
			case 'Multi':
				this.mode = SelectMode.multi;
				this.maxSelected = (this.options?.selectLimit) ? parseInt(this.options?.selectLimit, 10) : null;
				break;
			default:
				this.tracker.event(new ContainerSelectDefaultCaseEvent(this?.options?.selectMode));
				break;
		}

		this.formService.getModelValue$<number[]>(this.fields.value.modelPaths).subscribe(selectedValue => {
			this.selectValue = selectedValue;
		});
	}

	public select(index: number): void {
		switch (this.mode) {
			case SelectMode.single:
				this.selectSingle(index);
				break;
			case SelectMode.radio:
				this.selectRadio(index);
				break;
			case SelectMode.multi:
				this.selectMulti(index);
				break;
		}
	}

	public isSelected(index: number): boolean {
		if (this.mode === SelectMode.single) {
			return this.selectValue?.id === this.selectOptions?.[index]?.id;
		} else {
			return _.some(this.selectValue, (selected) => selected?.id === this.selectOptions?.[index]?.id);
		}
	}

	private selectSingle(index: number): void {
		const currentlySelected = this.selectOptions[index];
		this.formService.setModelValue(currentlySelected, this.fields?.value?.modelPaths);
	}

	private selectRadio(index: number): void {
		this.formService.setModelValue([this.selectOptions[index]], this.fields?.value?.modelPaths);
	}

	private selectMulti(index: number): void {
		if (this.isSelected(index)) {
			this.selectValue = this.selectValue.filter((val) => val.id !== this.selectOptions[index].id);
		} else {
			if (this.maxSelected && this.maxSelected <= this.selectValue.length) {
				this.notificationService.displaySnackBarMessage(`You may only select ${this.maxSelected} items.`);
				return;
			}
			this.selectValue.push(this.selectOptions[index]);
		}
		this.formService.setModelValue(this.selectValue, this.fields?.value?.modelPaths);
	}
}

enum SelectMode {
	single,
	radio,
	multi,
}

interface SelectContainerComponentFields extends Record<string, FormField> {
	value: FormField;
	options: FormField;
}

interface SelectContainerComponentOptions {
	selectLimit: string;
	selectMode: string;
	isDisabled: boolean;
	classes: string;
}

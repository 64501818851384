import { NgModule } from '@angular/core';
import { mapToCanActivate, RouterModule, Routes } from '@angular/router';
import { ErrorComponent } from '@experience/app/components/error/error.component';
import { AuthenticationGuard } from '@common/lib/utilities/authentication/guards/authentication.guard';
import { LoginComponent } from '@experience/app/components/login/login.component';
import { LogoutComponent } from '@experience/app/components/logout/logout.component';
import { LandingPageComponent } from '@experience/app/components-new/landing-page/landing-page.component';
import { BodyComponent } from './components/body/body.component';
import { ProductSelectionComponent } from '@experience/app/components-new/product-selection/product-selection.component';
import { IndividualApplicantCardComponent } from '@experience/app/components-new/individual-applicant/individual-applicant-card.component';
import { ManualReviewComponent } from './components-new/manual-review/app-manual-review.component';
import { AppReviewDisclosuresComponent } from './components-new/app-review-disclosures/app-review-disclosures.component';
import { AddPeopleComponent } from '@experience/app/components-new/add-people/add-people.component';
import { AppSetupOnlineBankingComponent } from './components-new/app-setup-online-banking/app-setup-online-banking.component';
import { KnownRoutes } from './constants/known-routes';
import { ProhibitedBusinessesComponent } from './components-new/prohibited-businesses/prohibited-businesses.component';
import { BusinessExpectationsComponent } from './components-new/business-expectations/business-expectations.component';
import { AppDebitCardComponent } from './components-new/app-debit-card/app-debit-card.component';
import { BusinessApplicantCardComponent } from '@experience/app/components-new/business-applicant/business-applicant-card/business-applicant-card.component';
import { TerminalScreenComponent } from '@experience/app/components-new/terminal-screen/terminal-screen.component';
import { ApplicationRouteGuard } from './services/guards/application-route.guard';

const personalRoutes: Routes = [
	{
		path: KnownRoutes.ProductSelection,
		component: ProductSelectionComponent,
	},
	{
		path: KnownRoutes.PrimaryApplicant,
		component: IndividualApplicantCardComponent,
	},
	{
		path: KnownRoutes.AddApplicants,
		component: AddPeopleComponent,
	},
	{
		path: KnownRoutes.ApplicantDetails + '/:applicantIndex',
		component: IndividualApplicantCardComponent,
	},
	{
		path: KnownRoutes.DebitCard,
		component: AppDebitCardComponent,
	},
	{
		path: KnownRoutes.ApplicationReview,
		component: AppReviewDisclosuresComponent,
	},
	{
		path: KnownRoutes.ManualReview,
		component: ManualReviewComponent,
	},
	{
		path: KnownRoutes.SetupOnlineBanking,
		component: AppSetupOnlineBankingComponent,
	},
	{
		path: KnownRoutes.Denied,
		component: TerminalScreenComponent,
	},
	{
		path: KnownRoutes.OnHold,
		component: TerminalScreenComponent,
	},
];

const businessRoutes: Routes = [
	{
		path: KnownRoutes.ProhibitedBusinesses,
		component: ProhibitedBusinessesComponent,
	},
	{
		path: KnownRoutes.PrimaryApplicant,
		component: IndividualApplicantCardComponent,
	},
	{
		path: KnownRoutes.BusinessApplicant,
		component: BusinessApplicantCardComponent,
	},
	{
		path: KnownRoutes.BusinessExpectations,
		component: BusinessExpectationsComponent,
	},
	{
		path: KnownRoutes.ProductSelection,
		component: ProductSelectionComponent,
	},
	{
		path: KnownRoutes.AddApplicants,
		component: AddPeopleComponent,
	},
	{
		path: KnownRoutes.ApplicantDetails + '/:applicantIndex',
		component: IndividualApplicantCardComponent,
	},
	{
		path: KnownRoutes.DebitCard,
		component: AppDebitCardComponent,
	},
	{
		path: KnownRoutes.ApplicationReview,
		component: AppReviewDisclosuresComponent,
	},
	{
		path: KnownRoutes.ManualReview,
		component: ManualReviewComponent,
	},
	{
		path: KnownRoutes.SetupOnlineBanking,
		component: AppSetupOnlineBankingComponent,
	},
	{
		path: KnownRoutes.Denied,
		component: TerminalScreenComponent,
	},
	{
		path: KnownRoutes.OnHold,
		component: TerminalScreenComponent,
	},
];

export const defaultRoutes: Routes = [
	{
		path: 'error',
		component: ErrorComponent,
	},
	{
		path: 'login',
		component: LoginComponent,
	},
	{
		path: 'logout',
		component: LogoutComponent,
	},
	{
		path: '',
		component: LandingPageComponent,
		canActivate: mapToCanActivate([AuthenticationGuard]),
	},
	{
		path: 'personal',
		component: BodyComponent,
		canActivate: mapToCanActivate([AuthenticationGuard]),
		canActivateChild: [...mapToCanActivate([AuthenticationGuard]), ApplicationRouteGuard],
		children: personalRoutes,
	},
	{
		path: 'business',
		component: BodyComponent,
		canActivate: mapToCanActivate([AuthenticationGuard]),
		canActivateChild: [...mapToCanActivate([AuthenticationGuard]), ApplicationRouteGuard],
		children: businessRoutes,
	},
	{
		path: '**',
		redirectTo: '',
	},
];

@NgModule({
	imports: [RouterModule.forRoot(defaultRoutes, { scrollPositionRestoration: 'top' })],
	exports: [RouterModule],
})
export class AppRoutingModule {}

import { AdapterBase } from '@common/lib/utilities/adapter-base';
import { DynamicModelValue } from '@common/lib/models/model-value/dynamic-model-value';
import { StaticModelValue } from '@common/lib/models/model-value/static-model-value';
import { DynamicModelValueResource } from '@common/lib/models/resource/model-value/dynamic-model-value.resource';
import { StaticModelValueResource } from '@common/lib/models/resource/model-value/static-model-value.resource';
import { ModelValueResource } from '@common/lib/models/resource/model-value/model-value.resource';

// eslint-disable-next-line max-len
export class ModelValueResourceToModelValueAdapter extends AdapterBase<DynamicModelValueResource | StaticModelValueResource, DynamicModelValue | StaticModelValue> {
	adapt(source: ModelValueResource): DynamicModelValue | StaticModelValue {
		if (source.type === 'static') {
			const result = new StaticModelValue();
			const sourceAsStatic = source as StaticModelValueResource;
			result.value = sourceAsStatic.value;
			return result;
		} else {
			const result = new DynamicModelValue();
			const sourceAsDynamic = source as DynamicModelValueResource;
			result.path = sourceAsDynamic.path;
			result.context = sourceAsDynamic.context;
			return result;
		}
	}
}

import { Component } from '@angular/core';
import { RouteType } from '@common/lib/models/enum/route-type.enum';
import { Observable } from 'rxjs';
import { DynamicComponentBase } from '@common/lib/components/dynamic/dynamic-component.base';
import { FormField } from '@common/lib/models/form-field.model';
import { Router } from '@angular/router';
import { CommonFormService } from '@common/lib/services/common-form.service';
import { KnownValues } from '@common/lib/constants/known-values';
import { PresentationService } from '@experience/app/services/presentation.service';

@Component({
	selector: 'common-next-button',
	templateUrl: './next-button.component.html',
	styleUrls: ['./next-button.component.scss']
})
export class NextButtonComponent extends DynamicComponentBase<NextButtonComponentFields, NextButtonComponentOptions> {
	public buttonText$: Observable<string>;
	public disabled: boolean = false;
	public isReviewModeActive: boolean;

	constructor(
		private formService: CommonFormService,
		private router: Router,
		private presentationService: PresentationService
	) {
		super();
		this.isReviewModeActive = this.presentationService.isReviewModeActive;
	}

	public onComponentLoaded(): void {
		this.buttonText$ = this.formService.getModelValue$<string>(this.fields?.buttonTextPath?.modelPaths, this.options?.buttonTextValue);
		this.disabled = this.options?.disabled === 'true';
	}

	public onComponentUpdated(): void {
		this.disabled = this.options?.disabled === 'true';
	}

	public navigateToNext(): void {
		this.presentationService.navigateByRouteType(RouteType.next);
	}

	public navigateToReview(): void {
		const applicationReviewRoute = `${ KnownValues.workflowPath }application-review`;
		this.presentationService.navigateToURL$(applicationReviewRoute).subscribe();
	}
}

export interface NextButtonComponentFields extends Record<string, FormField> {
	buttonTextPath: FormField;
}

export interface NextButtonComponentOptions {
	disabled: string;
	buttonTextValue: string;
}

import { AfterViewInit, Component, HostListener, Injector, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { VerifyId } from '@experience/app/models/verify-id.model';
import { ExperienceConfiguration } from '@experience/app/experience.configuration';
import { VouchedConfig } from './vouched-models/vouched-config.model';
import { Condition } from '@common/lib/models/condition';
import { VouchedVerificationConfig } from './vouched-models/vouched-verification-config.model';
import { VouchedThemeConfig } from './vouched-models/vouched-theme-config.model';
import { Tracker } from '@nbkc/tracker-angular';
import { VouchedActionEvent } from './vouched-models/vouched-action.event';
import { DocumentService } from '@experience/app/services/document.service';


@Component({
	selector: 'common-identity-verification-vouched',
	templateUrl: './identity-verification-vouched.component.html',
	styleUrls: ['./identity-verification-vouched.component.scss'],
	encapsulation: ViewEncapsulation.None
})

export class IdentityVerificationVouchedComponent implements AfterViewInit, OnInit {
	@Input() firstName: string;
	@Input() lastName: string;
	@Input() phoneNumber: string;
	@Input() email: string;
	@Input() condition: Condition;

	isModalOpen: boolean = false;
	vouchedConfig = {} as VouchedConfig;
	isFirstSubmit: boolean = true;
	isComplete: boolean;

	constructor(public injector: Injector,
		private config: ExperienceConfiguration,
		private tracker: Tracker,
		private documentUploadService: DocumentService
	) { }

	@HostListener('window:beforeunload', ['$event'])
	beforeUnloadHander() {
		if (this.isModalOpen && !this.isComplete) {
			return false;
		}
	}

	ngOnInit() {
		this.setupVouchedConfig();
	}

	ngAfterViewInit(): void {
		const vouchedScriptId = 'vouched-script-element';

		if (document.getElementById(vouchedScriptId)) {
			return;
		}

		const script = document.createElement('script');
		script.src = 'https://static.vouched.id/widget/vouched-2.0.0.js';
		script.id = vouchedScriptId;
		document.head.appendChild(script);
	}

	openModal() {
		this.isModalOpen = true;
		window['Vouched'](this.vouchedConfig).mount('#vouched-element');
		this.tracker.event(new VouchedActionEvent('modal-opened'));
	}

	closeModal() {
		this.isModalOpen = false;
		this.tracker.event(new VouchedActionEvent('modal-closed'));
	}

	public sendJobRequest(jobToken: string): void {
		const verifyEvent = new VerifyId();
		verifyEvent.externalId = jobToken;
		verifyEvent.conditionId = this.condition.id;
		verifyEvent.verifyType = 'documentverify-vouched';

		this.documentUploadService.verifyId(verifyEvent);
	}

	setupVouchedConfig() {

		const vouchedElement = document.getElementsByTagName('common-identity-verification-vouched')[0];

		const vouchedVerification = {} as VouchedVerificationConfig;
		vouchedVerification.firstName = this.firstName;
		vouchedVerification.lastName = this.lastName;
		vouchedVerification.lastName = this.email;
		vouchedVerification.phone = this.phoneNumber;

		const vouchedTheme = {} as VouchedThemeConfig;
		vouchedTheme.name = 'avant';
		vouchedTheme.baseColor = getComputedStyle(vouchedElement).getPropertyValue('--nbkc-blue').trim();
		vouchedTheme.progressIndicatorTextColor = getComputedStyle(vouchedElement).getPropertyValue('--nbkc-medium-gray').trim();

		this.vouchedConfig.verification = vouchedVerification;
		this.vouchedConfig.theme = vouchedTheme;

		this.vouchedConfig.liveness = 'mouth';
		this.vouchedConfig.id = 'camera';
		this.vouchedConfig.face = 'camera';
		this.vouchedConfig.includeBarcode = true;
		this.vouchedConfig.idLiveness = 'distance';

		this.vouchedConfig.content = {};
		this.vouchedConfig.content.crossDeviceInstructions =
		`Please do not close this screen until this process is complete.
		 As a bank, we need to verify your information and identity.
		 This process requires a government-issued photo ID and a selfie.
		 Please follow the instructions below to complete the verification process on your phone.`;

		const useSandbox = this.config.vouchedDefaultToSandbox;

		this.vouchedConfig.sandbox = useSandbox;
		this.vouchedConfig.crossDevice = !useSandbox;
		this.vouchedConfig.crossDeviceQRCode = !useSandbox;
		this.vouchedConfig.crossDeviceSMS = !useSandbox;
		this.vouchedConfig.appId = useSandbox ? this.config.vouchedSandboxKey : this.config.vouchedPublicKey;
		this.vouchedConfig.onDone = (submitData) => {
			this.sendJobRequest(submitData.token);
			this.isComplete = true;
			this.tracker.event(new VouchedActionEvent('done'));

			setTimeout(() => {
				this.closeModal();
			}, (5000));
		};

		this.vouchedConfig.onSubmit = (submitData) => {
			this.tracker.event(new VouchedActionEvent(submitData.stage));
		};
	}
}

<div>
	<form [formGroup]="internalForm">
		<mat-form-field class="w-full" appearance="outline" [hideRequiredMarker]="hideRequiredMark">
			<mat-label>{{internalForm.value.label}}</mat-label>
			<mat-chip-grid #chipsSelect>
				<mat-chip-option *ngFor="let item of selectedItems" [selectable]="selectable" [removable]="removable"
					(removed)="remove(item)">
					{{item}}
					<mat-icon matChipRemove *ngIf="removable">x</mat-icon>
				</mat-chip-option>
				<input placeholder="New item..." #chipsInput [formControl]="chipsCtrl" [matAutocomplete]="auto"
					[matChipInputFor]="chipsSelect" [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
			</mat-chip-grid>
			<mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
				<mat-option *ngFor="let item of filteredItems$ | async" [value]="item.Description">
					{{item.Description}}
				</mat-option>
			</mat-autocomplete>
		</mat-form-field>
	</form>
</div>

import { Component, inject, OnInit, signal, ViewChild } from '@angular/core';
import { AppTodoHeaderComponent } from '../app-todo-header/app-todo-header.component';
import { AppCardComponent } from '../card/app-card.component';
import { ExperienceConfiguration } from '@experience/app/experience.configuration';
import {
	AbstractControl,
	FormBuilder,
	FormControl,
	FormGroup,
	ReactiveFormsModule,
	ValidationErrors,
	ValidatorFn,
	Validators,
} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatCheckbox } from '@angular/material/checkbox';
import { AppPasswordComponent } from '../app-password/app-password.component';
import { ApplicationsApiService } from '@experience/app/services/applications-api.service';
import { ApplicationStateService } from '@experience/app/services/application-state.service';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatButton } from '@angular/material/button';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';

@Component({
	selector: 'app-setup-online-banking',
	standalone: true,
	imports: [
		CommonModule,
		ReactiveFormsModule,
		AppTodoHeaderComponent,
		AppCardComponent,
		AppPasswordComponent,
		MatFormField,
		MatLabel,
		MatInput,
		MatCheckbox,
		MatProgressSpinner,
		MatButton,
	],
	templateUrl: './app-setup-online-banking.component.html',
	styleUrl: './app-setup-online-banking.component.scss',
})
export class AppSetupOnlineBankingComponent implements OnInit {
	private config = inject(ExperienceConfiguration);
	private formBuilder = inject(FormBuilder);
	private applicationApiService = inject(ApplicationsApiService);
	private applicationStateService = inject(ApplicationStateService);

	public readonly q2LoginFormURL = this.config.q2LoginFormURL;
	public enrollmentForm: FormGroup;
	public usernameFormControl: FormControl;
	public $enrollmentInProgress = signal(false);
	public $serverErrorMessage = signal(undefined);
	@ViewChild('autoLoginButton') autoLoginButton;

	ngOnInit(): void {
		this.enrollmentForm = this.formBuilder.group({
			username: [
				'',
				[
					Validators.required,
					Validators.maxLength(20),
					Validators.minLength(6),
					this.invalidCharactersValidator(),
					this.containsAtLeastOneLetterValidator(),
				],
			],
			consent: [false, [Validators.requiredTrue]],
		});

		this.usernameFormControl = this.enrollmentForm.get('username') as FormControl;
	}

	checkSubmitOnEnter(e: Event): void {
		e.preventDefault();
		if (!this.enrollmentForm.valid || this.$enrollmentInProgress()) {
			return;
		}

		this.enroll();
	}

	enroll(): void {
		this.$serverErrorMessage.set(undefined);
		this.$enrollmentInProgress.set(true);
		const enrollment = this.enrollmentForm.value;
		this.applicationApiService
			.enrollApplicant(this.applicationStateService.$id(), enrollment.username.trim(), enrollment.password.trim())
			.subscribe(
				() => {
					this.$enrollmentInProgress.set(false);
					this.autoLoginButton.nativeElement.click();
				},
				(errorResponse: HttpErrorResponse) => {
					this.$enrollmentInProgress.set(false);

					if (errorResponse?.status === HttpStatusCode.UnprocessableEntity && errorResponse?.error) {
						this.$serverErrorMessage.set(errorResponse.error);
					} else {
						this.$serverErrorMessage.set(
							'Something went wrong setting up your online banking. Please try again.',
						);
					}
				},
			);
	}

	private invalidCharactersValidator(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			if (!control.value) {
				return null;
			}
			const isValid = new RegExp('^[a-zA-Z0-9_-]+$').test(control.value);
			return isValid ? null : { invalidCharacters: true };
		};
	}

	private containsAtLeastOneLetterValidator(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			const hasLetter = control.value && /[a-zA-Z]/.test(control.value);
			return hasLetter ? null : { noLetter: true };
		};
	}
}

export const KnownRoutes = {
	AccountOwnership: 'account-ownership',
	AdditionalApplicants: 'additional-applicants',
	ApplicationReview: 'application-review',
	BusinessApplicant: 'business-applicant',
	BusinessExpectations: 'business-expectations',
	DebitCard: 'debit-card',
	Denied: 'Denied',
	ManualReview: 'manual-review',
	OnHold: 'OnHold',
	PrimaryApplicant: 'primary-applicant',
	ProductSelection: 'product-selection',
	ProhibitedBusinesses: 'prohibited-businesses',
	SetupOnlineBanking: 'setup-online-banking',
};

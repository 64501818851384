<div id="anchor" class="card-div-container flex justify-center mb-6">
	<app-card
		title="Verify Your Identity"
		bodyText="We need to check the front and back of your ID to be sure it matches the info you provided."
		headerIcon="fa-camera"
		headerIconColor="blue"
		cardActionInfo="Begin Verification"
		(click)="startVerification()"
		(keydown.enter)="startVerification()"
		class="w-full"
	>
	</app-card>
</div>

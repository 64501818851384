@if ('todoList().length > 0') {
	<div class="todo-list absolute">
		<div [@.disabled]="animationsDisabled">
			<ul [@listChanged]="todoList().length">
				<li><div class="todo-header mb-8 font-semibold leading-12">To Do:</div></li>
				@for (item of todoList(); track item.title) {
					<li>
						<div
							[ngClass]="{
								'cursor-pointer': item.complete,
								'cursor-not-allowed': !item.complete,
								'scale-125': isActiveScreen(item.associatedRoute),
							}"
							class="flex items-center mb-6 transform transition duration-300 hover:scale-125"
							(click)="navigateToRoute(item)"
						>
							<div
								[ngClass]="{ invisible: !item.complete }"
								class="fas fa-check-circle mr-4 text-base"
							></div>
							<div
								[ngClass]="{
									'active-screen': isActiveScreen(item.associatedRoute),
									'other-screen': !isActiveScreen(item.associatedRoute),
								}"
								class="text-base"
							>
								{{ item.title }}
							</div>
						</div>
					</li>
				}
			</ul>
		</div>
	</div>
}

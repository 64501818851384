import { applyPatch, Operation } from 'fast-json-patch/commonjs/core';
import { getValueByPointer } from 'fast-json-patch';

export abstract class JsonPatchUtility {
	private static pathCache = {};

	public static tryOrIgnorePatch(object: any, patch: Operation[]): void {
		if (!patch || patch.length === 0) {
			return;
		}

		try {
			applyPatch(object, patch);
		} catch (error) {
			// eslint-disable-next-line no-console
			console.warn('Patch Fail. Usually Harmless');
			// Just ignore. It was a stale patch.
		}
	}

	public static convertJsonPathToJsonPointer(jsonPath: string) {
		if (this.pathCache[jsonPath]) {
			return this.pathCache[jsonPath];
		}
		const patternForThingsITurnToSlashes = /(\$\.)|(]\.)|([[\].])/g;
		const patternForNoTrailingSlashes = /([/])$/g;
		let result = jsonPath.replace(patternForThingsITurnToSlashes, '/');
		result = result.replace(patternForNoTrailingSlashes, '');
		this.pathCache[jsonPath] = result;
		return result;
	}

	public static resolveLastArrayIndexForPathsWithDashes(model: any, path: string): string  {
		if (path.includes('/-')) {
			const arrayRefPos = path.indexOf('/-');
			const array = getValueByPointer(model, path.slice(0, arrayRefPos));
			if (!array) {
				console.error('Could not populate current index because array was not found in the model.');
				return path;
			}
			const lastIndex = array.length - 1;
			const newPath = path.replace('/-', `/${ lastIndex }`);
			if (newPath.includes('/-')) {
				return this.resolveLastArrayIndexForPathsWithDashes(model, newPath);
			}
			return newPath;
		}
		return path;
	}
}

import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core';
import { InternalStateService } from '@experience/app/services/internal-state.service';
import { ApplicationsApiService } from '@experience/app/services/applications-api.service';
import { ApplicationStateService } from '@experience/app/services/application-state.service';
import { AppRoutingService } from '@experience/app/services/app-routing.service';

@Component({
	selector: 'app-todo-header',
	standalone: true,
	imports: [CommonModule],
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './app-todo-header.component.html',
	styleUrl: './app-todo-header.component.scss',
})
export class AppTodoHeaderComponent {
	internalStateService = inject(InternalStateService);
	applicationApiService = inject(ApplicationsApiService);
	applicationStateService = inject(ApplicationStateService);
	routingService = inject(AppRoutingService);
	$todoList = this.internalStateService.$todoItems;

	$currentRouteIndex = computed<number>(() => {
		const currentScreenName = this.internalStateService.$currentScreen();
		const currentRouteIndex = this.$todoList().findIndex((t) => t.associatedRoute === currentScreenName);
		return currentRouteIndex;
	});

	$title = computed<string>(() => this.$todoList()[this.$currentRouteIndex()]?.title);

	$subtitle = computed<string>(() => this.$todoList()[this.$currentRouteIndex()]?.subtitle);

	navigateBack(): void {
		const application = this.applicationStateService.$application();

		this.applicationApiService.saveApplication(application);
		const previousTodoItem = this.$todoList()[this.$currentRouteIndex() - 1];
		this.routingService.routeToApplicationScreen(previousTodoItem.associatedRoute);
	}
}

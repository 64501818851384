export class ApplyEntryPoint {
	public applicationName: string;
	public applicationId: string;
	public version: string;
	public title: string;
	public description: string;
	public actionDescription: string;
	public actionUri: string;
	public isVisible: boolean;
}

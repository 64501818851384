import { Component, Injector } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { DynamicFormComponentBase } from '../../dynamic/dynamic-form-component.base';
import { ComparisonUtility } from '@common/lib/utilities/comparison-utility';
import { FormField } from '@common/lib/models/form-field.model';
import _ from 'lodash';

//<!-- See the spec.ts file before using, this will need to be refactored do to mat-chip-list being deprecated in favor of mat-chip-listbox -->

@Component({
	selector: 'common-radio-button-chips',
	templateUrl: './radio-button-chips.component.html',
	styleUrls: ['./radio-button-chips.component.scss']
})
export class RadioButtonChipsComponent extends DynamicFormComponentBase<RadioButtonChipsComponentFields, RadioButtonChipsComponentOptions> {
	public hideRequiredMark: boolean = false;
	public chipOptions$: Observable<Record<string, string>[]>;
	public selectedOptions$: Observable<Record<string, string>[]>;
	public selectedValue: string = '';
	public displaySelector: string = '';
	public valueSelector: string = '';
	public validationRuleApplied: boolean = false;

	constructor(
		public injector: Injector
	) {
		super(injector);
	}

	onComponentLoaded(): void {
		this.registerAllControls();
		this.chipOptions$ = this.formService.getModelValue$<Record<string, string>[]>(this.fields?.options.modelPaths);
		this.selectedOptions$ = this.formService.getModelValue$<Record<string, string>[]>(this.fields?.value?.modelPaths);
		this.displaySelector = this.options?.displaySelector;
		this.valueSelector = this.options?.valueSelector;
		this.hideRequiredMark = this.options?.hideRequiredMark || false;
		this.validationRuleApplied = !!this.fields?.value.validationRules;

		forkJoin({
			allChipOptions: this.chipOptions$.pipe(take(1)),
			selectedOptions: this.selectedOptions$.pipe(take(1))
		}).subscribe((chipsContext) => {
			const selectedChipOption = _.find(chipsContext.selectedOptions, (selectedOption) =>
				_.find(chipsContext.allChipOptions, (chipOption) => ComparisonUtility.isEqual(selectedOption, chipOption)));

			if (selectedChipOption) {
				this.selectedValue = this.valueSelector ? selectedChipOption[this.valueSelector] : selectedChipOption[0];
			}
		});
	}

	public getValue(item: Record<string, string>): string {
		if (this.valueSelector) {
			return item[this.valueSelector];
		}
		return item[0];
	}

	public getDisplay(item: Record<string, string>): string {
		if (this.displaySelector) {
			return item[this.displaySelector];
		}
		return item[0];
	}

	public selectOption(option: Record<string, string>): void {
		this.selectedValue = this.valueSelector ? option[this.valueSelector] : option[0];

		forkJoin({
			allChipOptions: this.chipOptions$.pipe(take(1)),
			selectedOptions: this.selectedOptions$.pipe(take(1))
		}).subscribe((chipsContext) => {
			// If a chip is already selected, remove it
			_.remove(chipsContext.selectedOptions, (selectedOption) =>
				_.some(chipsContext.allChipOptions, (chipOption) => ComparisonUtility.isEqual(selectedOption, chipOption)));

			chipsContext.selectedOptions.push(option);
			this.formService.setModelValue(chipsContext.selectedOptions, this.fields?.value.modelPaths);
		});
	}
}

interface RadioButtonChipsComponentFields extends Record<string, FormField> {
	value: FormField;
}

interface RadioButtonChipsComponentOptions {
	valueSelector: string;
	displaySelector: string;
	hideRequiredMark: boolean;
}

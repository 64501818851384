import { ConfigurationLoader } from '@nbkc/configuration';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import _ from 'lodash';

export class ConfigurationUtilityBase {
	private configurationSourceUrl: string;

	public start$(url: string): Observable<any> {
		this.configurationSourceUrl = url;
		return this.loadConfiguration();
	}

	public refresh$(): Observable<any> {
		return this.loadConfiguration();
	}

	private loadConfiguration(): Observable<void> {
		const configLoader = new ConfigurationLoader(this.configurationSourceUrl);
		return configLoader.configurationValues$.pipe(
			tap((values: any) => {
				_.each(values, (value, key) => {
					this[key] = value;
				});
			})
		);
	}

}


<div class="{{myClasses}}">
	<div tabindex="0" class="select-container rounded-xl cursor-pointer mb-4 relative"
		 *ngFor="let component of components; trackBy:trackByIdFn; index as index"
		 [ngClass]="{'selected': isSelected(index)}"
		 (click)="select(index)" (keydown.Enter)="select(index)">
		<app-dynamic [componentDefinition]="getComponentDefinition(component)" [parentForm]="parentForm">
		</app-dynamic>
		<div *ngIf="isSelected(index)" class="triangle"></div>
		<i *ngIf="isSelected(index)" class="star fas fa-star"></i>
	</div>
</div>

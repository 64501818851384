<mat-form-field [formGroup]="$formGroup()" class="w-full" appearance="outline">
	<mat-label>Password</mat-label>
	<input
		matInput
		id="password"
		name="password"
		formControlName="password"
		[type]="passwordIsVisible ? 'text' : 'password'"
		data-cy="input-field"
	/>
	<button
		type="button"
		mat-icon-button
		matSuffix
		class="password-visibility-toggle"
		(click)="togglePasswordVisibility()"
	>
		<i
			class="far"
			[ngClass]="passwordIsVisible ? 'fa-eye-slash' : 'fa-eye'"
			[attr.aria-label]="passwordIsVisible ? 'Hide password' : 'Show password'"
		></i>
	</button>
</mat-form-field>
@if (passwordFormControl?.dirty) {
	@if (passwordFormControl?.hasError('required')) {
		<div class="pl-2.5 pt-2.5 validation-message">Please enter your new online banking password</div>
	} @else if (passwordFormControl?.hasError('specialCharacters')) {
		<div class="pl-2.5 pt-2.5 validation-message">
			Password may only contain the following special characters: !&#64;#$%^*
		</div>
	} @else if (passwordFormControl?.hasError('maxlength')) {
		<div class="pl-2.5 pt-2.5 validation-message">Password is too long</div>
	}
}
<div class="pb-2.5 password-validation-container">
	@for (passwordRequirement of passwordRequirements; track $index) {
		<div class="password-validation" [ngClass]="{ 'validation-met': passwordRequirement.isValid === true }">
			<i class="fas fa-circle password-validation-icon"></i>
			<span class="password-validation-text">{{ passwordRequirement.message }}</span>
		</div>
	}
</div>

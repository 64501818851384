import { Component, Injector } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { DynamicFormComponentBase } from '@common/lib/components/dynamic/dynamic-form-component.base';
import { FormField } from '@common/lib/models/form-field.model';
import { AuthorizedUser } from '@common/lib/models/authorized-user';
import { map, take } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
	selector: 'common-authorized-users',
	templateUrl: './authorized-users.component.html'
})
export class AuthorizedUsersComponent extends DynamicFormComponentBase<AuthorizedUsersComponentFields, AuthorizedUsersComponentOptions> {
	public isEditing: boolean = false;
	public authorizedUsers$: Observable<AuthorizedUser[]>;

	constructor(
		public injector: Injector,
	) {
		super(injector);
	}

	onComponentLoaded() {
		this.internalForm.addControl('firstName', new FormControl('', Validators.required));
		this.internalForm.addControl('lastName', new FormControl('', Validators.required));

		this.authorizedUsers$ = this.formService.getModelValue$<AuthorizedUser[]>(this.fields?.collectionPath.modelPaths).pipe(
			map((users) => users?.filter((user) => !!user?.firstName && !!user?.lastName))
		);
	}

	public add(): void {
		this.isEditing = true;
	}

	public save(): void {
		const newAuthorizedUser: AuthorizedUser = {
			firstName: this.internalForm.get('firstName').value,
			lastName: this.internalForm.get('lastName').value
		};

		this.authorizedUsers$
			.pipe(take(1))
			.subscribe((authorizedUsers) => {
				if (!authorizedUsers) {
					authorizedUsers = [];
				}

				authorizedUsers.push(newAuthorizedUser);
				this.formService.setModelValue(authorizedUsers, this.fields.collectionPath.modelPaths);

				this.internalForm.reset();
				this.isEditing = false;
			});
	}

	public cancel(): void {
		this.isEditing = false;
		this.internalForm.reset();
	}

	public removeUser(user: AuthorizedUser) {
		this.authorizedUsers$
			.pipe(take(1))
			.subscribe((authorizedUsers) => {
				const filteredUsers = authorizedUsers.filter((authorizedUser) =>
					authorizedUser.firstName !== user.firstName || authorizedUser.lastName !== user.lastName);
				this.formService.setModelValue(filteredUsers, this.fields.collectionPath.modelPaths);
			});
	}
}

interface AuthorizedUsersComponentFields extends Record<string, FormField> {
	collectionPath: FormField;
}

interface AuthorizedUsersComponentOptions {
	label: string;
	isDisabled: string;
}

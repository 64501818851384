<div [formGroup]="$taxIdentificationFormGroup()" class="flex flex-col gap-4">
	@if ($isSSN()) {
		<mat-form-field>
			<mat-label>Social Security Number</mat-label>
			<app-masked-input
				[formControl]="$taxIdentificationFormGroup().get('number') | formControl"
				maskType="ssn"
				[required]="true"
				data-cy="input-field"
			>
			</app-masked-input>
			@if (
				$taxIdentificationFormGroup().get('number').hasError('required') ||
				$taxIdentificationFormGroup().get('number').hasError('mask')
			) {
				<mat-error
					>Please include a valid social security number for {{ $applicantNameOrDefault() }}.</mat-error
				>
			}
			@if ($taxIdentificationFormGroup().get('number').hasError('taxIdNumberMismatch')) {
				<mat-error
					>Please confirm that social security numbers for {{ $applicantNameOrDefault() }} match.</mat-error
				>
			}
		</mat-form-field>
		<mat-form-field>
			<mat-label>Confirm Social Security Number</mat-label>
			<app-masked-input
				[formControl]="$taxIdentificationFormGroup().get('numberConfirmation') | formControl"
				maskType="ssn"
				[required]="true"
				data-cy="input-field"
			>
			</app-masked-input>
			@if ($taxIdentificationFormGroup().get('numberConfirmation').hasError('required')) {
				<mat-error>Please confirm social security number for {{ $applicantNameOrDefault() }}.</mat-error>
			}
			@if ($taxIdentificationFormGroup().get('numberConfirmation').hasError('taxIdNumberMismatch')) {
				<mat-error
					>Please confirm that social security numbers for {{ $applicantNameOrDefault() }} match.</mat-error
				>
			}
		</mat-form-field>
	} @else {
		<mat-form-field>
			<mat-label>Employer Identification Number (EIN)</mat-label>
			<app-masked-input
				[formControl]="$taxIdentificationFormGroup().get('number') | formControl"
				maskType="ein"
				[required]="true"
				data-cy="input-field"
			>
			</app-masked-input>
			@if (
				$taxIdentificationFormGroup().get('number').hasError('required') ||
				$taxIdentificationFormGroup().get('number').hasError('mask')
			) {
				<mat-error>Please include a valid EIN for {{ $applicantNameOrDefault() }}.</mat-error>
			}
			@if ($taxIdentificationFormGroup().get('number').hasError('taxIdNumberMismatch')) {
				<mat-error>Please confirm that EINs for {{ $applicantNameOrDefault() }} match.</mat-error>
			}
		</mat-form-field>
		<mat-form-field>
			<mat-label>Confirm Employer Identification Number</mat-label>
			<app-masked-input
				[formControl]="$taxIdentificationFormGroup().get('numberConfirmation') | formControl"
				maskType="ein"
				[required]="true"
				data-cy="input-field"
			>
			</app-masked-input>
			@if ($taxIdentificationFormGroup().get('numberConfirmation').hasError('required')) {
				<mat-error>Please confirm EIN for {{ $applicantNameOrDefault() }}.</mat-error>
			}
			@if ($taxIdentificationFormGroup().get('numberConfirmation').hasError('taxIdNumberMismatch')) {
				<mat-error>Please confirm that EINs for {{ $applicantNameOrDefault() }} match.</mat-error>
			}
		</mat-form-field>
	}
</div>

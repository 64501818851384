import { ErrorTypes } from './enum/error-types.enum';

export class ProviderError {
	public message: string;
	public errorType: ErrorTypes;
	public isRetrying: boolean;
	public rawError: any;

	constructor(type: ErrorTypes, message: string, rawError: any) {
		this.errorType = type;
		this.message = message;
		this.isRetrying = false;
		this.rawError = rawError;
	}
}

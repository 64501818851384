import { RequestDocumentUploadRequestResource } from '@common/lib/models/resource/events/client/request-document-upload-request-resource';
import { AdapterBase } from '@common/lib/utilities/adapter-base';
import { RequestDocumentUpload } from '@experience/app/models/request-document-upload.model';

export class RequestDocumentUploadAdapter extends AdapterBase<RequestDocumentUpload, RequestDocumentUploadRequestResource> {
	adapt(source: RequestDocumentUpload): RequestDocumentUploadRequestResource {
		const dest = new RequestDocumentUploadRequestResource();
		dest.documentGroupId = source?.documentGroupId;
		dest.documentGroup = source?.documentGroup;

		return dest;
	}
}

<form [formGroup]="internalForm">
	<div  *ngIf="!loadingService.isLoadingCardSkeletonVisible" class="card-div-container flex justify-center mb-4 whitespace-normal {{classes}}">
		<mat-card appearance="outlined"
				  class="w-full overflow-hidden rounded-xl shadow-card"
				  data-cy="card">
			<app-dynamic *ngIf="cardHeader" [componentDefinition]="cardHeader" [parentForm]="internalForm">
			</app-dynamic>
			<div>
				<app-dynamic *ngIf="cardBody" [componentDefinition]="cardBody"
							 [parentForm]="internalForm"></app-dynamic>
				<app-dynamic *ngIf="cardActions" [componentDefinition]="cardActions"
							 [parentForm]="internalForm">
				</app-dynamic>
				<app-dynamic *ngIf="cardFooter" [componentDefinition]="cardFooter"
							 [parentForm]="internalForm">
				</app-dynamic>
			</div>
		</mat-card>
	</div>
</form>

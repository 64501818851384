import { Injectable } from '@angular/core';
import { Condition } from '@common/lib/models/condition';
import { ReviewData } from '@common/lib/models/review-data';
import _ from 'lodash';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class ModelStateService {

	public modelState$: BehaviorSubject<ModelState> = new BehaviorSubject({} as ModelState);
	public reviewData$: BehaviorSubject<ReviewData> = new BehaviorSubject({} as ReviewData);
	public conditions$: BehaviorSubject<Condition[]> = new BehaviorSubject([]);

	constructor() {}

	public updateModelState(updatedModelState: ModelState) {
		updatedModelState.shouldNotSendUpdateToApi = false;
		this.modelState$.next(updatedModelState);
	}

	public updateReviewData(reviewData: ReviewData) {
		this.reviewData$.next(reviewData);
	}

	public updateConditions(conditions: Condition[]) {
		this.conditions$.next(conditions);
	}

	updateApplicationWithoutTriggeringApi(application) {
		const currentModelState = _.cloneDeep(this.modelState$.getValue());
		currentModelState.application = application;
		currentModelState.shouldNotSendUpdateToApi = true;
		this.modelState$.next(currentModelState);
	}

	public updateLookupsWithoutNotifyingSubscribers(lookups) {
		const currentModelState = this.modelState$.getValue();
		currentModelState.lookups = lookups;
	}
}

export class ModelState {
	context: any;
	application: any;
	lookups: any;
	shouldNotSendUpdateToApi?: boolean;
}

export enum knownApplicationStatus {
	New = 'New',
	InProgress = 'InProgress',
	InReview = 'InReview',
	Approved = 'Approved',
	Denied = 'Denied',
	Onboarded = 'Onboarded',
	Archived = 'Archived',
	Withdrawn = 'Withdrawn',
	OnHold = 'OnHold'
}

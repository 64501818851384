import { Component } from '@angular/core';
import { DynamicComponentBase } from '../../dynamic/dynamic-component.base';
import { FormField } from '@common/lib/models/form-field.model';
import { ComparisonUtility } from '@common/lib/utilities/comparison-utility';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { CommonFormService } from '@common/lib/services/common-form.service';

@Component({
	selector: 'common-container',
	templateUrl: './container.component.html',
	styleUrls: ['./container.component.scss']
})
export class ContainerComponent extends DynamicComponentBase<ContainerComponentFields, ContainerComponentOptions> {
	public display$: Observable<boolean> = of(true);

	constructor(public formService: CommonFormService) {
		super();
	}

	onComponentLoaded(): void {
		this.containerClass = this.options?.containerClass;
		const displayTargetPath = this.fields?.displayTargetPath;

		if (displayTargetPath && (this.options?.displayWhen || this.options?.hideWhen)) {
			this.display$ = this.formService.getModelValue$<boolean | string | number>(displayTargetPath.modelPaths)
				.pipe(
					map((displayTargetValue) => {
						const valueToCompare = this.getDisplayComparisonValue(displayTargetValue);

						if (this.options?.displayWhen) {
							return ComparisonUtility.isEqual(displayTargetValue, valueToCompare);
						} else if (this.options?.hideWhen) {
							return !ComparisonUtility.isEqual(displayTargetValue, valueToCompare);
						}

						return true;
					})
				);
		}
	}

	private getDisplayComparisonValue(targetValue: boolean | string | number): boolean | string | number {
		const displayComparisonValue: string = this.options?.displayWhen || this.options?.hideWhen;
		return ComparisonUtility.matchTargetPrimitiveType(targetValue, displayComparisonValue);
	}
}

interface ContainerComponentFields extends Record<string, FormField> {
	displayTargetPath: FormField;
}

interface ContainerComponentOptions {
	displayWhen: string;
	hideWhen: string;
	containerClass: string;
}

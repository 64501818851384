<div class="max-w-xl w-full flex flex-col gap-4 pl-2 mt-1">
	<div>
		<span class="block text-3xl font-semibold leading-8 nbkc-black">{{ $headerText() }}</span>
	</div>
	<div>
		<span class="block nbkc-dark-gray text-lg">
			<span>{{ $bodyText() }}</span>
			@if ($application().status === knownApplicationStatus.Denied) {
				<a
					class="nbkc-blue"
					target="_blank"
					href="https://support.nbkc.com/hc/en-us/articles/4548772396819-Why-was-my-account-application-declined"
					>here.</a
				>
			}
		</span>
	</div>
</div>

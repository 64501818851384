import { Component, Injector } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { DynamicFormComponentBase } from '@common/lib/components/dynamic/dynamic-form-component.base';
import { TEXT_MASKS } from '@common/lib/constants/text-masks';
import { FormField } from '@common/lib/models/form-field.model';
import { ValidationRule } from '@common/lib/models/validation-rule';
import { ValidationRuleTypes } from '@common/lib/models/enum/validation-rule-types.enum';
import _ from 'lodash';

@Component({
	selector: 'common-social-security',
	templateUrl: './social-security.component.html',
	styleUrls: ['./social-security.component.scss']
})
export class SocialSecurityComponent extends DynamicFormComponentBase<SocialSecurityComponentFields, SocialSecurityComponentOptions> {
	public socialSecurityMask: any = TEXT_MASKS.ssn;
	public ssnValueControl: AbstractControl;
	public ssnMaskedControl: FormControl;
	public hideRequiredMark: boolean = false;

	constructor(
		public injector: Injector
	) {
		super(injector);
	}

	onComponentLoaded(): void {
		this.hideRequiredMark = this.options?.hideRequiredMark || false;

		// To handle both masking and proper validation we have 2 form controls.
		// One is hidden and handles the actual value and validation. The other is displayed and handles masking and user interaction.
		this.registerAllControls();
		this.ssnValueControl = this.internalForm.get('value');

		const requiredValidator: ValidationRule[] | null = this.checkForRequiredValidationRule();
		this.ssnMaskedControl = this.internalForm.registerControl('ssnMasked', this.buildFormControl(requiredValidator)) as FormControl;

		if (this.ssnValueControl.value) {
			this.maskSSN();
		}
	}

	public updateFormValue(): void {
		// Since this control is not actually visible to the user, we need to manually mark it as touched for validation to trigger.
		this.ssnValueControl.markAsTouched();

		if (!this.isMasked(this.ssnMaskedControl.value)) {
			this.ssnValueControl.setValue(this.ssnMaskedControl.value);
		}

		if (this.ssnValueControl.valid) {
			this.maskSSN();
		}
	}

	public maskSSN(): void {
		this.ssnMaskedControl.setValue('***-**' + this.ssnValueControl.value.slice(6));
	}

	public unmaskSSN(): void {
		this.ssnMaskedControl.setValue(this.ssnValueControl.value);
	}

	private isMasked(value: string): boolean {
		return _.includes(value, '*');
	}

	private checkForRequiredValidationRule(): ValidationRule[] | null {
		const requiredValidator = _.find(this.fields?.value.validationRules, (rule: ValidationRule) => rule.type === ValidationRuleTypes.required);
		return requiredValidator ? [requiredValidator] : null;
	}
}

interface SocialSecurityComponentFields extends Record<string, FormField> {
	value: FormField;
}

interface SocialSecurityComponentOptions {
	label: string;
	inputClass: string;
	subtext: string;
	isDisabled: boolean;
	hideRequiredMark: boolean;
}

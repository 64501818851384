import { AdapterBase } from '@common/lib/utilities/adapter-base';
import { NavigateRequestResource } from '@common/lib/models/resource/events/client/navigate-request-resource';
import { KnownValues } from '@common/lib/constants/known-values';
import { NavigateRequest } from '@common/lib/models/navigate-request.model';

export class NavigateRequestAdapter extends AdapterBase<NavigateRequest, NavigateRequestResource> {
	adapt(source: NavigateRequest): NavigateRequestResource {
		const dest = new NavigateRequestResource();
		if (source?.target) {
			dest.target = source?.target?.replace(KnownValues.workflowPath, '');
		}
		return dest;
	}
}

import { Component } from '@angular/core';
import { DynamicComponentBase } from '../dynamic/dynamic-component.base';
import { FormField } from '@common/lib/models/form-field.model';
import { Observable } from 'rxjs';
import { CommonFormService } from '@common/lib/services/common-form.service';

@Component({
	selector: 'common-icon',
	templateUrl: './icon.component.html',
	styleUrls: ['./icon.component.scss']
})
export class IconComponent extends DynamicComponentBase<IconComponentFields, IconComponentOptions> {
	public iconClass$: Observable<string>;

	constructor(public formService: CommonFormService) {
		super();
	}

	onComponentLoaded(): void {
		this.iconClass$ = this.formService.getModelValue$<string>(this.fields?.iconPath?.modelPaths, this.options.iconClass);
		this.containerClass = this.options?.containerClass;
	}

	onComponentUpdated(): void {
		this.containerClass = this.options?.containerClass;
	}
}

interface IconComponentFields extends Record<string, FormField> {
	iconPath: FormField;
}

interface IconComponentOptions {
	iconClass: string;
	containerClass: string;
}

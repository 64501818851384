import { SessionUtility } from '@common/lib/utilities/session/session-utility';
import { ClientContext } from '@experience/app/models/client-context';
import { Injectable } from '@angular/core';
import { CacheUtility } from '@common/lib/utilities/cache/cache.utility';

@Injectable({
	providedIn: 'root'
})
export class AppSession extends SessionUtility<ClientContext> {
	constructor(cacheUtility: CacheUtility) {
		super(cacheUtility);
	}
}


import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DynamicComponentBase } from '../dynamic/dynamic-component.base';
import { FormField } from '@common/lib/models/form-field.model';
import { CommonFormService } from '@common/lib/services/common-form.service';

@Component({
	selector: 'common-divider',
	templateUrl: './divider.component.html',
	styleUrls: ['./divider.component.scss']
})
export class DividerComponent extends DynamicComponentBase<DividerComponentFields, DividerComponentOptions> {
	public classes: string;
	public hasText: boolean = false;
	public color: string = null;
	public visible$: Observable<boolean>;

	private defaultClasses: string = 'my-4 mx-0';


	constructor(public formService: CommonFormService) {
		super();
		this.classes = this.defaultClasses;
	}

	onComponentLoaded(): void {
		this.classes = this.options?.classes || this.defaultClasses;
		this.color = this.options?.color || null;
		this.visible$ = this.formService.getModelValue$(this.fields?.isVisible?.modelPaths).pipe(
			map((isVisible) => {
				if (!isVisible) {
					return true;
				}
				return isVisible.toString() === this.options?.isVisibleWhen;
			}),
		);
	}
}

interface DividerComponentFields extends Record<string, FormField> {
	isVisible: FormField;
}

interface DividerComponentOptions {
	classes: string;
	isVisibleWhen: string;
	text: string;
	color: string;
}

<div class="mb-4">
	<span class="block screen-header">Application Recieved!</span>
</div>
<div class="mb-4 pl-2">
	<p class="screen-text">
		Thank you for applying for an nbkc bank account. To expedite your application, please submit the items below.
		Have questions? Give us a call at 877.777.7298.
	</p>
	<p class="screen-text">
		Once we've received and reviewed all documents, we'll email you at {{ primaryApplicantEmail() }} to confirm the
		status of your application.
	</p>
</div>

<app-divider text="Items we need from you"></app-divider>

@if ($qualificationResults()?.conditions?.length > 0) {
	@for (condition of $qualificationResults().conditions; track condition.id) {
		@switch (condition.conditionType) {
			@case ('DocumentRequired') {
				<app-document-upload [$condition]="condition"></app-document-upload>
			}
			@case ('DocumentVerificationRequired') {
				<app-alloy-identity-verification [$condition]="condition"></app-alloy-identity-verification>
			}
			@default {}
		}
	}
} @else {
	<div class="card-div-container flex justify-center mb-6">
		<app-card
			bodyText="
				We're gathering a list of items we need from you. Check back in a bit and refresh this page in a few minutes to see what we need.
			"
			class="w-full"
		>
		</app-card>
	</div>
}

<app-divider text="What to expect from us"></app-divider>
<div class="card-div-container flex justify-center mb-4">
	<app-card title="Next Steps..." [bodyText]="nextStepsBodyText()" class="w-full"></app-card>
</div>

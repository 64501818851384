<div class="flex justify-center m-4">
	<button
		tabindex="0"
		id="next-button"
		class="max-w-xs w-full text-white rounded-full py-3 px-4 shadow-button text-lg"
		mat-raised-button
		color="primary"
		data-cy="next-button"
		[disabled]="false"
		(click)="onClickNextButton()"
	>
		Next
	</button>
</div>

<div class="flex">
	<i class="{{icon$ | async}} text-5xl"></i>
	<div class="ml-4 mt-1">
		<div>
			<span class="header-title">{{title$ | async}}</span>
		</div>
		<div>
			<span class="text-xs subtitle whitespace-pre-line">{{subtitle$ | async}}</span>
		</div>
	</div>
</div>

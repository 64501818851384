<div class="flex items-center p-2">
	@if (!$hideBackButton() && $currentRouteIndex() > 0) {
		<div
			tabindex="0"
			class="far fa-chevron-left text-2xl text-nbkc-dark-gray cursor-pointer pr-2 w-8 h-8 flex justify-center pl-0.5"
			(keydown.Enter)="navigateBack()"
			(click)="navigateBack()"
		></div>
	}

	<div class="max-w-lg w-full p-0">
		<span class="block screen-header p-0">{{ $title() }}</span>
	</div>
</div>

@if ($subtitle()) {
	<div class="mb-4 pl-2">
		<span class="sub-text">{{ $subtitle() }}</span>
	</div>
}

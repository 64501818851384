import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import _ from 'lodash';
import { Observable } from 'rxjs';
import { Tracker } from '@nbkc/tracker-angular';
import { NavigationBlockedEvent } from '@experience/app/tracking/events/navigation-blocked.event';
import { PresentationService } from '../presentation.service';

@Injectable({
	providedIn: 'root',
})
export class AvailableRouteGuard {
	constructor(
		private presentationService: PresentationService,
		private tracker: Tracker,
	) {}

	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot,
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		const currentDefinition = this.presentationService.getCurrentPresentationDefinition();

		if (typeof currentDefinition === 'undefined') {
			return true;
		} else if (
			_.some(currentDefinition.availableRoutes, (availableRoute) => availableRoute === `${next.routeConfig.path}`)
		) {
			return true;
		}
		this.tracker.event(new NavigationBlockedEvent(state.url, next.routeConfig.path));
		return false;
	}
}

import { Component, Injector } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import _ from 'lodash';
import { TEXT_MASKS } from '../../../constants/text-masks';
import { DynamicFormComponentBase } from '../../dynamic/dynamic-form-component.base';
import { FormField } from '@common/lib/models/form-field.model';

const cardNumberRegex: CardInfoRegex[] = [
	{
		mask: TEXT_MASKS.amexCreditCard,
		regex: new RegExp('^3[47]\\d{0,13}'),
		cardType: 'amex'
	},
	{
		mask: TEXT_MASKS.creditCard,
		regex: new RegExp('^(?:6011|65\\d{0,2}|64[4-9]\\d?)\\d{0,12}'),
		cardType: 'discover'
	},
	{
		mask: TEXT_MASKS.creditCard,
		regex: new RegExp('^(5[1-5]\\d{0,2}|22[2-9]\\d{0,1}|2[3-7]\\d{0,2})\\d{0,12}'),
		cardType: 'mastercard'
	},
	{
		mask: TEXT_MASKS.creditCard,
		regex: new RegExp('^4\\d{0,15}'),
		cardType: 'visa'
	},
];

@Component({
	selector: 'common-credit-card',
	templateUrl: './credit-card.component.html',
	styleUrls: ['./credit-card.component.scss']
})

export class CreditCardComponent extends DynamicFormComponentBase<CreditCardComponentFields, CreditCardComponentOptions> {
	public hideRequiredMark: boolean = false;
	public securityCodeMask: any = TEXT_MASKS.securityCode;
	public expirationDateMask: any = TEXT_MASKS.monthYearDate;
	public creditCardNumberMask: any = TEXT_MASKS.creditCard;
	public cardType: string = null;

	// eslint-disable-next-line max-len
	constructor(
		public injector: Injector,
		private matIconRegistry: MatIconRegistry,
		sanitizer: DomSanitizer
	) {
		super(injector);
		this.matIconRegistry.addSvgIcon(
			'visa',
			sanitizer.bypassSecurityTrustResourceUrl('assets/credit-card-logos/visa.svg'));
		this.matIconRegistry.addSvgIcon(
			'amex',
			sanitizer.bypassSecurityTrustResourceUrl('assets/credit-card-logos/amex.svg'));
		this.matIconRegistry.addSvgIcon(
			'discover',
			sanitizer.bypassSecurityTrustResourceUrl('assets/credit-card-logos/discover.svg'));
		this.matIconRegistry.addSvgIcon(
			'mastercard',
			sanitizer.bypassSecurityTrustResourceUrl('assets/credit-card-logos/mastercard.svg'));
	}

	onComponentLoaded(): void {
		this.registerAllControls();
		this.hideRequiredMark = !!(this.options?.hideRequiredMark) || false;
		const creditCardNumberControl = this.internalForm.get('cardNumberValue') as FormControl;
		this.calculateCardType(creditCardNumberControl.value);
		creditCardNumberControl.valueChanges.subscribe((val) => {
			this.calculateCardType(val);
		});
	}

	private calculateCardType(cardNumber: string): void {
		let foundMatch = false;
		if (cardNumber) {
			_.forEach(cardNumberRegex, (cardInfo) => {
				const isType = cardInfo.regex.test(cardNumber.replace(/\D/g, ''));
				if (isType) {
					foundMatch = true;
					this.cardType = cardInfo.cardType;
					this.creditCardNumberMask = cardInfo.mask;
				}
			});
		}
		this.cardType = (foundMatch) ? this.cardType : null;
		this.creditCardNumberMask = (foundMatch) ? this.creditCardNumberMask : TEXT_MASKS.creditCard;
	}
}

interface CardInfoRegex {
	mask: any;
	cardType: string;
	regex: RegExp;
}

interface CreditCardComponentFields extends Record<string, FormField> {

}

interface CreditCardComponentOptions {
	displaySelector: string;
	hideRequiredMark: string;
}

export const ONBOARDING_SUMMARY_CARDS = {
	InProgress: {
		title: 'Application In Progress',
		statusDescription: 'In Progress',
		description:
			'Your application is still in progress. We will review your application once you are finished. Click continue to finish it now.',
		icon: 'fa-user',
		headerIconColor: 'blue',
	},
	InReview: {
		title: 'Application Pending Review',
		statusDescription: 'In Review',
		description:
			'Your application is pending review. We may need additional information or documents from you. Click continue to provide those now.',
		icon: 'fa-file-magnifying-glass',
		headerIconColor: 'blue',
	},
	Approved: {
		title: 'Application Approved',
		statusDescription: 'Approved',
		description:
			'Your application was approved and your account has been created. Click continue to create your online banking account,' +
			'fund your account, and begin using your account now!',
		icon: 'fa-check',
		headerIconColor: 'green',
	},
	Denied: {
		title: 'Application Denied',
		statusDescription: 'Denied',
		description: 'We were unable to proceed with your application.',
		icon: 'fa-file-circle-xmark',
		headerIconColor: 'dark-gray',
	},
	Onboarded: {
		title: 'Application Approved',
		statusDescription: 'Onboarded',
		description: 'You’re all set. Everything you need can be found in online banking.',
		icon: 'fa-check',
		headerIconColor: 'bright-green',
	},
	OnHold: {
		title: 'Application On Hold',
		statusDescription: 'On Hold',
		description: 'Your application is currently on hold and will resume processing shortly.',
		icon: 'fa-user',
		headerIconColor: 'blue',
	},
	Archived: {
		title: 'Application Archived',
		statusDescription: 'Archived',
		description: 'This application was archived due to inactivity on',
		icon: 'fa-file-archive',
		headerIconColor: 'dark-gray',
	},
	Withdrawn: {
		title: 'Application Withdrawn',
		statusDescription: 'Withdrawn',
		description: 'This application was withdrawn on',
		icon: 'fa-arrow-left',
		headerIconColor: 'dark-gray',
	},
} as const;

export const NEW_APPLICATION_SUMMARY_CARDS = {
	Personal: {
		title: 'Personal Account',
		description: 'Open a personal deposit account.',
		icon: 'fa-user',
		headerIconColor: 'blue',
	},
	Business: {
		title: 'Business Account',
		description: 'Open a business deposit account.',
		icon: 'fa-city',
		headerIconColor: 'blue',
	},
} as const;

import { Component } from '@angular/core';
import { AuthenticationUtility } from '@common/lib/utilities/authentication/authentication.utility';
import { Router } from '@angular/router';

@Component({
	selector: 'experience-authentication-login',
	template: ``,
})
export class LoginComponent {
	constructor(private authenticationUtility: AuthenticationUtility, private router: Router) {
		this.authenticationUtility.handleAuthCallback$().subscribe({
			next: (url) => {
				if (url === '/') {
					this.router.navigate(['/']);
				}
				window.location.href = url;
			},
			error: () => {
				this.router.navigate(['/']);
			}
		});
	}
}

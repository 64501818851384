import { ApplicationInsightsTrackingProvider, ConsoleTrackingProvider, TrackerEvent } from '@nbkc/tracker';
import { ApplicationTrackerContext } from '@experience/app/tracking/application-tracker-context';

export class NavigationBlockedEvent extends TrackerEvent<ApplicationTrackerContext> {
	public name: string = 'navigation-blocked';
	public providers = [ConsoleTrackingProvider, ApplicationInsightsTrackingProvider];
	constructor(current: string, destination: string) {
		super();
		this.data = {
			current,
			destination
		};
	}

}

<form [formGroup]="internalForm" [ngClass]="containerClass || 'text-base flex justify-end mb-4'" >
	<mat-label [ngClass]="labelClass || 'mr-auto'">{{options?.label}}</mat-label>
	<mat-label *ngIf="options?.offLabel" class="mx-3 text-base">{{options?.offLabel}}</mat-label>
	<mat-slide-toggle
		color="primary"
		formControlName="enabled"
		data-cy="toggle-field">
	</mat-slide-toggle>
	<mat-label *ngIf="options?.onLabel" class="mx-3 text-base">{{options?.onLabel}}</mat-label>
</form>

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthenticationContext } from '@common/lib/utilities/authentication/authentication-context';
import { RedirectLoginResult } from '@auth0/auth0-spa-js';

@Injectable({
	providedIn: 'root'
})
export abstract class AuthenticationProviderBase {
	public onProviderAuthentication$: Observable<AuthenticationContext>;

	abstract login(redirectPath: string): void;

	abstract logout(): void;

	abstract refreshAuth(): Promise<any>;

	abstract handleAuthenticationCallback$(): Observable<RedirectLoginResult>;

	abstract onProviderStart(): void;

}

import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { AuthenticationUtility } from '@common/lib/utilities/authentication/authentication.utility';
import { navigateActivity } from '@common/lib/constants/activities';
import { LoadingService } from '@experience/app/services/loading.service';
import { PresentationService } from '@experience/app/services/presentation.service';
import { LegacyRoutingService } from '@experience/app/services/legacy-routing.service';
import { InternalStateService } from '@experience/app/services/internal-state.service';
import { Router } from '@angular/router';

@Component({
	selector: 'experience-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
	public previousRoute: string = '';
	public options: HeaderComponentOptions;

	public auth = inject(AuthenticationUtility);
	private loadingService = inject(LoadingService);
	private presentationService = inject(PresentationService);
	private router = inject(Router);
	private legacyRoutingService = inject(LegacyRoutingService);
	private internalStateService = inject(InternalStateService);
	private destroy$: Subject<void> = new Subject<void>();

	ngOnInit(): void {
		this.presentationService.currentPresentationDefinition$.subscribe((currentPresentationDefinition) => {
			this.options = { isHidden: currentPresentationDefinition?.header?.options?.isHidden };
		});
	}

	ngOnDestroy(): void {
		this.destroy$.next();
	}

	public goToAvailability(): void {
		if (this.internalStateService.$isOldExperience() === true) {
			this.loadingService.startLoadingActivity(navigateActivity);
			this.presentationService.resetExperience();
			this.legacyRoutingService.navigateToUrl$('/').subscribe();
		} else {
			this.router.navigateByUrl('/');
		}
	}
}

interface HeaderComponentOptions {
	isHidden: string;
}

<div class="mb-1 flex upload-bar-container">
	@if (showCompleteDisplay) {
		<span class="mr-2 italic text-xs">complete</span>
	} @else {
		<span class="mr-2 italic text-xs">uploading</span>
	}
	<mat-progress-bar class="flex-grow mt-2" mode="determinate" value="{{ currentProgressPercent }}"></mat-progress-bar>
	@if (showCompleteDisplay) {
		<i class="far fa-check-circle pl-6"></i>
	} @else {
		<span class="pl-6 italic">{{ currentProgressPercent }}%</span>
	}
</div>

import { Component } from '@angular/core';
import { DynamicComponentBase } from '../../dynamic/dynamic-component.base';
import { FormField } from '@common/lib/models/form-field.model';
import { RouteType } from '@common/lib/models/enum/route-type.enum';
import { PresentationService } from '@experience/app/services/presentation.service';

@Component({
	selector: 'common-back-button',
	templateUrl: './back-button.component.html',
	styleUrls: ['./back-button.component.scss']
})
export class BackButtonComponent extends DynamicComponentBase<BackButtonComponentFields, BackButtonComponentOptions> {
	constructor(private presentationService: PresentationService) {
		super();
	}

	public onComponentLoaded(): void {
	}

	public onComponentUpdated(): void {
	}

	public navigateToBack(): void {
		this.presentationService.navigateByRouteType(RouteType.previous);
	}
}

interface BackButtonComponentFields extends Record<string, FormField> {
	buttonTextPath: FormField;
}

interface BackButtonComponentOptions {
	disabled: string;
	buttonTextValue: string;
	iconClass: string;
}

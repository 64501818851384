import { TrackerError } from '@nbkc/tracker';
import { ApplicationTrackerContext } from '@experience/app/tracking/application-tracker-context';

export class NavigationError extends TrackerError<ApplicationTrackerContext> {
	public name: string = 'navigation-error';

	constructor(direction: string) {
		super(new Error('Navigation has failed!'));
		this.data = {
			direction
		};
	}
}

import { Component } from '@angular/core';

@Component({
	selector: 'experience-root',
	templateUrl: './app.component.html',
})
export class AppComponent {
	constructor() {
	}
}

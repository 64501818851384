import { Injectable, Signal, signal } from '@angular/core';
import { Application } from '@experience/app/models/onboarding/application.model';
import { Qualification } from '@experience/app/models/onboarding/qualification.model';

@Injectable({
	providedIn: 'root',
})
export class ApplicationStateService {
	private readonly state = {
		$id: signal<string>(''),
		$application: signal<Application>({} as Application),
		$qualification: signal<Qualification>({} as Qualification),
	};
	readonly $id = this.state.$id.asReadonly();
	readonly $application: Signal<Application> = this.state.$application.asReadonly();
	readonly $qualification = this.state.$qualification.asReadonly();

	constructor() {}

	setId(id: string): void {
		this.state.$id.set(id);
	}

	setApplication(application: Application): void {
		this.state.$application.set(application as Application);
	}

	updateApplicationValues(updates: Partial<Application>) {
		this.state.$application.set({
			...this.state.$application(),
			...updates,
		});
	}

	setQualification(qualification: Qualification): void {
		this.state.$qualification.set(qualification);
	}

	clearState(): void {
		this.setId('');
		this.setApplication({} as Application);
		this.setQualification({} as Qualification);
	}
}

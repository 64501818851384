import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	computed,
	DestroyRef,
	inject,
	model,
	OnInit,
	signal,
} from '@angular/core';
import { LoadingService } from '@experience/app/services/loading.service';
import { ApplicationStateService } from '@experience/app/services/application-state.service';
import { InternalStateService } from '@experience/app/services/internal-state.service';
import { BusinessApplicantFormGroup } from '@experience/app/components-new/business-applicant/business-applicant-form/business-applicant-form.group';
import {
	FormArray,
	FormBuilder,
	FormControl,
	FormGroup,
	FormsModule,
	ReactiveFormsModule,
	Validators,
} from '@angular/forms';
import { Address } from '@experience/app/models/onboarding/address.model';
import { TaxIdentification } from '@experience/app/models/onboarding/tax-identification.model';
import { ApplicantKind } from '@experience/app/models/enums/applicant-kind';
import { TaxIdentificationFormGroup } from '@experience/app/components-new/individual-applicant/individual-applicant-form/individual-applicant-form.group';
import { TaxIdentificationType } from '@experience/app/models/enums/tax-identification-type';
import { AddressFormGroup } from '@experience/app/components-new/address-form/address-form.group';
import { AddressType } from '@experience/app/models/enums/address-type';
import { AppDividerComponent } from '@experience/app/components-new/divider/app-divider.component';
import {
	MatError,
	MatFormField,
	MatLabel,
	MatHint,
	MAT_FORM_FIELD_DEFAULT_OPTIONS,
} from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { MatOption } from '@angular/material/autocomplete';
import { MatSelect } from '@angular/material/select';
import { TaxIdentificationComponent } from '@experience/app/components-new/tax-identification/tax-identification.component';
import { MaskedInputComponent } from '@experience/app/components-new/masked-input/masked-input.component';
import { ApplicationsApiService } from '@experience/app/services/applications-api.service';
import { FormControlPipe } from '@experience/app/shared/pipes/form-control-pipe/form-control.pipe';
import { AddressFormComponent } from '@experience/app/components-new/address-form/address-form.component';
import { Subscription } from 'rxjs';
import { AuthenticationUtility } from '@common/lib/utilities/authentication/authentication.utility';

@Component({
	selector: 'app-business-applicant-form',
	standalone: true,
	imports: [
		ReactiveFormsModule,
		AppDividerComponent,
		MatError,
		MatFormField,
		MatInput,
		MatLabel,
		MatSlideToggle,
		FormsModule,
		MatOption,
		MatSelect,
		TaxIdentificationComponent,
		MatHint,
		MaskedInputComponent,
		FormControlPipe,
		AddressFormComponent,
	],
	templateUrl: './business-applicant-form.component.html',
	styleUrl: './business-applicant-form.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
			useValue: {
				appearance: 'outline',
				subscriptSizing: 'dynamic',
			},
		},
	],
})
export class BusinessApplicantFormComponent implements OnInit, AfterViewInit {
	private loadingService = inject(LoadingService);
	private auth = inject(AuthenticationUtility);
	private applicationStateService = inject(ApplicationStateService);
	private internalStateService = inject(InternalStateService);
	private fb = inject(FormBuilder);
	private applicationsApiService = inject(ApplicationsApiService);
	private destroyRef = inject(DestroyRef);

	$application = this.applicationStateService.$application;
	$lookups = this.internalStateService.$lookups;

	businessApplicantForm: FormGroup<BusinessApplicantFormGroup>;
	$applicantNameOrDefault = computed(() => this.$application().business?.name || 'your business');
	$hasDbaName = model<boolean>(false);
	$hasEIN = model<boolean>(true);
	$usePhysicalAddressAsMailingAddress = model<boolean>(true);
	$taxIdentification = signal<FormGroup<TaxIdentificationFormGroup>>({} as FormGroup<TaxIdentificationFormGroup>);
	$physicalAddress = signal<FormGroup<AddressFormGroup>>({} as FormGroup<AddressFormGroup>);
	$mailingAddress = signal<FormGroup<AddressFormGroup>>({} as FormGroup<AddressFormGroup>);

	subscription = new Subscription();

	ngOnInit() {
		this.loadingService.stopAllLoadingActivities();
		const applicant = this.$application()?.business;
		const physicalAddress = applicant?.addresses?.[0] || ({} as Address);
		const mailingAddress = applicant?.addresses?.[1] || ({} as Address);
		const taxIdentification = applicant?.taxIdentification || ({} as TaxIdentification);

		this.businessApplicantForm = this.fb.group<BusinessApplicantFormGroup>(
			{
				kind: new FormControl(ApplicantKind.Business),
				email: new FormControl(''),
				name: new FormControl(applicant?.name || '', [Validators.required, Validators.maxLength(40)]),
				doingBusinessAs: new FormControl(applicant?.doingBusinessAs || '', [Validators.maxLength(36)]),
				structure: new FormControl(applicant?.structure || '', [Validators.required]),
				registeredState: new FormControl(applicant?.registeredState || '', [Validators.required]),
				taxIdentification: this.fb.group<TaxIdentificationFormGroup>({
					type: new FormControl(
						taxIdentification?.type || TaxIdentificationType.EmployerIdentificationNumber,
						[Validators.required],
					),
					//eslint-disable-next-line id-blacklist
					number: new FormControl(taxIdentification?.number || '', [Validators.required]),
					numberConfirmation: new FormControl(taxIdentification?.number || '', [Validators.required]),
				}),
				description: new FormControl(applicant?.description || '', [
					Validators.required,
					Validators.minLength(80),
					Validators.maxLength(500),
				]),
				naicsCode: new FormControl(applicant?.naicsCode || '', [Validators.required]),
				phoneNumber: new FormControl(applicant?.phoneNumber || '', [Validators.required]),
				website: new FormControl(applicant?.website || '', [Validators.required]),
				addresses: this.fb.array<FormGroup<AddressFormGroup>>([
					this.fb.group<AddressFormGroup>(
						{
							type: new FormControl(AddressType.Physical),
							address1: new FormControl(physicalAddress?.address1 || '', [
								Validators.required,
								Validators.maxLength(40),
							]),
							address2: new FormControl(physicalAddress?.address2 || '', Validators.maxLength(40)),
							city: new FormControl(physicalAddress?.city || '', [
								Validators.required,
								Validators.maxLength(25),
							]),
							state: new FormControl(physicalAddress?.state || '', Validators.required),
							postalCode: new FormControl(physicalAddress?.postalCode || '', Validators.required),
						},
						{ updateOn: 'change' },
					),
					this.fb.group<AddressFormGroup>(
						{
							type: new FormControl(AddressType.Mailing),
							address1: new FormControl(mailingAddress?.address1 || '', [
								Validators.required,
								Validators.maxLength(40),
							]),
							address2: new FormControl(mailingAddress?.address2 || '', Validators.maxLength(40)),
							city: new FormControl(mailingAddress?.city || '', [
								Validators.required,
								Validators.maxLength(25),
							]),
							state: new FormControl(mailingAddress?.state || '', Validators.required),
							postalCode: new FormControl(mailingAddress?.postalCode || '', Validators.required),
						},
						{ updateOn: 'change' },
					),
				]),
			},
			{ updateOn: 'blur' },
		);

		this.setEmail();
		this.setFormGroupSignals();

		// Set use mailing address as physical address toggle in template
		if (
			applicant?.addresses?.[0]?.address1 &&
			applicant?.addresses?.[0]?.address1 !== applicant?.addresses?.[1]?.address1
		) {
			this.$usePhysicalAddressAsMailingAddress.set(false);
		}

		if (this.internalStateService.$reviewModeEnabled()) {
			this.businessApplicantForm.markAllAsTouched();
			this.businessApplicantForm.updateValueAndValidity();
		}

		this.destroyRef.onDestroy(() => this.subscription.unsubscribe());
	}

	ngAfterViewInit(): void {
		this.subscribeToFormChanges();
	}

	setEmail(): void {
		this.subscription.add(
			this.auth.authenticationContext$.subscribe((context) => {
				const applicantEmail = context.user?.email || '';
				this.businessApplicantForm.get('email').setValue(applicantEmail);
			}),
		);
	}

	setFormGroupSignals(): void {
		this.$taxIdentification.set(this.taxIdentification);
		this.$physicalAddress.set(this.getAddressGroup(0));
		this.$mailingAddress.set(this.getAddressGroup(1));
	}

	subscribeToFormChanges(): void {
		this.subscription.add(
			this.businessApplicantForm.valueChanges.subscribe(() => {
				this.updateApplicant();
			}),
		);
	}

	onDbaNameChange(): void {
		if (!this.$hasDbaName()) {
			this.businessApplicantForm.get('doingBusinessAs').setValue('');
		}
	}

	onHasEINChange(): void {
		this.taxIdentification.get('number').setValue('');
		this.taxIdentification.get('numberConfirmation').setValue('');
	}

	onChangeAddressType(): void {
		const mailingAddress = this.getAddressGroup(1);
		if (this.$usePhysicalAddressAsMailingAddress()) {
			const physicalAddress = this.getAddressGroup(0);
			mailingAddress.patchValue(physicalAddress.getRawValue());
		} else {
			mailingAddress.reset();
		}
		mailingAddress.get('type').setValue(AddressType.Mailing);
	}

	updateApplicant(): void {
		const formApplicant = this.businessApplicantForm.getRawValue();

		let mailingAddress = formApplicant.addresses[1];
		if (this.$usePhysicalAddressAsMailingAddress()) {
			mailingAddress = { ...formApplicant.addresses[0] };
			mailingAddress.type = AddressType.Mailing;
			formApplicant.addresses[1] = mailingAddress;
		}

		const businessApplicant = this.$application().business;
		const updatedApplicant = { ...businessApplicant, ...formApplicant };
		this.applicationStateService.updateApplicationValues({ business: updatedApplicant });
	}

	saveApplication(): void {
		this.updateApplicant();
		this.applicationsApiService.saveApplication(this.$application()).subscribe();
	}

	get taxIdentification(): FormGroup<TaxIdentificationFormGroup> {
		return this.businessApplicantForm.get('taxIdentification') as FormGroup;
	}

	getAddressGroup(index: number): FormGroup<AddressFormGroup> {
		const addresses = this.businessApplicantForm.get('addresses') as FormArray;
		return addresses.at(index) as FormGroup;
	}
}

import { ActionCollection } from '@common/lib/models/actions/action.types';

export class ReviewItem {
	modelPath: string;
	message: string;
	ruleName: string;
	targetScreen: string;
	targetElementId: string;
	actions: ActionCollection;
}

<div class="flex items-center">
	<input
		#maskedInput
		[formControl]="formControl()"
		(blur)="checkForErrors()"
		(focus)="onInputFocus()"
		[allowNegativeNumbers]="currentMask()?.config?.allowNegativeNumbers || false"
		[apm]="currentMask()?.config?.apm || false"
		[clearIfNotMatch]="currentMask()?.config?.clearIfNotMatch || false"
		[decimalMarker]="'.'"
		[dropSpecialCharacters]="currentMask()?.config?.dropSpecialCharacters || false"
		[hiddenInput]="hideProtected()"
		[keepCharacterPositions]="currentMask()?.config?.keepCharacterPositions || false"
		[leadZeroDateTime]="currentMask()?.config?.leadZeroDateTime || true"
		[leadZero]="currentMask()?.config?.leadZero || false"
		[mask]="mask()"
		[ngStyle]="{ opacity: disabled ? 0.5 : 1 }"
		[patterns]="maskPatterns()"
		[placeholder]="currentMask()?.config?.placeHolderCharacter || ''"
		[prefix]="currentMask()?.config?.prefix || ''"
		[showMaskTyped]="currentMask()?.config?.showMaskTyped || false"
		[showTemplate]="currentMask()?.config?.showTemplate || false"
		[suffix]="currentMask()?.config?.suffix || ''"
		[thousandSeparator]="','"
		[triggerOnMaskChange]="currentMask()?.config?.triggerOnMaskChange || false"
		class="mat-input-element mat-mdc-form-field-input-control"
		cdkMonitorElementFocus
		data-cy="input-field"
	/>

	@if (maskType() === 'ssn') {
		<button
			type="button"
			mat-icon-button
			matSuffix
			class="password-visibility-toggle"
			(click)="toggleShowProtected()"
		>
			<i
				class="far"
				[ngClass]="hideProtected() ? 'fa-eye-slash' : 'fa-eye'"
				[attr.aria-label]="hideProtected() ? 'Show password' : 'Hide password'"
			></i>
		</button>
	}
</div>

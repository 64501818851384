import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GeneralError } from '@experience/app/tracking/events/general.error';
import { HttpError } from '@experience/app/tracking/events/http.error';
import { Tracker } from '@nbkc/tracker-angular';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class ErrorInterceptor implements HttpInterceptor {
	constructor(public tracker: Tracker) {
	}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(req).pipe(
			catchError((event) => {
				if (event instanceof HttpErrorResponse) {
					try {
						this.tracker.error(new HttpError({ error: event }));
					} catch (error) {
						this.tracker.error(new GeneralError(error));
					}
				}
				return throwError(event);
			})
		);
	}
}

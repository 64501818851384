import { createAutoCorrectedDatePipe, createNumberMask, emailMask } from 'text-mask-addons/dist/textMaskAddons';

const pipes = {
	// Type Definition file contains a bug. This signature is correct.
	/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
	// @ts-ignore
	datePipePast: createAutoCorrectedDatePipe('mm/dd/yyyy HH:MM', { minYear: 1900, maxYear: new Date().getFullYear() }),
	/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
	// @ts-ignore
	datePipeFuture: createAutoCorrectedDatePipe('mm/dd/yyyy HH:MM', { minYear: new Date().getFullYear() }),
	datePipeAll: createAutoCorrectedDatePipe('mm/dd/yyyy HH:MM'),
};

const customMaskDefinitions = {
	percentMask: createNumberMask({
		suffix: '%', // This will put the dollar sign at the end, with a space.,
		prefix: '',
		allowDecimal: true,
		integerLimit: 2,
		decimalLimit: 2,
	}),
	currencyMask: createNumberMask({
		prefix: '$',
		allowDecimal: true,
		integerLimit: 8,
	})
};

const TEXT_MASK_CONFIGS = {
	percentMaskingConfig: { mask: customMaskDefinitions.percentMask, placeholderChar: '\u2000' },
	singleLetterMaskConfig: {
		mask: [/[a-z]/i],
		placeholderChar: '\u2000',
	},
	emailMaskConfig: {
		mask: emailMask,
	},
	decimalMaskConfig: {
		mask: createNumberMask({ prefix: '', allowDecimal: true }),
		placeholderChar: '\u2000',
	},
	numberMaskConfig: {
		mask: createNumberMask({ prefix: '', allowDecimal: false }),
		placeholderChar: '\u2000',
	},
	zipMaskConfig: {
		mask: createNumberMask({
			prefix: '', allowDecimal: false, integerLimit: 5, allowLeadingZeroes: true, includeThousandsSeparator: false
		}),
		placeholderChar: '\u2000'
	},
	ageMaskConfig: {
		mask: createNumberMask({ prefix: '', allowDecimal: false, integerLimit: 2 }),
		placeholderChar: '\u2000',
	},
	securityCodeMaskConfig: {
		mask: createNumberMask({ prefix: '', allowDecimal: false, integerLimit: 3 }),
		placeholderChar: '\u2000',
	},
	fourDigitCurrencyConfig: {
		mask: createNumberMask({ prefix: '$', allowDecimal: true, integerLimit: 4 }),
		placeholderChar: '\u2000',
	},
	phoneMaskConfig: {
		mask: ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
		placeholderChar: '\u2000',
	},
	ssnMaskConfig: {
		mask: [/[\d|*]/, /[\d|*]/, /[\d|*]/, '-', /[\d|*]/, /[\d|*]/, '-', /[\d|*]/, /[\d|*]/, /[\d|*]/, /[\d|*]/],
		placeholderChar: '\u2000',
		guide: false,
	},
	einMaskConfig: {
		mask: [/[\d|*]/, /[\d|*]/, '-', /[\d|*]/, /[\d|*]/, /[\d|*]/, /[\d|*]/, /[\d|*]/, /[\d|*]/, /[\d|*]/],
		placeholderChar: '\u2000',
		guide: false,
	},
	currencyMaskConfig: {
		mask: customMaskDefinitions.currencyMask,
		placeholderChar: '\u2000',
	},
	dateMaskAllConfig: {
		mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
		pipe: pipes.datePipeAll,
		keepCharPositions: true,
		placeholderChar: '\u2000',
	},
	dateMaskPastConfig: {
		mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
		pipe: pipes.datePipePast,
		keepCharPositions: true,
		placeholderChar: '\u2000',
	},
	dateMaskFutureConfig: {
		mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
		pipe: pipes.datePipeFuture,
		keepCharPositions: true,
		placeholderChar: '\u2000',
	},
	monthYearDateMaskConfig: {
		mask: [/\d/, /\d/, '/', /\d/, /\d/],
		pipe: pipes.datePipePast,
		keepCharPositions: true,
		placeholderChar: '\u2000',
	},
	creditCardNumber: {
		mask: [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/],
		placeholderChar: '\u2000',
		guide: false,
	},
	amexCreditCardNumber: {
		mask: [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/],
		placeholderChar: '\u2000',
		guide: false,
	}
};

export const TEXT_MASKS: TextMasks = {
	currency: TEXT_MASK_CONFIGS.currencyMaskConfig,
	dateAll: TEXT_MASK_CONFIGS.dateMaskAllConfig,
	datePast: TEXT_MASK_CONFIGS.dateMaskPastConfig,
	dateFuture: TEXT_MASK_CONFIGS.dateMaskFutureConfig,
	decimal: TEXT_MASK_CONFIGS.decimalMaskConfig,
	email: TEXT_MASK_CONFIGS.emailMaskConfig,
	// eslint-disable-next-line id-blacklist
	number: TEXT_MASK_CONFIGS.numberMaskConfig,
	phone: TEXT_MASK_CONFIGS.phoneMaskConfig,
	ssn: TEXT_MASK_CONFIGS.ssnMaskConfig,
	ein: TEXT_MASK_CONFIGS.einMaskConfig,
	age: TEXT_MASK_CONFIGS.ageMaskConfig,
	fourDigitCurrency: TEXT_MASK_CONFIGS.fourDigitCurrencyConfig,
	percent: TEXT_MASK_CONFIGS.percentMaskingConfig,
	zip: TEXT_MASK_CONFIGS.zipMaskConfig,
	singleLetter: TEXT_MASK_CONFIGS.singleLetterMaskConfig,
	monthYearDate: TEXT_MASK_CONFIGS.monthYearDateMaskConfig,
	securityCode: TEXT_MASK_CONFIGS.securityCodeMaskConfig,
	creditCard: TEXT_MASK_CONFIGS.creditCardNumber,
	amexCreditCard: TEXT_MASK_CONFIGS.amexCreditCardNumber
};

export interface TextMasks {
	currency: any;
	dateAll: any;
	dateFuture: any;
	datePast: any;
	decimal: any;
	email: any;
	// eslint-disable-next-line id-blacklist
	number: any;
	phone: any;
	ssn: any;
	ein: any;
	age: any;
	fourDigitCurrency: any;
	percent: any;
	zip: any;
	singleLetter: any;
	monthYearDate: any;
	securityCode: any;
	creditCard: any;
	amexCreditCard: any;
}

<div>
	<form [formGroup]="internalForm">
		<mat-form-field class="w-full text-opacity-0 {{options.formFieldClasses}}" appearance="outline" [hideRequiredMarker]="hideRequiredMark">
			<mat-label>{{options?.label}}</mat-label>
			<mat-select (selectionChange)="onChange($event)" [formControl]="chipsCtrl">
				<mat-option *ngFor="let item of availableOptions$ | async" value="{{getValue(item)}}">
					{{getDisplay(item)}}
				</mat-option>
			</mat-select>
		</mat-form-field>
		<mat-chip-listbox #chipsSelect>
			<mat-chip-option
				class="{{options.matChipClasses}}"
				*ngFor="let item of selected$ | async"
				[removable]="removable"
				(removed)="remove(item)">
				{{item}}
				<mat-icon matChipRemove *ngIf="removable" class="fas fa-times-circle"></mat-icon>
			</mat-chip-option>
		</mat-chip-listbox>
	</form>
</div>

import { Injectable } from '@angular/core';
import { BlobServiceClient } from '@azure/storage-blob';
import { DocumentInfo } from '@common/lib/models/document-info';

@Injectable({
	providedIn: 'root'
})
export class StorageUtility {

	public async postDocuments$(documents: DocumentInfo[], containerName: string, sasUrl: string): Promise<void> {
		try {
			const containerClient = new BlobServiceClient(sasUrl).getContainerClient(containerName);
			const promises = documents.map((doc) => {
				const promise = containerClient.getBlockBlobClient(doc.id).uploadBrowserData(doc.file);
				return promise;
			});
			await Promise.all(promises);
		} catch (error) {
			// throw error event
		}
	}
}

export class ApplyEntryPointResource {
	applicationName: string;
	applicationId: string;
	version: string;
	displayName: string;
	displayDescription: string;
	actionDescription: string;
	actionUri: string;
	isVisible: boolean;
}

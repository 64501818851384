import { Component, Injector } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Address } from '@common/lib/models/address';
import { TEXT_MASKS } from '@common/lib/constants/text-masks';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { DynamicFormComponentBase } from '../../dynamic/dynamic-form-component.base';
import { FormField } from '@common/lib/models/form-field.model';

@Component({
	selector: 'common-address',
	templateUrl: './address.component.html',
	styleUrls: ['./address.component.scss']
})
export class AddressComponent extends DynamicFormComponentBase<AddressComponentFields, AddressComponentOptions> {

	public states$: Observable<Record<string, string>[]>;
	public zipMask: any = TEXT_MASKS.zip;
	public address1Control: FormControl;
	public hideRequiredMark: boolean = false;
	public filteredOptions: Observable<Address[]>;
	public visible$: Observable<boolean>;

	constructor(
		public injector: Injector
	) {
		super(injector);
	}

	onComponentLoaded(): void {
		this.registerAllControls({ isDisabled: this.options?.isDisabled, debounceTime: 125 });
		this.states$ = this.formService.getModelValue$<Record<string, string>[]>(this.fields?.stateOptions?.modelPaths);
		this.hideRequiredMark = !!(this.options?.hideRequiredMark) || false;
		this.address1Control = this.internalForm.get('address1') as FormControl;
		this.visible$ = this.formService.getModelValue$(this.fields?.isVisible?.modelPaths).pipe(
			map((isVisible) => {
				if (!isVisible) {
					return true;
				}
				return isVisible.toString() === this.options?.isVisibleWhen;
			})
		);
		this.filteredOptions = this.address1Control.valueChanges.pipe(
			debounceTime(125),
			distinctUntilChanged(),
			switchMap<string, Observable<Address[]>>((value) => {
				if (!value) {
					return of([]);
				}
				return this.formService.searchForAddress$(value);
			})
		);
	}

	public addressSelected(selectedEvent: MatAutocompleteSelectedEvent): void {
		(this.internalForm.get('address1') as FormControl).setValue(selectedEvent.option?.value?.address1 || '');
		if (this.fields.address2) {
			(this.internalForm.get('address2') as FormControl).setValue(selectedEvent.option?.value?.address2 || '');

		}
		(this.internalForm.get('city') as FormControl).setValue(selectedEvent.option?.value?.city || '');
		(this.internalForm.get('state') as FormControl).setValue(selectedEvent.option?.value?.state || '');
		(this.internalForm.get('zip') as FormControl).setValue(selectedEvent.option?.value?.zip || '');
	}
}

interface AddressComponentFields extends Record<string, FormField> {
	stateOptions: FormField;
	isVisible: FormField;
	address1: FormField;
	address2: FormField;
	city: FormField;
	state: FormField;
	zip: FormField;
}

interface AddressComponentOptions {
	hideRequiredMark: string;
	isVisibleWhen: string;
	address1Label: string;
	address1Subtext: string;
	address2Label: string;
	address2Subtext: string;
	cityLabel: string;
	citySubtext: string;
	stateLabel: string;
	stateSubtext: string;
	stateDisplaySelector: string;
	stateValueSelector: string;
	zipLabel: string;
	zipSubtext: string;
	isDisabled: boolean;
	inputClass: string;
	selectClass: string;
}

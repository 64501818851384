import {
	Component,
	HostListener,
	OnDestroy,
	OnInit,
	ChangeDetectorRef,
	AfterViewChecked,
	inject,
	computed,
} from '@angular/core';
import { PresentationDefinition } from '@common/lib/models/presentation-definition';
import { Subscription } from 'rxjs';
import { RouterOutlet } from '@angular/router';
import { slideInAnimation } from '@common/lib/animations/slide-in-out';
import { PresentationService } from '@experience/app/services/presentation.service';
import { AnimationCache } from '@experience/app/models/animation-cache.model';
import { ApplicationStateService } from '@experience/app/services/application-state.service';
import { knownApplicationStatus } from '@common/lib/constants/known-application-statuses';

@Component({
	selector: 'experience-body',
	templateUrl: './body.component.html',
	styleUrls: ['./body.component.scss'],
	animations: [slideInAnimation],
})
export class BodyComponent implements OnInit, OnDestroy, AfterViewChecked {
	private applicationStateService = inject(ApplicationStateService);
	private presentationService = inject(PresentationService);
	private changeRef = inject(ChangeDetectorRef);

	public presentationDefinition$: PresentationDefinition = null;
	public options: BodyComponentOptions;
	public $hiddenTodoList = computed<boolean>(
		() =>
			this.$application().status === knownApplicationStatus.OnHold ||
			this.$application().status === knownApplicationStatus.Denied,
	);
	private animationCache: AnimationCache;
	private animationStateSubscription: Subscription;
	private $application = this.applicationStateService.$application;

	constructor() {}

	@HostListener('window:focus', ['$event'])
	public onWindowFocus() {
		if (this.animationCache?.direction) {
			this.doneAnimating();
		}
	}

	ngOnDestroy(): void {
		this.animationStateSubscription?.unsubscribe();
	}

	ngOnInit(): void {
		this.presentationDefinition$ = this.presentationService.getCurrentPresentationDefinition();

		this.presentationService.currentPresentationDefinition$.subscribe((presentationDefinition) => {
			this.options = {
				containerClass: presentationDefinition?.body?.options?.containerClass,
				hideTodoList: presentationDefinition?.body?.options?.hideTodoList,
			};
		});
	}

	//This is a workaround to eliminate "ExpressionChangedAfterItHasBeenCheckedError" being thrown in development mode
	//when determineRouteAnimationState gets called
	ngAfterViewChecked(): void {
		this.changeRef.detectChanges();
	}

	public determineRouteAnimationState(outlet: RouterOutlet): string {
		if (this.animationCache) {
			if (outlet?.isActivated && this.animationCache.screenIndex !== outlet.activatedRouteData.screenIndex) {
				this.animationCache = {
					direction:
						outlet.activatedRouteData.screenIndex > this.animationCache.screenIndex ? 'forward' : 'back',
					screenIndex: outlet.activatedRouteData.screenIndex,
				};
			}
		} else {
			this.animationCache = {
				direction: 'forward',
				screenIndex: outlet.activatedRouteData.screenIndex,
			};
		}

		return this.animationCache.direction;
	}

	public doneAnimating(): void {
		this.animationCache.direction = null;
	}
}

interface BodyComponentOptions {
	containerClass: string;
	hideTodoList: string;
}

import { AdapterBase, AdapterOptions, CollectionAdapterOptions } from '@common/lib/utilities/adapter-base';
import { PresentationDefinition } from '@common/lib/models/presentation-definition';
import { ElementResourceToComponentDefinitionAdapter } from './element-resource-to-component-definition.adapter';
import { ScreenResource } from '@common/lib/models/resource/screen-resource';

export class ScreenResourceToPresentationDefinitionAdapter extends AdapterBase<ScreenResource, PresentationDefinition> {
	// eslint-disable-next-line max-len
	private elementResourceToComponentDefinitionAdapter: ElementResourceToComponentDefinitionAdapter = new ElementResourceToComponentDefinitionAdapter();

	adapt(source: ScreenResource, options: ScreenResourceToPresentationDefinitionAdapterOptions): PresentationDefinition {
		const dest = new PresentationDefinition();
		dest.name = source?.name;
		dest.description = source?.description;
		dest.displayName = source?.displayName;
		dest.requiresContext = source?.requiresContext;
		dest.route = `${source?.name}`;
		source.elements?.forEach((element) => {
			if (element.type === 'body') {
				dest.body = this.elementResourceToComponentDefinitionAdapter.adapt(element, { screenName: dest.name, elementMap: options.elementMap });
			} else if (element.type === 'header') {
				dest.header = this.elementResourceToComponentDefinitionAdapter.adapt(element, { screenName: dest.name, elementMap: options.elementMap });
			} else if (element.type === 'footer') {
				dest.footer = this.elementResourceToComponentDefinitionAdapter.adapt(element, { screenName: dest.name, elementMap: options.elementMap });
			}
		});
		dest.next = source?.routeDefinition?.nextScreen ? `${source?.routeDefinition?.nextScreen}` : null;
		dest.previous = source?.routeDefinition?.previousScreen ? `${source?.routeDefinition?.previousScreen}` : null;
		dest.availableRoutes = options?.availableRoutes.map((screen) => `${screen}`);
		return dest;
	}

	adaptCollection(
		sourceCollection: Array<ScreenResource>,
		options: ScreenResourceToPresentationDefinitionCollectionAdapterOptions
	): Array<PresentationDefinition> {
		return super.adaptCollection(sourceCollection, options);
	}
}

interface ScreenResourceToPresentationDefinitionAdapterOptions extends AdapterOptions {
	availableRoutes?: string[];
	elementMap?: Record<string, string>;
}

interface ScreenResourceToPresentationDefinitionCollectionAdapterOptions extends CollectionAdapterOptions {
	availableRoutes?: string[];
	elementMap?: Record<string, string>;
}

import { AdapterBase} from '@common/lib/utilities/adapter-base';
import { VerifyIdRequestResource } from '@common/lib/models/resource/events/client/verify-id-request-resource';
import { VerifyId } from '../models/verify-id.model';

export class VerifyIdAdapter extends AdapterBase<VerifyId, VerifyIdRequestResource> {
	adapt(source: VerifyId): VerifyIdRequestResource {
		const dest = new VerifyIdRequestResource();
		dest.externalId = source.externalId;
		dest.conditionId = source.conditionId;
		dest.verifyType = source.verifyType;
		return dest;
	}
}

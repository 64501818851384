import { Component, Injector } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormField } from '@common/lib/models/form-field.model';
import { DynamicFormComponentBase } from '../dynamic/dynamic-form-component.base';

@Component({
	selector: 'common-validation-badge',
	templateUrl: './validation-badge.component.html',
	styleUrls: ['./validation-badge.component.scss']
})
export class ValidationBadgeComponent extends DynamicFormComponentBase<ValidationBadgeComponentFields, ValidationBadgeComponentOptions> {
	public form: FormGroup;
	public loaded: boolean = false;

	constructor(
		public injector: Injector
	) {
		super(injector);
	}

	onComponentLoaded(): void {
		this.registerAllControls();
		this.loaded = true;
	}

	public isValid(): boolean {
		return this.internalForm.get(this.fields.validationTarget?.name)?.valid ?? false;
	}
}
interface ValidationBadgeComponentFields extends Record<string, FormField> {
	validationTarget: FormField;
}

interface ValidationBadgeComponentOptions {
	classes: string;
	textClasses: string;
}

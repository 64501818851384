import { SessionContextBase } from '@common/lib/utilities/session/session-context-base';

/**
 * Marks a session value to be cached.
 *
 * @constructor
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export const LocalStorage =
	() =>
	(target: SessionContextBase, propertyKey: string): any => {
		if (!target.localStorageKeys) {
			target.localStorageKeys = [];
		}
		target.localStorageKeys.push(`${propertyKey}`);
	};

import { Component, Injector, Input } from '@angular/core';
import { DynamicFormComponentBase } from '../../../dynamic/dynamic-form-component.base';
import { FormField } from '@common/lib/models/form-field.model';

@Component({
	selector: 'common-card-footer',
	templateUrl: './card-footer.component.html',
	styleUrls: ['./card-footer.component.scss']
})
export class CardFooterComponent extends DynamicFormComponentBase<CardFooterComponentFields, CardFooterComponentOptions> {
	@Input() data: CardInputData;

	constructor(
		public injector: Injector
	) {
		super(injector);
	}
}

interface CardFooterComponentFields extends Record<string, FormField> {

}

interface CardFooterComponentOptions {

}

interface CardInputData {
	expanded: boolean;
}

import { marked } from 'marked';

type SupportedTextEngines = 'markdown';

export abstract class TextTransformUtility {
	public static render(text: string, engine: SupportedTextEngines = 'markdown') {
		switch (engine) {
			case 'markdown':
				return marked(text);
		}
	}
}

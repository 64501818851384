import { Component, Injector } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { DynamicFormComponentBase } from '@common/lib/components/dynamic/dynamic-form-component.base';
import { FormField } from '@common/lib/models/form-field.model';
import { Observable } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import _ from 'lodash';

@Component({
	selector: 'common-checkbox-group',
	templateUrl: './checkbox-group.component.html',
	styleUrls: ['./checkbox-group.component.scss']
})
export class CheckboxGroupComponent extends DynamicFormComponentBase<CheckboxGroupComponentFields, CheckboxGroupComponentOptions> {
	public choices$: Observable<string[]>;

	constructor(
		public injector: Injector
	) {
		super(injector);
	}

	onComponentLoaded(): void {
		this.registerAllControls();
		this.containerClass = this.options?.containerClass;

		this.choices$ = this.formService
			.getModelValue$<Record<string, any>[] | string[]>(this.fields?.choices.modelPaths)
			.pipe(
				takeUntil(this.destroy$),
				map((allChoices) => {
					let flattenedChoices = [];

					allChoices.forEach((choice) => {
						if (Array.isArray(choice)) {
							flattenedChoices = [...flattenedChoices, ...choice];
						} else {
							flattenedChoices.push(choice);
						}
					});

					return flattenedChoices;
				})
			);
	}

	public getCheckboxDisplay(item: Record<string, any> | string): string {
		if (this.options?.displaySelectors) {
			const allDisplayValues: string[] = [];

			this.options?.displaySelectors.forEach((selector) => {
				allDisplayValues.push(item[selector]);
			});

			return allDisplayValues.join(' ');
		}

		return item as string;
	}

	public getCheckboxValue(item: Record<string, any> | string): string {
		if (this.options?.valueSelector) {
			return item[this.options?.valueSelector];
		}

		return item as string;
	}

	public isChecked(item: Record<string, any> | string): Observable<boolean> {
		return this.formService.getModelValue$<string[]>(this.fields?.selected.modelPaths)
			.pipe(
				map((selectedValues) => {
					const checkboxValue = this.getCheckboxValue(item);
					return _.some(selectedValues, (value) => value === checkboxValue);
				})
			);
	}

	public toggleCheckbox(event: MatCheckboxChange): void {
		this.formService.getModelValue$<string[]>(this.fields?.selected.modelPaths)
			.pipe(take(1))
			.subscribe((selectedValues) => {
				if (!selectedValues) {
					selectedValues = [];
				}

				if (event.checked && !selectedValues.some((value) => value === event.source.value)) {
					selectedValues.push(event.source.value);
				} else {
					_.remove(selectedValues, (value) => value === event.source.value);
				}

				this.formService.setModelValue(selectedValues, this.fields?.selected.modelPaths);
			});
	}

}

interface CheckboxGroupComponentFields extends Record<string, FormField> {
	selected: FormField;
	choices: FormField;
}

interface CheckboxGroupComponentOptions {
	label: string;
	valueSelector: string;
	displaySelectors: string[];
	containerClass: string;
}

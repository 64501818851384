<div>
	<form [formGroup]="internalForm">
		<mat-form-field class="w-full" appearance="outline" [hideRequiredMarker]="hideRequiredMark">
			<mat-label>{{options?.label}}</mat-label>
			<input matInput formControlName="ssnMasked"
				   class="{{options.inputClass}}"
				   [readonly]="options.isDisabled"
				   [textMask]="socialSecurityMask" (blur)="updateFormValue()"
				   (focus)="unmaskSSN()"
					data-cy="input-field">
		</mat-form-field>
		<div class="text-10.5px pt-2.5 pb-2.5">
			<div *ngIf="options?.subtext" class="subtext">
				{{options.subtext}}
			</div>
			<common-validation-messages [validatedControl]="ssnValueControl" [associatedField]="fields['value']"></common-validation-messages>
		</div>
	</form>
</div>

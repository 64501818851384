import { Component, Injector } from '@angular/core';
import { ComponentDefinition } from '@common/lib/models/component-definition.model';
import { DynamicFormComponentBase } from '@common/lib/components/dynamic/dynamic-form-component.base';
import { FormField } from '@common/lib/models/form-field.model';
import { LoadingService } from '@experience/app/services/loading.service';

@Component({
	selector: 'common-card',
	templateUrl: './card.component.html',
	styleUrls: ['./card.component.scss']
})
export class CardComponent extends DynamicFormComponentBase<CardComponentFields, CardComponentOptions> {

	public classes: string = null;
	public cardHeader: ComponentDefinition;
	public cardBody: ComponentDefinition;
	public cardActions: ComponentDefinition;
	public cardFooter: ComponentDefinition;

	constructor(
		public injector: Injector,
		public loadingService: LoadingService
	) {
		super(injector);
	}

	onComponentLoaded(): void {
		this.cardHeader = this.getCardComponent('card-header');
		this.cardBody = this.getCardComponent('card-body');
		this.cardActions = this.getCardComponent('card-actions');
		this.cardFooter = this.getCardComponent('card-footer');
		this.classes = this.options?.classes;
	}

	private getCardComponent(cardComponentType: string): ComponentDefinition {
		return this.components.find(component => component.componentType === cardComponentType);
	}
}

interface CardComponentFields extends Record<string, FormField> {

}

interface CardComponentOptions {
	classes: string;
}

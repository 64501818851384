import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ValidationRuleTypes } from '@common/lib/models/enum/validation-rule-types.enum';
import { FormField } from '@common/lib/models/form-field.model';
import { AbstractControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
	selector: 'common-character-length',
	templateUrl: './character-length.component.html',
	styleUrls: ['./character-length.component.scss']
})
export class CharacterLengthComponent implements OnInit, OnDestroy {
	@Input()
	public formField: FormField;

	@Input()
	public validatedControl: AbstractControl;

	@Output()
	public isGuideVisible = new EventEmitter<boolean>();

	public minLengthConstraint: number = 0;
	public minLengthCharactersRemainingMessage: string = null;

	public maxLengthConstraint: number = 0;
	public maxLengthCharactersRemainingMessage: string = null;

	private changeSubscription: Subscription;

	constructor() {
	}

	public ngOnDestroy(): void {
		this.changeSubscription?.unsubscribe();
	}

	public ngOnInit(): void {
		const minLengthValidationRule = this.formField?.validationRules?.find((rule) => rule.type === ValidationRuleTypes.minLength);
		if (minLengthValidationRule) {
			this.minLengthConstraint = +minLengthValidationRule.constraintValue;
		}

		const maxLengthValidationRule = this.formField?.validationRules?.find((rule) => rule.type === ValidationRuleTypes.maxLength);
		if (maxLengthValidationRule) {
			this.maxLengthConstraint = +maxLengthValidationRule.constraintValue;
		}

		this.changeSubscription = this.validatedControl?.valueChanges?.subscribe((value) => this.inputChanged(value));
	}

	private inputChanged(value: string): void {
		this.handleMinLength(value);
		this.handleMaxLength(value);
		this.isGuideVisible.emit(!!this.minLengthCharactersRemainingMessage || !!this.maxLengthCharactersRemainingMessage);
	}

	private handleMinLength(value: string): void {
		if (this.minLengthConstraint <= 0) {
			return;
		}

		const charactersRemaining = this.minLengthConstraint - value.length ?? 0;

		if (charactersRemaining <= 0) {
			this.minLengthCharactersRemainingMessage = '';
		} else {
			this.minLengthCharactersRemainingMessage = `${ charactersRemaining } characters remaining`;
		}
	}

	private handleMaxLength(value: string): void {
		if (this.maxLengthConstraint <= 0) {
			return;
		}

		const charactersRemaining = this.maxLengthConstraint - value.length ?? 0;

		if (charactersRemaining <= 0) {
			this.maxLengthCharactersRemainingMessage = '';
			this.isGuideVisible.emit(false);
		} else {
			this.maxLengthCharactersRemainingMessage = `${ charactersRemaining } characters remaining`;
		}
	}

}

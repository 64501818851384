<div class="text-center mt-2">
	<div class="text-2xl mb-8">{{title$ | async}}</div>
	<div class="text-4xl mb-24">{{userName$ | async }}</div>
	<div class="text-2xl mb-6">Routing Number: {{routingNumber$ | async}}</div>
	<div class="flex justify-center text-2xl mb-2">
		<div class="w-6/12">Account Type</div>
		<div class="w-6/12">Account Number</div>
	</div>
	<div class="flex justify-center text-xl mb-2" *ngFor="let accountInfo of accountInfo$ | async">
		<div class="w-6/12">{{accountInfo.accountType}}</div>
		<div class="w-6/12">{{accountInfo.accountNumber}}</div>
	</div>
</div>

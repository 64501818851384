import { ApplicationInsightsEvent, ApplicationInsightsProviderMapping, TrackerEvent } from '@nbkc/tracker';
import { ApplicationTrackerContext } from '@experience/app/tracking/application-tracker-context';
import { ErrorTypes } from '@common/lib/models/enum/error-types.enum';

export class HandledError extends TrackerEvent<ApplicationTrackerContext>
	implements ApplicationInsightsProviderMapping {
	public name: string = 'Error Handled';
	public rawError: any;
	public errorType: any;

	constructor(type: ErrorTypes, rawError: any) {
		super();
		this.rawError = rawError;
		this.errorType = type;
	}

	public ai(): ApplicationInsightsEvent {
		const event = new ApplicationInsightsEvent();
		event.name = this.name + ' for ' + this.errorType;
		event.data = this.rawError;
		return event;
	}
}

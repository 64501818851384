import { ApplicationInsightsEvent, GoogleTagManagerEvent, TrackerEvent } from '@nbkc/tracker';
import { ApplicationTrackerContext } from '@experience/app/tracking/application-tracker-context';

export class OnlineBankingViewEvent extends TrackerEvent<ApplicationTrackerContext> {
	public name: string = '';

	constructor(params: OnlineBankingViewEventParams) {
		super();
		this.name = params.experienceType;
	}

	public ai(): ApplicationInsightsEvent {
		const event = new ApplicationInsightsEvent();
		event.name = this.name;
		return event;
	}

	public gtm(): GoogleTagManagerEvent {
		const event = new GoogleTagManagerEvent();
		event.event = this.name;
		return event;
	}
}

interface OnlineBankingViewEventParams {
	experienceType: 'bluefin-view-olb-personal' | 'bluefin-view-olb-business';
}
